import Organization from "./Organization";
import TicketComment from "./TicketComment";
import {TicketSeverity} from "./TicketSeverity";
import {TicketState} from "./TicketState";
import User from "./User";
import VulSourceItem from "./VulSourceItem";

class Ticket {
    constructor(
        public id?: string,
        public created?: Date,
        public severity?: TicketSeverity,
        public state?: TicketState,
        public title?: string,
        public organization?: Organization,
        public author?: User,
        public originalItem?: VulSourceItem,
        public relatedItems?: VulSourceItem[],
        public assignees?: User[],
        public description?: string,
        public comments?: TicketComment[],) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const entity = new Ticket();

        entity.id = data.id;
        entity.created = data.created;
        entity.severity = data.severity;
        entity.state = data.state;
        entity.title = data.title;
        entity.description = data.description;
        entity.organization = Organization.fromPOJO(data.organization);
        entity.author = data.author ? User.fromPOJO(data.author) : null;
        entity.originalItem = VulSourceItem.fromPOJO(data.originalItem);
        entity.relatedItems = data.relatedItems ? data.relatedItems.map(item => VulSourceItem.fromPOJO(item)) : null;
        entity.assignees = data.assignees ? data.assignees.map(item => User.fromPOJO(item)) : null;
        entity.comments = data.comments ? data.comments.map(item => TicketComment.fromPOJO(item)) : null;

        return entity;
    }

}

export default Ticket;
