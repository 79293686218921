import {message} from 'antd';
import Axios from "axios";
import * as NProgress from "nprogress";
import 'nprogress/nprogress.css';
import * as React from "react";
import {AppContext} from "../AppContext";
import dayjs from "dayjs";

class AxiosService {

    public static setUpInterceptors(appContext: AppContext) {
        Axios.defaults.baseURL = "/api"
        Axios.defaults.withCredentials = true;
        Axios.defaults.xsrfCookieName = 'User-XSRF-TOKEN';
        Axios.defaults.headers.common['X-Requested-With'] = "XMLHttpRequest";

        if (appContext.publicAccess) {
            Axios.defaults.headers.common['X-Public-Access-User-Id'] = appContext.publicAccessUserId;
        }

        NProgress.configure({showSpinner: false});

        Axios.interceptors.request.use(config => {
            const headers = config.headers || {};

            // @ts-ignore
            config.headers = Object.assign(headers, {
                common: {
                    'Accept-Language': appContext.language,
                    'X-Timezone-Offset': dayjs().utcOffset()
                }
            });

            NProgress.start();

            return config
        });

        Axios.interceptors.response.use(response => {
                NProgress.done();

                if (response.headers['x-sal-jwt-token-expires-at']) {
                    appContext.jwtTokenExpiresAt = new Date(response.headers['x-sal-jwt-token-expires-at']);
                }

                return response
            }, error => {
                const intlMessage = (text, variables?) => text;

                NProgress.done();

                if (error.response) {
                    const ignoreInterceptorHeader = error.response.headers['x-ignore-response-interceptor'];

                    if (ignoreInterceptorHeader && parseInt(ignoreInterceptorHeader, 10) === error.response.status) {
                        // handled in components
                    } else if (error.response.status === 200) {
                        // handled in components
                    } else if (error.response.status === 201) {
                        // handled in components
                    } else if (error.response.status === 202) {
                        // handled in components
                    } else if (error.response.status === 400) {
                        // handled in components
                    } else if (error.response.status === 401) {
                        appContext.user = undefined;
                    } else if (error.response.status === 403) {
                        message.error("Action is forbidden", 5);
                    } else if (error.response.status === 404) {
                        // handled in components
                    } else if (error.response.status === 409) {
                        const messageProducer = (key: string) => {
                            switch (error.response.data) {
                                case "attribute_extraction_definition_vul_source":
                                    return <>Object cannot be deleted because it's used in section <b>Definitions - Attributes</b></>;
                                default:
                                    return <>Object cannot be deleted because it's used</>;
                            }
                        }

                        message.error(messageProducer((error.response.data)), 5);
                    } else if (error.response.status === 500) {
                        message.error("Server error: " + JSON.stringify(error.response.data), 5);
                    } else if (error.response.status === 520) {
                        message.error("This action is allowed only with valid license", 5);
                    } else {
                        message.error("Unexpected response from server", 5);
                    }
                }

                return Promise.reject(error);
            }
        );
    }
}

export default AxiosService;
