import {Feature} from "./Feature";

class Organization {
    constructor(
        public id?: string,
        public name?: string,
        public organizationId?: string,
        public features?: Feature[]) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const organization = new Organization();

        organization.id = data.id;
        organization.name = data.name;
        organization.organizationId = data.organizationId;
        organization.features = data.features;

        return organization;
    }

}

export default Organization;
