import {LoadingOutlined} from "@ant-design/icons";
import Collapse from "@kunukn/react-collapse";
import {Breadcrumb, Button, Card, Descriptions, Form, Input, message, Modal, Select, Spin} from "antd";
import {useForm} from "antd/es/form/Form";
import Password from "antd/es/input/Password";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, RoleServiceContext, UserServiceContext} from "../../Contexts";
import Organization from "../../domain/Organization";
import User from "../../domain/User";
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import * as styles from "./UserDetail.module.css";
import *as globalStyles from "../App.module.css";
import {formatUserRole, UserRole} from "../../domain/UserRole";
import Role from "../../domain/auth/Role";

function UserDetail() {
    const appContext = useContext(AppContextContext);
    const userService = useContext(UserServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const roleService = useContext(RoleServiceContext);
    const navigate = useNavigate();
    const {userId}: any = useParams();
    const [editMode, setEditMode] = useState(false);
    const [changePasswordMode, setChangePasswordMode] = useState(false);
    const [user, setUser] = useState<User>();
    const [editForm] = useForm();
    const [changePasswordForm] = useForm();
    const [roles, setRoles] = useState<Role[]>();

    const [organizations, setOrganizations] = useState<Organization[]>();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    const serverViolationsHolder = new ServerConstraintViolationsHolder();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (appContext.user.isSystemAdmin()) {
            organizationService.getList(new QueryOptions("name", 100, 0)).then(value => {
                setOrganizations(value.data);
            });
        }

        roleService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setRoles(value.data));

        reload();
    }, [])

    return (
        <DocumentTitle title={user?.username}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/users"}>Users</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{user?.username}</Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={!user} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{user?.username}</h1>

                    <div className={globalStyles["common__top-button-bar"]}>
                        <Button onClick={() => setEditMode(!editMode)}>Edit</Button>

                        {!user?.publicAccessAccount && <Button onClick={() => setChangePasswordMode(!changePasswordMode)}>Change password</Button>}

                        {!user?.publicAccessAccount && <Button onClick={event => {
                            Modal.confirm({
                                content: "Do you really want to change this user into public access account? Login with password will be no longer possible and all content accessible to this user will be made public using special URL.",
                                okText: "Change into public access account",
                                cancelText: "Cancel",
                                onOk: () => onUpdatePublicAccessAccount(true)
                            })
                        }}>Change into public access account</Button>}

                        {user?.publicAccessAccount && <Button onClick={event => {
                            Modal.confirm({
                                content: "Do you really want to change this user into regular account? All content currently available using special URL will be inaccessible without login.",
                                okText: "Change into regular account",
                                cancelText: "Cancel",
                                onOk: () => onUpdatePublicAccessAccount(false)
                            })
                        }}>Change into regular account</Button>}

                        <Button danger={true}
                                title={"Delete"}
                                onClick={() => {
                                    Modal.confirm({
                                        content: "Do you really want to delete this user?",
                                        okText: "Delete",
                                        cancelText: "Cancel",
                                        okButtonProps: {danger: true},
                                        onOk: () => onDeleteConfirm(user!)
                                    });
                                }}>Delete</Button>
                    </div>

                    {renderEditForm()}

                    {renderChangePasswordForm()}

                    {user?.publicAccessAccount && <Card size="small" title="Public access URL" className={styles['url-card']}>
                        <p>Content available to this user is publicly accessible using following URL:</p>

                        <p><Link to={user.publicAccessUrl} target={"_blank"}>{user.publicAccessUrl}</Link></p>
                    </Card>}

                    <Descriptions column={1} bordered={true} size="small" className={globalStyles.details}>
                        <Descriptions.Item label={"Name"}>{user?.username}</Descriptions.Item>
                        <Descriptions.Item label={"Type"}>{user?.publicAccessAccount ? 'public access account' : 'regular account'}</Descriptions.Item>

                        {appContext.user.isSystemAdmin() &&
                            <Descriptions.Item label={"Organization"}>{user?.organization?.name}</Descriptions.Item>}
                        {appContext.user.isSystemAdmin() &&
                            <Descriptions.Item label={"Role (legacy)"}>{formatUserRole(user?.role)}</Descriptions.Item>}

                        <Descriptions.Item label={"Roles"}>
                            {user?.roles.map(role => <div><Link to={`/roles/${role.id}`}>{role.name}</Link></div>)}
                        </Descriptions.Item>
                    </Descriptions>

                </Spin>
            </>
        </DocumentTitle>
    )

    function renderEditForm() {
        return (
            <Collapse isOpen={editMode}>
                <h3>Edit of user {user?.username}</h3>

                <Form {...layout} form={editForm}
                      className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`}
                      onFinish={onFinishEdit}>
                    {appContext.user.isSystemAdmin() &&
                        <Form.Item
                            name={"organization"}
                            label={"Organization"}
                            rules={[{required: true, message: "required"}]}>
                            <Select>
                                {organizations && organizations!.map(organization => {
                                    return <Select.Option key={organization?.name}
                                                          value={organization?.id!}>{organization?.name}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    }

                    <Form.Item
                        name={"username"}
                        label={"Username"}
                        rules={[
                            {required: true, message: "username-required"},
                            {
                                validator: serverViolationsHolder.createServerValidator('UNIQUE', 'username', undefined, {compareLowerCaseValues: true}),
                                message: "Username is already used."
                            }
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    {appContext.user.isSystemAdmin() &&
                        <Form.Item
                            name={"role"}
                            label={"Role (legacy)"}
                            rules={[{required: true, message: "Role is required."}]}>
                            <Select
                                options={Object.values(UserRole).map(role => ({
                                    label: formatUserRole(role),
                                    value: role
                                }))}
                            />
                        </Form.Item>
                    }

                    <Form.Item
                        name={"roles"}
                        label={"Roles"}
                        rules={[{required: true, message: "At least one role is required."}]}>
                        <Select
                            mode={"multiple"}
                            options={roles?.map(role => ({
                                label: role.name,
                                value: role.id
                            }))}>
                        </Select>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => setEditMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function renderChangePasswordForm() {
        return (
            <Collapse isOpen={changePasswordMode}>
                <h3>Change password</h3>

                <Form {...layout} form={changePasswordForm}
                      className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`}
                      onFinish={onFinishChangePassword}>
                    <Form.Item
                        name={"password"}
                        label={"Password"}
                        rules={[
                            {required: true, message: "Password-required"},
                            {
                                validator: serverViolationsHolder.createServerValidator('CUSTOM'),
                                message: serverViolationsHolder.violations.constraintViolations.password?.CUSTOM.message
                            }]}>
                        <Password/>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Change password</Button>
                        <Button onClick={() => setChangePasswordMode(false)}>Cancel</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function onFinishEdit(values: any) {
        userService.update(userId!, values)
            .then(
                (id) => {
                    message.success(<>User <b>{user.username}</b> successfully updated.</>);

                    setEditMode(false);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }

    function onFinishChangePassword(values: any) {
        userService.setPassword(userId!, values.password)
            .then(() => {
                    setChangePasswordMode(false);

                    message.success(<>Password of user <b>{user.username}</b> successfully updated.</>);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, changePasswordForm));
    }


    function onDeleteConfirm(user: User) {
        userService.delete(user)
            .then(() => {
                message.success(<>User <b>{user.username}</b> successfully deleted.</>);

                navigate(`/users`)
            });
    }

    function onUpdatePublicAccessAccount(publicAccessAccount: boolean) {
        userService.updatePublicAccessAccount(userId, publicAccessAccount)
            .then(() => {
                if (publicAccessAccount) {
                    message.success(<>User was successfully changed into public access account.</>);
                } else {
                    message.success(<>User was successfully changed into regular account.</>);
                }

                reload();
            })
    }

    function reload() {
        userService.get(userId!).then(user => {
            setUser(user);

            editForm.setFieldsValue({
                ...user,
                organization: user.organization.id,
                roles: user.roles.map(role => role.id)
            });

            changePasswordForm.setFieldsValue({password: undefined});
        });

    }

}

export default UserDetail;
