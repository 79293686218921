
.form-section-title {
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    margin: 0 0 12px;
    border-bottom: 2px solid #bfbfbf;
}

.form-section-title-bigger {
    composes: form-section-title;
    font-size: 18px;
    margin-bottom: 24px;
}

.form-horizontal {
    margin-bottom: 24px;
    max-width: 800px;
}

.form-horizontal :global(.ant-form-item-row) {
    align-items: start;
}

.form-horizontal :global(.ant-form-item) {
    margin: 18px 0;
}

.form-horizontal :global(.ant-form-item-control-input .ant-form-item) {
    margin: 0;
}

.form-horizontal :global(.ant-switch) {
    margin-top: 6px;
}

.form-horizontal :global(.ant-checkbox-group), .form-horizontal :global(.ant-radio-group) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.form-horizontal :global(.ant-radio-group) {
    margin-top: 6px;
}

.form-horizontal :global(.ant-checkbox-wrapper), .form-horizontal :global(.ant-checkbox) {
    display: flex;
}

.form-horizontal :global(.ant-form-item-control) {
    min-height: auto;
}

.form-horizontal :global(.ant-form-item-control-input) {
    min-height: auto;
}

.form-horizontal :global(.ant-form-item-extra) {
    padding-top: 4px;
}

.form-horizontal :global(.ant-form-item-label) {
    padding-top: 6px;
}

.form-horizontal label {
    height: 100%;
}

.form-horizontal :global(.ant-form-item-explain) {
    padding: 4px 0 8px 0;
}

.form-horizontal :global(.ant-input), .form-horizontal :global(.ant-input-wrapper), .form-horizontal :global(.ant-input-password), .form-horizontal :global(.ant-select) {
    max-width: 400px;
}

.form-horizontal .form-buttons :global(.ant-btn) {
    margin-top: 0.5em;
    margin-left: 0.5em;
}

.form-horizontal .form-buttons :global(.ant-btn):first-child {
    margin-left: 0;
}

.list-tag {
    margin-top: 8px;
}

.list-tag :global(.ant-tag) {
    font-size: 11px;
    padding: 0 4px;
}

.heading-tag:global(.ant-tag) {
    vertical-align: middle;
    font-size: 12px;
    padding: 0 4px;
}

.column-tagged {
    display: flex;
    align-items: center;
}

.column-tagged :global(svg.anticon), .column-tagged :global(span.anticon) {
    opacity: 0.8;
    margin-left: 4px;
    font-size: 16px !important;
    color: #515165;
}

.top-button-bar {
    margin-bottom: 1em;
}

.top-button-bar :global(.ant-btn) {
    margin-left: 0.5em;
}

.top-button-bar :global(.ant-btn):first-child {
    margin-left: 0;
}

.details {
    background-color: #fff;
    max-width: 800px;
    margin-bottom: 1.5em;
}

.details :global(.ant-descriptions-item-label) {
    width: 300px;
}

.common__top-button-bar {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}

.common__top-button-bar :global(.ant-btn) {
    margin-left: 0.5em;
}

.common__top-button-bar :global(.ant-btn:first-child), .common__top-button-bar :global(.ant-upload .ant-btn) {
    margin-left: 0;
}

.common__form {
    margin-bottom: 24px;
    max-width: 800px;
}


.common__form-buttons :global(.ant-btn) {
    margin-top: 0.5em;
    margin-left: 0.5em;
}

.common__form-buttons :global(.ant-btn):first-child {
    margin-left: 0;
}

:global(.ant-descriptions) {
    max-width: 800px;
    background: white;
}

pre {
    outline: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
}

.formatted-json :global(.string) {
    color: green;
}

.formatted-json :global(.number) {
    color: darkorange;
}

.formatted-json :global(.boolean) {
    color: blue;
}

.formatted-json :global(.null) {
    color: magenta;
}

.formatted-json :global(.key) {
    color: red;
}

.formatted-json {
    white-space: break-spaces;
}

.ant-btn-icon-only {
    border: none;
    background: none;
    color: #434656;
}

.common_form--change-password {
    dummy: 0;
}

.table-actions {
    dummy: 0;
    vertical-align: center;
}

.common__filter {
    dummy: 0;
}

.common_form--add {
    dummy: 0;
}

.common_form--edit {
    dummy: 0;
}

.common__breadcrumb {
    dummy: 0;
}

.feedback {
    position: absolute;
    left: 84px;
    top: 6px;
}

.markdown p:last-child {
    margin-bottom: 0;
}

.markdown h1, .markdown h2, .markdown h3 {
    margin-bottom: 0.25em;
    margin-top: 0;
}