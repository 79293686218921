export class AppConfig {

    constructor(
        public version?: string,
        public defaultLanguage?: string,
        public fqdn?: string,
        public baseUrl?: string,
        public appName?: string) {
        //
    }

    public static fromPOJO(data: any) {
        const config = new AppConfig();

        config.version = data.version;
        config.defaultLanguage = data.defaultLanguage;
        config.fqdn = data.fqdn;
        config.baseUrl = data.baseUrl;
        config.appName = data.appName;

        return config;
    }

}
