import dayjs from "dayjs";
import SbomComponent from "./SbomComponent";

export enum SbomComponentHealth {
    HEALTHY = "HEALTHY",
    OUT_OF_DATE = "OUT_OF_DATE",
    OBSOLETE = "OBSOLETE"
}

export function evaluateSbomComponentHealth(component: SbomComponent, instant: Date): SbomComponentHealth {
    const publishedAt = dayjs(component.publishedAt);

    if (publishedAt.add(4, 'year').isBefore(instant)) {
        return SbomComponentHealth.OBSOLETE;
    } else if (publishedAt.add(2, 'year').isBefore(instant)) {
        return SbomComponentHealth.OUT_OF_DATE;
    } else {
        return SbomComponentHealth.HEALTHY;
    }
}
