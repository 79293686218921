import * as styles from "./VulSourceItemListTable.module.css";
import {ColumnsType} from "antd/es/table";
import VulSourceItem from "../../domain/VulSourceItem";
import {Badge, Button, Checkbox, ConfigProvider, Dropdown, Form, MenuProps, message, Modal, Table} from "antd";
import {CodeOutlined, FileAddOutlined, HistoryOutlined, ReloadOutlined} from "@ant-design/icons";
import ItemVoteCompact from "./ItemVoteCompact";
import VulSourceItemDescription from "./VulSourceItemDescription";
import {formatDateTime} from "../../utils/FormatUtils";
import * as globalStyles from "../App.module.css";
import React, {useContext, useEffect, useReducer, useRef, useState} from "react";
import {isColumnVisible, TableConfig} from "../tableconfig/TableConfig";
import CreateTicketModal from "../ticket/CreateTicketModal";
import {Link, useNavigate} from "react-router-dom";
import QueryOptions from "../../sal-ui/QueryOptions";
import PagedResult from "../../service/PagedResult";
import {AppContextContext, AttributeDefinitionServiceContext, VulSourceItemServiceContext} from "../../Contexts";
import AttributeDefinition from "../../domain/AttributeDefinition";
import {TableHandler, TableUiConfig} from "../../sal-ui/TableHandler";
import {VulSourceItemChangeType} from "../../domain/VulSourceItemChangeType";
import VulSourceItemChangeLog from "../../domain/VulSourceItemChangeLog";
import VulSourceChangeLogListDrawer from "./VulSourceChangeLogListDrawer";
import {FiExternalLink} from "react-icons/fi";
import SealScore from "./SealScore";
import SourceTag from "./SourceTag";
import {useForm} from "antd/es/form/Form";
import {Permission} from "../../domain/auth/Permission";

interface Props {
    persistentIdent: string;
    items: PagedResult<VulSourceItem>;
    tableHandler: TableHandler;
    tableUiConfig: TableUiConfig;
    setTableUiConfig: (uiConfig: TableUiConfig) => void;
}

function VulSourceItemListTable(props: Props) {
    const appContext = useContext(AppContextContext);
    const vulSourceItemService = useContext(VulSourceItemServiceContext);
    const attributeDefinitionService = useContext(AttributeDefinitionServiceContext);
    const navigate = useNavigate();
    const [createTicketFormVisible, setCreateTicketFormVisible] = useState<boolean>(false);
    const [vulSourceItem, setVulSourceItem] = useState<VulSourceItem>();
    const [attributeDefinitions, setAttributeDefinitions] = useState<AttributeDefinition[]>();
    const [drawerLoading, setDrawerLoading] = useState(false);
    const [drawerChangeLogs, setDrawerChangeLogs] = useState<VulSourceItemChangeLog[]>();
    const [changeLogDrawerOpen, setChangeLogDrawerOpen] = useState(false);
    const [changeLogDrawerItem, setChangeLogDrawerItem] = useState<VulSourceItem>();
    const [extractAndSaveModalOpen, setExtractAndSaveModalOpen] = useState(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const reloadTimerRef = useRef<any>();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        attributeDefinitionService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setAttributeDefinitions(value.data));
    }, [])

    useEffect(() => {
        if (props.tableUiConfig?.autoRefreshEnabled && props.tableUiConfig?.autoRefreshInterval) {
            reloadTimerRef.current && clearInterval(reloadTimerRef.current);

            reloadTimerRef.current = setInterval(props.tableHandler.reload, props.tableUiConfig?.autoRefreshInterval * 1000);
        } else {
            reloadTimerRef.current && clearInterval(reloadTimerRef.current);
        }

        return function cleanup() {
            reloadTimerRef.current && clearInterval(reloadTimerRef.current);
        }
    }, [props.tableUiConfig]);

    const columns: ColumnsType<VulSourceItem> = [
        {
            dataIndex: "id",
            title: "ID",
            width: 80,
            align: "center",
            render: renderId
        },
        {
            dataIndex: ["source", "name"],
            title: "Source",
            className: styles['column-source'],
            align: "center",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: renderSource
        },
        {
            dataIndex: "description",
            title: "Description",
            render: renderDescription
        },
        {
            dataIndex: "attributes",
            title: "Attributes",
            className: styles['column-attributes'],
            render: (value, item: VulSourceItem) => <>
                <VulSourceItemDescription
                    showDescription={false}
                    sourceType={item.source.sourceType}
                    attributes={value}
                    attributeDefinitions={attributeDefinitions}
                    attributeLayout={"vertical"}
                    hideSealScoreTag={true}
                    hideLink={true}
                />
            </>
        },
        {
            dataIndex: "created",
            title: "Created at",
            width: 160,
            align: "center",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: value => formatDateTime(value)
        },
        {
            dataIndex: "lastModified",
            title: "Data modification",
            width: 160,
            align: "center",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: value => formatDateTime(value)
        },
        {
            dataIndex: "lastModifiedAttributes",
            title: "Attributes modification",
            width: 160,
            align: "center",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: value => formatDateTime(value)
        },
        {
            dataIndex: "sourcePublished",
            title: "Published",
            width: 160,
            align: "center",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "descend",
            render: value => formatDateTime(value)
        },
        {
            dataIndex: "sourceLastUpdate",
            title: "Last update",
            width: 160,
            align: "center",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: value => formatDateTime(value)
        },
    ];

    const items: MenuProps['items'] = [
        {
            label: 'Extract attributes',
            title: 'Extract attributes from all items of current filter.',
            key: 'extract-attributes',
        },
    ];

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        if (e.key === "extract-attributes") {
            setExtractAndSaveModalOpen(true);
        }
    };

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <>
            <div className={globalStyles["common__top-button-bar"]}>
                <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={props.tableHandler.reload}/>

                <TableConfig
                    columns={columns}
                    value={props.tableUiConfig}
                    onChange={uiConfig => {
                        props.setTableUiConfig(uiConfig);

                        props.tableHandler.saveUiConfig(uiConfig);
                    }}
                />

                {appContext.user.isSystemAdmin() &&
                    <Dropdown menu={menuProps} trigger={["click"]}>
                        <Button icon={<CodeOutlined/>} className={"btn-seamless"} title={"Special actions"}/>
                    </Dropdown>
                }
            </div>

            <Table className={styles.table}
                   showSorterTooltip={false}
                   loading={props.tableHandler.loading}
                   dataSource={props.items?.data}
                   size="middle"
                   onChange={props.tableHandler.onTableChange}
                   pagination={props.tableHandler.pagination}
                   onRow={onRow}
                   rowKey="id"
                   columns={columns.filter(column => isColumnVisible(props.tableUiConfig.visibleColumns, column))}
            />

            <CreateTicketModal
                visible={createTicketFormVisible}
                onTicketCreate={() => setCreateTicketFormVisible(false)}
                vulSourceItem={vulSourceItem}
                onCancel={() => setCreateTicketFormVisible(false)}
            />

            <ExtractAndSaveAttributesModal
                open={extractAndSaveModalOpen}
                onClose={() => {
                    setExtractAndSaveModalOpen(false);

                    props.tableHandler.reload();
                }}
                itemCount={props.tableHandler.total()}
                rqbQuery={props.tableHandler.queryOptions.rqbQuery}
            />

            <VulSourceChangeLogListDrawer item={changeLogDrawerItem} changeLogs={drawerChangeLogs} open={changeLogDrawerOpen} onClose={() => setChangeLogDrawerOpen(false)}/>
        </>
    )

    function renderId(value: string, item: VulSourceItem) {
        if (item.isReadable(appContext.user)) {
            return <Link to={`/vul-source-items/${value}`}>
                <span title={value}>{value.substring(0, 2) + '...' + value.substring(value.length - 2)}</span>
            </Link>;
        }

        return <span title={value}>{value.substring(0, 2) + '...' + value.substring(value.length - 2)}</span>;
    }

    function renderSource(value: any, vulSourceItem: VulSourceItem) {
        return <Link to={`/vul-sources/${vulSourceItem.source.id}`}>{value}</Link>;
    }

    function onRow(vulSourceItem: VulSourceItem) {
        return {onDoubleClick: () => navigate(`/vul-source-items/${vulSourceItem.id}`)}
    }

    function renderExternalLink(item: VulSourceItem) {
        if (item?.attributes?.['link']) {
            return <Button className={"btn-seamless"} title={"External link"} icon={<FiExternalLink className={"anticon"}/>} onClick={() => window.open(item.attributes['link'], '_blank')}/>;
        }
    }

    function renderDescription(value: any, item: VulSourceItem) {
        return <>
            <div className={styles['description']}>
                <SourceTag source={item?.source}/>

                <SealScore item={item}/>

                {item.changedItem && <Badge count={"Updated"} style={{marginLeft: 12}} color={"blue"} title={`Item created at ${formatDateTime(item.created)}.`}/>}

                <div className={styles['action-bar']}>
                    {renderExternalLink(item)}

                    {appContext.user.hasPermission(Permission.TICKET__MANAGE) &&
                        <Button icon={<FileAddOutlined/>}
                                size={"small"}
                                className={"btn-seamless"}
                                title={"Create ticket"}
                                onClick={() => {
                                    setCreateTicketFormVisible(true);
                                    setVulSourceItem(item);
                                }}/>
                    }

                    {item.changedItem && <>
                        <Button icon={<HistoryOutlined/>}
                                size={"small"}
                                className={"btn-seamless"}
                                title={"Show changes"}
                                disabled={drawerLoading}
                                onClick={() => {
                                    setDrawerLoading(true);

                                    vulSourceItemService.getChangeLogList(item)
                                        .then(changeLogs => {
                                            setDrawerChangeLogs(changeLogs.filter(value => value.type === VulSourceItemChangeType.ATTRIBUTES))
                                            setChangeLogDrawerItem(item);
                                            setChangeLogDrawerOpen(true);
                                        })
                                        .finally(() => setDrawerLoading(false))
                                }}
                        />
                    </>}

                    {item.isReadable(appContext.user) && <ItemVoteCompact onVote={updateItems} item={item}/>}
                </div>
            </div>

            <VulSourceItemDescription
                showAttributes={false}
                sourceType={item.source.sourceType}
                attributes={item.attributes}
                affected={item.affected}
                attributeDefinitions={attributeDefinitions}
            />
        </>;
    }

    function updateItems(item: VulSourceItem) {
        props.items.data.forEach(value => {
            if (value.id === item.id) {
                value.flags = item.flags;
                value.flagStats = item.flagStats;
            }
        });

        forceUpdate();
    }

}

function ExtractAndSaveAttributesModal(props: { open: boolean, itemCount: number, rqbQuery: any, onClose: () => void }) {
    const vulSourceItemService = useContext(VulSourceItemServiceContext);
    const [form] = useForm();
    const [inProgress, setInProgress] = useState(false);

    return (
        <Modal open={props.open}
               title={"Mass extraction of attributes"}
               okText={"Extract"}
               cancelText={"Cancel"}
               okButtonProps={{disabled: inProgress}}
               onOk={() => {
                   form.validateFields().then((values: any) => {
                       const options = QueryOptions.newUnlimitedRqbQueryInstance(props.rqbQuery);

                       setInProgress(true);

                       vulSourceItemService.extractAndSaveAttributes(options, values.skipAuditLogs, values.skipItemChangeLogs, values.skipUpdateFindings)
                           .then(() => {
                               message.success("Attributes extracted successully.");

                               props.onClose();
                           })
                           .finally(() => setInProgress(false));
                   });
               }}
               onCancel={() => props.onClose()}
        >
            Do you really want to extract and save attributes for {props.itemCount} items?

            <ConfigProvider theme={{components: {Form: {itemMarginBottom: 8}}}}>
                <Form form={form} className={globalStyles["common__form"]} labelCol={{span: 16}}>
                    <Form.Item name="skipAuditLogs" label={"Skip audit logs"} valuePropName={"checked"} initialValue={true}>
                        <Checkbox/>
                    </Form.Item>

                    <Form.Item name="skipItemChangeLogs" label={"Skip item change logs"} valuePropName={"checked"} initialValue={true}>
                        <Checkbox/>
                    </Form.Item>

                    <Form.Item name="skipUpdateFindings" label={"Skip update of SBOM component findings"} valuePropName={"checked"} initialValue={true}>
                        <Checkbox/>
                    </Form.Item>

                    <Form.Item name="skipUpdateLastModified" label={"Skip update of last modified attribute"} valuePropName={"checked"} initialValue={true}>
                        <Checkbox/>
                    </Form.Item>
                </Form>
            </ConfigProvider>

        </Modal>
    );
}

export default VulSourceItemListTable;
