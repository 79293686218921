import {DeleteFilled, MinusSquareOutlined, PlusOutlined, PlusSquareOutlined, ReloadOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, Checkbox, message, Modal, Table, Tag} from "antd";
import Column from "antd/es/table/Column";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, AttributeDefinitionServiceContext} from "../../Contexts";
import AttributeDefinition from "../../domain/AttributeDefinition";
import {useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";
import {DocumentTitle} from "../DocumentTitle";
import * as globalStyles from "../App.module.css";
import * as styles from "./AttributeDefinitionList.module.css";
import AttributeExtractionDefinitionList from "../attributeextractiondefinition/AttributeExtractionDefinitionList";
import ReactMarkdown from "react-markdown";

function AttributeDefinitionList() {
    const persistentIdent = 'AttributeDefinitionList';
    const appContext = useContext(AppContextContext);
    const attributeDefinitionService = useContext(AttributeDefinitionServiceContext);
    const navigate = useNavigate();
    const tableHandler = useTableHandler("name", {reloadFunction: reload, persistentIdent});
    const [attributeDefinitions, setAttributeDefinitions] = useState<PagedResult<AttributeDefinition>>();
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        tableHandler.reload();
    }, [])

    const title = "Attribute definitions";

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}
                            items={[
                                {title: appContext.config?.appName},
                                {title}
                            ]}
                />

                <h1>{title}</h1>

                <div className={globalStyles["common__top-button-bar"]}>
                    <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>

                    <Button className={"btn-seamless"} icon={<PlusSquareOutlined/>} title={"Expand all"} onClick={() => setExpandedRowKeys(attributeDefinitions?.data?.map(value => value.id))}/>
                    <Button className={"btn-seamless"} icon={<MinusSquareOutlined/>} title={"Collapse all"} onClick={() => setExpandedRowKeys([])}/>

                    {appContext.user.isSystemAdmin() &&
                        <Button
                            type={"primary"}
                            icon={<PlusOutlined/>}
                            onClick={() => navigate("/attributes/definitions/add")}>
                            Add
                        </Button>
                    }
                </div>

                <Table className={styles.table}
                       showSorterTooltip={false}
                       loading={tableHandler.loading}
                       dataSource={attributeDefinitions?.data}
                       size="middle"
                       onChange={tableHandler.onTableChange}
                       pagination={tableHandler.pagination}
                       expandable={{
                           expandedRowKeys: expandedRowKeys,
                           onExpand: (expanded, record) => {
                               if (expanded) {
                                   setExpandedRowKeys(prevState => [...prevState, record.id]);
                               } else {
                                   setExpandedRowKeys(prevState => prevState.filter(v => v != record.id));
                               }
                           },
                           expandedRowRender: record => (
                               <div className={styles['extraction-definitions']}>
                                   <AttributeExtractionDefinitionList attributeId={record.id} compactReadOnlyView={true}/>
                               </div>
                           )
                       }}
                       onRow={onRow}
                       rowKey="id">

                    <Column dataIndex="name" title={"Name"} sorter={true} sortDirections={["ascend", "descend", "ascend"]} defaultSortOrder={"ascend"} render={renderName}/>
                    <Column dataIndex="priority" title={"Priority"} sorter={true} sortDirections={["ascend", "descend", "ascend"]} width={120} align={"center"}/>
                    <Column dataIndex="valueType" title={"Value type"} sorter={true} sortDirections={["ascend", "descend", "ascend"]} width={120} align={"center"}/>
                    <Column dataIndex="mergeTicket" title={"Link items"} sorter={true} sortDirections={["ascend", "descend", "ascend"]} width={120} align={"center"} render={value => <Checkbox checked={value} disabled={true}/>}/>
                    <Column dataIndex="showTag" title={"Visible"} sorter={true} sortDirections={["ascend", "descend", "ascend"]} width={120} align={"center"} render={value => <Checkbox checked={value} disabled={true}/>}/>

                    {appContext.user.isSystemAdmin() && <Column title={"Actions"} width='100px' render={renderAction} className={globalStyles["table-actions"]}/>}
                </Table>
            </>
        </DocumentTitle>
    )

    function renderName(value: string, definition: AttributeDefinition) {
        return <>
            <div><Link to={`/attributes/definitions/${definition.id}`}>{definition.name}</Link>{appContext.user.isSystemAdmin() && definition.importedAt && <Tag style={{marginLeft: 8}} bordered={false} color={"blue"}>imported</Tag>}</div>

            {definition.description && <ReactMarkdown className={`${globalStyles.markdown} ${styles.description}`} children={definition.description}/>}

            {appContext.user.isSystemAdmin() && definition.tags.length > 0 && <div style={{marginTop: 8}}>{definition.tags?.map(value => <Tag>{value}</Tag>)}</div>}
        </>;
    }

    function renderAction(value: any, attributeDefinition: AttributeDefinition, index: number) {
        return (
            <>
                <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                        title={"Delete"}
                        onClick={() => {
                            Modal.confirm({
                                content: <>Do you really want to delete source <b>{attributeDefinition.name}?</b></>,
                                okText: "Delete",
                                cancelText: "Cancel",
                                onOk: () => onDeleteConfirm(attributeDefinition)
                            });
                        }}/>
            </>
        )
    }

    function onDeleteConfirm(attributeDefinition: AttributeDefinition) {
        attributeDefinitionService.delete(attributeDefinition)
            .then(() => {
                message.success("Vulnerability source deleted.");

                reload();
            });
    }

    function reload() {
        return attributeDefinitionService.getList(tableHandler.queryOptions, true).then(value => {
            tableHandler.updateTotal(value.total);

            setAttributeDefinitions(value);
        });
    }

    function onRow(attributeDefinition: AttributeDefinition, index?: number) {
        return {onDoubleClick: () => navigate(`/attributes/definitions/${attributeDefinition.id}`)}
    }
}

export default AttributeDefinitionList;
