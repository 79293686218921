import {Breadcrumb, Button, Form, Input, message, Select} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, SbomProjectServiceContext} from "../../Contexts";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import * as globalStyles from "../App.module.css";
import Organization from "../../domain/Organization";
import QueryOptions from "../../sal-ui/QueryOptions";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function SbomProjectAdd() {
    const appContext = useContext(AppContextContext);
    const sbomProjectService = useContext(SbomProjectServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const navigate = useNavigate();
    const [form] = useForm();
    const [organizations, setOrganizations] = useState<Organization[]>();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 16},
    };

    const title = "Add new SBOM project";

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (appContext.user.isSystemAdmin()) {
            organizationService.getList(new QueryOptions("name", 100, 0)).then(value => {
                setOrganizations(value.data);
            });
        }
    }, [])

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb
                    items={[
                        {title: appContext.config?.appName},
                        {title: <Link to={"/sbom-projects"}>{"SBOM projects"}</Link>},
                        {title: title}
                    ]}
                />

                <h1>{title}</h1>

                <Form {...layout} form={form} className={`${globalStyles["common__form"]}`} onFinish={onFinish}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                            {validator: serverViolationsHolder.createServerValidator('UNIQUE', 'name, organization_id', undefined, {compareLowerCaseValues: true}), message: "Name is already used."}
                        ]}>
                        <Input autoFocus={true} maxLength={100}/>
                    </Form.Item>

                    {appContext.user.isSystemAdmin() &&
                        <Form.Item
                            name={"organization"}
                            label={"Organization"}
                            rules={[{required: true, message: "Organization is required."}]}>
                            <Select>
                                {organizations && organizations!.map(organization => {
                                    return <Select.Option key={organization?.name} value={organization?.id!}>{organization?.name}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    }

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate("/sbom-projects")}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    )

    function onFinish(values: any) {
        sbomProjectService.add(values)
            .then(
                (id) => {
                    message.success(<>SBOM project <b>{values.name}</b> successfully added.</>);

                    navigate(`/sbom-projects/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }
}

export default SbomProjectAdd;
