import Axios from "axios";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";
import SbomProjectReportDefinition from "../domain/SbomProjectReportDefinition";

class SbomProjectReportDefinitionService {

    readonly basePath = "/sbom-project-report-definitions";

    public add(definition: SbomProjectReportDefinition): Promise<string> {
        return Axios.post(`${this.basePath}`, definition).then(value => value.data.id)
    }

    public update(id: string, VulReportDefinition: SbomProjectReportDefinition): Promise<void> {
        return Axios.put(`${this.basePath}/${id}`, VulReportDefinition);
    }

    public get(id: string): Promise<SbomProjectReportDefinition> {
        return Axios.get(`${this.basePath}/${id}`)
            .then(value => SbomProjectReportDefinition.fromPOJO(value.data))
    }

    public delete(definition: SbomProjectReportDefinition): Promise<void> {
        return Axios.delete(`${this.basePath}/${definition.id}`);
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<SbomProjectReportDefinition>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: SbomProjectReportDefinition[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(SbomProjectReportDefinition.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

}

export default SbomProjectReportDefinitionService;
