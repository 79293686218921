import VulSource from "./VulSource";
import VulSourceItemChangeLog from "./VulSourceItemChangeLog";
import VulSourceItemFlags from "./VulSourceItemFlags";
import VulSourceItemFlagStats from "./VulSourceItemFlagStats";
import {ResourceOwnerType} from "./ResourceOwnerType";
import {Permission} from "./auth/Permission";
import User from "./User";

class VulSourceItem {
    constructor(
        public id?: string,
        public created?: Date,
        public lastModified?: Date,
        public lastModifiedAttributes?: Date,
        public sourcePublished?: Date,
        public sourceLastUpdate?: Date,
        public changedItem?: boolean, // jen pro zobrazení reportu
        public guid?: any,
        public source?: VulSource,
        public jsonData?: any,
        public attributes?: any,
        public affected?: any,
        public changeLogs?: VulSourceItemChangeLog[],
        public flags?: VulSourceItemFlags,
        public flagStats?: VulSourceItemFlagStats) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const entity = new VulSourceItem();

        entity.id = data.id;
        entity.created = data.created;
        entity.lastModified = data.lastModified;
        entity.lastModifiedAttributes = data.lastModifiedAttributes;
        entity.sourcePublished = data.sourcePublished;
        entity.sourceLastUpdate = data.sourceLastUpdate;
        entity.changedItem = data.changedItem;
        entity.guid = data.guid;
        entity.source = VulSource.fromPOJO(data.source);
        entity.jsonData = data.jsonData ? JSON.parse(data.jsonData) : null;
        entity.attributes = data.attributes ? JSON.parse(data.attributes) : null;
        entity.affected = data.affected;
        entity.changeLogs = data.changeLogs ? data.changeLogs.map(item => VulSourceItemChangeLog.fromPOJO(item)) : null;
        entity.flags = VulSourceItemFlags.fromPOJO(data.flags);
        entity.flagStats = VulSourceItemFlagStats.fromPOJO(data.flagStats);
        return entity;
    }

    public static findOlderChangeLog(changeLogs: VulSourceItemChangeLog[], changeLog: VulSourceItemChangeLog) {
        let logFound = false;

        for (const log of changeLogs) {
            if (logFound && changeLog.type === log.type) {
                return log;
            }

            if (!logFound && log.id === changeLog.id) {
                logFound = true;
            }
        }
    }

    public findOlderChangeLog(changeLog: VulSourceItemChangeLog) {
        return VulSourceItem.findOlderChangeLog(this.changeLogs, changeLog);
    }

    public isReadable(user: User): boolean {
        return (this.source.owner === ResourceOwnerType.ORGANIZATION && user.hasPermission(Permission.SOURCE__READ_ORG)) || (this.source.owner === ResourceOwnerType.SYSTEM && user.hasPermission(Permission.SOURCE__READ_SEAL));
    }

}

export default VulSourceItem;
