import Axios from "axios";
import AttributeDefinition from "../domain/AttributeDefinition";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";

class AttributeDefinitionService {

    readonly basePath = "/attribute-definitions";

    public add(definition: AttributeDefinition): Promise<string> {
        return Axios.post(`${this.basePath}`, definition).then(value => value.data.id)
    }

    public update(id: string, definition: AttributeDefinition): Promise<void> {
        return Axios.put(`${this.basePath}/${id}`, definition);
    }

    public get(id: string): Promise<AttributeDefinition> {
        return Axios.get(`${this.basePath}/${id}`)
            .then(value => AttributeDefinition.fromPOJO(value.data))
    }

    public delete(definition: AttributeDefinition): Promise<void> {
        return Axios.delete(`${this.basePath}/${definition.id}`);
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<AttributeDefinition>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: AttributeDefinition[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(AttributeDefinition.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }


}

export default AttributeDefinitionService;
