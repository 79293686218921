import Axios, {AxiosResponse} from "axios";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";
import SbomComponent from "../domain/SbomComponent";
import SbomComponentFinding from "../domain/SbomComponentFinding";

class SbomComponentService {

    public evaluateComponents(sbomProjectVersionId: string) {
        return Axios.post(`/sbom-project-versions/${sbomProjectVersionId}/evaluate`);
    }

    public importCycloneDxJsonBom(sbomProjectVersionId: string, sbom: string, type: string) {
        return Axios.put(`/sbom-project-versions/${sbomProjectVersionId}/sbom`, sbom, {headers: {'Content-Type': type}});
    }

    public getUnovisGraph(sbomComponentId: string): Promise<AxiosResponse<any>> {
        return Axios.get(`/sbom-components/${sbomComponentId}/unovis-graph`);
    }

    public updateFinding(findingId: string, finding: SbomComponentFinding) {
        return Axios.put(`/sbom-component-findings/${findingId}`, finding);
    }

    public getFindings(sbomComponentId: string): Promise<SbomComponentFinding[]> {
        return Axios.get(`/sbom-components/${sbomComponentId}/findings`)
            .then(value => {
                const objectList: SbomComponentFinding[] = [];

                value.data.forEach((objectJson: object) => {
                    objectList.push(SbomComponentFinding.fromPOJO(objectJson));
                });

                return objectList;
            })
    }

    public getList(sbomProjectVersionId: string, queryOptions: QueryOptions): Promise<PagedResult<SbomComponent>> {
        return Axios.get(`/sbom-project-versions/${sbomProjectVersionId}/components?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: SbomComponent[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(SbomComponent.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public getListAll(queryOptions: QueryOptions): Promise<PagedResult<SbomComponent>> {
        return Axios.get(`/sbom-components?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: SbomComponent[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(SbomComponent.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public getLicenses(): Promise<string[]> {
        return Axios.get("/sbom-components/licenses").then(response => response.data);
    }

}

export default SbomComponentService;
