.filter {
    max-width: 1200px;
}

.table {
    max-width: 1200px;
}

.description {
    margin-top: 8px;
    font-size: 12px;
}

.query {
    margin: 0 96px 8px 8px;
}

.query h2 {
    margin-top: 0;
}
