.diff pre {
    outline: 0;
}

.drawer :global(.ant-drawer-content-wrapper) {
    width: auto !important;
    min-width: 600px;
    max-width: 1100px;
}

.timestamps {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.timestamps span {
    flex: 1;
    margin-bottom: 8px;
}