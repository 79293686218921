import {Button, Col, Form, Input, Layout, message, Row} from "antd";
import * as styles from "./LoginPage.module.css";
import {useContext} from "react";
import {AppContextContext, AuthServiceContext} from "../Contexts";

function LoginPage() {
    const appContext = useContext(AppContextContext);
    const authService = useContext(AuthServiceContext);

    return (
        <Layout className={styles.layout}>
            <Layout.Header className={styles.header}>
                <div className={styles.logo}>
                    <img src="/img/logo.svg" alt={"SEAL"}/>
                </div>
            </Layout.Header>
            <Layout.Content>
                <Row align={"middle"}>
                    <Col span={6} offset={9} className={styles.form}>
                        <h1>SEAL Login</h1>

                        <Form layout={"vertical"} onFinish={onFinish}>
                            <Form.Item
                                name={"username"}
                                label={"Username"}
                                requiredMark={"optional"}
                                rules={[{required: true}]}>
                                <Input autoFocus={true}/>
                            </Form.Item>

                            <Form.Item
                                name={"password"}
                                label={"Password"}
                                requiredMark={"optional"}
                                rules={[{required: true}]}>
                                <Input.Password/>
                            </Form.Item>

                            <Form.Item>
                                <Button type={"primary"} htmlType={"submit"}>Log in</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Layout.Content>
            <Layout.Footer className={styles.footer}>
                <a href="https://www.sonpo.cz" target="_blank" rel="noopener noreferrer">SONPO, a.s. © 2024</a>
            </Layout.Footer>
        </Layout>
    );

    function onFinish(values: any) {
        authService.authenticate(values.username, values.password)
            .then(
                () => authService.getLoggedInUser().then(value => appContext.user = value),
                () => {
                    message.error("Invalid username or password.");
                });
    }

}

export default LoginPage;