.table, .filter, .details {
    max-width: 1600px;
}

.filter {
    margin-top: 1em;
}

.button-bar :global(.ant-upload) :global(.ant-btn) {
    margin-left: 0;
}

.column-status {
    min-width: 120px;
}

.column-status :global(.ant-tag) {
    margin: 0;
    font-size: 12px;
    padding: 2px 4px;
    border-radius: 4px;
}

.analysis-arrow {
    font-size: 14px;
    vertical-align: middle;
    margin: 8px;
}

.component-tag {
    cursor: pointer;
    margin-left: 8px;
    padding: 0 4px;
}

.statistics {
    display: inline-block;
    margin-left: 16px;
    vertical-align: text-top;
}
