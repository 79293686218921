.name-container {
    display: flex;
    gap: 8px;
}

.name {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.description {
    font-size: 12px;
    opacity: 0.8;
    font-weight: normal;
}
