import Organization from "./Organization";
import {ResourceOwnerType} from "./ResourceOwnerType";
import SoftwareLicense from "./SoftwareLicense";

class SoftwareLicensePolicy {

    constructor(
        public id?: string,
        public name?: string,
        public categories?: string[],
        public licenseKeys?: string[],
        public licenses?: SoftwareLicense[],
        public owner?: ResourceOwnerType,
        public organization?: Organization) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const policy = new SoftwareLicensePolicy();

        policy.id = data.id;
        policy.name = data.name;
        policy.categories = data.categories;
        policy.licenseKeys = data.licenseKeys;
        policy.licenses = (data.licenses) ? data.licenses.map(license => SoftwareLicense.fromPOJO(license)) : [];
        policy.owner = data.owner;
        policy.organization = (data.organization) ? Organization.fromPOJO(data.organization) : undefined;

        return policy;
    }

}

export default SoftwareLicensePolicy;
