import VulReportDefinition from "./VulReportDefinition";
import VulView from "./VulView";
import Organization from "./Organization";

class VulReport {
    constructor(
        public id?: string,
        public name?: string,
        public organization?: Organization,
        public timestampFrom?: Date,
        public timestampTo?: Date,
        public timeZone?: string,
        public generatedAt?: Date,
        public definition?: VulReportDefinition,
        public vulViews?: VulView[]) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const report = new VulReport();

        report.id = data.id;
        report.name = data.name;
        report.organization = (data.organization) ? Organization.fromPOJO(data.organization) : undefined;
        report.timestampFrom = new Date(data.timestampFrom);
        report.timestampTo = new Date(data.timestampTo);
        report.timeZone = data.timeZone;
        report.generatedAt = new Date(data.generatedAt);
        report.definition = VulReportDefinition.fromPOJO(data.definition);
        report.vulViews = (data.vulViews) ? data.vulViews.map(value => VulView.fromPOJO(value)) : [];

        return report;
    }

}

export default VulReport;
