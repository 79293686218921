import {LoadingOutlined} from "@ant-design/icons";
import Collapse from "@kunukn/react-collapse";
import {Breadcrumb, Button, Descriptions, Form, Input, message, Modal, Select, Spin} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext} from "../../Contexts";
import Organization from "../../domain/Organization";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import "./OrganizationDetail.module.css";
import *as globalStyles from "../App.module.css";
import {Feature, formatFeature} from "../../domain/Feature";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function OrganizationDetail() {
    const appContext = useContext(AppContextContext);
    const organizationService = useContext(OrganizationServiceContext);
    const navigate = useNavigate();
    const {organizationId}: any = useParams();
    const [editMode, setEditMode] = useState(false);
    const [organization, setOrganization] = useState<Organization>();
    const [editForm] = useForm();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        reload();

    }, [])

    return (
        <DocumentTitle title={organization?.name}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/organizations"}>Organizations</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{organization?.name}</Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={!organization} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{organization?.name}</h1>

                    <div className={globalStyles["common__top-button-bar"]}>
                        <Button onClick={() => setEditMode(!editMode)}>Edit</Button>

                        <Button danger={true}
                                title={"Delete"}
                                onClick={() => {
                                    Modal.confirm({
                                        content: "Do you really want to delete this organization?",
                                        okText: "Delete",
                                        cancelText: "Cancel",
                                        okButtonProps: {danger: true},
                                        onOk: () => onDeleteConfirm(organization!)
                                    });
                                }}>Delete</Button>
                    </div>

                    {renderEditForm()}

                    <Descriptions column={1} bordered={true} size="small" className={globalStyles.details}>
                        <Descriptions.Item label={"Name"}>{organization?.name}</Descriptions.Item>
                        <Descriptions.Item label={"Organization ID"}>{organization?.organizationId}</Descriptions.Item>
                        <Descriptions.Item label={"Features"}>
                            {organization?.features.map(feature => <div>{formatFeature(feature)}</div>)}
                        </Descriptions.Item>
                    </Descriptions>

                </Spin>
            </>
        </DocumentTitle>
    )

    function renderEditForm() {
        return (
            <Collapse isOpen={editMode}>
                <h3>Edit of organization {organization?.name}</h3>

                <Form {...layout} form={editForm} className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}` } onFinish={onFinishEdit}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                            {validator: serverViolationsHolder.createServerValidator('UNIQUE', 'name', undefined, {compareLowerCaseValues: true}), message: "Name is already used."}
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"organizationId"}
                        label={"Organization ID"}
                        rules={[
                            {required: true, message: "Organization ID is required."},
                            {validator: serverViolationsHolder.createServerValidator('UNIQUE', 'organizationId', undefined, {compareLowerCaseValues: true}), message: "Organizaton ID is already used."}
                        ]}>
                        <Input  maxLength={50}/>
                    </Form.Item>

                    <Form.Item
                        name={["features"]}
                        label={"Features"}
                        rules={[{required: true, message: "Please select at least one feature."}]}>
                        <Select
                            mode="multiple"
                            allowClear
                            options={Object.values(Feature).map(feature => ({
                                label: formatFeature(feature),
                                value: feature
                            }))}
                        />
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => setEditMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function onFinishEdit(values: any) {
        organizationService.update(organizationId!, values)
            .then(
                (id) => {
                    message.success(<>Organization <b>{values.name}</b> successfully updated.</>);

                    setEditMode(false);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }

    function onDeleteConfirm(organization: Organization) {
        organizationService.delete(organization)
            .then(() => {
                message.success(<>Organization <b>{organization.name}</b> successfully deleted.</>);

                navigate(`/organizations`)
            });
    }

    function reload() {
        organizationService.get(organizationId!).then(organization => {
            setOrganization(organization);

            editForm.setFieldsValue(organization);
        });
    }

}

export default OrganizationDetail;
