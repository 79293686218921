import * as styles from "./ItemAttribute.module.css";
import {message, Tag} from "antd";
import React from "react";
import {Link} from "react-router-dom";
import {FiExternalLink} from "react-icons/fi";
import CopyToClipboard from "react-copy-to-clipboard";
import {BsSearch} from "react-icons/bs";
import {onlyText} from "react-children-utilities";

function AttributeBox(props: { value: string | React.ReactNode, href?: string, showSearch?: boolean, clipboardText?: string }) {
    const tag = <Tag color={"geekblue"} className={styles.container}>
        {props.value}
        {(props.showSearch === undefined || props.showSearch === true) && <Link to={`/vul-source-items?${createFilterParam(props.value)}`} target={"_blank"}>
            <BsSearch className={"anticon"} title={"Search in items"}/>
        </Link>}
        {props.href && <a href={props.href} target={"_blank"}>
            <FiExternalLink className={"anticon"} title={"Open external link"}/>
        </a>}
    </Tag>

    if (props.clipboardText) {
        return <CopyToClipboard text={props.clipboardText} onCopy={text => message.info(<>Value <b>{text}</b> copied to clipboard.</>)}>
            <span className={styles.clipboardable}>{tag}</span>
        </CopyToClipboard>;
    } else {
        return tag;
    }
}

function createFilterParam(value: React.ReactNode) {
    const textValue = onlyText(value);

    const filter: any = {
        typeHints: {},
        values: {
            [textValue?.startsWith("CVE-") ? 'cveId' : 'attributeValue']: textValue
        }
    }

    const searchParams = new URLSearchParams();

    searchParams.set("filter", window.btoa(JSON.stringify(filter)))
    searchParams.set("$readOnly", "");

    return searchParams.toString();
}

export default AttributeBox;