import AttributeExtractionDefinition from "./AttributeExtractionDefinition";
import {AttributeValueType} from "./AttributeValueType";
import TicketAttributeRule from "./TicketAttributeRule";
import {AttributeValueTransformerType} from "./AttributeValueTransformerType";

class AttributeDefinition {
    constructor(
        public id?: string,
        public name?: string,
        public description?: string,
        public priority?: number,
        public valueType?: AttributeValueType,
        public attributeValueTransformer?: AttributeValueTransformerType,
        public mergeTicket?: boolean,
        public showTag?: boolean,
        public importedAt?: Date,
        public extractionDefinitions?: AttributeExtractionDefinition[],
        public ticketAttributeRules?: TicketAttributeRule[],
        public tags?: string[]) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const attribute = new AttributeDefinition();

        attribute.id = data.id;
        attribute.name = data.name;
        attribute.description = data.description;
        attribute.priority = data.priority;
        attribute.valueType = data.valueType;
        attribute.attributeValueTransformer = data.attributeValueTransformer;
        attribute.mergeTicket = data.mergeTicket;
        attribute.showTag = data.showTag;
        attribute.importedAt = (data.importedAt) ? new Date(data.importedAt) : undefined;
        attribute.extractionDefinitions = data.extractionDefinitions && data.extractionDefinitions.map(extractionDefinition => AttributeExtractionDefinition.fromPOJO(extractionDefinition));
        attribute.ticketAttributeRules = data.ticketAttributeRules && data.ticketAttributeRules.map(rules => TicketAttributeRule.fromPOJO(rules));
        attribute.tags = data.tags;

        return attribute;
    }


}

export default AttributeDefinition;
