import {FormInstance} from "antd/lib/form";
import moment from "moment";
import dayjs from "dayjs";

interface PersistentData {
    typeHints: any,
    values: any
}

export class FormPersister {

    constructor(public ident: string) {
        //
    }

    public resetState(form: FormInstance, onFinishFilter: (values: any) => any) {
        form.resetFields();

        form.validateFields().then(values => {
            this.saveState(values);

            onFinishFilter(values);
        });
    }

    public saveState(values: any) {
        const data: PersistentData = {
            typeHints: {},
            values
        }

        for (const key of Object.keys(values)) {
            if (moment.isMoment(values[key])) {
                data.typeHints[key] = 'moment';
            } else if (dayjs.isDayjs(values[key])) {
                data.typeHints[key] = 'dayjs';
            }
        }

        localStorage.setItem(this.getQualifiedIdent(), JSON.stringify(data));
    }

    public loadState(form: FormInstance, onFinishFilter: (values: any) => any, serializedData?: any) {
        if (serializedData === undefined) {
            serializedData = localStorage.getItem(this.getQualifiedIdent());
        }

        if (serializedData === null) {
            form.validateFields().then(onFinishFilter);

            return;
        }

        const data: PersistentData = JSON.parse(serializedData);

        for (const key of Object.keys(data.typeHints)) {
            if (data.typeHints[key] === 'moment') {
                data.values[key] = moment(data.values[key]);
            } else if (data.typeHints[key] === 'dayjs') {
                data.values[key] = dayjs(data.values[key]);
            }
        }

        form.setFieldsValue(data.values);

        onFinishFilter(data.values);
    }

    public loadValues(serializedData?: any): any {
        if (serializedData === undefined) {
            serializedData = localStorage.getItem(this.getQualifiedIdent());
        }

        if (serializedData === null) {
            return {};
        }

        const data: PersistentData = JSON.parse(serializedData);

        for (const key of Object.keys(data.typeHints)) {
            if (data.typeHints[key] === 'moment') {
                data.values[key] = moment(data.values[key]);
            } else if (data.typeHints[key] === 'dayjs') {
                data.values[key] = dayjs(data.values[key]);
            }
        }

        return data.values;
    }

    private getQualifiedIdent() {
        return `Seal.Admin.Form.${this.ident}`;
    }

}
