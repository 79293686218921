"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var locale = {
  // Options
  items_per_page: '/ strana',
  jump_to: 'Přejít',
  jump_to_confirm: 'potvrdit',
  page: '',
  // Pagination
  prev_page: 'Předchozí strana',
  next_page: 'Následující strana',
  prev_5: 'Předchozích 5 stran',
  next_5: 'Následujících 5 stran',
  prev_3: 'Předchozí 3 strany',
  next_3: 'Následující 3 strany',
  page_size: 'velikost stránky'
};
var _default = exports.default = locale;