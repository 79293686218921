.filter {
    dummy: 0;
}

.item-table {
    width: 1200px;
}

.timeline {
    width: 1200px;
    padding: 32px;
    background-color: white;
    padding-bottom: 0px;
}

.column-source {
    min-width: 200px;
}
