import Organization from "./Organization";
import {TicketSeverity} from "./TicketSeverity";
import User from "./User";
import VulSourceItem from "./VulSourceItem";

class TicketComment {
    constructor(
        public id?: string,
        public created?: Date,
        public content?: string,
        public user?: User) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const entity = new TicketComment();

        entity.id = data.id;
        entity.created = data.created;
        entity.content = data.content;
        entity.user = User.fromPOJO(data.user);

        return entity;
    }

}

export default TicketComment;
