.filter {
    max-width: 1800px;
}

.table {
    max-width: 1800px;
}

.column-name {
    min-width: 160px;
}