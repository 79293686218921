.table {
    max-width: 1500px;
}

.column-name {
    min-width: 160px;
}

.analysis-arrow {
    font-size: 14px;
    vertical-align: middle;
    margin: 8px;
}
