import {DeleteFilled, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, Checkbox, message, Modal, Space, Table, Tabs, Tag} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, VulSourceServiceContext} from "../../Contexts";
import {ResourceOwnerType} from "../../domain/ResourceOwnerType";
import VulSource from "../../domain/VulSource";
import {useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";
import {formatDateTime} from "../../utils/FormatUtils";
import {DocumentTitle} from "../DocumentTitle";
import * as styles from "./VulSourceList.module.css";
import * as globalStyles from "../App.module.css";
import * as tackStyles from "../vulsourceitem/Tack.module.css";
import {formatVulSourceType} from "../../domain/VulSourceType";
import {ColumnsType} from "antd/es/table";
import ReactMarkdown from "react-markdown";
import {GetComponentProps} from "rc-table/lib/interface";
import {Permission} from "../../domain/auth/Permission";
import {formatVulSourceHealth, VulSourceHealth} from "../../domain/VulSourceHealth";

function VulSourceList() {
    const appContext = useContext(AppContextContext);
    const vulSourceService = useContext(VulSourceServiceContext);
    const navigate = useNavigate();
    const [tableStateId, setTableStateId] = useState(crypto.randomUUID());

    const title = "Vulnerability sources";

    const columns: ColumnsType<VulSource> = [
        {
            dataIndex: "name",
            title: "Name",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "ascend",
            className: styles['column-name'],
            render: renderName
        },
        {
            dataIndex: "sourceType",
            title: "Type",
            width: 200,
            align: "center",
            render: formatVulSourceType
        },
        {
            dataIndex: "itemCount",
            title: "Items",
            width: 100,
            align: "center",
            render: renderItemCount,
        },
        {
            dataIndex: ["organization", "name"],
            key: "organization",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            title: "Organization",
            align: "left",
            render: renderOrganization
        },
        {
            dataIndex: "enabled",
            title: "Enabled",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            width: 80,
            align: "center",
            render: value => <Checkbox checked={value} disabled={true}/>
        },
        {
            dataIndex: "lastRun",
            title: "Last collection",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            width: 150,
            align: "left",
            render: value => formatDateTime(value)
        },
        {
            dataIndex: "latestItemPublishedAt",
            title: "Latest item published at",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            width: 150,
            align: "left",
            render: value => formatDateTime(value)
        }
    ];

    if (appContext.user.hasPermission(Permission.SOURCE__MANAGE_ORG)) {
        columns.push(
            {
                title: "Actions",
                key: "actions",
                width: 100,
                className: globalStyles["table-actions"],
                render: renderAction
            }
        );
    }

    const orgTabItem = {
        key: "org",
        label: "Organization",
        children: <OrgSourcesTable onRow={onRow} columns={columns} tableStateId={tableStateId}/>
    };

    const systemTabItem = {
        key: "system",
        label: "System",
        children: <SystemSourcesTable onRow={onRow} columns={columns} tableStateId={tableStateId}/>
    };

    const allTabItem = {
        key: "all",
        label: "All sources",
        children: <AllSourcesTable onRow={onRow} columns={columns} tableStateId={tableStateId}/>
    };

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}
                            items={[
                                {title: appContext.config?.appName},
                                {title}
                            ]}
                />

                <h1>{title}</h1>

                {(appContext.user.isSystemAdmin() || appContext.user.hasPermission(Permission.SOURCE__MANAGE_ORG)) &&
                    <div className={globalStyles["common__top-button-bar"]}>
                        {appContext.user.hasPermission(Permission.SOURCE__MANAGE_ORG) &&
                            <Button type={"primary"}
                                    icon={<PlusOutlined/>}
                                    onClick={() => navigate("/vul-sources/add")}>Add</Button>
                        }

                        {appContext.user.isSystemAdmin() && <>
                            <Button onClick={() => {
                                Modal.confirm({
                                    content: "Do you really want to collect vulnerability items from all enabled sources?",
                                    okText: "Collect",
                                    cancelText: "Cancel",
                                    onOk: () => onCollectAllConfirm()
                                });
                            }}>
                                Collect all
                            </Button>

                            <Button onClick={() => {
                                Modal.confirm({
                                    content: "Do you really want to update vulnerability items of all sources in OpenSearch?",
                                    okText: "Update",
                                    cancelText: "Cancel",
                                    onOk: () => onUpdateAllSourcesInOpenSearch()
                                });
                            }}>
                                Update in OpenSearch
                            </Button>
                        </>}
                    </div>
                }

                <Tabs items={getTabItems()}/>
            </>
        </DocumentTitle>
    )

    function renderOrganization(value: any, source: VulSource) {
        if (source.owner === ResourceOwnerType.SYSTEM) {
            return <i>System source</i>;
        } else {
            return value;
        }
    }

    function renderItemCount(value: any, source: VulSource) {
        const filter: any = {
            typeHints: {},
            values: {
                sources: [source.id]
            }
        }

        const searchParams = new URLSearchParams();

        searchParams.set("filter", window.btoa(JSON.stringify(filter)))

        return <a href={`vul-source-items?${searchParams.toString()}`} target={"_blank"}>{value}</a>;
    }

    function renderName(value: any, source: VulSource, index: number) {
        const tackStyle =
            (!source.enabled) ? 'tack-grey' : (source.health === VulSourceHealth.HEALTHY)
                ? 'tack-green' : (source.health === VulSourceHealth.LAST_RUN_OUT_OF_DATE)
                    ? 'tack-red' : (source.health === VulSourceHealth.LATEST_ITEM_PUBLISHED_AT_OUT_OF_DATE)
                        ? 'tack-orange' : 'tack-grey';

        return <>
            <div className={`${tackStyles.tack} ${tackStyles[tackStyle]}`}
                 title={(!source.enabled) ? 'health not available for disabled sources' : formatVulSourceHealth(source.health)}
            />

            <div><Link to={`/vul-sources/${source.id}`}>{value}</Link>{appContext.user.isSystemAdmin() && source.importedAt && <Tag style={{marginLeft: 8}} bordered={false} color={"blue"}>imported</Tag>}</div>

            {source.description && <ReactMarkdown className={`${globalStyles.markdown} ${styles.description}`} children={source.description}/>}

            {appContext.user.isSystemAdmin() && source.tags.length > 0 && <div style={{marginTop: 8}}>{source.tags?.map(value => <Tag>{value}</Tag>)}</div>}
        </>;
    }

    function renderAction(value: any, vulSource: VulSource, index: number) {
        return (
            <Space>
                {(appContext.user.isSystemAdmin() || vulSource.organization?.id === appContext.user.organization?.id) &&
                    <Button icon={<ReloadOutlined/>} className={"ant-btn-icon-only"}
                            title={"Run"}
                            onClick={() => {
                                Modal.confirm({
                                    content: "Do you really want to run collection for this source?",
                                    okText: "Run",
                                    cancelText: "Cancel",
                                    onOk: () => onRunConfirm(vulSource)
                                });
                            }}/>
                }

                {(vulSource.owner !== ResourceOwnerType.SYSTEM || appContext.user.isSystemAdmin()) &&
                    <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                            title={"Delete"}
                            onClick={() => {
                                Modal.confirm({
                                    content: <>Do you really want to delete source <b>{vulSource.name}</b>?</>,
                                    okText: "Delete",
                                    cancelText: "Cancel",
                                    onOk: () => onDeleteConfirm(vulSource)
                                });
                            }}/>
                }
            </Space>
        )
    }

    function onDeleteConfirm(vulSource: VulSource) {
        vulSourceService.delete(vulSource)
            .then(() => {
                message.success(<>Source <b>{vulSource.name}</b> successfully deleted.</>);

                setTableStateId(crypto.randomUUID());
            });
    }

    function onRunConfirm(vulSource: VulSource) {
        vulSourceService.run(vulSource)
            .then(() => {
                message.success("Collection of vulnerability items started.");
            });
    }

    function onCollectAllConfirm() {
        vulSourceService.runAll()
            .then(() => {
                message.success("Collection from all enabled sources successfully queued.");
            });
    }

    function onUpdateAllSourcesInOpenSearch() {
        vulSourceService.updateAllSourcesInOpenSearch()
            .then(() => {
                message.success("Vulnerability items of all sources updated in OpenSearch successfully.");
            });
    }

    function onRow(vulSource: VulSource) {
        return {onDoubleClick: () => navigate(`/vul-sources/${vulSource.id}`)}
    }

    function getTabItems() {
        if (appContext.user.isSystemAdmin()) {
            return [systemTabItem, orgTabItem, allTabItem];
        }

        if (appContext.user.hasPermission(Permission.SOURCE__READ_SEAL) && appContext.user.hasPermission(Permission.SOURCE__READ_ORG)) {
            return [orgTabItem, systemTabItem, allTabItem];
        }

        if (appContext.user.hasPermission(Permission.SOURCE__READ_SEAL)) {
            return [systemTabItem];
        }

        if (appContext.user.hasPermission(Permission.SOURCE__READ_ORG)) {
            return [orgTabItem];
        }

        return [];
    }

}

interface TableProps {
    onRow: GetComponentProps<VulSource>;
    columns: ColumnsType<VulSource>;
    tableStateId: string;
}

function OrgSourcesTable(props: TableProps) {
    const appContext = useContext(AppContextContext);
    const vulSourceService = useContext(VulSourceServiceContext);
    const [sources, setSources] = useState<PagedResult<VulSource>>();

    const tableHandler = useTableHandler("name", {
        reloadFunction: reload,
        persistentIdent: 'OrgVulSourceList',
        fixedRqbQuery: {
            combinator: "and",
            rules: [{
                field: "owner",
                operator: "ne",
                value: ResourceOwnerType.SYSTEM
            }]
        }
    });

    useEffect(() => tableHandler.reload(), [props.tableStateId]);

    return <>
        <div className={globalStyles["common__top-button-bar"]}>
            <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>
        </div>

        <Table className={styles.table}
               showSorterTooltip={false}
               loading={tableHandler.loading}
               dataSource={sources?.data}
               size="middle"
               onChange={tableHandler.onTableChange}
               pagination={tableHandler.pagination}
               onRow={props.onRow}
               rowKey="id"
               columns={props.columns.filter(column => appContext.user.isSystemAdmin() ? true : column.key !== "organization")}
        />
    </>;

    function reload() {
        return vulSourceService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setSources(value);
        });
    }
}

function SystemSourcesTable(props: TableProps) {
    const appContext = useContext(AppContextContext);
    const vulSourceService = useContext(VulSourceServiceContext);
    const [sources, setSources] = useState<PagedResult<VulSource>>();

    const tableHandler = useTableHandler("name", {
        reloadFunction: reload,
        persistentIdent: 'SystemVulSourceList',
        fixedRqbQuery: {
            combinator: "and",
            rules: [{
                field: "owner",
                operator: "eq",
                value: ResourceOwnerType.SYSTEM
            }]
        }
    });

    useEffect(() => tableHandler.reload(), [props.tableStateId]);

    return <>
        <div className={globalStyles["common__top-button-bar"]}>
            <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>
        </div>

        <Table className={styles.table}
               showSorterTooltip={false}
               loading={tableHandler.loading}
               dataSource={sources?.data}
               size="middle"
               onChange={tableHandler.onTableChange}
               pagination={tableHandler.pagination}
               onRow={props.onRow}
               rowKey="id"
               columns={props.columns.filter(column => {
                   if (column.key === "organization") {
                       return false;
                   } else if (column.key === "actions") {
                       return appContext.user.isSystemAdmin();
                   } else {
                       return true;
                   }
               })}
        />
    </>;

    function reload() {
        return vulSourceService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setSources(value);
        });
    }
}

function AllSourcesTable(props: TableProps) {
    const vulSourceService = useContext(VulSourceServiceContext);
    const [sources, setSources] = useState<PagedResult<VulSource>>();

    const tableHandler = useTableHandler("name", {
        reloadFunction: reload,
        persistentIdent: 'AllVulSourceList'
    });

    useEffect(() => tableHandler.reload(), [props.tableStateId]);

    return <>
        <div className={globalStyles["common__top-button-bar"]}>
            <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>
        </div>

        <Table className={styles.table}
               showSorterTooltip={false}
               loading={tableHandler.loading}
               dataSource={sources?.data}
               size="middle"
               onChange={tableHandler.onTableChange}
               pagination={tableHandler.pagination}
               onRow={props.onRow}
               rowKey="id"
               columns={props.columns}
        />
    </>;

    function reload() {
        return vulSourceService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setSources(value);
        });
    }

}

export default VulSourceList;
