"use strict";
"use client";

/* eslint import/no-unresolved: 0 */
// @ts-ignore
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _version = _interopRequireDefault(require("./version"));
var _default = exports.default = _version.default;