import Axios, {AxiosResponse} from "axios";
import VulSourceItem from "../domain/VulSourceItem";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";
import VulSourceItemChangeLog from "../domain/VulSourceItemChangeLog";
import VulReport from "../domain/VulReport";
import Ticket from "../domain/Ticket";

class VulSourceItemService {

    readonly basePath = "/vul-source-items";

    public add(vulSourceItem: VulSourceItem): Promise<string> {
        return Axios.post(`${this.basePath}`, vulSourceItem).then(value => value.data.id)
    }

    public update(vulSourceItemId: string, vulSourceItem: VulSourceItem): Promise<void> {
        return Axios.put(`${this.basePath}/${vulSourceItemId}`, vulSourceItem);
    }

    public get(id: string): Promise<VulSourceItem> {
        return Axios.get(`${this.basePath}/${id}`).then(value => VulSourceItem.fromPOJO(value.data));
    }

    public getRelatedItems(id: string, relatedItemsMaxDepth: number): Promise<VulSourceItem[]> {
        return Axios.get(`${this.basePath}/${id}/related-items?maxDepth=${relatedItemsMaxDepth}`)
            .then(value => {
                const objectList: VulSourceItem[] = [];

                value.data.forEach((objectJson: object) => {
                    objectList.push(VulSourceItem.fromPOJO(objectJson));
                });

                return objectList;
            })
    }

    public getRelatedTickets(id: string, relatedItemsMaxDepth: number): Promise<Ticket[]> {
        return Axios.get(`${this.basePath}/${id}/related-tickets?maxDepth=${relatedItemsMaxDepth}`)
            .then(value => {
                const objectList: Ticket[] = [];

                value.data.forEach((objectJson: object) => {
                    objectList.push(Ticket.fromPOJO(objectJson));
                });

                return objectList;
            })
    }

    public delete(vulSourceItem: VulSourceItem): Promise<void> {
        return Axios.delete(`${this.basePath}/${vulSourceItem.id}`);
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<VulSourceItem>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: VulSourceItem[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(VulSourceItem.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public getListForReport(queryOptions: QueryOptions, report: VulReport): Promise<PagedResult<VulSourceItem> & {newItems: number, modifiedItems: number}> {
        return Axios.get(`/vul-reports/${report.id}/items?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: VulSourceItem[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(VulSourceItem.fromPOJO(objectJson));
                });

                return {
                    total: value.data.total,
                    newItems: value.data.newItems,
                    modifiedItems: value.data.modifiedItems,
                    data: objectList
                };
            })
    }

    public extractAndSaveAttributes(queryOptions: QueryOptions, skipAuditLogs: boolean, skipItemChangeLogs: boolean, skipUpdateFindings: boolean): Promise<AxiosResponse> {
        return Axios.post(`${this.basePath}/extract-and-save?skipAuditLogs=${skipAuditLogs}&skipItemChangeLogs=${skipItemChangeLogs}&skipUpdateFindings=${skipUpdateFindings}&${queryOptions.toQueryParams()}`);
    }

    public createTicket(vulSourceItem: VulSourceItem): Promise<string> {
        return Axios.post(`${this.basePath}/${vulSourceItem.id}/ticket`).then(value => value.data.id);
    }
    
    public extract(vulSourceItem: VulSourceItem): Promise<void> {
        return Axios.post(`${this.basePath}/${vulSourceItem.id}/extract`);
    }

    public extractDry(vulSourceItem: VulSourceItem): Promise<void> {
        return Axios.post(`${this.basePath}/${vulSourceItem.id}/extract/dry-run`);
    }

    public evaluate(vulSourceItem: VulSourceItem): Promise<void> {
        return Axios.post(`${this.basePath}/${vulSourceItem.id}/evaluate`);
    }

    public evaluateDry(vulSourceItem: VulSourceItem): Promise<void> {
        return Axios.post(`${this.basePath}/${vulSourceItem.id}/evaluate/dry-run`);
    }

    public getChangeLogList(vulSourceItem: VulSourceItem): Promise<VulSourceItemChangeLog[]> {
        return Axios.get(`${this.basePath}/${vulSourceItem.id}/change-logs`)
            .then(value => {
                const objectList: VulSourceItemChangeLog[] = [];

                value.data.forEach((objectJson: object) => {
                    objectList.push(VulSourceItemChangeLog.fromPOJO(objectJson));
                });

                return objectList;
            })
    }

    public thumbUp(vulSourceItem: VulSourceItem) {
        return Axios.post(`${this.basePath}/${vulSourceItem.id}/thumb-up`);
    }
    public thumbDown(vulSourceItem: VulSourceItem) {
        return Axios.post(`${this.basePath}/${vulSourceItem.id}/thumb-down`);
    }
}

export default VulSourceItemService;
