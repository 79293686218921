import VulSource from "../../domain/VulSource";
import * as styles from "./SourceTag.module.css";
import {Tag} from "antd";
import React from "react";

function SourceTag(props: { source?: VulSource }) {
    return <>
        <Tag className={styles.source}>{props.source?.name}</Tag>
    </>
}

export default SourceTag;
