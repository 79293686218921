import {Permission} from "../domain/auth/Permission";

class SingleResult<T> {

    public data: T;

    public permissions?: Permission[]

    constructor(data: T, permissions?: Permission[]) {
        this.data = data;
        this.permissions = permissions;
    }
}

export default SingleResult;
