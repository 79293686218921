import {DeleteFilled, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, message, Modal, Table} from "antd";
import React, {useContext, useEffect, useRef, useState} from "react";
import Organization from "../../domain/Organization";
import {DocumentTitle} from "../DocumentTitle";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, RoleServiceContext} from "../../Contexts";
import {useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";
import * as styles from "./RoleList.module.css";
import *as globalStyles from "../App.module.css";
import {ColumnsType} from "antd/es/table";
import Role from "../../domain/auth/Role";
import {ResourceOwnerType} from "../../domain/ResourceOwnerType";
import RolePermission from "../../domain/auth/RolePermission";
import RolePermissionFormatter from "./RolePermissionFormatter";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import ReactMarkdown from "react-markdown";

function RoleList() {
    const persistentIdent = 'RoleList';
    const appContext = useContext(AppContextContext);
    const roleService = useContext(RoleServiceContext);
    const navigate = useNavigate();
    const tableHandler = useTableHandler("name", {reloadFunction: reload, persistentIdent});
    const [roles, setRoles] = useState<PagedResult<Role>>();
    const queryClientRef = useRef(new QueryClient());

    useEffect(() => tableHandler.reload(), [])

    const columns: ColumnsType<Role> = [];

    columns.push(
        {
            dataIndex: "name",
            title: "Name",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "ascend",
            className: styles['column-name'],
            render: renderName
        }
    );

    if (appContext.user.isSystemAdmin()) {
        columns.push({
            dataIndex: ["organization", "name"],
            title: "Organization",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: (organization, role) => (role.owner === ResourceOwnerType.SYSTEM) ? <i>System role</i> : renderOrganization(organization)
        });
    }

    columns.push(
        {
            dataIndex: "permissions",
            title: "Permissions",
            render: renderPermissions
        },
        {
            title: "Actions",
            width: 100,
            className: globalStyles["table-actions"],
            render: renderAction
        }
    );

    const title = "User roles";

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}
                            items={[
                                {title: appContext.config?.appName},
                                {title}
                            ]}
                />

                <h1>{title}</h1>

                <div className={globalStyles["common__top-button-bar"]}>
                    <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>
                    <Button type={"primary"}
                            icon={<PlusOutlined/>}
                            onClick={() => navigate("/roles/add")}>Add</Button>
                </div>

                <QueryClientProvider client={queryClientRef.current}>
                    <Table className={`${appContext.user.isSystemAdmin() ? styles['admin-table'] : styles.table}`}
                           showSorterTooltip={false}
                           loading={tableHandler.loading}
                           dataSource={roles?.data}
                           size="middle"
                           onChange={tableHandler.onTableChange}
                           pagination={tableHandler.pagination}
                           onRow={onRow}
                           rowKey="id"
                           columns={columns}
                    />
                </QueryClientProvider>
            </>
        </DocumentTitle>
    )

    function renderName(value: any, role: Role) {
        return <>
        <Link to={`/roles/${role.id}`}>{value}</Link>
            {role.description && <ReactMarkdown className={`${globalStyles.markdown} ${styles.description}`} children={role.description}/>}
            </>
    }

    function renderOrganization(organization: Organization) {
        return <Link to={`/organizations/${organization.id}`}>{organization.name}</Link>;
    }

    function renderPermissions(permissions: RolePermission[], role: Role) {
        return <div style={{display: "flex", gap: 8, flexDirection: "column"}}>
            {permissions.map(rolePermission => <div>
                {!rolePermission.expanded && <RolePermissionFormatter rolePermission={rolePermission}/>}
                {rolePermission.expanded && <i title={"transitive permission"}><RolePermissionFormatter rolePermission={rolePermission}/></i>}
            </div>)}
        </div>;
    }

    function renderAction(_: any, role: Role) {
        return (
            <>
                {role.owner === ResourceOwnerType.ORGANIZATION &&
                    <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                            title={"Delete"}
                            onClick={() => {
                                Modal.confirm({
                                    content: <>Do you really want to delete role <b>{role.name}</b>?</>,
                                    okText: "Delete",
                                    cancelText: "Cancel",
                                    onOk: () => onDeleteConfirm(role)
                                });
                            }}/>
                }
            </>
        )
    }

    function onDeleteConfirm(role: Role) {
        roleService.delete(role)
            .then(() => {
                message.success(<>Role <b>{role.name}</b> successfully deleted.</>);

                reload();
            });
    }

    function reload() {
        return roleService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setRoles(value);
        });
    }

    function onRow(role: Role) {
        return {onDoubleClick: () => navigate(`/roles/${role.id}`)}
    }
}

export default RoleList;
