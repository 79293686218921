import {DeleteFilled, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import Collapse from "@kunukn/react-collapse";
import {Breadcrumb, Button, Checkbox, Descriptions, Form, Input, message, Modal, Select, Space, Spin, Switch, Table} from "antd";
import {useForm} from "antd/es/form/Form";
import Column from "antd/es/table/Column";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, AttributeDefinitionServiceContext, OrganizationServiceContext, TicketAttributeRuleServiceContext, TicketCreationDefinitionServiceContext} from "../../Contexts";
import AttributeDefinition from "../../domain/AttributeDefinition";
import Organization from "../../domain/Organization";
import TicketAttributeRule from "../../domain/TicketAttributeRule";
import {TicketAttributeRuleOperationType} from "../../domain/TicketAttributeRuleOperationType";
import TicketCreationDefinition from "../../domain/TicketCreationDefinition";
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {useTableHandler} from "../../sal-ui/TableHandler";
import {DocumentTitle} from "../DocumentTitle";
import * as styles from "./TicketCreationDefinitionDetail.module.css";
import *as globalStyles from "../App.module.css";

function TicketCreationDefinitionDetail() {
    const appContext = useContext(AppContextContext);
    const ticketCreationDefinitionService = useContext(TicketCreationDefinitionServiceContext);
    const ticketAttributeRuleService = useContext(TicketAttributeRuleServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const attributeDefinitionService = useContext(AttributeDefinitionServiceContext);
    const navigate = useNavigate();
    const {ruleId}: any = useParams();
    const [editMode, setEditMode] = useState(false);
    const [addRuleMode, setAddRuleMode] = useState(false);
    const [editRuleMode, setEditRuleMode] = useState(false);

    const [ticketCreationDefinition, setTicketCreationDefinition] = useState<TicketCreationDefinition>();
    const [ticketAttributeRule, setTicketAttributeRule] = useState<TicketAttributeRule>();

    const [editForm] = useForm();
    const [ruleForm] = useForm();

    const [organizations, setOrganizations] = useState<Organization[]>();
    const [attributeDefinitions, setAttributeDefinitions] = useState<AttributeDefinition[]>();


    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 8, span: 16},
    };

    const serverViolationsHolder = new ServerConstraintViolationsHolder();
    const tableHandler = useTableHandler("id", {reloadFunction: null});

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        reload();

        organizationService.getList(new QueryOptions("name", 100, 0)).then(value => {
            setOrganizations(value.data);
        });

        attributeDefinitionService.getList(new QueryOptions("name", 100, 0)).then(value => {
            setAttributeDefinitions(value.data);
        });


    }, [])

    return (
        <DocumentTitle title={ticketCreationDefinition?.name}>
            <>
                <Breadcrumb className={globalStyles.common__breadcrumb}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/definitions/ticket-rules"}>Ticket rules</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{ticketCreationDefinition?.name}</Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={!ticketCreationDefinition} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{ticketCreationDefinition?.name}</h1>

                    <div className={globalStyles['common__top-button-bar']}>
                        <Button onClick={() => setEditMode(!editMode)}>Edit</Button>

                        <Button danger={true}
                                title={"Delete"}
                                onClick={() => {
                                    Modal.confirm({
                                        content: "Do you really want to delete this ticket definition?",
                                        okText: "Delete",
                                        cancelText: "Cancel",
                                        okButtonProps: {danger: true},
                                        onOk: () => onDeleteConfirm(ticketCreationDefinition!)
                                    });
                                }}>Delete</Button>

                        <Button type={"primary"}
                                icon={<PlusOutlined/>}
                                onClick={onAddRuleMode}>Add rule</Button>
                    </div>

                    {renderEditForm()}

                    <Descriptions column={1} bordered={true} size="small" className={globalStyles.details}>
                        <Descriptions.Item label={"Title"}>{ticketCreationDefinition?.name}</Descriptions.Item>
                        <Descriptions.Item label={"Enabled"}><Checkbox checked={ticketCreationDefinition?.enabled} disabled={true} /></Descriptions.Item>
                        <Descriptions.Item label={"Organization"}>{ticketCreationDefinition?.organization.name}</Descriptions.Item>
                    </Descriptions>

                    <h2>Attribute rules</h2>

                    {renderRuleForm()}

                    <Table className={styles['rules-table']}
                           showSorterTooltip={false}
                           loading={tableHandler.loading}
                           dataSource={ticketCreationDefinition?.attributeRules}
                           size="middle"
                           onChange={tableHandler.onTableChange}
                           pagination={tableHandler.pagination}
                           rowKey="id">

                        <Column dataIndex="id" title={"ID"} width={80} align={"center"} render={renderId}/>
                        <Column dataIndex={"attributeDefinition"} title={"Attribute"} width={120} align={"left"} render={renderAttributeDefinition}/>
                        <Column dataIndex={["operationType"]} title={"Operation"} width={120} align={"left"}/>
                        <Column dataIndex={["value"]} title={"value"} width={120} align={"left"}/>
                        <Column title={"Actions"} width='100px' render={renderAction} className={globalStyles["table-actions"]}/>

                    </Table>
                </Spin>
            </>
        </DocumentTitle>
    )

    function renderAttributeDefinition(value: AttributeDefinition) {
        return <Link to={`/attributes/definitions/${value.id}`}>
            {value.name} ({value.valueType})
        </Link>;
    }

    function renderAction(ticketAttributeRule: TicketAttributeRule) {
        return (
            <Space>
                <Button onClick={() => onEditRuleMode(ticketAttributeRule)}>Edit</Button>

                <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                        title={"Delete"}
                        onClick={() => {
                            Modal.confirm({
                                content: "Do you really want to delete this item?",
                                okText: "Delete",
                                cancelText: "Cancel",
                                onOk: () => onDeleteRuleConfirm(ticketAttributeRule)
                            });
                        }}/>
            </Space>
        )
    }

    function onAddRuleMode() {
        setEditRuleMode(false);
        setAddRuleMode(!addRuleMode);
        ruleForm.resetFields()
        setTicketAttributeRule(undefined);
    }

    function onEditRuleMode(ticketAttributeRule: TicketAttributeRule) {
        setEditRuleMode(true);
        setAddRuleMode(false);
        setTicketAttributeRule(ticketAttributeRule);
        ruleForm.setFieldsValue(ticketAttributeRule)
    }

    function renderId(value: string) {
        return (
            <span title={value}>{value.substring(0, 2) + '...' + value.substring(value.length - 2)}</span>
        )
    }

    function renderEditForm() {
        return (
            <Collapse isOpen={editMode}>
                <h3>Edit of ticket rule</h3>

                <Form {...layout} form={editForm} className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`} onFinish={onFinishEdit}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={["organization", "id"]}
                        label={"Organization"}
                        rules={[{required: true, message: "required"}]}>

                        <Select>
                            {organizations && organizations!.map(organization => {
                                return <Select.Option key={organization?.name} value={organization?.id!}>{organization?.name}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={["enabled"]}
                        label={"Enabled"}
                        valuePropName={"checked"}>
                        <Switch/>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => setEditMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function renderRuleForm() {
        return (
            <Collapse isOpen={addRuleMode || editRuleMode}>
                <h3>{addRuleMode ? "New attribute rule" : "Edit of attribute rule"}</h3>

                <Form {...layout} form={ruleForm} className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`} onFinish={onFinishRule}>
                    <Form.Item
                        name={["attributeDefinition", "id"]}
                        label={"Attribute definition"}
                        rules={[{required: true, message: "required"}]}>

                        <Select>
                            {attributeDefinitions && attributeDefinitions!.map(attributeDefinition => {
                                return <Select.Option key={attributeDefinition?.name} value={attributeDefinition?.id!}>{attributeDefinition?.name} ({attributeDefinition?.valueType})</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={"operationType"}
                        label={"Operation type"}
                        rules={[
                            {required: true, message: "Name is required."},
                        ]}>
                        <Select>
                            {Object.keys(TicketAttributeRuleOperationType).map(value => {
                                return <Select.Option key={value} value={value}>{value}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={["value"]}
                        label={"Value"}
                        rules={[
                            {required: true, message: "value-required"},
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => (addRuleMode) ? setAddRuleMode(false) : setEditRuleMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function onFinishEdit(values: any) {
        ticketCreationDefinitionService.update(ruleId, values)
            .then(
                (id) => {
                    message.success("Ticket creation definition updated successfully.");

                    setEditMode(false);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }

    function onFinishRule(values: any) {
        if (addRuleMode) {
            ticketCreationDefinitionService.addRule(ruleId, values)
                .then(
                    (id) => {
                        message.success("Ticket rule added successfully.");

                        setAddRuleMode(false);

                        reload();
                    },
                    error => serverViolationsHolder.handleServerError(error, editForm)
                );

        } else if (editRuleMode) {
            ticketAttributeRuleService.update(ticketAttributeRule.id, values)
                .then(
                    (id) => {
                        message.success("Ticket rule updated successfully.");

                        setAddRuleMode(false);

                        setEditRuleMode(false);

                        reload();
                    },
                    error => serverViolationsHolder.handleServerError(error, editForm)
                );
        }
    }

    function onDeleteConfirm(definition: TicketCreationDefinition) {
        ticketCreationDefinitionService.delete(definition)
            .then(() => {
                message.success("Ticket rule deleted successfully.");

                navigate(`/tickets/rules`)
            });
    }

    function onDeleteRuleConfirm(ticketAttributeRule: TicketAttributeRule) {
        ticketAttributeRuleService.delete(ticketAttributeRule)
            .then(() => {
                message.success("Ticket rule deleted successfully.");

                reload();
            });
    }


    function reload() {
        ticketCreationDefinitionService.get(ruleId).then(ticket => {
            setTicketCreationDefinition(ticket);

            editForm.setFieldsValue(ticket);
        });

    }

}

export default TicketCreationDefinitionDetail;
