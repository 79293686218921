.status-UNKNOWN {
    color: black;
    background-color: #d9d9d9;
}

.status-AFFECTED {
    color: white;
    background-color: #f5222d;
}

.status-NOT_AFFECTED {
    color: white;
    background-color: #52c41a;
}

.status-UNDER_INVESTIGATION {
    color: white;
    background-color: #fadb14;
}
