import {DeleteFilled, PlusOutlined, QuestionCircleTwoTone, ReloadOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, message, Modal, Popover, Table} from "antd";
import React, {useContext, useEffect, useState} from "react";
import Organization from "../../domain/Organization";
import {DocumentTitle} from "../DocumentTitle";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, SoftwareLicensePolicyServiceContext} from "../../Contexts";
import {useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";
import * as styles from "./SoftwareLicensePolicyList.module.css";
import * as globalStyles from "../App.module.css";
import {ColumnsType} from "antd/es/table";
import SoftwareLicense from "../../domain/SoftwareLicense";
import SoftwareLicensePolicy from "../../domain/SoftwareLicensePolicy";
import {Permission} from "../../domain/auth/Permission";
import {formatSoftwareLicenseCategory} from "../softwarelicense/functions";

function SoftwareLicensePolicyList() {
    const appContext = useContext(AppContextContext);
    const softwareLicensePolicyService = useContext(SoftwareLicensePolicyServiceContext);
    const navigate = useNavigate();
    const tableHandler = useTableHandler("name", {reloadFunction: reload});
    const [policies, setPolicies] = useState<PagedResult<SoftwareLicensePolicy>>();

    const columns: ColumnsType<SoftwareLicense> = [];

    if (appContext.user.isSystemAdmin()) {
        columns.push({
            dataIndex: "organization",
            title: "Organization",
            render: renderOrganization
        });
    }

    columns.push(
        {
            dataIndex: "name",
            title: "Name",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "ascend",
            render: renderName
        },
        {
            dataIndex: "categories",
            title: "Categories",
            render: categories => (
                <>
                    {categories?.length === 0 && <i>no categories</i>}
                    {categories?.map(category => <div>{category} <Popover title={category} content={formatSoftwareLicenseCategory(category)} overlayStyle={{maxWidth: 400}}><QuestionCircleTwoTone/></Popover></div>)}
                </>
            )
        },
        {
            dataIndex: "licenses",
            title: "Licenses",
            render: licenses => (
                <>
                    {licenses?.length === 0 && <i>no licenses</i>}
                    {licenses?.map(license => <div>
                        <Link to={`/software-licenses/${license.id}`}>{license.shortName}</Link>
                    </div>)}
                </>
            )
        },
    );

    columns.push({
        title: "Actions",
        width: 100,
        align: "center",
        className: globalStyles["table-actions"],
        render: renderAction
    });

    useEffect(() => {
        tableHandler.reload();
    }, []);

    const title = "Software license policies";

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}
                            items={[
                                {title: appContext.config?.appName},
                                {title}
                            ]}
                />

                <h1>{title}</h1>

                <div className={globalStyles["common__top-button-bar"]}>
                    <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>

                    {appContext.user.hasPermission(Permission.SBOM_PROJECT__MANAGE_ORG) &&
                        <Button type={"primary"}
                                icon={<PlusOutlined/>}
                                onClick={() => navigate("/software-license-policies/add")}>Add</Button>
                    }
                </div>

                <Table className={styles.table}
                       showSorterTooltip={false}
                       loading={tableHandler.loading}
                       dataSource={policies?.data}
                       size="middle"
                       onChange={tableHandler.onTableChange}
                       pagination={tableHandler.pagination}
                       onRow={onRow}
                       rowKey="id"
                       columns={columns}
                />
            </>
        </DocumentTitle>
    )

    function renderName(value: any, policy: SoftwareLicensePolicy) {
        return <Link title={policy.name} to={`/software-license-policies/${policy.id}`}>{value}</Link>;
    }

    function renderOrganization(value: Organization) {
        if (value === undefined) {
            return <i>system resource</i>;
        }

        return <Link to={`/organizations/${value.id}`}>{value.name}</Link>;
    }

    function renderAction(_: any, policy: SoftwareLicensePolicy) {
        return (
            <>
                <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                        title={"Delete"}
                        onClick={() => {
                            Modal.confirm({
                                content: <>Do you really want to delete software license policy <b>{policy.name}</b>?</>,
                                okText: "Delete",
                                cancelText: "Cancel",
                                onOk: () => onDeleteConfirm(policy)
                            });
                        }}/>
            </>
        )
    }

    function onDeleteConfirm(policy: SoftwareLicensePolicy) {
        softwareLicensePolicyService.delete(policy)
            .then(() => {
                message.success(<>Software license policy <b>{policy.name}</b> successfully deleted.</>);

                reload();
            });
    }

    function reload() {
        return softwareLicensePolicyService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setPolicies(value);
        });
    }

    function onRow(policy: SoftwareLicensePolicy) {
        return {onDoubleClick: () => navigate(`/software-license-policies/${policy.id}`)}
    }
}

export default SoftwareLicensePolicyList;
