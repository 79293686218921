.table {
    max-width: 1200px;
}

.details {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
}

.details :global(.ant-descriptions) {
    flex: 1;
    max-width: 600px;
    margin-bottom: 0;
}

.details :global(.ant-descriptions-item-label) {
    width: 160px;
}

.view-description {
    margin-top: 16px;
    font-size: 12px;
}
