.layout {
    min-height: 100vh;
}

.header:global(.ant-layout-header) {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 8px 16px;
    height: 60px;
    background: #fff;
    text-align: center;
}

.logo {
    height: 60px;
    width: 240px;
    text-align: left;
    display: flex;
    align-items: center;
}

.logo img {
    max-height: 40px;
    max-width: 180px;
    margin-top: 8px;
}

.footer {
    text-align: center;
    font-size: 12px;
    padding: 18px 60px;
    background-color: white;
}

.form {
    margin-top: 4em;
    padding: 3em;
    background-color: white;
}

.form h1 {
    text-align: center;
}

.form button {
    width: 100%;
    margin-top: 2em;
}

.form :global(.ant-form-item) {
    margin-bottom: 8px;
}