import {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {AppContextContext, AuthServiceContext, ClientNotificationServiceContext} from "../../Contexts";
import {Link, matchRoutes, Outlet, useLocation} from "react-router-dom";
import {routes,} from "../../routes";
import {Button, Layout, Menu} from "antd";
import * as styles from "./HomePage.module.css";
import ProfileMenu from "./ProfileMenu";
import {runInAction} from "mobx";
import {observer} from "mobx-react-lite";
import {Permission} from "../../domain/auth/Permission";
import {Feature} from "../../domain/Feature";

const SESSION_CHECK_INTERVAL_IN_MS = 5000;

const MainMenu = function ({items}: {items: any[]}) {
    const location = useLocation();
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState([]);

    useEffect(() => {
        const matchedRoutes = matchRoutes(routes, location.pathname);

        if (matchedRoutes != null) {
            const openMenuKey = matchedRoutes[1].route.handle?.menuOpenKey;
            const menuKey = matchedRoutes[1].route.handle?.menuKey;

            setSelectedKeys([menuKey]);

            setOpenKeys([openMenuKey])
        } else {
            setSelectedKeys([]);
        }
    }, [location]);

    return <Menu className={"home-page__main-menu"}
                 theme="dark"
                 openKeys={openKeys}
                 onOpenChange={keys => setOpenKeys(keys)}
                 selectedKeys={selectedKeys}
                 mode="inline"
                 items={items}
    />;
};

const HomePage = observer(() => {
    const appContext = useContext(AppContextContext);
    const authService = useContext(AuthServiceContext);
    const clientNotificationService = useContext(ClientNotificationServiceContext);
    const sessionChecked = useRef(false);

    useLayoutEffect(() => {
        clientNotificationService.connectToServer();

        return function cleanup() {
            clientNotificationService.disconnectFromServer();
        }
    }, []);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        checkSessionExpiresAt();

        const timerHandle = setInterval(checkSessionExpiresAt, SESSION_CHECK_INTERVAL_IN_MS);

        return function cleanup() {
            clearInterval(timerHandle);
        }
    }, []);

    const mainMenuItems = [];

    if (appContext.user.hasOneOfPermission([Permission.SOURCE__READ_SEAL, Permission.SOURCE__READ_ORG])) {
        mainMenuItems.push({key: "vul-source-items", label: <Link to={"/vul-source-items"}>Vulnerability items</Link>});
    }

    if (appContext.user.hasPermission(Permission.REPORT__READ)) {
        mainMenuItems.push({key: "vul-reports", label: <Link to={"/vul-reports"}>Reports</Link>});
    }

    if (appContext.user.hasPermission(Permission.TICKET__MANAGE)) {
        mainMenuItems.push({key: "tickets", label: <Link to={"/tickets"}>Tickets</Link>});
    }

    if (appContext.user.hasFeature(Feature.SBOM) && appContext.user.hasOneOfPermission([Permission.SBOM_PROJECT__READ, Permission.SBOM_PROJECT__MANAGE, Permission.SBOM_PROJECT__MANAGE_ORG])) { //
        const sbomItems = [
            {key: "sbom-projects", label: <Link to={"/sbom-projects"}>SBOM projects</Link>},
            {key: "sbom-components", label: <Link to={"/sbom-components"}>SBOM components</Link>},
        ];

        if (appContext.user.hasPermission(Permission.SBOM_PROJECT__MANAGE_ORG)) {
            sbomItems.push({key: "software-licenses", label: <Link to={"/software-licenses"}>Licenses</Link>});
            sbomItems.push({key: "software-license-policies", label: <Link to={"/software-license-policies"}>License policies</Link>});
            sbomItems.push({key: "software-license-overrides", label: <Link to={"/software-license-overrides"}>License overrides</Link>});
            sbomItems.push({key: "sbom-report-definitions", label: <Link to={"/sbom-project-report-definitions"}>Report definitions</Link>});
        }

        mainMenuItems.push(
            {
                key: "sbom", label: "SBOM", children: sbomItems
            }
        );
    }

    const definitionsMenuItems = [];

    if (appContext.user.hasOneOfPermission([Permission.SOURCE__READ_SEAL, Permission.SOURCE__READ_ORG])) {
        definitionsMenuItems.push({key: "vul-sources", label: <Link to={"/vul-sources"}>Sources</Link>});
    }

    if (appContext.user.hasPermission(Permission.SOURCE__MANAGE_ORG)) {
        definitionsMenuItems.push({
            key: "vul-source-health-report-definitions",
            label: <Link to={"/vul-source-health-report-definitions"}>Source health reports</Link>
        });
    }

    if (appContext.user.hasPermission(Permission.ATTRIBUTE__READ)) {
        definitionsMenuItems.push({
            key: "attributes/definitions",
            label: <Link to={"/attributes/definitions"}>Attributes</Link>
        });
    }

    if (appContext.user.hasOneOfPermission([Permission.VIEW__READ_SEAL, Permission.VIEW__READ_ORG])) {
        definitionsMenuItems.push({
            key: "vul-source-items/views",
            label: <Link to={"/vul-source-items/views"}>Vulnerability views</Link>
        });
    }

    if (appContext.user.hasPermission(Permission.REPORT__MANAGE)) {
        definitionsMenuItems.push({
            key: "vul-reports/definitions",
            label: <Link to={"/definitions/vul-reports"}>Vulnerability reports</Link>
        });
    }

    if (definitionsMenuItems.length > 0) {
        mainMenuItems.push({
            key: "definitions",
            label: "Definitions",
            children: definitionsMenuItems
        });
    }

    if (appContext.user.hasPermission(Permission.USER__MANAGE)) {
        mainMenuItems.push(
            {
                key: "users-and-groups", label: "Users and roles", children: [
                    {key: "users", label: <Link to={"/users"}>Users</Link>},
                    {key: "api-tokens", label: <Link to={"/api-tokens"}>API tokens</Link>},
                    {key: "user-roles", label: <Link to={"/roles"}>User roles</Link>}
                ]
            }
        );
    }

    if (appContext.user.isSystemAdmin()) {
        mainMenuItems.push(
            {key: "organizations", label: <Link to={"/organizations"}>Organizations</Link>},
            {key: "audit-logs", label: <Link to={"/audit-logs"}>{"Audit logs"}</Link>},
            {key: "import-export", label: <Link to={"/import-export"}>{"Import / Export"}</Link>}
        );
    }

    return <Layout className={styles.layout}>
        {!appContext.presentationMode && <Layout.Header className={styles.header}>
            <div className={styles.logo}>
                <img src="/img/logo.svg" alt={"SEAL"}/>
            </div>

            <div className={styles.center}/>

            <div className={styles.toolbar}>
                {!appContext.publicAccess && <ProfileMenu/>}
            </div>
        </Layout.Header>}
        <Layout hasSider={!appContext.presentationMode}>
            {!appContext.presentationMode && <Layout.Sider width={220} className={styles.sider}>
                <MainMenu items={mainMenuItems}/>
            </Layout.Sider>}
            <Layout.Content className={styles.content}>
                <Outlet/>

                {appContext.presentationMode && <ExitPresentationMode/>}
            </Layout.Content>
        </Layout>
    </Layout>;

    function checkSessionExpiresAt() {
        if (appContext.jwtTokenExpiresAt) {
            sessionChecked.current = true;

            // pokud předpokládáme že sezení pozbylo platnosti, pošleme dotaz na server pro informaci o přihlášeném uživateli
            // - pokud uživatel není přihlášen (sezení není platné), provede Axios interceptor automaticky odhlášení na frontendu
            if (appContext.jwtTokenExpiresAt.getTime() < (new Date().getTime() + SESSION_CHECK_INTERVAL_IN_MS)) {
                authService.getLoggedInUser();
            } else {
                sessionChecked.current = true;
            }
        }
    }
});

function ExitPresentationMode() {
    const appContext = useContext(AppContextContext);

    return <>
        <Button className={styles['exit-presentation-mode']}
                onClick={() => runInAction(() => appContext.presentationMode = false)}
                type={"primary"}>
            Exit presentation mode
        </Button>
    </>;
}

export default HomePage;
