import Organization from "./Organization";
import {ResourceOwnerType} from "./ResourceOwnerType";
import VulSourceItem from "./VulSourceItem";
import {VulSourceType} from "./VulSourceType";
import {RssGuidSource} from "./RssGuidSource";
import {VulSourceHealth} from "./VulSourceHealth";

class VulSource {
    constructor(
        public id?: string,
        public name?: string,
        public description?: string,
        public sourceType?: VulSourceType,
        public owner?: ResourceOwnerType,
        public organization?: Organization,
        public tags?: string[],
        public enabled?: boolean,
        public maxLastRunAge?: number,
        public maxLatestItemPublishedAtAge?: number,
        public imported?: boolean,
        public initialCollectionDone?: boolean,
        public initialCollectionDoneAt?: Date,
        public importedAt?: Date,
        public pollState?: any,
        public lastRun?: Date,
        public latestItemPublishedAt?: Date,
        public health?: VulSourceHealth,
        public itemCount?: number,
        public items?: VulSourceItem[],
        public apiKey?: string,
        public apiClientSecret?: string,
        public url?: string,
        public guidSource?: RssGuidSource,
        public userAgent?: string,
        public username?: string) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const source = new VulSource();

        source.id = data.id;
        source.name = data.name;
        source.description = data.description;
        source.sourceType = data.sourceType;
        source.owner = data.owner;
        source.organization = Organization.fromPOJO(data.organization);
        source.tags = data.tags;
        source.enabled = data.enabled;
        source.maxLastRunAge = data.maxLastRunAge;
        source.maxLatestItemPublishedAtAge = data.maxLatestItemPublishedAtAge;
        source.imported = data.imported;
        source.initialCollectionDone = data.initialCollectionDone;
        source.initialCollectionDoneAt = data.initialCollectionDoneAt;
        source.importedAt = (data.importedAt) ? new Date(data.importedAt) : undefined;
        source.pollState = data.pollState;
        source.lastRun = (data.lastRun) ? new Date(data.lastRun) : undefined;
        source.latestItemPublishedAt = (data.latestItemPublishedAt) ? new Date(data.latestItemPublishedAt) : undefined;
        source.health = data.health;
        source.itemCount = data.itemCount;
        source.items = data.items && data.items.map(item => VulSourceItem.fromPOJO(item));
        source.apiKey = data.apiKey;
        source.apiClientSecret = data.apiClientSecret;
        source.url = data.url;
        source.guidSource = data.guidSource;
        source.userAgent = data.userAgent;
        source.username = data.username;

        return source;
    }

}

export function vulSourceSystemLastComparator(a: VulSource, b: VulSource) {
    if (a.owner === ResourceOwnerType.ORGANIZATION && b.owner === ResourceOwnerType.ORGANIZATION) {
        return 0;
    } else if (a.owner === ResourceOwnerType.ORGANIZATION && b.owner == ResourceOwnerType.SYSTEM) {
        return -1;
    } else if (a.owner === ResourceOwnerType.SYSTEM && b.owner === ResourceOwnerType.ORGANIZATION) {
        return 1;
    }

    return a.name.localeCompare(b.name);
}

export default VulSource;
