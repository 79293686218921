import {Breadcrumb, Button, Form, Input, message, Select} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, TicketServiceContext} from "../../Contexts";
import Organization from "../../domain/Organization";
import {ResourceOwnerType} from "../../domain/ResourceOwnerType";
import {TicketSeverity} from "../../domain/TicketSeverity";
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import *as globalStyles from "../App.module.css";


const serverViolationsHolder = new ServerConstraintViolationsHolder();



function TicketAdd() {
    const appContext = useContext(AppContextContext);
    const ticketService = useContext(TicketServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const navigate = useNavigate();
    const [form] = useForm();

    // const [sourceType, setSourceType] = useState<TicketType>(TicketType.RSS);
    const [owner, setOwner] = useState<ResourceOwnerType>(ResourceOwnerType.SYSTEM);

    const [organizations, setOrganizations] = useState<Organization[]>();


    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 8, span: 16},
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {

        organizationService.getList(new QueryOptions("name", 100, 0)).then(value => {
            setOrganizations(value.data);
        });


    }, [])

    return (
        <DocumentTitle title={"ticket.add-title"}>
            <>
                <Breadcrumb className={"common__breadcrumb"}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/tickets"}>{"common.ticketistrators"}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{"ticket.add-title"}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{"ticket.add-title"}</h1>

                <Form {...layout} form={form} className={`${globalStyles["common__form"]} ${globalStyles["common_form--add"]}`} onFinish={onFinish}>
                    <Form.Item
                        name={"title"}
                        label={"Title"}
                        rules={[
                            {required: true, message: "Name is required."},
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={["organization", "id"]}
                        label={"Organization"}
                        rules={[{required: true, message: "required"}]}>

                        <Select >
                            {organizations && organizations!.map(organization => {
                                return <Select.Option key={organization?.name} value={organization?.id!}>{organization?.name}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={["severity"]}
                        label={"Severity"}
                        rules={[{required: true, message: "required"}]}>

                        <Select >
                            {Object.keys(TicketSeverity).map(value => {
                                return <Select.Option key={value} value={value}>{value}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate("/tickets")}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    )

    function onFinish(values: any) {
        ticketService.add(values)
            .then(
                (id) => {
                    message.success("Ticket created successfully.");

                    navigate(`/tickets/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }
}

export default TicketAdd;
