import {VulSourceItemChangeType} from "./VulSourceItemChangeType";

class VulSourceItemChangeLog {
    constructor(
        public id?: string,
        public timestamp?: Date,
        public type?: VulSourceItemChangeType,
        public jsonData?: string,
        public attributes?: any) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const entity = new VulSourceItemChangeLog();

        entity.id = data.id;
        entity.timestamp = data.timestamp;
        entity.type = data.type;
        entity.jsonData = data.jsonData ? JSON.parse(data.jsonData) : null;
        entity.attributes = data.attributes ? JSON.parse(data.attributes) : null;

        return entity;
    }
}

export default VulSourceItemChangeLog;
