import Axios from "axios";
import Organization from "../domain/Organization";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";

class OrganizationService {

    readonly basePath = "/organizations";

    public add(organization: Organization): Promise<string> {
        return Axios.post(`${this.basePath}`, organization).then(value => value.data.id)
    }

    public update(organizationId: string, user: Organization): Promise<void> {
        return Axios.put(`${this.basePath}/${organizationId}`, user);
    }

    public get(id: string): Promise<Organization> {
        return Axios.get(`${this.basePath}/${id}`)
            .then(value => Organization.fromPOJO(value.data))
    }

    public delete(organization: Organization): Promise<void> {
        return Axios.delete(`${this.basePath}/${organization.id}`);
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<Organization>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: Organization[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(Organization.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public searchByName(name: string): Promise<PagedResult<Organization>> {
        return Axios.get(`${this.basePath}/search?name=${name}`)
            .then(value => {
                const objectList: Organization[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(Organization.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }
}

export default OrganizationService;
