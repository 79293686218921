import React, {useContext, useEffect} from "react";
import {AppContextContext} from "../Contexts";
import {Permission} from "../domain/auth/Permission";
import {useNavigate} from "react-router-dom";

function Dashboard() {
    const appContext = useContext(AppContextContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (appContext.user?.hasOneOfPermission([Permission.SOURCE__READ_ORG, Permission.SOURCE__READ_SEAL])) {
            navigate("/vul-source-items");
        } else if (appContext.user?.hasOneOfPermission([Permission.SBOM_PROJECT__READ, Permission.SBOM_PROJECT__MANAGE, Permission.SBOM_PROJECT__MANAGE_ORG])) {
            navigate("/sbom-projects");
        } else if (appContext.user?.hasPermission(Permission.USER__MANAGE)) {
            navigate("/users");
        }
    }, []);

    return <></>;
}

export default Dashboard;
