import React, {useEffect, useState} from "react";
import {ClearOutlined, CloseOutlined, DownOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import {Button, Card, DatePicker, Dropdown, Input, Popover, Select, SelectProps, Space} from "antd";
import * as styles from "./TableFilter.module.css";
import dayjs from "dayjs";
import {FilterFunc} from "rc-select/lib/Select";

export interface FilterEditorProps {
    label: string | React.ReactNode;
    labelCursor?: string;
    onLabelClick?: () => void;
    closeable?: boolean;
    onClose: () => any;
    onClear: () => any;
    children: React.ReactNode;
}

export function FilterEditor(props: FilterEditorProps) {
    return (
        <div className={styles.editor}>
            <Popover trigger={"contextMenu"} content={<Button size={"small"} danger={true} onClick={props.onClose}>Remove filter item</Button>}>
                <span className={styles["editor-label"]} style={{cursor: props.labelCursor || 'auto'}} onClick={props.onLabelClick}>{props.label}</span>
            </Popover>

            {props.children}

            {(props.closeable === true || props.closeable === undefined) && <span className={styles["editor-clear"]} title={"Clear value"} onClick={props.onClear}><CloseOutlined/></span>}
        </div>
    )
}

export interface SelectEditorProps {
    placeholder?: string;
    multiple: boolean;
    optionLabelProp?: string;
    options?: SelectProps['options'];
    children?: React.ReactNode;
    filterOption?: boolean | FilterFunc<any>;
    value?: string | string[],
    onChange: (value: any) => any;
    width?: number;
}

export function SelectEditor(props: SelectEditorProps) {
    const spanRef = React.useRef<HTMLSpanElement>(null);

    return (
        <span ref={spanRef}>
            <Select
                size={"small"}
                className={styles["editor-select"]}
                placeholder={props.placeholder}
                popupMatchSelectWidth={350}
                mode={(props.multiple) ? "multiple" : undefined}
                suffixIcon={(props.multiple) ? null : <DownOutlined/>}
                value={props.value}
                onChange={props.onChange}
                options={props.options}
                optionLabelProp={props.optionLabelProp}
                filterOption={props.filterOption}
                allowClear={!props.multiple}
                virtual={false}
                listHeight={300}
            >
                {props.children}
            </Select>
        </span>
    );
}

export interface DatePickerEditorProps {
    placeholder?: string;
    value?: dayjs.Dayjs,
    onChange: (value: any) => any;
}

export function DatePickerEditor(props: DatePickerEditorProps) {
    return (
        <DatePicker
            className={styles["editor-date-picker"]}
            size={"small"}
            allowClear={false}
            showTime={{defaultValue: dayjs('00:00:00', 'HH:mm:ss')}}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
        />
    )
}

export interface InputEditorProps {
    placeholder?: string;
    value?: string,
    onChange: (value: any) => any;
}

export function InputEditor(props: InputEditorProps) {
    const [text, setText] = useState("");

    useEffect(() => {
        setText(props.value);
    }, [props.value]);

    return (
        <Input
            className={styles["editor-input"]}
            size={"small"}
            placeholder={props.placeholder}
            value={text}
            onKeyUp={onKeyUp}
            onBlur={onBlur}
            onChange={e => setText(e.target.value)}
        />
    )

    function onKeyUp(event) {
        if (event.key === 'Enter') {
            props.onChange(text);
        }
    }

    function onBlur() {
        props.onChange(text);
    }

}

export interface AttributeEditorProps {
    options: SelectProps['options'];
    value?: { name: string, value: string },
    onChange: (value: any) => any;
}

export function AttributeEditor(props: AttributeEditorProps) {
    const spanRef = React.useRef<HTMLSpanElement>(null);
    const [inputWidth, setInputWidth] = useState(0);

    useEffect(() => {
        if (spanRef.current) {
            setInputWidth(spanRef.current.scrollWidth + 16);
        }
    }, [props.value]);

    return (
        <Space.Compact size={"small"} className={styles["editor-attribute"]}>
            <Select
                options={props.options}
                showSearch={true}
                value={props.value?.name}
                popupMatchSelectWidth={false}
                onChange={value => props.onChange(Object.assign({}, props.value, {name: value}))}
            />

            <Input
                style={{width: inputWidth}}
                value={props.value?.value}
                onChange={e => {
                    props.onChange(Object.assign({}, props.value, {value: e.target.value}))
                }}
            />
            <span ref={spanRef} className={styles["input-measure-node"]}>{props.value?.value}</span>
        </Space.Compact>
    )
}

export interface FilterItemProps {
    value?: any;
    onChange: (value?: any) => void;
}

export interface FilterItem {
    name: string;
    label: string | React.ReactNode;
    labelCursor?: string;
    onLabelClick?: () => void,
    key: string;
    closeable?: boolean;
    multiple?: boolean;
    index?: number; // pouze pro multiple
    render: (props: FilterItemProps) => React.ReactNode;
}

export interface TableFilterProps {
    className?: string;
    items: FilterItem[];
    values?: any;
    defaultValues?: any;
    readOnly?: boolean;
    immediateMode?: boolean,
    onChange: (values: any) => void;
}

export function TableFilter(props: TableFilterProps) {
    const [activeItems, setActiveItems] = useState<FilterItem[]>([]);
    const [immediateMode, setImmediateMode] = useState(true);
    const [values, setValues] = useState(props.defaultValues);

    const availableItems = props.items.filter(value => value.multiple || !activeItems.map(item => item.name).includes(value.name));

    useEffect(() => {
        if (props.immediateMode !== undefined) {
            setImmediateMode(props.immediateMode);
        }
    }, [props.immediateMode]);

    useEffect(() => {
        if (props.values !== undefined) {
            const matchedItems = props.items
                .filter(item => Object.hasOwn(props.values, item.name))
                .flatMap(item => {
                    if (item.multiple) {
                        return (props.values[item.name] || []).map((value: any, index: number) => {
                            return Object.assign({}, item, {index});
                        })
                    } else {
                        return [item];
                    }
                })

            setActiveItems(matchedItems);

            setValues(props.values);
        }
    }, [props]);

    useEffect(() => {
        if (immediateMode) {
            props.onChange(values);
        }
    }, [values]);

    return (
        <div className={props.className}>
            <Card className={styles.card}>
                <div className={styles.label}>Filter:</div>

                <div className={styles.line}>
                    <div className={styles["editor-list"]}>
                        {activeItems.length === 0 && <span className={styles["no-filter-applied"]}>No conditions applied</span>}

                        {activeItems.map(item => renderFilterItem(item))}

                        {immediateMode === false && <Button type={"primary"} icon={<SearchOutlined/>} title={"Search"} onClick={onSearch}/>}

                        {availableItems.length > 0 &&
                            <Dropdown
                                menu={{
                                    items: [{
                                        type: 'group',
                                        label: 'Add condition',
                                        children: availableItems.map(value => ({
                                            key: value.name,
                                            label: value.label
                                        })),
                                    }],
                                    onClick: onClickFilterItem
                                }}
                                trigger={["hover"]}
                            >
                                <Button icon={<PlusOutlined/>}/>
                            </Dropdown>
                        }

                        {props.defaultValues && <Button icon={<ClearOutlined/>} title={"Reset filter"} onClick={resetFilter}/>}
                    </div>
                </div>
            </Card>
        </div>
    )

    function resetFilter() {
        if (props.defaultValues) {
            props?.onChange(props.defaultValues);
        }
    }

    function renderFilterItem(item: FilterItem) {
        if (item.multiple) {
            return (
                <FilterEditor key={`${item.key}_${item.index}`}
                              label={item.label}
                              labelCursor={item.labelCursor}
                              onLabelClick={item.onLabelClick}
                              closeable={item.closeable}
                              onClear={() => onEditorClear(item)}
                              onClose={() => onEditorClose(item)}>
                    {item.render({
                        value: (values[item.name]) ? values[item.name][item.index!] : undefined,
                        onChange: (value) => onChangeMultipleFilterItem(item, value)
                    })}
                </FilterEditor>
            )
        } else {
            return (
                <FilterEditor key={item.key}
                              label={item.label}
                              labelCursor={item.labelCursor}
                              onLabelClick={item.onLabelClick}
                              closeable={item.closeable}
                              onClear={() => onEditorClear(item)}
                              onClose={() => onEditorClose(item)}>
                    {item.render({
                        value: values[item.name],
                        onChange: (value) => onChangeFilterItem(item, value)
                    })}
                </FilterEditor>
            )
        }
    }

    function onChangeFilterItem(item: FilterItem, value: any) {
        setValues({
            ...values,
            [item.name]: value
        });
    }

    function onChangeMultipleFilterItem(item: FilterItem, value: any) {
        setValues({
            ...values,
            [item.name]: values[item.name].map((val: any, idx: number) => {
                if (idx === item.index) {
                    return value;
                } else {
                    return values[item.name][idx]
                }
            })
        });
    }

    function onEditorClose(item: FilterItem) {
        setActiveItems(prevState => prevState.filter(value => value !== item));

        let value: any = undefined;

        if (item.multiple) {
            value = (values[item.name] || []).filter((val: any, idx: number) => idx !== item.index);

            setValues(Object.assign({}, values, {[item.name]: value}))
        } else {
            const clone = Object.assign({}, values);

            delete clone[item.name];

            setValues(clone)
        }
    }

    function onEditorClear(item: FilterItem) {
        setValues(Object.assign({}, values, {[item.name]: undefined}))
    }

    function onClickFilterItem(e: any) {
        const item = props.items.find(value => value.name === e.key);

        if (item !== undefined) {
            if (item.multiple) {
                const newValues = Object.assign({}, values, {[item.name]: (values[item.name] || []).concat({name: undefined, value: undefined})});

                setValues(newValues);

                props.onChange(newValues);
            } else {
                const newValues = Object.assign({}, values, {[item.name]: undefined});

                setValues(newValues);

                props.onChange(newValues);
            }
        }
    }

    function onSearch() {
        props.onChange(values);
    }

}

