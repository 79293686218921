import React from "react";
import {FilterItem, FilterItemProps, InputEditor, SelectEditor, TableFilter} from "../tablefilter/TableFilter";
import {FindingSeverity} from "../../domain/FindingSeverity";
import {Checkbox} from "antd";

interface SeverityProps extends FilterItemProps {
    severities: FindingSeverity[];
}

function SeverityItem(props: SeverityProps) {
    return (
        <SelectEditor
            options={Object.keys(FindingSeverity).map(severity => ({
                value: severity,
                label: <span style={{display: "inline-block"}} className={"selection-item-text"}>&gt;= {severity}</span>
            }))}
            multiple={false}
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

function NameItem(props: FilterItemProps) {
    return (
        <InputEditor
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

function HideNotAffectedItem(props: FilterItemProps) {
    return (
        <Checkbox checked={props.value} onChange={value => props.onChange(value.target.checked)} />
    )
}

function HideAnalyzedItem(props: FilterItemProps) {
    return (
        <Checkbox checked={props.value} onChange={value => props.onChange(value.target.checked)} />
    )
}

function OnlyDirectItem(props: FilterItemProps) {
    return (
        <Checkbox checked={props.value} onChange={value => props.onChange(value.target.checked)} />
    )
}

interface FilterProps {
    className?: string;
    values?: any;
    onChange: (values: any) => void;
    readOnly: boolean;
    immediateMode?: boolean;
}

function SbomProjectVersionVulnerableComponentFilter(filterProps: FilterProps) {
    const items: FilterItem[] = [
        {
            name: "name",
            key: "name",
            label: "Name",
            multiple: false,
            render: (props) => <NameItem {...props} />
        },
        {
            name: "severity",
            key: "severity",
            label: "Finding severity",
            multiple: false,
            render: (props) => <SeverityItem {...props} />
        },
        {
            name: "onlyDirect",
            key: "onlyDirect",
            label: "Only direct dependencies",
            labelCursor: "pointer",
            onLabelClick: () => {
                filterProps.onChange({
                    ...filterProps.values,
                    onlyDirect: !filterProps.values.onlyDirect
                })
            },
            multiple: false,
            render: (props) => <OnlyDirectItem {...props} />
        },
        {
            name: "hideNotAffected",
            key: "hideNotAffected",
            label: "Hide not affected",
            labelCursor: "pointer",
            onLabelClick: () => {
                filterProps.onChange({
                    ...filterProps.values,
                    hideNotAffected: !filterProps.values.hideNotAffected
                })
            },
            multiple: false,
            render: (props) => <HideNotAffectedItem {...props} />
        },
        {
            name: "hideAnalysed",
            key: "hideAnalysed",
            label: "Hide analysed",
            labelCursor: "pointer",
            onLabelClick: () => {
                filterProps.onChange({
                    ...filterProps.values,
                    hideAnalysed: !filterProps.values.hideAnalysed
                })
            },
            multiple: false,
            render: (props) => <HideAnalyzedItem {...props} />
        },
    ];

    return (
        <TableFilter
            items={items}
            className={filterProps.className}
            values={filterProps.values}
            defaultValues={{severity: [], name: "", onlyDirect: false, hideNotAffected: true, hideAnalysed: false}}
            readOnly={filterProps.readOnly}
            immediateMode={filterProps.immediateMode}
            onChange={onChange}
        />
    )

    function onChange(values: any) {
        filterProps.onChange(values);
    }

}

export default SbomProjectVersionVulnerableComponentFilter;