import {DeleteFilled, ReloadOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, Card, Col, Form, Input, message, Modal, Row, Space, Table} from "antd";
import Column from "antd/es/table/Column";
import {useForm} from "antd/lib/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, TicketAttributeRuleServiceContext} from "../../Contexts";
import TicketAttributeRule from "../../domain/TicketAttributeRule";
import {FilterBuilder} from "../../sal-ui/filter/FilterBuilder";
import {useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";
import {DocumentTitle} from "../DocumentTitle";
import {syntaxHighlight} from "../../utils/FormatUtils";
import * as globalStyles from "../App.module.css";

import * as styles from "./TicketAttributeRuleList.module.css";

function TicketAttributeRuleList() {
    const appContext = useContext(AppContextContext);
    const ticketAttributeRuleService = useContext(TicketAttributeRuleServiceContext);
    const navigate = useNavigate();
    const tableHandler = useTableHandler("id", {reloadFunction: reload});
    const [filterForm] = useForm();

    const [ticketAttributeRules, setTicketAttributeRules] = useState<PagedResult<TicketAttributeRule>>();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        reload();
    }, [])

    return (
        <DocumentTitle title={"TicketAttributeRule attribute rules"}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item>Ticket attribute rules</Breadcrumb.Item>
                </Breadcrumb>

                <h1>Ticket attribute rules</h1>

                {false && renderFilterForm()}

                <div className={globalStyles["common__top-button-bar"]}>
                    <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>
                </div>

                <Table className={styles.table}
                       showSorterTooltip={false}
                       loading={tableHandler.loading}
                       dataSource={ticketAttributeRules?.data}
                       size="middle"
                       onChange={tableHandler.onTableChange}
                       pagination={tableHandler.pagination}
                       onRow={onRow}
                       rowKey="id">

                    <Column dataIndex="id" title={"ID"} width={80} align={"center"} render={renderId}/>
                    <Column dataIndex="value" title={"Value"} sorter={true} sortDirections={["ascend", "descend", "ascend"]} defaultSortOrder={"ascend"} width={120}/>
                    <Column dataIndex="operationType" title={"Operation type"} sorter={true} sortDirections={["ascend", "descend", "ascend"]} width={80} align={"center"}/>

                    <Column dataIndex="attributeDefinition" title={"Attribute definitions"} sorter={true} sortDirections={["ascend", "descend", "ascend"]} align={"left"} render={value => <div className={globalStyles["formatted-json"]} dangerouslySetInnerHTML={{__html: `${value && syntaxHighlight(value)}`}} />}/>
                    <Column dataIndex="ticketCreationDefinition" title={"Ticket rule"} sorter={true} sortDirections={["ascend", "descend", "ascend"]} align={"left"} render={value => <div className={globalStyles["formatted-json"]} dangerouslySetInnerHTML={{__html: `${value && syntaxHighlight(value)}`}} />}/>

                    <Column title={"Actions"} width='100px' render={renderAction}
                            className={globalStyles["table-actions"]}/>
                </Table>
            </>
        </DocumentTitle>
    )

    function renderId(value: string) {
        return (
            <span title={value}>{value.substring(0, 2) + '...' + value.substring(value.length - 2)}</span>
        )
    }

    function renderFilterForm() {
        return (
            <Card className={`${globalStyles["common__filter"]} ${styles.filter}`}>
                <Form form={filterForm} layout={"vertical"} onFinish={onFinishFilter} labelAlign={"left"}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name={"quickSearch"}
                                label={"Filter"}>
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={4}>
                            <Form.Item>
                                <Space>
                                    <Button type={"primary"}
                                            htmlType={"submit"}>{"Apply"}</Button>
                                    <Button htmlType={"reset"}
                                            onClick={onResetFilter}>{"Cancel"}</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        )
    }

    function onFinishFilter(values: any) {
        const builder = new FilterBuilder();

        values.quickSearch && builder.quickSearch(values.quickSearch);

        tableHandler.onSearchSubmit(builder.build());
    }

    function onResetFilter() {
        tableHandler.onSearchSubmit('');

        filterForm.resetFields();
    }

    function renderTitle(value: any, attributeRule: TicketAttributeRule, index: number) {
        return <Link to={`/ticket-attribute-rules/${attributeRule.id}`}>{value}</Link>;
    }


    function renderAction(value: any, attributeRule: TicketAttributeRule, index: number) {
        return (
            <>
                <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                        title={"Delete"}
                        onClick={() => {
                            Modal.confirm({
                                content: "Do you really want to delete this item?",
                                okText: "Delete",
                                cancelText: "Cancel",
                                onOk: () => onDeleteConfirm(attributeRule)
                            });
                        }}/>
            </>
        )
    }

    function onDeleteConfirm(attributeRule: TicketAttributeRule) {
        ticketAttributeRuleService.delete(attributeRule)
            .then(() => {
                message.success("Item successfully deleted.");

                reload();
            });
    }

    function reload() {
        return ticketAttributeRuleService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setTicketAttributeRules(value);
        });
    }

    function onRow(attributeRule: TicketAttributeRule, index?: number) {
        return {onDoubleClick: () => navigate(`/ticket-attribute-rules/${attributeRule.id}`)}
    }
}

export default TicketAttributeRuleList;
