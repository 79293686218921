import React from "react";
import {FilterItem, FilterItemProps, InputEditor, SelectEditor, TableFilter} from "../tablefilter/TableFilter";

interface LicensesProps extends FilterItemProps {
    licenses?: string[];
}

function LicensesItem(props: LicensesProps) {
    return (
        <SelectEditor
            options={props.licenses?.map(license => ({
                value: license,
                label: <span style={{display: "inline-block"}} className={"selection-item-text"}>{license}</span>
            }))}
            multiple={true}
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

function NameItem(props: FilterItemProps) {
    return (
        <InputEditor
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

interface FilterProps {
    className?: string;
    values?: any;
    onChange: (values: any) => void;
    readOnly: boolean;
    immediateMode?: boolean;
    licenses?: string[]
}

function SoftwareLicenseOverrideFilter(filterProps: FilterProps) {
    const items: FilterItem[] = [
        {
            name: "purl",
            key: "purl",
            label: "Package URL",
            multiple: false,
            render: (props) => <NameItem {...props} />
        },
        {
            name: "licenses",
            key: "licenses",
            label: "Licenses",
            multiple: false,
            render: (props) => <LicensesItem {...props} licenses={filterProps.licenses}/>
        },
    ];

    return (
        <TableFilter
            items={items}
            className={filterProps.className}
            values={filterProps.values}
            defaultValues={{purl: "", licenses: []}}
            readOnly={filterProps.readOnly}
            immediateMode={filterProps.immediateMode}
            onChange={onChange}
        />
    )

    function onChange(values: any) {
        filterProps.onChange(values);
    }

}

export default SoftwareLicenseOverrideFilter;