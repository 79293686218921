import {Tag} from "antd";
import React from "react";
import * as styles from "./FindingStatistics.module.css";
import {FindingSeverity} from "../../domain/FindingSeverity";

interface IProps {
    findingStatistics: any;
    compact?: boolean;
}

function FindingStatistics(props: IProps) {
    if (props.findingStatistics === undefined || props.findingStatistics === null) {
        return;
    }

    if (Object.keys(props.findingStatistics).length == 0) {
        return <span>
            <Tag className={`${styles['status']} ${props.compact && styles.compact} ${styles['finding-NO_VULNERABLE']}`} bordered={false}>
                None
            </Tag>
        </span>;
    }

    const keys = Object.keys(FindingSeverity);

    return <span>
        {Object.keys(props.findingStatistics).sort((a, b) => keys.indexOf(a) - keys.indexOf(b)).map(key => {
            return <Tag key={key} className={`${styles['status']} ${props.compact && styles.compact} ${styles[statusClass(key as FindingSeverity)]}`} bordered={false}>
                <b>{key.substring(0, 1).toUpperCase()}</b>: {props.findingStatistics[key]}
            </Tag>
        })}
    </span>;

    function statusClass(severity: FindingSeverity) {
        switch (severity) {
            case FindingSeverity.UNKNOWN:
            case FindingSeverity.NONE:
                return "finding-NONE";
            case FindingSeverity.LOW:
                return "finding-LOW";
            case FindingSeverity.MEDIUM:
                return "finding-MEDIUM";
            case FindingSeverity.HIGH:
                return "finding-HIGH";
            case FindingSeverity.CRITICAL:
                return "finding-CRITICAL";
        }
    }
}

export default FindingStatistics;