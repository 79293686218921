import Organization from "./Organization";
import {SoftwareLicenseOrigin} from "./SoftwareLicenseOrigin";
import {ResourceOwnerType} from "./ResourceOwnerType";

class SoftwareLicense {

    constructor(
        public id?: string,
        public origin?: SoftwareLicenseOrigin,
        public licenseKey?: string,
        public shortName?: string,
        public name?: string,
        public spdxLicenseKey?: string,
        public otherSpdxLicenseKeys?: string[],
        public aliases?: string[],
        public licenseOwner?: string,
        public category?: string,
        public text?: string,
        public homepageUrl?: string,
        public owner?: ResourceOwnerType,
        public lastModified?: string,
        public organization?: Organization) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const license = new SoftwareLicense();

        license.id = data.id;
        license.origin = data.origin;
        license.licenseKey = data.licenseKey;
        license.shortName = data.shortName;
        license.name = data.name;
        license.spdxLicenseKey = data.spdxLicenseKey;
        license.otherSpdxLicenseKeys = data.otherSpdxLicenseKeys;
        license.aliases = data.aliases;
        license.licenseOwner = data.licenseOwner;
        license.category = data.category;
        license.text = data.text;
        license.homepageUrl = data.homepageUrl;
        license.owner = data.owner;
        license.lastModified = data.lastModified;
        license.organization = (data.organization) ? Organization.fromPOJO(data.organization) : undefined;

        return license;
    }

    public get allSpdxLicenseKeys() {
        const keys: string[] = [];

        if (this.spdxLicenseKey) {
            keys.push(this.spdxLicenseKey);
        }

        if (this.otherSpdxLicenseKeys) {
            keys.push(...this.otherSpdxLicenseKeys)
        }

        return keys;
    }

}

export default SoftwareLicense;
