import SbomComponentFinding from "../../domain/SbomComponentFinding";
import {Button, Form, Select} from "antd";
import * as globalStyles from "../App.module.css";
import * as styles from "./FindingStatusForm.module.css";
import {FindingStatus, formatFindingStatus} from "../../domain/FindingStatus";
import TextArea from "antd/lib/input/TextArea";
import {FindingJustification, formatFindingJustification} from "../../domain/FindingJustification";
import React, {useContext, useEffect, useState} from "react";
import {useForm, useWatch} from "antd/es/form/Form";
import {SbomProjectVersionServiceContext} from "../../Contexts";
import SbomProjectVersion from "../../domain/SbomProjectVersion";
import QueryOptions from "../../sal-ui/QueryOptions";
import SbomProject from "../../domain/SbomProject";

interface IProps {
    allowModification: boolean;
    project?: SbomProject;
    finding?: SbomComponentFinding;
    onCancel: (finding: SbomComponentFinding) => void;
    onFinish: (finding: SbomComponentFinding, values: any) => void;
}

function FindingStatusForm(props: IProps) {
    const sbomProjectVersionService = useContext(SbomProjectVersionServiceContext);
    const [projectVersions, setProjectVersions] = useState<SbomProjectVersion[]>();
    const [form] = useForm();
    const findingForEditStatus = useWatch("status", form);
    const projectVersionScope = useWatch(["updateContext", "projectVersionScope"], form);

    const layout = {
        labelCol: {span: 5},
        wrapperCol: {span: 19},
    };

    const tailLayout = {
        wrapperCol: {offset: 5, span: 19},
    };

    useEffect(() => {
        if (props.finding === undefined) {
            return;
        }

        sbomProjectVersionService.getList(props.project.id, QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setProjectVersions(value.data));
    }, []);

    return <>
        <Form {...layout} disabled={!props.allowModification} form={form} className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]} ${styles.form}`} onFinish={values => props.onFinish(props.finding, values)}>
            <Form.Item
                name={"status"}
                label={"Status"}
                initialValue={props.finding?.status}
                extra={"Impact of vulnerability."}
                rules={[{required: true, message: "Status is required."}]}>
                <Select
                    options={Object.keys(FindingStatus).map(key => {
                        return {
                            key: key,
                            label: formatFindingStatus(key as FindingStatus),
                            value: key
                        }
                    })}
                />
            </Form.Item>

            {findingForEditStatus === FindingStatus.AFFECTED && <Form.Item
                name={"actionStatement"}
                initialValue={props.finding?.actionStatement}
                extra={"Actions to remediate or mitigate vulnerability."}
                label={"Action statement"}>
                <TextArea maxLength={10000} autoSize={{minRows: 4, maxRows: 8}}/>
            </Form.Item>}

            {findingForEditStatus === FindingStatus.NOT_AFFECTED && <Form.Item
                name={"justification"}
                extra={"Justification statement of why component is not affected."}
                label={"Justification"}
                initialValue={props.finding?.justification}
                rules={[{required: true, message: "Justification is required."}]}>
                <Select>
                    {Object.keys(FindingJustification).map(value => <Select.Option key={value} value={value}>{formatFindingJustification(value as FindingJustification)}</Select.Option>)}
                </Select>
            </Form.Item>}

            {findingForEditStatus === FindingStatus.NOT_AFFECTED && <Form.Item
                name={"impactStatement"}
                initialValue={props.finding?.impactStatement}
                extra={"Optional explanation of how or why component is not affected."}
                label={"Impact statement"}>
                <TextArea maxLength={10000} disabled={!props.allowModification} autoSize={{minRows: 4, maxRows: 8}}/>
            </Form.Item>}

            <Form.Item label={"Apply to"}>
                <Form.Item
                    name={["updateContext", "componentScope"]}
                    noStyle={true}
                    initialValue={'THIS_COMPONENT'}>
                    <Select style={{width: 'auto'}} variant={"borderless"} options={[
                        {value: 'THIS_COMPONENT', label: 'only this component'},
                        {value: 'ALL_AFFECTED_COMPONENTS', label: 'all affected components'},
                    ]}/>
                </Form.Item>
                <span style={{margin: '0 4px'}}>with</span>
                <Form.Item
                    name={["updateContext", "statusScope"]}
                    noStyle={true}
                    initialValue={'UNKNOWN_STATUS'}>
                    <Select style={{width: 'auto'}} variant={"borderless"} options={[
                        {value: 'UNKNOWN_STATUS', label: 'unknown'},
                        {value: 'ANY_STATUS', label: 'any'},
                    ]}/>
                </Form.Item>
                <span style={{margin: '0 4px'}}>finding status in</span>
                <Form.Item
                    name={["updateContext", "projectVersionScope"]}
                    noStyle={true}
                    initialValue={'THIS_PROJECT_VERSION'}>
                    <Select style={{width: 'auto'}} variant={"borderless"} options={[
                        {value: 'THIS_PROJECT_VERSION', label: 'this project version'},
                        {value: 'ALL_PROJECT_VERSIONS', label: 'all project versions'},
                        {value: 'SELECTED_VERSIONS', label: 'selected versions'},
                    ]}/>
                </Form.Item>
                {projectVersionScope == 'SELECTED_VERSIONS' && <Form.Item
                    name={["updateContext", "projectVersions"]}
                    noStyle={true}>
                    <Select className={styles['versions-select']}
                            mode={"multiple"}
                            options={projectVersions?.map(version => {
                                return {
                                    label: version.name,
                                    value: version.id
                                }
                            })}/>
                </Form.Item>}
            </Form.Item>

            {props.allowModification &&
                <Form.Item {...tailLayout} className={`${globalStyles["common__form-buttons"]}  `}>
                    <Button type={"primary"} htmlType={"submit"}>{"Apply"}</Button>
                    <Button onClick={() => props.onCancel(props.finding)}>{"Cancel"}</Button>
                </Form.Item>
            }
        </Form>
    </>;
}

export default FindingStatusForm;