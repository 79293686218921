import {RouteObject,} from "react-router-dom";
import AttributeDefinitionAdd from "./component/attributedefinition/AttributeDefinitionAdd";
import AttributeDefinitionDetail from "./component/attributedefinition/AttributeDefinitionDetail";
import AttributeDefinitionList from "./component/attributedefinition/AttributeDefinitionList";
import AttributeExtractionDefinitionAdd from "./component/attributeextractiondefinition/AttributeExtractionDefinitionAdd";
import AttributeExtractionDefinitionDetail from "./component/attributeextractiondefinition/AttributeExtractionDefinitionDetail";
import AuditLogList from "./component/auditlog/AuditLogList";
import HomePage from "./component/homepage/HomePage";
import OrganizationAdd from "./component/organization/OrganizationAdd";
import OrganizationDetail from "./component/organization/OrganizationDetail";
import OrganizationList from "./component/organization/OrganizationList";
import TicketAdd from "./component/ticket/TicketAdd";
import TicketDetail from "./component/ticket/TicketDetail";
import TicketList from "./component/ticket/TicketList";
import TicketAttributeRuleAdd from "./component/ticketattributerule/TicketAttributeRuleAdd";
import TicketAttributeRuleDetail from "./component/ticketattributerule/TicketAttributeRuleDetail";
import TicketAttributeRuleList from "./component/ticketattributerule/TicketAttributeRuleList";
import TicketCreationDefinitionAdd from "./component/ticketcreationdefinition/TicketCreationDefinitionAdd";
import TicketCreationDefinitionDetail from "./component/ticketcreationdefinition/TicketCreationDefinitionDetail";
import TicketCreationDefinitionList from "./component/ticketcreationdefinition/TicketCreationDefinitionList";
import UserAdd from "./component/user/UserAdd";
import UserDetail from "./component/user/UserDetail";
import UserList from "./component/user/UserList";
import VulSourceAdd from "./component/vulsource/VulSourceAdd";
import VulSourceDetail from "./component/vulsource/VulSourceDetail";
import VulSourceList from "./component/vulsource/VulSourceList";
import VulSourceItemDetail from "./component/vulsourceitem/VulSourceItemDetail";
import VulSourceItemList from "./component/vulsourceitem/VulSourceItemList";
import VulViewAdd from "./component/vulview/VulViewAdd";
import VulViewList from "./component/vulview/VulViewList";
import VulViewDetail from "./component/vulview/VulViewDetail";
import VulReportDefinitionList from "./component/vulreportdefinition/VulReportDefinitionList";
import VulReportDefinitionAdd from "./component/vulreportdefinition/VulReportDefinitionAdd";
import VulReportDefinitionDetail from "./component/vulreportdefinition/VulReportDefinitionDetail";
import VulReportList from "./component/vulreport/VulReportList";
import VulReportAdd from "./component/vulreport/VulReportAdd";
import VulReportDetail from "./component/vulreport/VulReportDetail";
import SbomProjectList from "./component/sbomproject/SbomProjectList";
import SbomProjectDetail from "./component/sbomproject/SbomProjectDetail";
import SbomProjectVersionDetail from "./component/sbomproject/SbomProjectVersionDetail";
import SbomProjectAdd from "./component/sbomproject/SbomProjectAdd";
import SbomProjectVersionAdd from "./component/sbomproject/SbomProjectVersionAdd";
import ImportExport from "./component/importexport/ImportExport";
import ApiTokenAdd from "./component/apitoken/ApiTokenAdd";
import ApiTokenList from "./component/apitoken/ApiTokenList";
import RoleList from "./component/role/RoleList";
import RoleDetail from "./component/role/RoleDetail";
import RoleAdd from "./component/role/RoleAdd";
import React from "react";
import Dashboard from "./component/Dashboard";
import SbomProjectReportDefinitionList from "./component/sbomprojectreportdefinition/SbomProjectReportDefinitionList";
import SbomProjectReportDefinitionAdd from "./component/sbomprojectreportdefinition/SbomProjectReportDefinitionAdd";
import SbomProjectReportDefinitionDetail from "./component/sbomprojectreportdefinition/SbomProjectReportDefinitionDetail";
import VulSourceHealthReportDefinitionAdd from "./component/vulsourcehealthreportdefinition/VulSourceHealthReportDefinitionAdd";
import VulSourceHealthReportDefinitionDetail from "./component/vulsourcehealthreportdefinition/VulSourceHealthReportDefinitionDetail";
import VulSourceHealthReportDefinitionList from "./component/vulsourcehealthreportdefinition/VulSourceHealthReportDefinitionList";
import SbomComponentList from "./component/sbomcomponent/SbomComponentList";
import SoftwareLicenseList from "./component/softwarelicense/SoftwareLicenseList";
import SoftwareLicenseDetail from "./component/softwarelicense/SoftwareLicenseDetail";
import SoftwareLicensePolicyList from "./component/softwarelicensepolicy/SoftwareLicensePolicyList";
import SoftwareLicensePolicyAdd from "./component/softwarelicensepolicy/SoftwareLicensePolicyAdd";
import SoftwareLicensePolicyDetail from "./component/softwarelicensepolicy/SoftwareLicensePolicyDetail";
import SoftwareLicenseOverrideList from "./component/softwarelicenseoverride/SoftwareLicenseOverrideList";
import SoftwareLicenseOverrideAdd from "./component/softwarelicenseoverride/SoftwareLicenseOverrideAdd";
import SoftwareLicenseOverrideDetail from "./component/softwarelicenseoverride/SoftwareLicenseOverrideDetail";
import SbomProjectVersionChangeLog from "./component/sbomproject/SbomProjectVersionChangeLog";

export const routes: RouteObject[] = [
    {
        element: <HomePage/>,
        children: [
            {
                index: true,
                element: <Dashboard/>,
                handle: {
                    menuKey: "dashboard",
                    menuOpenKey: "dashboard"
                }
            },
            {
                path: "/vul-source-items",
                element: <VulSourceItemList/>,
                handle: {
                    menuKey: "vul-source-items",
                    menuOpenKey: "vul-source-items"
                }
            },
            {
                path: "/vul-source-items/:vulSourceItemId",
                element: <VulSourceItemDetail/>,
                handle: {
                    menuKey: "vul-source-items",
                    menuOpenKey: "vul-source-items"
                }
            },
            {
                path: "/vul-sources",
                element: <VulSourceList/>,
                handle: {
                    menuKey: "vul-sources",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/vul-sources/:vulSourceId",
                element: <VulSourceDetail/>,
                handle: {
                    menuKey: "vul-sources",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/vul-sources/add",
                element: <VulSourceAdd/>,
                handle: {
                    menuKey: "vul-sources",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/tickets",
                element: <TicketList/>,
                handle: {
                    menuKey: "tickets",
                    menuOpenKey: "tickets"
                }
            },
            {
                path: "/tickets/add",
                element: <TicketAdd/>,
                handle: {
                    menuKey: "tickets",
                    menuOpenKey: "tickets"
                }
            },
            {
                path: "/tickets/:ticketId",
                element: <TicketDetail/>,
                handle: {
                    menuKey: "tickets",
                    menuOpenKey: "tickets"
                }
            },
            {
                path: "/definitions/ticket-rules",
                element: <TicketCreationDefinitionList/>,
                handle: {
                    menuKey: "tickets/rules",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/definitions/ticket-rules/add",
                element: <TicketCreationDefinitionAdd/>,
                handle: {
                    menuKey: "tickets/rules",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/definitions/ticket-rules/:ruleId",
                element: <TicketCreationDefinitionDetail/>,
                handle: {
                    menuKey: "tickets/rules",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/definitions/ticket-attribute-rules",
                element: <TicketAttributeRuleList/>,
                handle: {
                    menuKey: "tickets/rules",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/definitions/ticket-attribute-rules/add",
                element: <TicketAttributeRuleAdd/>,
                handle: {
                    menuKey: "tickets/rules",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/definitions/ticket-attribute-rules/:ruleId",
                element: <TicketAttributeRuleDetail/>,
                handle: {
                    menuKey: "tickets/rules",
                    menuOpenKey: "definitions"
                }
            },

            {
                path: "/audit-logs",
                element: <AuditLogList/>,
                handle: {
                    menuKey: "audit-logs",
                    menuOpenKey: "audit-logs"
                }
            },
            {
                path: "/attributes/definitions",
                element: <AttributeDefinitionList/>,
                handle: {
                    menuKey: "attributes/definitions",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/attributes/definitions/:attributeDefinitionId",
                element: <AttributeDefinitionDetail/>,
                handle: {
                    menuKey: "attributes/definitions",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/attributes/definitions/add",
                element: <AttributeDefinitionAdd/>,
                handle: {
                    menuKey: "attributes/definitions",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/attributes/extraction-definitions/:attributeExtractionDefinitionId",
                element: <AttributeExtractionDefinitionDetail/>,
                handle: {
                    menuKey: "attributes/definitions",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/attributes/:attributeDefinitionId/extraction-definitions/add",
                element: <AttributeExtractionDefinitionAdd/>,
                handle: {
                    menuKey: "attributes/definitions",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/vul-source-items/views/add",
                element: <VulViewAdd/>,
                handle: {
                    menuKey: "vul-source-items/views",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/vul-source-items/views/clone/:vulViewId",
                element: <VulViewAdd/>,
                handle: {
                    menuKey: "vul-source-items/views",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/vul-source-items/views/:vulViewId",
                element: <VulViewDetail/>,
                handle: {
                    menuKey: "vul-source-items/views",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/vul-source-items/views",
                element: <VulViewList/>,
                handle: {
                    menuKey: "vul-source-items/views",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/definitions/vul-reports/add",
                element: <VulReportDefinitionAdd/>,
                handle: {
                    menuKey: "vul-reports/definitions",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/definitions/vul-reports/clone/:definitionId",
                element: <VulReportDefinitionAdd/>,
                handle: {
                    menuKey: "vul-reports/definitions",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/definitions/vul-reports/:definitionId",
                element: <VulReportDefinitionDetail/>,
                handle: {
                    menuKey: "vul-reports/definitions",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/definitions/vul-reports",
                element: <VulReportDefinitionList/>,
                handle: {
                    menuKey: "vul-reports/definitions",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/vul-reports/add",
                element: <VulReportAdd/>,
                handle: {
                    menuKey: "vul-reports",
                    menuOpenKey: "vul-reports"
                }
            },
            {
                path: "/vul-reports/clone/:reportId",
                element: <VulReportAdd/>,
                handle: {
                    menuKey: "vul-reports",
                    menuOpenKey: "vul-reports"
                }
            },
            {
                path: "/vul-reports/:reportId",
                element: <VulReportDetail/>,
                handle: {
                    menuKey: "vul-reports",
                    menuOpenKey: "vul-reports"
                }
            },
            {
                path: "/vul-reports",
                element: <VulReportList/>,
                handle: {
                    menuKey: "vul-reports",
                    menuOpenKey: "vul-reports"
                }
            },
            {
                path: "/users",
                element: <UserList/>,
                handle: {
                    menuKey: "users",
                    menuOpenKey: "users-and-groups"
                }
            },
            {
                path: "/users/add",
                element: <UserAdd/>,
                handle: {
                    menuKey: "users",
                    menuOpenKey: "users-and-groups"
                }
            },
            {
                path: "/users/:userId",
                element: <UserDetail/>,
                handle: {
                    menuKey: "users",
                    menuOpenKey: "users-and-groups"
                }
            },
            {
                path: "/api-tokens",
                element: <ApiTokenList/>,
                handle: {
                    menuKey: "api-tokens",
                    menuOpenKey: "users-and-groups"
                }
            },
            {
                path: "/api-tokens/add",
                element: <ApiTokenAdd/>,
                handle: {
                    menuKey: "api-tokens",
                    menuOpenKey: "users-and-groups"
                }
            },
            {
                path: "/roles",
                element: <RoleList/>,
                handle: {
                    menuKey: "user-roles",
                    menuOpenKey: "users-and-groups"
                }
            },
            {
                path: "/roles/add",
                element: <RoleAdd/>,
                handle: {
                    menuKey: "user-roles",
                    menuOpenKey: "users-and-groups"
                }
            },
            {
                path: "/roles/:roleId",
                element: <RoleDetail/>,
                handle: {
                    menuKey: "user-roles",
                    menuOpenKey: "users-and-groups"
                }
            },
            {
                path: "/organizations",
                element: <OrganizationList/>,
                handle: {
                    menuKey: "organizations",
                    menuOpenKey: "organizations"
                }
            },
            {
                path: "/organizations/add",
                element: <OrganizationAdd/>,
                handle: {
                    menuKey: "organizations",
                    menuOpenKey: "organizations"
                }
            },
            {
                path: "/organizations/:organizationId",
                element: <OrganizationDetail/>,
                handle: {
                    menuKey: "organizations",
                    menuOpenKey: "organizations"
                }
            },
            {
                path: "/sbom-projects",
                element: <SbomProjectList/>,
                handle: {
                    menuKey: "sbom-projects",
                    menuOpenKey: "sbom"
                }
            },
            {
                path: "/sbom-projects/add",
                element: <SbomProjectAdd/>,
                handle: {
                    menuKey: "sbom-projects",
                    menuOpenKey: "sbom"
                }
            },
            {
                path: "/sbom-projects/:sbomProjectId",
                element: <SbomProjectDetail/>,
                handle: {
                    menuKey: "sbom-projects",
                    menuOpenKey: "sbom"
                }
            },
            {
                path: "/sbom-projects/:sbomProjectId/add",
                element: <SbomProjectVersionAdd/>,
                handle: {
                    menuKey: "sbom-projects",
                    menuOpenKey: "sbom"
                }
            },
            {
                path: "/sbom-projects/:sbomProjectId/:sbomProjectVersionId",
                element: <SbomProjectVersionDetail/>,
                handle: {
                    menuKey: "sbom-projects",
                    menuOpenKey: "sbom"
                }
            },
            {
                path: "/sbom-project-versions/:sbomProjectVersionId/change-log/:anotherProjectVersionId",
                element: <SbomProjectVersionChangeLog/>,
                handle: {
                    menuKey: "sbom-projects",
                    menuOpenKey: "sbom"
                }
            },

            {
                path: "/sbom-project-report-definitions/clone/:definitionId",
                element: <SbomProjectReportDefinitionAdd/>,
                handle: {
                    menuKey: "sbom-report-definitions",
                    menuOpenKey: "sbom"
                }
            },
            {
                path: "/sbom-project-report-definitions/add",
                element: <SbomProjectReportDefinitionAdd/>,
                handle: {
                    menuKey: "sbom-report-definitions",
                    menuOpenKey: "sbom"
                }
            },
            {
                path: "/sbom-project-report-definitions/:definitionId",
                element: <SbomProjectReportDefinitionDetail/>,
                handle: {
                    menuKey: "sbom-report-definitions",
                    menuOpenKey: "sbom"
                }
            },
            {
                path: "/sbom-project-report-definitions",
                element: <SbomProjectReportDefinitionList/>,
                handle: {
                    menuKey: "sbom-report-definitions",
                    menuOpenKey: "sbom"
                }
            },

            {
                path: "/sbom-components",
                element: <SbomComponentList/>,
                handle: {
                    menuKey: "sbom-components",
                    menuOpenKey: "sbom"
                }
            },

            {
                path: "/software-licenses",
                element: <SoftwareLicenseList/>,
                handle: {
                    menuKey: "software-licenses",
                    menuOpenKey: "sbom"
                }
            },
            {
                path: "/software-licenses/:licenseId",
                element: <SoftwareLicenseDetail/>,
                handle: {
                    menuKey: "software-licenses",
                    menuOpenKey: "sbom"
                }
            },

            {
                path: "/software-license-policies",
                element: <SoftwareLicensePolicyList/>,
                handle: {
                    menuKey: "software-license-policies",
                    menuOpenKey: "sbom"
                }
            },
            {
                path: "/software-license-policies/add",
                element: <SoftwareLicensePolicyAdd/>,
                handle: {
                    menuKey: "software-license-policies",
                    menuOpenKey: "sbom"
                }
            },
            {
                path: "/software-license-policies/:policyId",
                element: <SoftwareLicensePolicyDetail/>,
                handle: {
                    menuKey: "software-license-policies",
                    menuOpenKey: "sbom"
                }
            },

            {
                path: "/software-license-overrides",
                element: <SoftwareLicenseOverrideList/>,
                handle: {
                    menuKey: "software-license-overrides",
                    menuOpenKey: "sbom"
                }
            },
            {
                path: "/software-license-overrides/add",
                element: <SoftwareLicenseOverrideAdd/>,
                handle: {
                    menuKey: "software-license-overrides",
                    menuOpenKey: "sbom"
                }
            },
            {
                path: "/software-license-overrides/:overrideId",
                element: <SoftwareLicenseOverrideDetail/>,
                handle: {
                    menuKey: "software-license-overrides",
                    menuOpenKey: "sbom"
                }
            },

            {
                path: "/vul-source-health-report-definitions/clone/:definitionId",
                element: <VulSourceHealthReportDefinitionAdd/>,
                handle: {
                    menuKey: "vul-source-health-report-definitions",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/vul-source-health-report-definitions/add",
                element: <VulSourceHealthReportDefinitionAdd/>,
                handle: {
                    menuKey: "vul-source-health-report-definitions",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/vul-source-health-report-definitions/:definitionId",
                element: <VulSourceHealthReportDefinitionDetail/>,
                handle: {
                    menuKey: "vul-source-health-report-definitions",
                    menuOpenKey: "definitions"
                }
            },
            {
                path: "/vul-source-health-report-definitions",
                element: <VulSourceHealthReportDefinitionList/>,
                handle: {
                    menuKey: "vul-source-health-report-definitions",
                    menuOpenKey: "definitions"
                }
            },

            {
                path: "/import-export",
                element: <ImportExport/>,
                handle: {
                    menuKey: "import-export",
                    menuOpenKey: "import-export"
                }
            }
        ]
    }
];
