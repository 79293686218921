
export enum Feature {
    VULNERABILITY_ANALYSIS = "VULNERABILITY_ANALYSIS",
    SBOM = "SBOM"
}

export function formatFeature(feature?: Feature) {
    switch (feature) {
        case Feature.VULNERABILITY_ANALYSIS:
            return "Vulnerability analysis";
        case Feature.SBOM:
            return "SBOM";
    }
}
