import Organization from "./Organization";
import {ResourceOwnerType} from "./ResourceOwnerType";

class VulView {
    constructor(
        public id?: string,
        public name?: string,
        public description?: string,
        public query?: string,
        public itemCount?: number,
        public importedAt?: Date,
        public owner?: ResourceOwnerType,
        public organization?: Organization,
        public tags?: string[]) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const vulView = new VulView();

        vulView.id = data.id;
        vulView.name = data.name;
        vulView.description = data.description;
        vulView.query = data.query;
        vulView.itemCount = data.itemCount;
        vulView.importedAt = (data.importedAt) ? new Date(data.importedAt) : undefined;
        vulView.owner = data.owner;
        vulView.organization = Organization.fromPOJO(data.organization);
        vulView.tags = data.tags;

        return vulView;
    }

}

export function vulViewSystemFirstComparator(a: VulView, b: VulView) {
    if (a.owner === ResourceOwnerType.SYSTEM && b.owner === ResourceOwnerType.SYSTEM) {
        return 0;
    } else if (a.owner === ResourceOwnerType.SYSTEM && b.owner == ResourceOwnerType.ORGANIZATION) {
        return -1;
    } else if (a.owner === ResourceOwnerType.ORGANIZATION && b.owner === ResourceOwnerType.SYSTEM) {
        return 1;
    }

    return a.name.localeCompare(b.name);
}

export function vulViewSystemLastComparator(a: VulView, b: VulView) {
    if (a.owner === ResourceOwnerType.ORGANIZATION && b.owner === ResourceOwnerType.ORGANIZATION) {
        return 0;
    } else if (a.owner === ResourceOwnerType.ORGANIZATION && b.owner == ResourceOwnerType.SYSTEM) {
        return -1;
    } else if (a.owner === ResourceOwnerType.SYSTEM && b.owner === ResourceOwnerType.ORGANIZATION) {
        return 1;
    }

    return a.name.localeCompare(b.name);
}

export default VulView;
