import {createRoot} from "react-dom/client";
import App from "./component/App";
import AxiosService from "./service/AxiosService";
import {HelmetProvider} from "react-helmet-async";
import dayjs from 'dayjs'
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import "dayjs/locale/en";
import "dayjs/locale/cs";
import Axios from "axios";
import {appContext, authService} from "./Contexts";
import {AppConfig} from "./AppConfig";
import "./index.css";
import React from "react";

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

// detekce veřejných stránek

const publicUrlMatch = window.location.pathname.match(/^\/p\/([^/]+)\/.*/);

if (publicUrlMatch != null) {
    appContext.publicAccess = true;
    appContext.publicAccessUserId = publicUrlMatch[1];
}

// nastavení Axios interceptorů

AxiosService.setUpInterceptors(appContext);

Axios.get("/app-config").then(response => {
    appContext.config = AppConfig.fromPOJO(response.data);

    authService.getLoggedInUser()
        .then(
            admin => appContext.user = admin,
            () => appContext.user = null
        )
        .finally(() => {
            const container = document.getElementById("app");

            const root = createRoot(container)

            root.render(
                <HelmetProvider>
                    <App/>
                </HelmetProvider>,
            );
        });
});
