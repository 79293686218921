import {Breadcrumb, Button, Form, Input, message, Select} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext} from "../../Contexts";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import * as globalStyles from "../App.module.css";
import {Feature, formatFeature} from "../../domain/Feature";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function OrganizationAdd() {
    const appContext = useContext(AppContextContext);
    const organizationService = useContext(OrganizationServiceContext);
    const navigate = useNavigate();
    const [form] = useForm();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 16},
    };

    const title = "New organization";

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles[globalStyles["common__breadcrumb"]]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/organizations"}>{"Organizations"}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{title}</h1>

                <Form {...layout} form={form} className={`${globalStyles["common__form"]}`} onFinish={onFinish}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                            {validator: serverViolationsHolder.createServerValidator('UNIQUE', 'name', undefined, {compareLowerCaseValues: true}), message: "Name is already used."}
                        ]}>
                        <Input autoFocus={true} maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"organizationId"}
                        label={"Organization ID"}
                        rules={[
                            {required: true, message: "Organization ID is required."},
                            {validator: serverViolationsHolder.createServerValidator('UNIQUE', 'organizationId', undefined, {compareLowerCaseValues: true}), message: "Organizaton ID is already used."}
                        ]}>
                        <Input maxLength={50}/>
                    </Form.Item>

                    <Form.Item
                        name={["features"]}
                        label={"Features"}
                        rules={[{required: true, message: "Please select at least one feature."}]}>
                        <Select
                            mode="multiple"
                            allowClear
                            options={Object.values(Feature).map(feature => ({
                                label: formatFeature(feature),
                                value: feature
                            }))}
                        />
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate("/organizations")}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    )

    function onFinish(values: any) {
        organizationService.add(values)
            .then(
                (id) => {
                    message.success(<>Organization <b>{values.name}</b> successfully added.</>);

                    navigate(`/organizations/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }
}

export default OrganizationAdd;
