export enum TicketSeverity {
    UNKNOWN = "UNKNOWN",
    INFORMATIONAL = "INFORMATIONAL",
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
    CRITICAL = "CRITICAL"
}

export function getEqualOrGreaterSeverities(severity: TicketSeverity) {
    const index = Object.keys(TicketSeverity).indexOf(severity);

    return Object.keys(TicketSeverity).filter((value, idx) => idx >= index);
}
