.filter {
    width: 1100px;
}

.table {
    width: 1100px;
}

.versions-table {
    margin: 1em 0;
}

.versions-table :global(.ant-table-footer) {
    text-align: center;
}

.versions-table :global(.ant-table) {
    margin-inline: 0 !important;
}

.column-name {
    width: 40%;
}

.versions {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.versions > div > span {
    margin-left: 0.5em;
}
