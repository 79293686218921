import {LoadingOutlined} from "@ant-design/icons";
import Collapse from "@kunukn/react-collapse";
import {Breadcrumb, Button, Descriptions, Form, Input, message, Modal, Select, Spin} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, AttributeDefinitionServiceContext, VulReportServiceContext, VulSourceItemServiceContext} from "../../Contexts";
import VulReport from "../../domain/VulReport";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import *as globalStyles from "../App.module.css";
import {DocumentTitle} from "../DocumentTitle";
import dayjs from "dayjs";
import {formatDateTime} from "../../utils/FormatUtils";
import {TableUiConfig, useTableHandler} from "../../sal-ui/TableHandler";
import CreateTicketModal from "../ticket/CreateTicketModal";
import PagedResult from "../../service/PagedResult";
import VulSourceItem from "../../domain/VulSourceItem";
import VulSourceItemListTable from "../vulsourceitem/VulSourceItemListTable";
import {Permission} from "../../domain/auth/Permission";
import {ResourceOwnerType} from "../../domain/ResourceOwnerType";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

const defaultVisibleColumns = ["id", "description", "attributes", "sourcePublished"];

const defaultTableUiConfig = {immediateMode: false, visibleColumns: defaultVisibleColumns};

function VulReportDetail() {
    const persistentIdent = 'VulReportDetail';
    const appContext = useContext(AppContextContext);
    const vulReportService = useContext(VulReportServiceContext);
    const vulSourceItemService = useContext(VulSourceItemServiceContext);
    const attributeDefinitionService = useContext(AttributeDefinitionServiceContext);
    const navigate = useNavigate();
    const {reportId}: any = useParams();
    const tableHandler = useTableHandler("sourcePublished desc", {
        reloadFunction: reloadItems,
        initialPageSize: 25,
        persistentIdent: `${persistentIdent}`
    });
    const [editMode, setEditMode] = useState(false);
    const [sendReportMode, setSendReportMode] = useState(false);
    const [report, setReport] = useState<VulReport>();
    const [editForm] = useForm();
    const [sendReportForm] = useForm();
    const [vulSourceItems, setVulSourceItems] = useState<PagedResult<VulSourceItem>>();
    const [tableUiConfig, setTableUiConfig] = useState<TableUiConfig>(defaultTableUiConfig);
    const [createTicketFormVisible, setCreateTicketFormVisible] = useState<boolean>(false);
    const [vulSourceItem, setVulSourceItem] = useState<VulSourceItem>();
    const [itemCount, setItemCount] = useState<{ total: number, newItems: number, modifiedItems: number }>();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 16},
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        reload();

        setTableUiConfig(tableHandler.loadUiConfig() || defaultTableUiConfig);
    }, [])

    useEffect(() => {
        onFinishFilter();
    }, [report]);

    return (
        <DocumentTitle title={report?.name}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/vul-reports"}>Vulnerability reports</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{report?.name}</Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={report === undefined} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{report?.name}</h1>

                    <div className={globalStyles["common__top-button-bar"]}>
                        {appContext.user.hasPermission(Permission.REPORT__MANAGE) &&
                            <>
                                <Button onClick={() => setEditMode(!editMode)}>Edit</Button>

                                <Button danger={true}
                                        title={"Delete"}
                                        onClick={() => {
                                            Modal.confirm({
                                                content: "Do you really want to delete this report?",
                                                okText: "Delete",
                                                cancelText: "Cancel",
                                                okButtonProps: {danger: true},
                                                onOk: () => onDeleteConfirm()
                                            });
                                        }}>Delete</Button>

                                <Button title={"Regenerate report"}
                                        onClick={() => {
                                            Modal.confirm({
                                                content: "Do you really want to regenerate this report? This should be done if there are new vulnerability items for defined timestamp range or if vulnerability views definitions were updated from last report generation.",
                                                okText: "Regenerate",
                                                cancelText: "Cancel",
                                                onOk: () => onRegenerateConfirm()
                                            });
                                        }}>Regenerate report</Button>
                            </>
                        }

                        <Button onClick={() => setSendReportMode(!sendReportMode)}>Send report</Button>

                        {appContext.user.hasPermission(Permission.REPORT__MANAGE) && <Button title={"Clone"} onClick={() => navigate(`/vul-reports/clone/${report.id}`)}>Clone</Button>}
                    </div>

                    {renderEditForm()}
                    {renderSendReportForm()}

                    <Descriptions column={1} bordered={true} size="small" className={globalStyles.details}>
                        {appContext.user.isSystemAdmin() && <Descriptions.Item label={"Organization"}>
                            <Link to={`/organizations/${report?.organization?.id}`}>{report?.organization?.name}</Link>
                        </Descriptions.Item>}

                        {report?.definition && <Descriptions.Item label={"Vulnerability report definition"}><Link
                            to={`/definitions/vul-reports/${report?.definition?.id}`}>{report?.definition?.name}</Link></Descriptions.Item>}
                        {(appContext.user.hasPermission(Permission.VIEW__READ_ORG) || appContext.user.hasPermission(Permission.VIEW__READ_SEAL)) &&
                            <Descriptions.Item label={"Vulnerability views"}>
                                {report?.vulViews?.map(view => <>
                                        {view.owner === ResourceOwnerType.ORGANIZATION && appContext.user.hasPermission(Permission.VIEW__READ_ORG) && <div><Link key={view.id} to={`/vul-source-items/views/${view.id}`}>{view.name}</Link></div>}
                                        {view.owner === ResourceOwnerType.SYSTEM && appContext.user.hasPermission(Permission.VIEW__READ_SEAL) && <div><Link key={view.id} to={`/vul-source-items/views/${view.id}`}>{view.name}</Link></div>}
                                    </>
                                )}
                            </Descriptions.Item>
                        }

                        <Descriptions.Item label={"Date range"}>
                            {formatDateTime(report?.timestampFrom)} - {formatDateTime(report?.timestampTo)} ({report?.timeZone})
                        </Descriptions.Item>

                        <Descriptions.Item label={"Generated at"}>
                            {formatDateTime(report?.generatedAt)}
                        </Descriptions.Item>
                    </Descriptions>

                    <CreateTicketModal
                        visible={createTicketFormVisible}
                        onTicketCreate={() => setCreateTicketFormVisible(false)}
                        vulSourceItem={vulSourceItem}
                        onCancel={() => setCreateTicketFormVisible(false)}
                    />

                    <h2>Vulnerability items{itemCount && ` (${itemCount.newItems} new, ${itemCount.modifiedItems} modified)`}</h2>

                    <VulSourceItemListTable
                        persistentIdent={persistentIdent}
                        items={vulSourceItems}
                        tableHandler={tableHandler}
                        tableUiConfig={tableUiConfig}
                        setTableUiConfig={setTableUiConfig}
                    />
                </Spin>
            </>
        </DocumentTitle>
    )

    function renderEditForm() {
        return (
            <Collapse isOpen={editMode}>
                <h3>Edit of vulnerability report</h3>

                <Form {...layout} form={editForm} className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`} onFinish={onFinishEdit}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."}
                        ]}>
                        <Input autoFocus={true} maxLength={100}/>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => setEditMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function renderSendReportForm() {
        return (
            <Collapse isOpen={sendReportMode}>
                <h3>Send report</h3>

                <Form {...layout} form={sendReportForm} className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`} onFinish={onSendReport}>

                    <Form.Item
                        name={"targetType"}
                        label={"Notificaton type"}
                        initialValue={"EMAIL"}>
                        <Select>
                            <Select.Option value={"EMAIL"}>E-Mail</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={"target"}
                        label={"Destination"}
                        rules={[
                            {required: true, message: "Destination is required."}
                        ]}>
                        <Input autoFocus={true} maxLength={100}/>
                    </Form.Item>


                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Send"}</Button>
                        <Button onClick={() => setSendReportMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function onSendReport(values: any) {
        vulReportService.sendReport(report, values.targetType, values.target)
            .then(() => {
                message.success("Vulnerability report sent.");

                setSendReportMode(false);
            });
    }

    function onFinishEdit(values: any) {
        vulReportService.update(reportId, values)
            .then(
                () => {
                    message.success("Vulnerability report updated.");

                    setEditMode(false);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }

    function onDeleteConfirm() {
        vulReportService.delete(report)
            .then(() => {
                message.success("Vulnerability report deleted.");

                navigate(`/vul-reports`);
            });
    }

    function onRegenerateConfirm() {
        vulReportService.regenerateItems(report)
            .then(() => {
                message.success("Vulnerability report items regenerated.");

                reload();
            });
    }

    function onFinishFilter() {
        if (report === undefined) {
            return;
        }

        const rqbQuery = {
            combinator: "and",
            rules: []
        }

        rqbQuery.rules.push({
            field: "lastModifiedAttributes",
            operator: "ge",
            value: dayjs(report.timestampFrom).utc().format()
        });

        rqbQuery.rules.push({
            field: "lastModifiedAttributes",
            operator: "le",
            value: dayjs(report.timestampTo).utc().format()
        });

        rqbQuery.rules.push({
            field: "vulView.id",
            operator: "in",
            value: report.vulViews.map(value => value.id)
        });

        tableHandler.onRqbSearchSubmit(rqbQuery);
    }

    function reload() {
        vulReportService.get(reportId).then(report => {
            setReport(report);

            editForm.setFieldsValue({
                ...report,
                timestampFrom: dayjs(report.timestampFrom),
                timestampTo: dayjs(report.timestampTo),
                vulViews: report.vulViews.map(value => value.id)
            });
        });
    }

    function reloadItems() {
        return vulSourceItemService.getListForReport(tableHandler.queryOptions, report).then(value => {
            tableHandler.updateTotal(value.total);

            setVulSourceItems(value);
            setItemCount({total: value.total, newItems: value.newItems, modifiedItems: value.modifiedItems});
        });
    }

}

export default VulReportDetail;
