import React, {useContext, useEffect, useState} from "react";
import {AttributeExtractionDefinitionServiceContext} from "../../Contexts";
import AttributeExtractionDefinition from "../../domain/AttributeExtractionDefinition";
import {useTableHandler} from "../../sal-ui/TableHandler";
import {Descriptions, Table} from "antd";
import {filterObject} from "../../utils/FormatUtils";
import * as styles from "./AttributeExtractionDefinitionDetail.module.css";

function ExtractorReadOnlyList({attributeExtractionDefinitionId}: { attributeExtractionDefinitionId: string }) {
    const attributeExtractionDefinitionService = useContext(AttributeExtractionDefinitionServiceContext);
    const [attributeExtractionDefinition, setAttributeExtractionDefinition] = useState<AttributeExtractionDefinition>();
    const tableHandler = useTableHandler("priority", {initialPageSize: 1000, reloadFunction: null});

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        attributeExtractionDefinitionService.get(attributeExtractionDefinitionId!).then(attributeExtractionDefinition => {
            setAttributeExtractionDefinition(attributeExtractionDefinition);
        });
    }, [])

    return (
        <>
            <h2>Extractors</h2>

            <Table
                showSorterTooltip={false}
                loading={tableHandler.loading}
                dataSource={attributeExtractionDefinition?.extractors}
                size="small"
                onChange={tableHandler.onTableChange}
                pagination={false}
                rowKey="id"
                columns={[
                    {dataIndex: "id", title: "ID", width: 80, align: "center", render: value => <span title={value}>{value.substring(0, 2) + '...' + value.substring(value.length - 2)}</span>},
                    {dataIndex: "priority", title: "Priority", width: 80, align: "center"},
                    {dataIndex: "extractorType", title: "Extractor type", width: 160, align: "left"},
                    {
                        title: "Detail", align: "left", render: (value: any, attribute: any) => (
                            <Descriptions column={1} size={"small"} bordered={false} className={styles['extractor-description']}>
                                {Object.keys(filterObject(attribute, ['id', 'extractorType', 'priority'])).map(key => <Descriptions.Item key={key} label={key}>
                                    {value[key]}
                                </Descriptions.Item>)}
                            </Descriptions>
                        )
                    }
                ]}
            />

        </>
    )
}

export default ExtractorReadOnlyList;