export enum AuditLogSeverity {
    DEBUG = "DEBUG",
    INFO = "INFO",
    NOTICE = "NOTICE",
    WARNING = "WARNING",
    ERROR = "ERROR"
}

export function getEqualOrGreaterSeverities(severity: AuditLogSeverity) {
    const index = Object.keys(AuditLogSeverity).indexOf(severity);

    return Object.keys(AuditLogSeverity).filter((value, idx) => idx >= index);
}
