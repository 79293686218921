import {Breadcrumb, Button, Form, Input, message, Select, Switch} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, TicketCreationDefinitionServiceContext} from "../../Contexts";
import Organization from "../../domain/Organization";
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import * as globalStyles from "../App.module.css";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function TicketCreationDefinitionAdd() {
    const appContext = useContext(AppContextContext);
    const ticketCreationDefinitionService = useContext(TicketCreationDefinitionServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const navigate = useNavigate();
    const [form] = useForm();
    const [organizations, setOrganizations] = useState<Organization[]>();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 8, span: 16},
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        organizationService.getList(new QueryOptions("name", 100, 0)).then(value => {
            setOrganizations(value.data);
        });
    }, [])

    const title = "New ticket rule";

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={"common__breadcrumb"}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/definitions/ticket-rules"}>Ticket rules</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{title}</h1>

                <Form {...layout} form={form} className={`${globalStyles["common__form"]} ${globalStyles["common_form--add"]}`} onFinish={onFinish}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={["organization", "id"]}
                        label={"Organization"}
                        rules={[{required: true, message: "required"}]}>

                        <Select >
                            {organizations && organizations!.map(organization => {
                                return <Select.Option key={organization?.name} value={organization?.id!}>{organization?.name}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={["enabled"]}
                        label={"Enabled"}
                        valuePropName={"checked"}>
                        <Switch/>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate("/definitions/ticket-rules")}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    )

    function onFinish(values: any) {
        ticketCreationDefinitionService.add(values)
            .then(
                (id) => {
                    message.success("Ticket rule added.");

                    navigate(`/definitions/ticket-rules/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }
}

export default TicketCreationDefinitionAdd;
