import Axios from "axios";
import VulReport from "../domain/VulReport";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";

class VulReportService {

    readonly basePath = "/vul-reports";

    public add(vulReport: VulReport): Promise<string> {
        return Axios.post(`${this.basePath}`, vulReport).then(value => value.data.id)
    }

    public update(vulReportId: string, vulReport: VulReport): Promise<void> {
        return Axios.put(`${this.basePath}/${vulReportId}`, vulReport);
    }

    public get(id: string): Promise<VulReport> {
        return Axios.get(`${this.basePath}/${id}`)
            .then(value => VulReport.fromPOJO(value.data))
    }

    public delete(vulReport: VulReport): Promise<void> {
        return Axios.delete(`${this.basePath}/${vulReport.id}`);
    }

    public regenerateItems(vulReport: VulReport): Promise<void> {
        return Axios.post(`${this.basePath}/${vulReport.id}/regenerate-items`);
    }

    public sendReport(vulReport: VulReport, targetType: "EMAIL", target: string): Promise<void> {
        return Axios.post(`${this.basePath}/${vulReport.id}/send`, {targetType, target});
    }

    public countItems(vulReport: VulReport): Promise<{newItems: number, modifiedItems: number}> {
        return Axios.get(`${this.basePath}/${vulReport.id}/item-count`).then(value => value.data);
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<VulReport>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: VulReport[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(VulReport.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

}

export default VulReportService;
