import {CopyOutlined, DeleteFilled, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, Checkbox, message, Modal, Space, Table} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, VulSourceHealthReportDefinitionServiceContext} from "../../Contexts";
import {useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";
import {DocumentTitle} from "../DocumentTitle";
import * as styles from "./VulSourceHealthReportDefinitionList.module.css";
import * as globalStyles from "../App.module.css";
import {ColumnType} from "antd/es/table";
import VulReportDefinition from "../../domain/VulReportDefinition";
import {capitalize} from "../../utils/FormatUtils";
import VulSourceHealthReportDefinition from "../../domain/VulSourceHealthReportDefinition";

function VulSourceHealthReportDefinitionList() {
    const appContext = useContext(AppContextContext);
    const vulSourceHealthReportDefinitionService = useContext(VulSourceHealthReportDefinitionServiceContext);
    const navigate = useNavigate();
    const tableHandler = useTableHandler("name", {reloadFunction: reload});
    const [definitions, setDefinitions] = useState<PagedResult<VulSourceHealthReportDefinition>>();

    useEffect(() => tableHandler.reload(), [])

    const title = "Source health report definitions";

    const columns: ColumnType<VulSourceHealthReportDefinition>[] = [
        {
            dataIndex: "name",
            title: "Name",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "ascend",
            className: styles['column-name'],
            render: (value, definition) => <Link to={`/vul-source-health-report-definitions/${definition.id}`}>{value}</Link>
        },
        {
            dataIndex: "days",
            title: "Days",
            render: days => days?.length == 7 ? <i>every day</i> : days?.map(d => <div>{capitalize(d.toLowerCase())}</div>)
        },
        {
            dataIndex: "times",
            title: "Hours",
            render: (value, definition) => `${value?.join(", ")} (${definition.timeZone})`
        },
        {
            dataIndex: "enabled",
            title: "Enabled",
            align: "center",
            render: value => <Checkbox checked={value} disabled={true}/>
        },
        {
            dataIndex: "email",
            title: "E-mail",
        },
        {
            title: "Actions",
            width: 100,
            className: globalStyles["table-actions"],
            render: renderAction
        }
    ];

    if (appContext.user.isSystemAdmin()) {
        columns.splice(2, 0, {
                dataIndex: ["organization", "name"],
                title: "Organization",
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
                align: "left",
                render: renderOrganization
            },
        );
    }

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{title}</h1>

                <div className={globalStyles["common__top-button-bar"]}>
                    <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>

                    <Button type={"primary"}
                            icon={<PlusOutlined/>}
                            onClick={() => navigate("/vul-source-health-report-definitions/add")}>Add</Button>
                </div>

                <Table className={styles.table}
                       showSorterTooltip={false}
                       dataSource={definitions?.data}
                       size="middle"
                       loading={tableHandler.loading}
                       onChange={tableHandler.onTableChange}
                       pagination={tableHandler.pagination}
                       onRow={onRow}
                       rowKey="id"
                       columns={columns}
                />
            </>
        </DocumentTitle>
    );

    function renderOrganization(value: any, definition: VulSourceHealthReportDefinition) {
        return <Link to={`/organizations/${definition.organization.id}`}>{value}</Link>;
    }

    function renderAction(value: any, definition: VulReportDefinition, index: number) {
        return (
            <Space>
                <Button icon={<CopyOutlined/>} className={"ant-btn-icon-only"}
                        title={"Clone"}
                        onClick={() => navigate(`/vul-source-health-report-definitions/clone/${definition.id}`)}/>

                <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                        title={"Delete"}
                        onClick={() => {
                            Modal.confirm({
                                content: <>Do you really want to delete report definition <b>{definition.name}</b>?</>,
                                okText: "Delete",
                                cancelText: "Cancel",
                                onOk: () => onDeleteConfirm(definition)
                            });
                        }}/>
            </Space>
        )
    }

    function onDeleteConfirm(definition: VulSourceHealthReportDefinition) {
        vulSourceHealthReportDefinitionService.delete(definition)
            .then(() => {
                message.success(<>Source health report definition <b>{definition.name}</b> deleted.</>);

                reload();
            });
    }

    function reload() {
        return vulSourceHealthReportDefinitionService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setDefinitions(value);
        });
    }

    function onRow(definition: VulSourceHealthReportDefinition, index?: number) {
        return {onDoubleClick: () => navigate(`/vul-source-health-report-definitions/${definition.id}`)}
    }

}

export default VulSourceHealthReportDefinitionList;
