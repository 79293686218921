import Axios from "axios";
import TicketAttributeRule from "../domain/TicketAttributeRule";
import TicketCreationDefinition from "../domain/TicketCreationDefinition";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";

class TicketCreationDefinitionService {

    readonly basePath = "/ticket-creation-definitions";

    public add(ticket: TicketCreationDefinition): Promise<string> {
        return Axios.post(`${this.basePath}`, ticket).then(value => value.data.id)
    }

    public update(ticketId: string, ticket: TicketCreationDefinition): Promise<void> {
        return Axios.put(`${this.basePath}/${ticketId}`, ticket);
    }

    public get(id: string): Promise<TicketCreationDefinition> {
        return Axios.get(`${this.basePath}/${id}`)
            .then(value => TicketCreationDefinition.fromPOJO(value.data))
    }

    public delete(ticket: TicketCreationDefinition): Promise<void> {
        return Axios.delete(`${this.basePath}/${ticket.id}`);
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<TicketCreationDefinition>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: TicketCreationDefinition[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(TicketCreationDefinition.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }


    public addRule(ticketCreationDefinition: TicketCreationDefinition, rule: TicketAttributeRule): Promise<string> {
        return Axios.post(`${this.basePath}/${ticketCreationDefinition.id}/add-rule`, rule).then(value => value.data.id)
    }
}

export default TicketCreationDefinitionService;
