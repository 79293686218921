import Axios from "axios";
import AuditLog from "../domain/AuditLog";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";

class AuditLogService {

    public cache: any = {};  // cache ve formatu: {entita: {'uuid': 'hodnota'}};

    // podporovane klice auditlogu, ktere umime donacist
    public detailSupported: string[] = ["admin_id", "user_id", "authenticator_id", "protected_application_id", "protectedApplication_id"];

    public getList(queryOptions: QueryOptions): Promise<PagedResult<AuditLog>> {
        return Axios.get(`/audit-logs?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: AuditLog[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(AuditLog.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public lookupDetail(entity: string, key: string): Promise<any> {
        return Axios.get(`/audit-logs/detail/${entity}/${key}`);
    }

    public loadDetail(entity: string, id: string): Promise<string> {

        let value = this.cache[entity] ? this.cache[entity][id] : undefined;

        // zkusime donacist
        if (!value) {
            return this.lookupDetail(entity, id).then(result => {
                value = result.data.name;
                if (value) {
                    if (!this.cache[entity]) {
                        this.cache[entity] = {};
                    }
                    this.cache[entity][id] = value;
                }
                return value;

            }).catch(() => {
                this.cache[entity] = this.cache[entity] || {};

                this.cache[entity][id] = "???";
            });
        } else {
            return new Promise<string>(resolve => {
                return value
            });
        }
    }

}

export default AuditLogService;
