import Organization from "./Organization";

class VulSourceHealthReportDefinition {
    constructor(
        public id?: string,
        public createdAt?: Date,
        public name?: string,
        public organization?: Organization,
        public includeSystemSources?: boolean,
        public times?: string[],
        public days?: string[],
        public timeZone?: string,
        public enabled?: boolean,
        public generateEmptyReport?: boolean,
        public email?: String) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const definition = new VulSourceHealthReportDefinition();

        definition.id = data.id;
        definition.createdAt = new Date(data.createdAt);
        definition.name = data.name;
        definition.organization = (data.organization) ? Organization.fromPOJO(data.organization) : undefined;
        definition.includeSystemSources = data.includeSystemSources;
        definition.times = data.times;
        definition.days = data.days;
        definition.timeZone = data.timeZone;
        definition.enabled = data.enabled;
        definition.generateEmptyReport = data.generateEmptyReport;
        definition.email = data.email;

        return definition;
    }

}

export default VulSourceHealthReportDefinition;
