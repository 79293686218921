.table, .filter, .top-button-bar {
    max-width: 1800px;
}

.filter :global(.ant-picker) {
    width: 100%;
}

.inner-table :global(.ant-table.ant-table-small) {
    margin: 4px 8px 4px 8px !important;
}

.inner-table :global(.ant-table-title) {
    text-align: center;
}

.inner-table :global(.ant-empty-image) {
    display: none;
}

.attribute-key {
    width: 200px !important;
    overflow: hidden;
}

.attribute-value :global(button) {
    background: none !important;
    border: none !important;
    width: 20px !important;
    height: 20px !important;
}

.attribute-value :global(.ant-space-item) {
    margin-right: 0px !important;
}

.attribute-value :global(.audit-log-detail__attribute-value) {
    margin-left: 16px;
}

.attribute-value {
    white-space: pre-wrap;
}
.attributeKey {
    white-space: pre-wrap;
}