import dayjs from "dayjs";

export function formatDateTime(date?: Date) {
    if (date === undefined || date === null) {
        return <i>never</i>;
    }

    return dayjs(date).format("YYYY-MM-DD HH:mm:ss")
}

export function formatDate(date?: Date) {
    if (date === undefined || date === null) {
        return <i>never</i>;
    }

    return dayjs(date).format("YYYY-MM-DD")
}

export function capitalize(text: string) {
    return text[0].toUpperCase() + text.slice(1);
}

export function uncapitalize(text: string) {
    return text[0].toLowerCase() + text.slice(1);
}

export function formatDuration(durationInSeconds?: number) {
    if (durationInSeconds === undefined) {
        return;
    }

    const duration = dayjs.duration(durationInSeconds, 'seconds')

    return duration.humanize(false);
}

export function removeTrailingSlash(text?: string) {
    if (text && text.charAt(text.length - 1) === '/') {
        return text.substring(0, text.length - 1);
    } else {
        return text;
    }
}

export function filterObject(o: Object, exclude: string[]) {
    const filteredObject = {};
    Object.keys(o).forEach(key => {
        if (exclude.indexOf(key) === -1) {
            filteredObject[key] = o[key];
        }
    });

    return filteredObject;
}


export function syntaxHighlight(json) {
    if (typeof json != 'string') {
        json = JSON.stringify(json, undefined, 2);
    }

    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');

    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        let cls = 'number';

        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }

        return '<span class="' + cls + '">' + match + '</span>';
    });
}