import Axios from "axios";
import TicketAttributeRule from "../domain/TicketAttributeRule";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";

class TicketAttributeRuleService {

    readonly basePath = "/ticket-attribute-rules";

    public add(attributeRule: TicketAttributeRule): Promise<string> {
        return Axios.post(`${this.basePath}`, attributeRule).then(value => value.data.id)
    }

    public update(attributeRuleId: string, attributeRule: TicketAttributeRule): Promise<void> {
        return Axios.put(`${this.basePath}/${attributeRuleId}`, attributeRule);
    }

    public get(id: string): Promise<TicketAttributeRule> {
        return Axios.get(`${this.basePath}/${id}`)
            .then(value => TicketAttributeRule.fromPOJO(value.data))
    }

    public delete(attributeRule: TicketAttributeRule): Promise<void> {
        return Axios.delete(`${this.basePath}/${attributeRule.id}`);
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<TicketAttributeRule>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: TicketAttributeRule[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(TicketAttributeRule.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

}

export default TicketAttributeRuleService;
