import React, {useEffect, useState} from "react";
import * as styles from "./MoreLess.module.css";
import {Button} from "antd";

const MAX_HEIGHT = 160;

interface Props {
    children?: React.ReactNode;
    textMore?: string;
    textLess?: string;
}

function MoreLess(props: Props) {
    const divRef = React.useRef<HTMLDivElement>(null);
    const [hidden, setHidden] = useState(false);
    const [heightExceeded, setHeightExceeded] = useState(false);
    const [itemHeight, setItemHeight] = useState(0);

    useEffect(() => {
        if (divRef.current) {
            setItemHeight(divRef.current.scrollHeight);

            if (itemHeight > MAX_HEIGHT) {
                setHidden(true);
                setHeightExceeded(true);
            }
        }
    }, [divRef.current]);

    return (
        <div ref={divRef} className={`${styles['outer-box']} ${hidden ? styles['outer-box-less'] : ''}`}>
            <div className={styles['inner-box']}>
                {props.children}
            </div>

            {heightExceeded && <div>
                <Button className={styles['more-less-button']} size={"small"} type={"link"} onClick={() => setHidden(prevValue => !prevValue)}>
                    {hidden && (props.textMore || 'show more')}
                    {!hidden && (props.textLess || 'show less')}
                </Button>
            </div>}
        </div>
    );
}

export default MoreLess;