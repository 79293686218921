import {DeleteFilled, ReloadOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, message, Modal, Table} from "antd";
import Column from "antd/es/table/Column";
import {useForm} from "antd/lib/form/Form";
import _ from "lodash";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, TicketServiceContext} from "../../Contexts";
import Ticket from "../../domain/Ticket";
import {FormPersister} from "../../sal-ui/FormPersister";
import {TableUiConfig, useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";
import {DocumentTitle} from "../DocumentTitle";
import {formatDateTime} from "../../utils/FormatUtils";
import *as globalStyles from "../App.module.css";

import * as styles from "../ticket/TicketList.module.css";
import TicketFilter from "./TicketFilter";

const defaultVisibleColumns = ["id", "title"];

const defaultTableUiConfig = {immediateMode: false, visibleColumns: defaultVisibleColumns};

function TicketList() {
    const persistentIdent = 'TicketList';
    const appContext = useContext(AppContextContext);
    const ticketService = useContext(TicketServiceContext);
    const navigate = useNavigate();
    const tableHandler = useTableHandler("created desc", {reloadFunction: reload, persistentIdent});
    const [filterForm] = useForm();

    const [filterValues, setFilterValues] = useState<any>();
    const readOnly = useRef(false);
    const [tableUiConfig, setTableUiConfig] = useState<TableUiConfig>(defaultTableUiConfig);
    const filterFormPersister = new FormPersister(persistentIdent);

    const [tickets, setTickets] = useState<PagedResult<Ticket>>();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        readOnly.current = searchParams.has('$readOnly');

        const loadedValues = (searchParams.has("filter"))
            ? filterFormPersister.loadValues(window.atob(searchParams.get("filter")!))
            : filterFormPersister.loadValues();

        const values = (_.isEmpty(loadedValues)) ? {severity: [], state: [], assignee: []} : loadedValues;

        setFilterValues(values);

        if (searchParams.has("filter")) {
            navigate(location.pathname, {replace: true});
        }

        setTableUiConfig(tableHandler.loadUiConfig() || defaultTableUiConfig);
    }, [])

    const debouncedOnFinishFilter = useCallback(_.debounce(onFinishFilter, 500), []);

    return (
        <DocumentTitle title={"Tickets"}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item>Tickets</Breadcrumb.Item>
                </Breadcrumb>

                <h1>Tickets</h1>

                {renderFilterForm()}

                <div className={globalStyles["common__top-button-bar"]}>
                    <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>
                </div>

                <Table className={styles.table}
                       showSorterTooltip={false}
                       loading={tableHandler.loading}
                       dataSource={tickets?.data}
                       size="middle"
                       onChange={tableHandler.onTableChange}
                       pagination={tableHandler.pagination}
                       onRow={onRow}
                       rowKey="id">

                    <Column dataIndex="id" title={"ID"} width={80} align={"center"} render={renderId}/>
                    <Column dataIndex="title" title={"Title"} sorter={true} sortDirections={["ascend", "descend", "ascend"]} render={renderTitle}/>
                    <Column dataIndex={["organization", "name"]} sorter={true} sortDirections={["ascend", "descend", "ascend"]} title={"Organization"} align={"left"}/>
                    <Column dataIndex="severity" title={"Severity"} sorter={true} sortDirections={["ascend", "descend", "ascend"]} width={100} align={"center"}/>
                    <Column dataIndex="state" title={"State"} sorter={true} sortDirections={["ascend", "descend", "ascend"]} width={100} align={"center"}/>
                    <Column dataIndex="created" title={"Created"} sorter={true} sortDirections={["ascend", "descend", "ascend"]} defaultSortOrder={"descend"} width={140} align={"left"} render={value => formatDateTime(value)}/>

                    <Column title={"Actions"} width='100px' render={renderAction}
                            className={globalStyles["table-actions"]}/>
                </Table>
            </>
        </DocumentTitle>
    )

    function renderId(value: string) {
        return (
            <span title={value}>{value.substring(0, 2) + '...' + value.substring(value.length - 2)}</span>
        )
    }

    function renderFilterForm() {
        return (
            <TicketFilter
                className={styles.filter}
                onChange={values => {
                    setFilterValues(values);

                    debouncedOnFinishFilter(values);
                }}
                values={filterValues}
                readOnly={readOnly.current}
                immediateMode={tableUiConfig.immediateMode}
            />
        )
    }

    function createRqbQuery(values: any) {
        const rqbQuery = {
            combinator: "and",
            rules: []
        }

        values.severity && values.severity.length > 0 && rqbQuery.rules.push({
            field: "severity",
            operator: "in",
            value: values.severity
        });

        values.state && values.state.length > 0 && rqbQuery.rules.push({
            field: "state",
            operator: "in",
            value: values.state
        });

        values.assignee && values.assignee.length > 0 && rqbQuery.rules.push({
            field: "assignees.id",
            operator: "in",
            value: values.assignee
        });


        return rqbQuery;
    }

    function onFinishFilter(values: any) {
        const rqbQuery = createRqbQuery(values);

        if (!readOnly.current) {
            filterFormPersister.saveState(values);
        }

        tableHandler.onRqbSearchSubmit(rqbQuery);
    }

    function onResetFilter() {
        tableHandler.onSearchSubmit('');

        filterForm.resetFields();
    }

    function renderTitle(value: any, ticket: Ticket, index: number) {
        return <Link to={`/tickets/${ticket.id}`}>{value}</Link>;
    }

    function renderAction(value: any, ticket: Ticket, index: number) {
        return (
            <>
                <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                        title={"Delete"}
                        onClick={() => {
                            Modal.confirm({
                                content: "Do you really want to delete this ticket?",
                                okText: "Delete",
                                cancelText: "Cancel",
                                onOk: () => onDeleteConfirm(ticket)
                            });
                        }}/>
            </>
        )
    }

    function onDeleteConfirm(ticket: Ticket) {
        ticketService.delete(ticket)
            .then(() => {
                message.success("Ticket successfully deleted.");

                reload();
            });
    }

    function reload() {
        return ticketService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setTickets(value);
        });
    }

    function onRow(ticket: Ticket, index?: number) {
        return {onDoubleClick: () => navigate(`/tickets/${ticket.id}`)}
    }
}

export default TicketList;
