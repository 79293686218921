import {ExperimentOutlined, LoadingOutlined} from "@ant-design/icons";
import Collapse from "@kunukn/react-collapse";
import {Breadcrumb, Button, Descriptions, Form, Input, message, Modal, Select, Space, Spin} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, TicketAttributeRuleServiceContext, VulSourceItemServiceContext} from "../../Contexts";
import Organization from "../../domain/Organization";
import Ticket from "../../domain/Ticket";
import TicketAttributeRule from "../../domain/TicketAttributeRule";
import {TicketSeverity} from "../../domain/TicketSeverity";
import VulSourceItem from "../../domain/VulSourceItem";
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {useTableHandler} from "../../sal-ui/TableHandler";
import {syntaxHighlight} from "../../utils/FormatUtils";
import *as globalStyles from "../App.module.css";
import {DocumentTitle} from "../DocumentTitle";

function TicketAttributeRuleDetail() {
    const appContext = useContext(AppContextContext);
    const ticketAttributeRuleService = useContext(TicketAttributeRuleServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const navigate = useNavigate();
    const {ticketAttributeRuleId}: any = useParams();
    const [editMode, setEditMode] = useState(false);
    const [ticketAttributeRule, setTicketAttributeRule] = useState<TicketAttributeRule>();
    const [editForm] = useForm();
    const vulSourceItemService = useContext(VulSourceItemServiceContext);
    const [organizations, setOrganizations] = useState<Organization[]>();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 8, span: 16},
    };

    const serverViolationsHolder = new ServerConstraintViolationsHolder();

    const tableHandler = useTableHandler("id", {reloadFunction: null});

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        reload();

        organizationService.getList(new QueryOptions("name", 100, 0)).then(value => {
            setOrganizations(value.data);
        });


    }, [])

    return (
        <DocumentTitle title={ticketAttributeRule?.id}>
            <>
                <Breadcrumb className={globalStyles.common__breadcrumb}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/ticket-attribute-rules"}>Ticket attribute rules</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{ticketAttributeRule?.id}</Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={!ticketAttributeRule} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{ticketAttributeRule?.id}</h1>

                    <div className={globalStyles['common__top-button-bar']}>
                        <Button danger={true}
                                title={"Delete"}
                                onClick={() => {
                                    Modal.confirm({
                                        content: "Do you really want to delete this rule?",
                                        okText: "Delete",
                                        cancelText: "Cancel",
                                        okButtonProps: {danger: true},
                                        onOk: () => onDeleteConfirm(ticketAttributeRule!)
                                    });
                                }}>Delete</Button>
                    </div>

                    {renderEditForm()}

                    <Descriptions column={1} bordered={true} size="small" className={globalStyles.details}>
                        <Descriptions.Item label={"ID"}>{ticketAttributeRule?.id}</Descriptions.Item>
                        <Descriptions.Item label={"Value"}>{ticketAttributeRule?.value}</Descriptions.Item>
                        <Descriptions.Item label={"OperationType"}>{ticketAttributeRule?.operationType}</Descriptions.Item>

                        <Descriptions.Item label={"Attribute definitions"}>
                            <div className={globalStyles["formatted-json"]} dangerouslySetInnerHTML={{__html: `${ticketAttributeRule?.attributeDefinition && syntaxHighlight(ticketAttributeRule?.attributeDefinition)}`}} />
                        </Descriptions.Item>

                        <Descriptions.Item label={"Ticket rule"}>
                            <div className={globalStyles["formatted-json"]} dangerouslySetInnerHTML={{__html: `${ticketAttributeRule?.ticketCreationDefinition && syntaxHighlight(ticketAttributeRule?.ticketCreationDefinition)}`}} />
                        </Descriptions.Item>

                    </Descriptions>

                </Spin>
            </>
        </DocumentTitle>
    )

    function renderName(value: any, vulSourceItem: VulSourceItem, index: number) {
        return <Link to={`/vul-source-items/${vulSourceItem.id}`}>{value}</Link>;
    }

    function renderAttributes(value: any, vulSourceItem: VulSourceItem, index: number) {
        return value ? value.title ? value.title : value.description ? value.description : "" : "";
    }

    function renderAction(value: any, vulSourceItem: VulSourceItem, index: number) {
        return (
            <Space>
                <Button icon={<ExperimentOutlined />} className={"ant-btn-icon-only"}
                        title={"Run"}
                        onClick={() => {
                            Modal.confirm({
                                content: "Realy extract?",
                                okText: "Extract",
                                cancelText: "Cancel",
                                onOk: () => onExtractConfirm(vulSourceItem)
                            });
                        }}/>
            </Space>
        )
    }

    function onExtractConfirm(vulSourceItem: VulSourceItem) {
        vulSourceItemService.extract(vulSourceItem)
            .then((data:any) => {
                message.success("Extracting!");

                reload();
            });
    }

    function renderId(value: string) {
        return (
            <span title={value}>{value.substring(0, 2) + '...' + value.substring(value.length - 2)}</span>
        )
    }

    function renderCveId(cveId: string) {
        return cveId ? <Link to={`https://nvd.nist.gov/vuln/detail/CVE-${cveId}`}>cveId</Link> : null;
    }

    function renderEditForm() {
        return (
            <Collapse isOpen={editMode}>
                <h3>Edit ticket rule</h3>

                <Form {...layout} form={editForm} className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`} onFinish={onFinishEdit}>
                    <Form.Item
                        name={"title"}
                        label={"Title"}
                        rules={[
                            {required: true, message: "Name is required."},
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={["organization", "id"]}
                        label={"Organization"}
                        rules={[{required: true, message: "required"}]}>

                        <Select >
                            {organizations && organizations!.map(organization => {
                                return <Select.Option key={organization?.name} value={organization?.id!}>{organization?.name}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={["severity"]}
                        label={"Severity"}
                        rules={[{required: true, message: "required"}]}>

                        <Select >
                            {Object.keys(TicketSeverity).map(value => {
                                return <Select.Option key={value} value={value}>{value}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>


                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => setEditMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function onFinishEdit(values: any) {
        ticketAttributeRuleService.update(ticketAttributeRuleId!, values)
            .then(
                (id) => {
                    message.success("Ticket attribute rule updated successfully.");

                    setEditMode(false);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }

    function onDeleteConfirm(ticket: Ticket) {
        ticketAttributeRuleService.delete(ticket)
            .then(() => {
                message.success("Ticket attribute rule successfully deleted.");

                navigate(`/ticket-attribute-rules`)
            });
    }

    function reload() {
        ticketAttributeRuleService.get(ticketAttributeRuleId!).then(ticket => {
            setTicketAttributeRule(ticket);

            editForm.setFieldsValue(ticket);
        });

    }

}

export default TicketAttributeRuleDetail;
