import VulSource from "./VulSource";
import Ticket from "./Ticket";

class VulSourceItemFlagStats {
    constructor(
        public important?: number,
        public notImportant?: number) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const entity = new VulSourceItemFlagStats();

        entity.important = data.important;
        entity.notImportant = data.notImportant;

        return entity;
    }
}

export default VulSourceItemFlagStats;
