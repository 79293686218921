import {ExperimentOutlined, FileAddOutlined, FunctionOutlined, LoadingOutlined} from "@ant-design/icons";
import {Alert, Breadcrumb, Button, Card, Collapse, Descriptions, Form, InputNumber, message, Modal, Spin, Table} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useReducer, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {AppContextContext, AttributeDefinitionServiceContext, VulSourceItemServiceContext} from "../../Contexts";
import VulSourceItem from "../../domain/VulSourceItem";
import {formatDateTime, syntaxHighlight} from "../../utils/FormatUtils";
import CreateTicketModal from "../ticket/CreateTicketModal";
import ItemVote from "./ItemVote";
import * as styles from "./VulSourceItemDetail.module.css";
import * as globalStyles from "../App.module.css";
import {DocumentTitle} from "../DocumentTitle";
import AttributeDefinition from "../../domain/AttributeDefinition";
import QueryOptions from "../../sal-ui/QueryOptions";
import VulSourceItemDescription from "./VulSourceItemDescription";
import Column from "antd/es/table/Column";
import VulSourceItemChangeLog from "../../domain/VulSourceItemChangeLog";
import {VulSourceItemChangeType} from "../../domain/VulSourceItemChangeType";
import {Differ, Viewer} from "json-diff-kit";
import 'json-diff-kit/dist/viewer.css';
import SealScore from "./SealScore";
import SourceTag from "./SourceTag";
import Ticket from "../../domain/Ticket";
import {Permission} from "../../domain/auth/Permission";

const LOCAL_STORAGE_KEY_RELATED_ITEMS_MAX_DEPTH = "Seal.VulSourceItemDetail.RelatedItemsMaxDepth";

const differ = new Differ({
    detectCircular: true,    // default `true`
    maxDepth: Infinity,      // default `Infinity`
    showModifications: true, // default `true`
    arrayDiffMethod: 'lcs',  // default `"normal"`, but `"lcs"` may be more useful
});

const diffViewerProps = {
    indent: 4,
    highlightInlineDiff: true,
    hideUnchangedLines: true,
};

function VulSourceItemDetail() {
    const storedMaxDepth = localStorage.getItem(LOCAL_STORAGE_KEY_RELATED_ITEMS_MAX_DEPTH);

    const defaultRelatedItemsMaxDepth = (storedMaxDepth != null) ? parseInt(storedMaxDepth) : 1;

    const appContext = useContext(AppContextContext);
    const vulSourceItemService = useContext(VulSourceItemServiceContext);
    const attributeDefinitionService = useContext(AttributeDefinitionServiceContext);
    const {vulSourceItemId}: any = useParams();
    const [vulSourceItem, setVulSourceItem] = useState<VulSourceItem>();
    const [editForm] = useForm();
    const [extractResult, setExtractResult] = useState<any>();
    const [evaluateResults, setEvaluateResults] = useState<any>();
    const [attributeDefinitions, setAttributeDefinitions] = useState<AttributeDefinition[]>();
    const [createTicketFormVisible, setCreateTicketFormVisible] = useState<boolean>(false);
    const [relatedItemsMaxDepth, setRelatedItemsMaxDepth] = useState(defaultRelatedItemsMaxDepth);
    const [maxRelatedSealScore, setMaxRelatedSealScore] = useState<number>();
    const [relatedItems, setRelatedItems] = useState<VulSourceItem[]>();
    const [relatedTickets, setRelatedTickets] = useState<Ticket[]>();
    const [relatedItemsInProgress, setRelatedItemsInProgress] = useState(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        attributeDefinitionService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setAttributeDefinitions(value.data));

        reload();
        reloadRelatedItems();

        window.scrollTo({top: 0, left: 0, behavior: "smooth"})
    }, [vulSourceItemId])

    return (
        <DocumentTitle title={vulSourceItem?.id}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}
                            items={[
                                {title: appContext.config?.appName},
                                {title: <Link to={"/vul-source-items"}>Vulnerability items</Link>},
                                {title: vulSourceItem?.id}
                            ]}
                />

                <Spin spinning={!vulSourceItem} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{vulSourceItem?.attributes.title || vulSourceItem?.attributes?.description?.substring(0, 160) || vulSourceItem?.id}</h1>

                    <div className={globalStyles["common__top-button-bar"]}>
                        {appContext.user.isSystemAdmin() && <>
                            <Button icon={<ExperimentOutlined/>} onClick={() => onExtractDryConfirm(vulSourceItem)}>Extract (dry)</Button>

                            <Button icon={<FunctionOutlined/>} onClick={() => onEvaluateDryConfirm(vulSourceItem)}>Evaluate (dry)</Button>
                        </>}

                        <div className={`${styles.vote}`} style={{background: "transparent"}}>
                            {vulSourceItem && <ItemVote onVote={updateItem} item={vulSourceItem}/>}
                        </div>

                        {appContext.user.hasPermission(Permission.TICKET__MANAGE) &&
                            <Button icon={<FileAddOutlined/>}
                                    title={"Create ticket"}
                                    onClick={() => {
                                        setCreateTicketFormVisible(true);
                                        setVulSourceItem(vulSourceItem);
                                    }}>Create ticket
                            </Button>
                        }
                    </div>

                    {relatedTickets?.map(ticket => <Alert key={ticket.id} className={styles['ticket-alert']} type={"info"}
                                                          message={<>Related ticket: <Link to={`/tickets/${ticket.id}`}>{ticket.title}</Link></>}/>)}
                    <Descriptions column={1} bordered={true} size="small" className={styles.descriptions}>
                        <Descriptions.Item label={"Description"}>
                            <div className={styles['description']}>
                                <SourceTag source={vulSourceItem?.source}/>

                                <SealScore item={vulSourceItem}/>

                                <SealScore label="Max. related score" score={maxRelatedSealScore}/>
                            </div>

                            <VulSourceItemDescription sourceType={vulSourceItem?.source?.sourceType} attributes={vulSourceItem?.attributes} affected={vulSourceItem?.affected} attributeDefinitions={attributeDefinitions} hideSealScoreTag={true}/>
                        </Descriptions.Item>

                        <Descriptions.Item label={"Source"}><Link to={`/vul-sources/${vulSourceItem?.source?.id}`}>{vulSourceItem?.source?.name}</Link></Descriptions.Item>
                        <Descriptions.Item label={"Timestamp (SEAL)"}>
                            <Descriptions column={1} size={"small"} className={styles['nested-descriptions']}>
                                <Descriptions.Item label={"Created"}>{formatDateTime(vulSourceItem?.created)}</Descriptions.Item>
                                <Descriptions.Item label={"Data modification"}>{formatDateTime(vulSourceItem?.lastModified)}</Descriptions.Item>
                                <Descriptions.Item label={"Attribute modification"}>{formatDateTime(vulSourceItem?.lastModifiedAttributes)}</Descriptions.Item>
                            </Descriptions>
                        </Descriptions.Item>
                        <Descriptions.Item label={"Timestamp (source)"}>
                            <Descriptions column={1} size={"small"} className={styles['nested-descriptions']}>
                                <Descriptions.Item label={"Published"}>{formatDateTime(vulSourceItem?.sourcePublished)}</Descriptions.Item>
                                <Descriptions.Item label={"Last update"}>{formatDateTime(vulSourceItem?.sourceLastUpdate)}</Descriptions.Item>
                            </Descriptions>
                        </Descriptions.Item>

                        <Descriptions.Item label={"Attributes"}>
                            <Collapse
                                size={"small"}
                                items={[
                                    {key: "1", label: "Click to expand", children: <div className={globalStyles["formatted-json"]} dangerouslySetInnerHTML={{__html: `${vulSourceItem?.attributes && syntaxHighlight(vulSourceItem?.attributes)}`}}/>}
                                ]}
                            />
                        </Descriptions.Item>

                        <Descriptions.Item label={"JSON data"}>
                            <Collapse
                                size={"small"}
                                items={[
                                    {key: "1", label: "Click to expand", children: <div className={globalStyles["formatted-json"]} dangerouslySetInnerHTML={{__html: `${vulSourceItem?.jsonData && syntaxHighlight(vulSourceItem?.jsonData)}`}}/>}
                                ]}
                            />
                        </Descriptions.Item>
                    </Descriptions>

                    <CreateTicketModal
                        visible={createTicketFormVisible}
                        onTicketCreate={() => setCreateTicketFormVisible(false)}
                        vulSourceItem={vulSourceItem}
                        onCancel={() => setCreateTicketFormVisible(false)}
                    />


                    {extractResult &&
                        <>
                            <h2>Extraction result</h2>
                            <p>
                                <Button
                                    icon={<ExperimentOutlined/>}
                                    onClick={() => {
                                        Modal.confirm({
                                            content: "Realy extract and save?",
                                            okText: "Extract",
                                            cancelText: "Cancel",
                                            onOk: () => onExtractConfirm(vulSourceItem)
                                        });
                                    }}>Extract (save)</Button>
                            </p>
                            <Card size={"small"}>
                                <div className={globalStyles["formatted-json"]} dangerouslySetInnerHTML={{__html: `${extractResult && syntaxHighlight(extractResult)}`}}/>
                            </Card>
                        </>
                    }

                    {evaluateResults &&
                        <>
                            <h2>Evaluation result</h2>
                            <p>
                                <Button
                                    icon={<FunctionOutlined/>}
                                    onClick={() => {
                                        Modal.confirm({
                                            content: "Realy Evaluate?",
                                            okText: "Evaluate",
                                            cancelText: "Cancel",
                                            onOk: () => onEvaluateConfirm(vulSourceItem)
                                        })
                                    }}>Evaluate (save)</Button>
                            </p>
                            {evaluateResults.map(result => (
                                <Card size={"small"}>
                                    <div className={globalStyles["formatted-json"]} dangerouslySetInnerHTML={{__html: `${result && syntaxHighlight(result)}`}}/>
                                </Card>
                            ))}
                        </>
                    }

                    <h2>Data and attributes changes</h2>

                    <Table className={styles['change-log-table']}
                           locale={{emptyText: "No changes available"}}
                           showSorterTooltip={false}
                           dataSource={vulSourceItem?.changeLogs}
                           size="middle"
                           pagination={false}
                           rowKey="id">

                        <Column
                            dataIndex="timestamp"
                            title={"Timestamp"}
                            width={140}
                            align={"left"}
                            render={value => formatDateTime(value)}
                        />

                        <Column
                            dataIndex={"type"}
                            title={"Type"}
                            width={100}
                            render={renderChangeLogType}
                        />

                        <Column
                            dataIndex={"data"}
                            title={"Data"}
                            render={(value, record: VulSourceItemChangeLog) => renderChangeLogData(record)}
                        />


                        <Column
                            dataIndex={"difference"}
                            title={"Difference"}
                            render={(value, record: VulSourceItemChangeLog) => renderChangeLogDifference(record)}
                        />

                    </Table>

                    <h2>Related items</h2>

                    <Form layout={"inline"} className={styles['related-items-form']} onFinish={applyRelatedItemsMaxDepth} initialValues={{maxDepth: defaultRelatedItemsMaxDepth}}>
                        <Form.Item
                            name={"maxDepth"}
                            label={"Max. depth"}>
                            <InputNumber min={1} max={5}/>
                        </Form.Item>

                        <Form.Item>
                            <Button type={"primary"} htmlType={"submit"}>Apply</Button>
                        </Form.Item>
                    </Form>

                    <Table className={styles['related-items-table']}
                           showSorterTooltip={false}
                           dataSource={relatedItems}
                           locale={{emptyText: "No related items"}}
                           size="middle"
                           pagination={false}
                           loading={relatedItemsInProgress}
                           rowKey="id">

                        <Column
                            dataIndex="id"
                            title={"ID"}
                            width={80}
                            align={"center"}
                            render={value => <Link to={`/vul-source-items/${value}`}>
                                <span title={value}>{value.substring(0, 2) + '...' + value.substring(value.length - 2)}</span>
                            </Link>}
                        />

                        <Column
                            dataIndex={"attributes"}
                            title={"Description"}
                            render={(value, item: VulSourceItem) => <>
                                <div className={styles['description']}>
                                    <SourceTag source={item?.source}/>

                                    <SealScore item={item}/>
                                </div>

                                <VulSourceItemDescription sourceType={item.source.sourceType} attributes={value} attributeDefinitions={attributeDefinitions} hideSealScoreTag={true}/>
                            </>}
                        />

                        <Column
                            dataIndex="sourcePublished"
                            title={"Published"}
                            width={160}
                            align={"left"}
                            render={value => formatDateTime(value)}
                        />
                    </Table>
                </Spin>
            </>
        </DocumentTitle>
    )

    function applyRelatedItemsMaxDepth(values: any) {
        setRelatedItemsMaxDepth(values.maxDepth);

        localStorage.setItem(LOCAL_STORAGE_KEY_RELATED_ITEMS_MAX_DEPTH, JSON.stringify(values.maxDepth));

        reloadRelatedItems();
    }

    function updateItem(item: VulSourceItem) {
        vulSourceItem.flags = item.flags;
        vulSourceItem.flagStats = item.flagStats;

        forceUpdate();
    }

    function onExtractConfirm(vulSourceItem: VulSourceItem) {
        vulSourceItemService.extract(vulSourceItem)
            .then((data: any) => {
                message.success("Extracting!");

                setExtractResult(data.data);

                reload();
            });
    }

    function onExtractDryConfirm(vulSourceItem: VulSourceItem) {
        vulSourceItemService.extractDry(vulSourceItem)
            .then((data: any) => {
                message.success("Extracting!");

                setExtractResult(data.data);

                reload();
            });
    }

    function onEvaluateConfirm(item: VulSourceItem) {
        vulSourceItemService.evaluate(item)
            .then((response: any) => {
                message.success("Evaluating!");

                setEvaluateResults(response.data);

                reload();
            });
    }

    function onEvaluateDryConfirm(item: VulSourceItem) {
        vulSourceItemService.evaluateDry(item)
            .then((response: any) => {
                message.success("Evaluating!");

                setEvaluateResults(response.data);

                reload();
            });
    }

    function renderChangeLogType(type: VulSourceItemChangeType) {
        switch (type) {
            case VulSourceItemChangeType.JSON_DATA:
                return "JSON data";
            case VulSourceItemChangeType.ATTRIBUTES:
                return "attributes";
        }
    }

    function renderChangeLogData(changeLog: VulSourceItemChangeLog) {
        if (changeLog.type === VulSourceItemChangeType.JSON_DATA) {
            return <Collapse
                size={"small"}
                destroyInactivePanel={true}
                items={[
                    {key: "1", label: "Click to expand", children: <div className={globalStyles["formatted-json"]} dangerouslySetInnerHTML={{__html: `${vulSourceItem?.attributes && syntaxHighlight(changeLog.jsonData)}`}}/>}
                ]}
            />;
        } else if (changeLog.type === VulSourceItemChangeType.ATTRIBUTES) {
            return <Collapse
                size={"small"}
                destroyInactivePanel={true}
                items={[
                    {key: "1", label: "Click to expand", children: <div className={globalStyles["formatted-json"]} dangerouslySetInnerHTML={{__html: `${vulSourceItem?.attributes && syntaxHighlight(changeLog.attributes)}`}}/>}
                ]}
            />;
        }
    }

    function renderChangeLogDifference(changeLog: VulSourceItemChangeLog) {
        const olderChangeLog = vulSourceItem.findOlderChangeLog(changeLog);

        if (olderChangeLog === undefined) {
            return <i>no difference available</i>;
        }

        if (changeLog.type === VulSourceItemChangeType.JSON_DATA) {
            return <Collapse
                size={"small"}
                items={[
                    {key: "1", label: "Click to expand", children: <Viewer className={styles.diff} diff={differ.diff(olderChangeLog.jsonData, changeLog.jsonData)} {...diffViewerProps}/>}
                ]}
            />;
        } else if (changeLog.type === VulSourceItemChangeType.ATTRIBUTES) {
            return <Collapse
                size={"small"}
                items={[
                    {key: "1", label: "Click to expand", children: <Viewer className={styles.diff} diff={differ.diff(olderChangeLog.attributes, changeLog.attributes)} {...diffViewerProps}/>}
                ]}
            />;
        }
    }

    function reload() {
        vulSourceItemService.get(vulSourceItemId).then(vulSource => {
            setVulSourceItem(vulSource);

            editForm.setFieldsValue(vulSource);
        });
    }

    function reloadRelatedItems() {
        setRelatedItemsInProgress(true);

        Promise
            .all([
                vulSourceItemService.getRelatedItems(vulSourceItemId, relatedItemsMaxDepth),
                vulSourceItemService.getRelatedTickets(vulSourceItemId, relatedItemsMaxDepth)
            ])
            .then(values => {
                const [items, tickets] = values;

                setRelatedItems(items);
                setRelatedTickets(tickets);

                setMaxRelatedSealScore(items.map(item => item.attributes.sealScore).filter(item => item !== undefined).sort().pop());
            })
            .finally(() => setRelatedItemsInProgress(false));
    }

}

export default VulSourceItemDetail;
