import React, {useContext} from 'react';
import {Helmet} from 'react-helmet-async';
import {AppContextContext} from "../Contexts";
import {onlyText} from 'react-children-utilities';

interface Props {
    children?: React.ReactNode;
    title: string | React.ReactNode;
}

export const DocumentTitle = ({children, title}: Props) => {
    const appContext = useContext(AppContextContext);

    return (
        <>
            <Helmet>
                <title>{appContext.config?.appName}: {onlyText(title)}</title>
            </Helmet>
            {children}
        </>
    );
};
