import Axios from "axios";
import AttributeExtractionDefinition from "../domain/AttributeExtractionDefinition";
import AttributeExtractor from "../domain/AttributeExtractor";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";

class AttributeExtractionDefinitionService {

    static readonly basePath = "/attribute-extraction-definitions";

    public basePath(): string {
        return AttributeExtractionDefinitionService.basePath;
    }
    
    public add(definition: AttributeExtractionDefinition): Promise<string> {
        return Axios.post(`${this.basePath()}`, definition).then(value => value.data.id)
    }

    public update(id: string, definition: AttributeExtractionDefinition): Promise<void> {
        return Axios.put(`${this.basePath()}/${id}`, definition);
    }

    public get(id: string): Promise<AttributeExtractionDefinition> {
        return Axios.get(`${this.basePath()}/${id}`)
            .then(value => AttributeExtractionDefinition.fromPOJO(value.data))
    }

    public delete(definition: AttributeExtractionDefinition): Promise<void> {
        return Axios.delete(`${this.basePath()}/${definition.id}`);
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<AttributeExtractionDefinition>> {
        return Axios.get(`${this.basePath()}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: AttributeExtractionDefinition[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(AttributeExtractionDefinition.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public addExtractor(attributeExtractionDefinition: AttributeExtractionDefinition, extractor: AttributeExtractor): Promise<string> {
        return Axios.post(`${this.basePath()}/${attributeExtractionDefinition.id}/add-extractor`, extractor).then(value => value.data.id)
    }
}

export default AttributeExtractionDefinitionService;
