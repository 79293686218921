import {LoadingOutlined} from "@ant-design/icons";
import Collapse from "@kunukn/react-collapse";
import {Breadcrumb, Button, Checkbox, Descriptions, Form, Input, message, Modal, Select, Spin, Table, Tag} from "antd";
import {useForm, useWatch} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, TagServiceContext, VulReportDefinitionServiceContext, VulSourceServiceContext, VulViewServiceContext} from "../../Contexts";
import Organization from "../../domain/Organization";
import {ResourceOwnerType} from "../../domain/ResourceOwnerType";
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import * as globalStyles from "../App.module.css";
import * as styles from "./VulViewDetail.module.css";
import {DocumentTitle} from "../DocumentTitle";
import "./VulViewDetail.module.css";
import VulView from "../../domain/VulView";
import VulViewQueryBuilder from "./VulViewQueryBuilder";
import ReadOnlyQueryView from "./ReadOnlyQueryView";
import VulSource from "../../domain/VulSource";
import {downloadFile} from "../../utils/DownloadUtils";
import PagedResult from "../../service/PagedResult";
import VulReportDefinition from "../../domain/VulReportDefinition";
import {useTableHandler} from "../../sal-ui/TableHandler";
import {ColumnType} from "antd/es/table";
import {capitalize, formatDateTime} from "../../utils/FormatUtils";
import TextArea from "antd/lib/input/TextArea";
import ReactMarkdown from "react-markdown";
import {Permission} from "../../domain/auth/Permission";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function VulViewDetail() {
    const persistentIdent = 'VulViewDetailReportDefinitions';
    const appContext = useContext(AppContextContext);
    const organizationService = useContext(OrganizationServiceContext);
    const vulViewService = useContext(VulViewServiceContext);
    const vulSourceService = useContext(VulSourceServiceContext);
    const vulReportDefinitionService = useContext(VulReportDefinitionServiceContext);
    const tagService = useContext(TagServiceContext);
    const navigate = useNavigate();
    const {vulViewId}: any = useParams();
    const [editMode, setEditMode] = useState(false);
    const [vulView, setVulView] = useState<VulView>();
    const [sources, setSources] = useState<VulSource[]>();
    const [editForm] = useForm();
    const [organizations, setOrganizations] = useState<Organization[]>();
    const owner = useWatch("owner", editForm);
    const [reportDefinitions, setReportDefinitions] = useState<PagedResult<VulReportDefinition>>();
    const [tags, setTags] = useState<string[]>();

    const tableHandler = useTableHandler("name asc", {
        reloadFunction: reloadReportDefinitions,
        initialPageSize: 25,
        persistentIdent: `${persistentIdent}`
    });

    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };

    const tailLayout = {
        wrapperCol: {offset: 4, span: 20},
    };

    const columns: ColumnType<VulReportDefinition>[] = [
        {
            dataIndex: "name",
            title: "Name",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "ascend",
            className: styles['column-name'],
            render: (value, definition) => <Link to={`/definitions/vul-reports/${definition.id}`}>{value}</Link>
        },
        {
            dataIndex: "vulViews",
            title: "Vulnerability views",
            align: "left",
            render: renderVulViews
        },
        {
            dataIndex: "days",
            title: "Days",
            render: days => days?.length == 7 ? <i>every day</i> : days?.map(d => capitalize(d.toLowerCase())).join(", ")
        },
        {
            dataIndex: "times",
            title: "Hours",
            render: (value, definition) => `${value?.join(", ")} (${definition.timeZone})`
        },
        {
            dataIndex: "enabled",
            title: "Enabled",
            align: "center",
            render: value => <Checkbox checked={value} disabled={true}/>
        },
        {
            dataIndex: "email",
            title: "E-mail",
        },
    ];

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (appContext.user.isSystemAdmin()) {
            organizationService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setOrganizations(value.data));

            tagService.getList().then(setTags);
        }

        vulSourceService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setSources(value.data));

        reload();

        if (appContext.user.hasOneOfPermission([Permission.REPORT__READ])) {
            reloadReportDefinitions();
        }
    }, [vulViewId])

    return (
        <DocumentTitle title={vulView?.name}>
            <>
                <Breadcrumb
                    className={globalStyles["common__breadcrumb"]}
                    items={[
                        {title: appContext.config?.appName},
                        {title: <Link to={"/vul-source-items/views"}>Vulnerability views</Link>},
                        {title: vulView?.name}
                    ]}
                />

                <Spin spinning={vulView === undefined} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{vulView?.name}</h1>

                    {appContext.user.hasPermission(Permission.VIEW__MANAGE_ORG) &&
                        <div className={globalStyles["common__top-button-bar"]}>
                            {(appContext.user.isSystemAdmin() || vulView?.owner === ResourceOwnerType.ORGANIZATION) &&
                                <>
                                    <Button onClick={() => setEditMode(!editMode)}>Edit</Button>

                                    <Button danger={true}
                                            title={"Delete"}
                                            onClick={() => {
                                                Modal.confirm({
                                                    content: "Do you really want to delete this view?",
                                                    okText: "Delete",
                                                    cancelText: "Cancel",
                                                    okButtonProps: {danger: true},
                                                    onOk: () => onDeleteConfirm()
                                                });
                                            }}>Delete</Button>
                                </>
                            }

                            {appContext.user.hasPermission(Permission.VIEW__MANAGE_ORG) && <Button onClick={() => navigate(`/vul-source-items/views/clone/${vulView.id}`)}>Clone</Button>}

                            {appContext.user.isSystemAdmin() && <Button onClick={() => onExportToJson()}>Export to JSON</Button>}
                        </div>
                    }

                    {renderEditForm()}

                    <Descriptions column={1} bordered={true} size="small" className={`${globalStyles.details} ${styles.details}`} title={"View properties"}>
                        {vulView?.owner === ResourceOwnerType.ORGANIZATION && <Descriptions.Item label={"Owner"}>{vulView?.organization?.name}</Descriptions.Item>}
                        {vulView?.owner === ResourceOwnerType.SYSTEM && <Descriptions.Item label={"Owner"}><i>System source</i></Descriptions.Item>}

                        <Descriptions.Item label={"Description"}>
                            {vulView?.description && <ReactMarkdown className={globalStyles.markdown} children={vulView?.description}/>}
                            {!vulView?.description && <i>no description</i>}
                        </Descriptions.Item>

                        {appContext.user.isSystemAdmin() && <Descriptions.Item label={"Tags"}>{vulView?.tags?.map(value => <Tag>{value}</Tag>)}</Descriptions.Item>}
                        <Descriptions.Item label={"Items"}>{renderItemCount()}</Descriptions.Item>
                        <Descriptions.Item label={"Query"}>{renderQuery(vulView?.query)}</Descriptions.Item>
                        {appContext.user.isSystemAdmin() && <Descriptions.Item label={"Imported at"}>{formatDateTime(vulView?.importedAt)}</Descriptions.Item>}
                    </Descriptions>

                    {appContext.user.hasOneOfPermission([Permission.REPORT__READ]) && <>
                        <h2>Usage in report definitions</h2>

                        <Table className={styles.table}
                               showSorterTooltip={false}
                               dataSource={reportDefinitions?.data}
                               locale={{emptyText: 'This view is not used in any report definition.'}}
                               size="middle"
                               onChange={tableHandler.onTableChange}
                               pagination={tableHandler.pagination}
                               rowKey="id"
                               columns={columns}
                        />
                    </>}
                </Spin>
            </>
        </DocumentTitle>
    )

    function renderQuery(value: any) {
        if (value === undefined) {
            return;
        }

        return <ReadOnlyQueryView sources={sources} query={JSON.parse(value)}/>
    }

    function renderItemCount() {
        const filter: any = {
            typeHints: {},
            values: {
                vulView: [vulViewId]
            }
        }

        const searchParams = new URLSearchParams();

        searchParams.set("filter", window.btoa(JSON.stringify(filter)))
        searchParams.set("$readOnly", "");

        return <a href={`/vul-source-items?${searchParams.toString()}`} target={"_blank"}>{vulView?.itemCount}</a>;
    }

    function renderEditForm() {
        return (
            <Collapse isOpen={editMode}>
                <h3>Edit of vulnerability view</h3>

                <Form {...layout} form={editForm} className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]} ${styles.form}`} onFinish={onFinishEdit}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                            {validator: serverViolationsHolder.createServerValidator('UNIQUE', 'name', undefined, {compareLowerCaseValues: true}), message: "name-not-unique"}
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"description"}
                        label={"Description"}
                        extra={"Description of vulnerability source in CommonMark format."}>
                        <TextArea maxLength={1000} autoSize={{minRows: 4, maxRows: 8}}/>
                    </Form.Item>

                    {appContext.user.isSystemAdmin() && <Form.Item
                        name={["tags"]}
                        label={"Tags"}>
                        <Select mode="tags" options={tags?.map(tag => ({value: tag, label: tag}))}/>
                    </Form.Item>}

                    {appContext.user.isSystemAdmin() && <>
                        <Form.Item
                            name={["owner"]}
                            label={"Owner"}
                            rules={[{required: true, message: "Owner is required."}]}
                            initialValue={ResourceOwnerType.SYSTEM}>
                            <Select options={Object.keys(ResourceOwnerType).map(value => ({value: value, label: value}))}/>
                        </Form.Item>

                        {owner === ResourceOwnerType.ORGANIZATION && <Form.Item
                            name={"organization"}
                            label={"Organization"}
                            rules={[{required: true, message: "Organization is required."}]}>
                            <Select
                                allowClear={true}
                                options={organizations?.map(organization => ({value: organization.id, label: organization.name}))}
                            />
                        </Form.Item>}
                    </>}

                    <Form.Item
                        name={"query"}
                        label={"Query"}>
                        <VulViewQueryBuilder initialQuery={vulView?.query} form={editForm}/>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => setEditMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function onExportToJson() {
        downloadFile("vulview.json", JSON.stringify(vulView));
    }

    function onFinishEdit(values: any) {
        vulViewService.update(vulViewId, values)
            .then(
                (id) => {
                    message.success("Vulnerability view updated.");

                    setEditMode(false);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }

    function onDeleteConfirm() {
        vulViewService.delete(vulView)
            .then(() => {
                message.success("Vulnerability view deleted.");

                navigate(`/vul-source-items/views`);
            });
    }

    function renderVulViews(value: any, definition: VulReportDefinition) {
        return definition.vulViews?.map((view) => <>
            <div><Link to={`/vul-source-items/views/${view.id}`}>{view.name}</Link></div>
        </>);
    }

    function reload() {
        vulViewService.get(vulViewId).then(vulView => {
            setVulView(vulView);

            editForm.setFieldsValue({
                ...vulView,
                organization: vulView.organization?.id
            });
        });
    }

    function reloadReportDefinitions() {
        return vulReportDefinitionService.getListOfUsedForView(tableHandler.queryOptions, vulViewId).then(vulViews => {
            tableHandler.updateTotal(vulViews.total);

            setReportDefinitions(vulViews);
        })
    }

}

export default VulViewDetail;
