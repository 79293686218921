import Organization from "../Organization";
import {ResourceOwnerType} from "../ResourceOwnerType";
import RolePermission from "./RolePermission";

class Role {
    constructor(
        public id?: string,
        public name?: string,
        public description?: string,
        public owner?: ResourceOwnerType,
        public organization?: Organization,
        public permissions?: RolePermission[]) {
        //
    }

    public static fromPOJO(data: any) {
        const role = new Role();

        role.id = data.id;
        role.name = data.name;
        role.description = data.description;
        role.owner = data.owner;
        role.organization = Organization.fromPOJO(data.organization);
        role.permissions = data.permissions && data.permissions.map(perm => RolePermission.fromPOJO(perm));

        return role;
    }

}

export default Role;
