export enum FindingJustification {
    COMPONENT_NOT_PRESENT = "COMPONENT_NOT_PRESENT",
    VULNERABLE_CODE_NOT_PRESENT = "VULNERABLE_CODE_NOT_PRESENT",
    VULNERABLE_CODE_NOT_IN_EXECUTE_PATH = "VULNERABLE_CODE_NOT_IN_EXECUTE_PATH",
    VULNERABLE_CODE_CANNOT_BE_CONTROLLED_BY_ADVERSARY = "VULNERABLE_CODE_CANNOT_BE_CONTROLLED_BY_ADVERSARY",
    INLINE_MITIGATIONS_ALREADY_EXIST = "INLINE_MITIGATIONS_ALREADY_EXIST"
}

export function formatFindingJustification(justification?: FindingJustification) {
    switch (justification) {
        case FindingJustification.COMPONENT_NOT_PRESENT:
            return "component not present";
        case FindingJustification.VULNERABLE_CODE_NOT_PRESENT:
            return "vulnerable code not present";
        case FindingJustification.VULNERABLE_CODE_NOT_IN_EXECUTE_PATH:
            return "vulnerable code not in execute path";
        case FindingJustification.VULNERABLE_CODE_CANNOT_BE_CONTROLLED_BY_ADVERSARY:
            return "vulnerable code cannot be controlled by adversary";
        case FindingJustification.INLINE_MITIGATIONS_ALREADY_EXIST:
            return "inline mitigations already exist";
    }
}
