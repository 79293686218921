.filter {
    max-width: 1200px;
}

.extractor-table {
    max-width: 1200px;
}

.column-action {
    width: 140px;
    vertical-align: center;
}

.extractor-description:global(.ant-descriptions) {
    margin-bottom: 0;
    background: transparent;
}