class QualifiedEnum {
    constructor(
        public moduleName?: string,
        public name?: string) {
        //
    }

    public static fromPOJO(data: any) {
        const attribute = new QualifiedEnum();

        attribute.moduleName = data.name;
        attribute.name = data.name;

        return attribute;
    }
}

export default QualifiedEnum;
