import {CopyOutlined, DeleteFilled, MinusSquareOutlined, PlusOutlined, PlusSquareOutlined, ReloadOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, message, Modal, Space, Table, Tabs, Tag} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, VulSourceServiceContext, VulViewServiceContext} from "../../Contexts";
import VulSource from "../../domain/VulSource";
import {useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";
import {DocumentTitle} from "../DocumentTitle";
import * as styles from "./VulViewList.module.css";
import * as globalStyles from "../App.module.css";
import VulView from "../../domain/VulView";
import {ColumnsType} from "antd/es/table";
import {ResourceOwnerType} from "../../domain/ResourceOwnerType";
import ReadOnlyQueryView from "./ReadOnlyQueryView";
import {UserRole} from "../../domain/UserRole";
import QueryOptions from "../../sal-ui/QueryOptions";
import ReactMarkdown from "react-markdown";
import {GetComponentProps} from "rc-table/lib/interface";
import {Permission} from "../../domain/auth/Permission";

function VulViewList() {
    const appContext = useContext(AppContextContext);
    const vulViewService = useContext(VulViewServiceContext);
    const vulSourceService = useContext(VulSourceServiceContext);
    const navigate = useNavigate();
    const [sources, setSources] = useState<VulSource[]>();
    const [tableStateId, setTableStateId] = useState(crypto.randomUUID());

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        vulSourceService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setSources(value.data));
    }, [])

    const title = "Vulnerability views";

    const columns: ColumnsType<VulView> = [
        {
            dataIndex: "name",
            title: "Name",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "ascend",
            render: (value, vulView) => <>
                <div><Link to={`/vul-source-items/views/${vulView.id}`}>{value}</Link>{appContext.user.isSystemAdmin() && vulView.importedAt && <Tag style={{marginLeft: 8}} bordered={false} color={"blue"}>imported</Tag>}</div>

                {vulView.description && <ReactMarkdown className={`${globalStyles.markdown} ${styles.description}`} children={vulView.description}/>}

                {appContext.user.isSystemAdmin() && vulView.tags.length > 0 && <div style={{marginTop: 8}}>{vulView.tags?.map(value => <Tag>{value}</Tag>)}</div>}
            </>
        },
        {
            dataIndex: ["organization", "name"],
            key: "organization.name",
            title: "Organization",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            align: "left",
            render: renderOrganization
        }
    ];

    if (appContext.user.hasPermission(Permission.VIEW__MANAGE_ORG)) {
        columns.push(
            {
                title: "Actions",
                width: 100,
                className: globalStyles["table-actions"],
                render: renderAction
            }
        );
    }

    const orgTabItem = {
        key: "org",
        label: "Organization",
        children: <OrgViewsTable onRow={onRow} columns={columns} sources={sources} tableStateId={tableStateId}/>
    }

    const systemTabItem = {
        key: "system",
        label: "System",
        children: <SystemViewsTable onRow={onRow} columns={columns} sources={sources} tableStateId={tableStateId}/>
    }

    const allTabItem = {
        key: "all",
        label: "All views",
        children: <AllViewsTable onRow={onRow} columns={columns} sources={sources} tableStateId={tableStateId}/>
    }

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb
                    className={globalStyles["common__breadcrumb"]}
                    items={[
                        {title: appContext.config?.appName},
                        {title}
                    ]}
                />

                <h1>{title}</h1>

                {appContext.user.hasPermission(Permission.VIEW__MANAGE_ORG) &&
                    <div className={globalStyles["common__top-button-bar"]}>
                        <Button type={"primary"}
                                icon={<PlusOutlined/>}
                                onClick={() => navigate("/vul-source-items/views/add")}>Add</Button>
                    </div>
                }

                <Tabs items={getTabItems()}/>
            </>
        </DocumentTitle>
    )

    function renderOrganization(value: any, vulView: VulView) {
        if (vulView.owner === ResourceOwnerType.SYSTEM) {
            return <i>System view</i>;
        } else {
            return <Link to={`/organizations/${vulView.organization.id}`}>{value}</Link>;
        }
    }

    function renderAction(value: any, vulView: VulView, index: number) {
        return (
            <Space>
                <Button icon={<CopyOutlined/>} className={"ant-btn-icon-only"}
                        title={"Clone"}
                        onClick={() => navigate(`/vul-source-items/views/clone/${vulView.id}`)}/>

                {isEditableForPrincipal(vulView) &&
                    <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                            title={"Delete"}
                            onClick={() => {
                                Modal.confirm({
                                    content: <>Do you really want to delete view <b>{vulView.name}</b>?</>,
                                    okText: "Delete",
                                    cancelText: "Cancel",
                                    onOk: () => onDeleteConfirm(vulView)
                                });
                            }}/>
                }
            </Space>
        )
    }

    function isEditableForPrincipal(vulView: VulView) {
        if (vulView?.owner === ResourceOwnerType.SYSTEM && appContext.user.role !== UserRole.SYSTEM_ADMIN) {
            return false;
        }

        return true;
    }

    function onDeleteConfirm(vulView: VulView) {
        vulViewService.delete(vulView)
            .then(() => {
                message.success(<>View {vulView.name} successfully deleted.</>);

                setTableStateId(crypto.randomUUID());
            });
    }

    function onRow(vulSource: VulSource, index?: number) {
        return {onDoubleClick: () => navigate(`/vul-source-items/views/${vulSource.id}`)}
    }

    function getTabItems() {
        if (appContext.user.isSystemAdmin()) {
            return [systemTabItem, orgTabItem, allTabItem];
        }

        if (appContext.user.hasPermission(Permission.VIEW__READ_SEAL) && appContext.user.hasPermission(Permission.VIEW__READ_ORG)) {
            return [orgTabItem, systemTabItem, allTabItem];
        }

        if (appContext.user.hasPermission(Permission.VIEW__READ_SEAL)) {
            return [systemTabItem];
        }

        if (appContext.user.hasPermission(Permission.VIEW__READ_ORG)) {
            return [orgTabItem];
        }

        return [];
    }
}

interface TableProps {
    onRow: GetComponentProps<VulView>;
    columns: ColumnsType<VulView>;
    sources: VulSource[];
    tableStateId: string;
}

function OrgViewsTable(props: TableProps) {
    const appContext = useContext(AppContextContext);
    const vulViewService = useContext(VulViewServiceContext);
    const [views, setViews] = useState<PagedResult<VulView>>();
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const tableHandler = useTableHandler("name", {
        reloadFunction: reload,
        persistentIdent: 'OrgVulViewList',
        fixedRqbQuery: {
            combinator: "and",
            rules: [{
                field: "owner",
                operator: "eq",
                value: ResourceOwnerType.ORGANIZATION
            }]
        }
    });

    useEffect(() => tableHandler.reload(), [props.tableStateId]);

    return <>
        <div className={globalStyles["common__top-button-bar"]}>
            <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>

            <Button className={"btn-seamless"} icon={<PlusSquareOutlined/>} title={"Expand all"} onClick={() => setExpandedRowKeys(views?.data?.map(value => value.id))}/>
            <Button className={"btn-seamless"} icon={<MinusSquareOutlined/>} title={"Collapse all"} onClick={() => setExpandedRowKeys([])}/>
        </div>

        <Table className={styles.table}
               showSorterTooltip={false}
               dataSource={views?.data}
               size="middle"
               loading={tableHandler.loading}
               onChange={tableHandler.onTableChange}
               pagination={tableHandler.pagination}
               onRow={props.onRow}
               rowKey="id"
               columns={props.columns.filter(column => appContext.user.isSystemAdmin() ? true : column.key !== "organization.name")}
               expandable={{
                   expandedRowKeys: expandedRowKeys,
                   onExpand: (expanded, record) => {
                       if (expanded) {
                           setExpandedRowKeys(prevState => [...prevState, record.id]);
                       } else {
                           setExpandedRowKeys(prevState => prevState.filter(v => v != record.id));
                       }
                   },
                   expandedRowRender: record => (
                       <div className={styles.query}>
                           <h2>Query</h2>

                           <ReadOnlyQueryView sources={props.sources} query={JSON.parse(record.query)}/>
                       </div>
                   )
               }}
        />
    </>;

    function reload() {
        return vulViewService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setViews(value);
        });
    }

}

function SystemViewsTable(props: TableProps) {
    const vulViewService = useContext(VulViewServiceContext);
    const [views, setViews] = useState<PagedResult<VulView>>();
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const tableHandler = useTableHandler("name", {
        reloadFunction: reload,
        persistentIdent: 'SystemVulViewList',
        fixedRqbQuery: {
            combinator: "and",
            rules: [{
                field: "owner",
                operator: "eq",
                value: ResourceOwnerType.SYSTEM
            }]
        }
    });

    useEffect(() => tableHandler.reload(), [props.tableStateId]);

    return <>
        <div className={globalStyles["common__top-button-bar"]}>
            <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>

            <Button className={"btn-seamless"} icon={<PlusSquareOutlined/>} title={"Expand all"} onClick={() => setExpandedRowKeys(views?.data?.map(value => value.id))}/>
            <Button className={"btn-seamless"} icon={<MinusSquareOutlined/>} title={"Collapse all"} onClick={() => setExpandedRowKeys([])}/>
        </div>

        <Table className={styles.table}
               showSorterTooltip={false}
               dataSource={views?.data}
               size="middle"
               loading={tableHandler.loading}
               onChange={tableHandler.onTableChange}
               pagination={tableHandler.pagination}
               onRow={props.onRow}
               rowKey="id"
               columns={props.columns.filter(column => column.key !== "organization.name")}
               expandable={{
                   expandedRowKeys: expandedRowKeys,
                   onExpand: (expanded, record) => {
                       if (expanded) {
                           setExpandedRowKeys(prevState => [...prevState, record.id]);
                       } else {
                           setExpandedRowKeys(prevState => prevState.filter(v => v != record.id));
                       }
                   },
                   expandedRowRender: record => (
                       <div className={styles.query}>
                           <h2>Query</h2>

                           <ReadOnlyQueryView sources={props.sources} query={JSON.parse(record.query)}/>
                       </div>
                   )
               }}
        />
    </>;

    function reload() {
        return vulViewService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setViews(value);
        });
    }

}

function AllViewsTable(props: TableProps) {
    const vulViewService = useContext(VulViewServiceContext);
    const [views, setViews] = useState<PagedResult<VulView>>();
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const tableHandler = useTableHandler("name", {
        reloadFunction: reload,
        persistentIdent: 'AllVulViewList'
    });

    useEffect(() => tableHandler.reload(), [props.tableStateId]);

    return <>
        <div className={globalStyles["common__top-button-bar"]}>
            <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>

            <Button className={"btn-seamless"} icon={<PlusSquareOutlined/>} title={"Expand all"} onClick={() => setExpandedRowKeys(views?.data?.map(value => value.id))}/>
            <Button className={"btn-seamless"} icon={<MinusSquareOutlined/>} title={"Collapse all"} onClick={() => setExpandedRowKeys([])}/>
        </div>

        <Table className={styles.table}
               showSorterTooltip={false}
               dataSource={views?.data}
               size="middle"
               loading={tableHandler.loading}
               onChange={tableHandler.onTableChange}
               pagination={tableHandler.pagination}
               onRow={props.onRow}
               rowKey="id"
               columns={props.columns.filter(column => column.key !== "organization")}
               expandable={{
                   expandedRowKeys: expandedRowKeys,
                   onExpand: (expanded, record) => {
                       if (expanded) {
                           setExpandedRowKeys(prevState => [...prevState, record.id]);
                       } else {
                           setExpandedRowKeys(prevState => prevState.filter(v => v != record.id));
                       }
                   },
                   expandedRowRender: record => (
                       <div className={styles.query}>
                           <h2>Query</h2>

                           <ReadOnlyQueryView sources={props.sources} query={JSON.parse(record.query)}/>
                       </div>
                   )
               }}
        />
    </>;

    function reload() {
        return vulViewService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setViews(value);
        });
    }

}


export default VulViewList;
