import AttributeExtractionDefinition from "./AttributeExtractionDefinition";
import {AttributeValueTransformerType} from "./AttributeValueTransformerType";

class AttributeExtractor {
    constructor(
        public id?: string,
        public priority?: number,
        public attributeExtractionDefinition?: AttributeExtractionDefinition,
        public extractorType?: AttributeExtractorType,
        public path?: string,
        public patternMatch?: string,
        public patternReplace?: string,
        public attributeValueTransformer?: AttributeValueTransformerType
    ) {
        //

    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const entity = new AttributeExtractor();

        entity.id = data.id;
        entity.priority = data.priority;
        entity.attributeExtractionDefinition = data.attributeExtractionDefinition && AttributeExtractionDefinition.fromPOJO(data.attributeExtractionDefinition);
        entity.extractorType = data.extractorType;
        entity.path = data.path;
        entity.patternMatch = data.patternMatch;
        entity.patternReplace = data.patternReplace;
        entity.attributeValueTransformer = data.attributeValueTransformer;

        return entity;
    }


}

export enum AttributeExtractorType {
    JSON_POINTER = "JSON_POINTER",
    JSON_PATH = "JSON_PATH"
}

export default AttributeExtractor;
