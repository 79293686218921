.container {
    display: flex;
    align-items: center;
    gap: 4px;
}

.flag {
    margin-right: 6px;
    display: inline-block;
    padding: 1px;
    background-color: rgb(0, 0, 0, 0.05);
    line-height: 1em;
}
