export function formatSoftwareLicenseCategory(category: string) {
    switch (category) {
        case "CLA":
            return <>A Contributor License Agreement (CLA) describes and defines the contribution acceptance rules for the ongoing development and enhancement of a software project. The CLA may specify how the resulting software contribution itself will be licensed.</>;
        case "Commercial":
            return <>Third-party proprietary software offered under a direct commercial license between supplier and customer. Further fact-finding by Product Teams will be necessary to determine the code's license status and function, if any.</>;
        case "Copyleft":
            return <>Open source software with a "copyleft" license that offers irrevocable permission to the public to copy and redistribute the work in the same or modified form, but with the conditions that all such redistributions make the work available in a form that facilitates further modification and use the same license terms. A copyleft license can require code interacting with copyleft-licensed code to be licensed the same way.</>;
        case "Copyleft Limited":
            return <>A license that requires you to redistribute source code, including your changes, and also to provide attribution for the software authors. Your obligation to redistribute source code, including proprietary code linked with code under this license, is limited according to license-specific rules.</>;
        case "Free Restricted":
            return <>A Permissive-style license, that contains restrictions regarding the usage of the software (for example, where the software is not intended for use in nuclear power plants) or the redistribution of the software (for example, where commercial redistribution of the software is not allowed without express permission). The Free Software Foundation (FSF) says that a license with this kind of restriction is not really open source, although the OSI point of view is not that strict.</>;
        case "Patent License":
            return <>A license that applies to patents rather than specific software. May be used in conjunction with other software license(s) that apply to a software component.</>;
        case "Permissive":
            return <>Open Source software that is made available under "non-copyleft" licenses. These generally require attribution of the included open source and may include other obligations. </>;
        case "Proprietary Free":
            return <>Proprietary Free software that may not require a commercial license but may have specific terms and conditions which Product Teams are obligated to follow. Some of these terms and conditions are provided with or in the code or in clickable downloaded licenses. Examples are the Sun Binary Code License Agreement or a freely offered BSP.</>;
        case "Public Domain":
            return <>Open source software that is made available without explicit obligations, but which has a license notice that must be kept with the code per organization policy. The match may be to software, code examples on a website, published public domain specifications or another type of publication. </>;
        case "Source-available":
            return <>Source-available software is software released through a source code distribution model that includes arrangements where the source can be viewed, and in some cases modified, but without necessarily meeting the criteria to be called open-source.</>;
        case "Unstated License":
            return <>Third-party software that has a copyright notice, but no stated license. Common examples include code snippets from publications and websites (such as those from O'Reilly Media). The absence of a license poses a risk that the copyright owner may assert license obligations at some future time. Product Teams may need to contact the copyright owner to determine the license obligations, if any.</>;
    }
}