import {DeleteFilled, MinusSquareOutlined, PlusOutlined, PlusSquareOutlined, ReloadOutlined} from "@ant-design/icons";
import {Button, Checkbox, message, Modal, Table, Tag} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, AttributeExtractionDefinitionServiceContext} from "../../Contexts";
import AttributeExtractionDefinition from "../../domain/AttributeExtractionDefinition";
import Ticket from "../../domain/Ticket";
import {useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";
import * as globalStyles from "../App.module.css";
import * as styles from "./AttributeExtractionDefinitionList.module.css";
import {ColumnsType} from "antd/es/table";
import {formatAttributeExtractionSource} from "../../domain/AttributeExtractionSource";
import {formatVulSourceType} from "../../domain/VulSourceType";
import ExtractorReadOnlyList from "./ExtractorReadOnlyList";

interface Props {
    attributeId: string;
    compactReadOnlyView?: boolean;
}

function AttributeExtractionDefinitionList(props: Props) {
    const appContext = useContext(AppContextContext);
    const attributeExtractionDefinitionService = useContext(AttributeExtractionDefinitionServiceContext);
    const navigate = useNavigate();
    const tableHandler = useTableHandler("attributeDefinition.name", {
        reloadFunction: reload,
        fixedFilter: `attributeDefinition.id eq '${props.attributeId}'`,
        initialPageSize: (props.compactReadOnlyView) ? 1000 : undefined
    });
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const [attributeExtractionDefinitions, setAttributeExtractionDefinitions] = useState<PagedResult<AttributeExtractionDefinition>>();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        tableHandler.reload();
    }, [])

    const columns: ColumnsType<AttributeExtractionDefinition> = [];

    columns.push({
        dataIndex: "id",
        title: "ID",
        width: 140,
        align: "center",
        render: renderId
    });

    columns.push({
        dataIndex: "priority",
        title: "Priority",
        width: 80,
        align: "center"
    });

    columns.push({
        dataIndex: "extractionSource",
        title: "Extraction source",
        width: 160,
        align: "center",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: value => formatAttributeExtractionSource(value)
    });

    columns.push({
        dataIndex: "enabled",
        title: "Enabled",
        width: 120,
        align: "center",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: value => <Checkbox checked={value} disabled={true}/>
    });

    columns.push({
        dataIndex: ["sourceTypes"],
        title: "Source types",
        align: "left",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: value => value.map(formatVulSourceType).join(", ")
    });

    columns.push({
        dataIndex: ["sources"],
        title: "Sources",
        align: "left",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: sources => sources?.map(source => <Link to={`/vul-sources/${source.id}`}>{source.name}</Link>)
    });

    if (appContext.user.isSystemAdmin() && !props.compactReadOnlyView) {
        columns.push({
                title: "Actions",
                width: 100,
                className: globalStyles["table-actions"],
                render: renderAction
            }
        );
    }

    const title = "Attribute extractions";

    return (
        <>
            {props.compactReadOnlyView && <>
                <h2>{title}</h2>

                <div className={globalStyles["common__top-button-bar"]}>
                    <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>

                    <Button className={"btn-seamless"} icon={<PlusSquareOutlined/>} title={"Expand all"} onClick={() => setExpandedRowKeys(attributeExtractionDefinitions?.data?.map(value => value.id))}/>
                    <Button className={"btn-seamless"} icon={<MinusSquareOutlined/>} title={"Collapse all"} onClick={() => setExpandedRowKeys([])}/>
                </div>
            </>}

            {!props.compactReadOnlyView && <>
                <h1>{title}</h1>

                <div className={globalStyles["common__top-button-bar"]}>
                    <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>

                    {appContext.user.isSystemAdmin() &&
                        <Button type={"primary"}
                                icon={<PlusOutlined/>}
                                onClick={() => navigate(`/attributes/${props.attributeId}/extraction-definitions/add`)}>Add</Button>
                    }
                </div>
            </>}

            <Table className={styles.table}
                   showSorterTooltip={false}
                   loading={tableHandler.loading}
                   dataSource={attributeExtractionDefinitions?.data}
                   size={(props.compactReadOnlyView) ? "small" : "middle"}
                   locale={{emptyText: 'There are no attribute extraction definitions for this attribute.'}}
                   onChange={tableHandler.onTableChange}
                   pagination={(props.compactReadOnlyView) ? false : tableHandler.pagination}
                   onRow={onRow}
                   rowKey="id"
                   columns={columns}
                   expandable={{
                       expandedRowKeys: expandedRowKeys,
                       onExpand: (expanded, record) => {
                           if (expanded) {
                               setExpandedRowKeys(prevState => [...prevState, record.id]);
                           } else {
                               setExpandedRowKeys(prevState => prevState.filter(v => v != record.id));
                           }
                       },
                       expandedRowRender: record => (
                           <div className={styles.extractors}>
                               <ExtractorReadOnlyList attributeExtractionDefinitionId={record.id}/>
                           </div>
                       )
                   }}

            />
        </>
    )

    function renderId(value: string, definition: AttributeExtractionDefinition) {
        return (
            <>
                <Link to={`/attributes/extraction-definitions/${value}`}>
                    <span title={value}>{value.substring(0, 2) + '...' + value.substring(value.length - 2)}</span>
                </Link>
                {appContext.user.isSystemAdmin() && definition.importedAt && <Tag style={{marginLeft: 8}} bordered={false} color={"blue"}>imported</Tag>}
            </>
        )
    }

    function renderAction(value: any, definition: AttributeExtractionDefinition, index: number) {
        return (
            <>
                <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                        title={"Delete"}
                        onClick={() => {
                            Modal.confirm({
                                content: "Do you really want to delete this definition?",
                                okText: "Delete",
                                cancelText: "Cancel",
                                onOk: () => onDeleteConfirm(definition)
                            });
                        }}/>
            </>
        )
    }

    function onDeleteConfirm(ticket: Ticket) {
        attributeExtractionDefinitionService.delete(ticket)
            .then(() => {
                message.success("Definition successfully deleted.");

                reload();
            });
    }

    function reload() {
        return attributeExtractionDefinitionService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setAttributeExtractionDefinitions(value);
        });
    }

    function onRow(ticket: Ticket, index?: number) {
        return {onDoubleClick: () => navigate(`/attributes/extraction-definitions/${ticket.id}`)}
    }
}

export default AttributeExtractionDefinitionList;
