.query-buttons {
    margin-top: 8px;
    display: flex;
    gap: 8px;
}

.details, .form, .table {
    max-width: 1200px;
}

.details :global(.ant-descriptions-item-label) {
    width: 180px;
}

.column-name {
    min-width: 160px;
}