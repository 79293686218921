export enum Scope {
    SBOM_PROJECT = "SBOM_PROJECT"
}

export function formatScope(scope: Scope) {
    switch (scope) {
        case Scope.SBOM_PROJECT:
            return "SBOM project";
    }
}

export function scopeRequiresObjectId(scope: Scope) {
    switch (scope) {
        case Scope.SBOM_PROJECT:
            return true;
    }
}
