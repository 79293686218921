import React, {useContext, useEffect, useState} from "react";
import {SbomProjectVersionServiceContext} from "../../Contexts";
import SbomProjectVersion from "../../domain/SbomProjectVersion";
import {useForm} from "antd/es/form/Form";
import {ConfigProvider, Form, Modal, Select} from "antd";
import * as globalStyles from "../App.module.css";

function CompareModal(props: { open: boolean, sbomProjectId: string, sbomProjectVersionId: string, onClose: () => void }) {
    const sbomProjectVersionService = useContext(SbomProjectVersionServiceContext);
    const [projectVersions, setProjectVersions] = useState<SbomProjectVersion[]>();
    const [form] = useForm();

    useEffect(() => {
        if (props.sbomProjectId === undefined) {
            return;
        }

        sbomProjectVersionService.getComparableList(props.sbomProjectId).then(value => setProjectVersions(value.data));
    }, [props.sbomProjectId]);

    return (
        <Modal open={props.open}
               title={"Compare to another project version"}
               okText={"Compare"}
               cancelText={"Cancel"}
               destroyOnClose={true}
               onOk={() => {
                   form.validateFields().then((values: any) => {
                       window.open(`/sbom-project-versions/${props.sbomProjectVersionId}/change-log/${values.anotherProjectVersionId}`, "_blank");

                       props.onClose();
                   });
               }}
               onCancel={() => {
                   props.onClose();

                   form.resetFields();
               }}
        >
            Compare components of this version with components of another version in the same project.

            <ConfigProvider theme={{components: {Form: {itemMarginBottom: 8}}}}>
                <Form form={form} className={globalStyles["common__form"]} labelCol={{span: 8}}>
                    <Form.Item name={"anotherProjectVersionId"}
                               label={"Project version"}
                               rules={[{required: true, message: "Project version is required."}]}>
                        <Select
                            showSearch={true}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={projectVersions?.filter(version => version.id !== props.sbomProjectVersionId)?.map(version => {
                                return {
                                    label: version.name,
                                    value: version.id
                                }
                            })}/>
                    </Form.Item>
                </Form>
            </ConfigProvider>
        </Modal>
    );
}

export default CompareModal;
