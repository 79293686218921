import Organization from "./Organization";
import TicketAttributeRule from "./TicketAttributeRule";
import {TicketSeverity} from "./TicketSeverity";
import VulSourceItem from "./VulSourceItem";

class TicketCreationDefinition {
    constructor(
        public id?: string,
        public enabled?: boolean,
        public name?: string,
        public organization?: Organization,
        public attributeRules?: TicketAttributeRule[]) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const entity = new TicketCreationDefinition();

        entity.id = data.id;
        entity.enabled = data.enabled;
        entity.name = data.name;
        entity.organization = Organization.fromPOJO(data.organization);
        entity.attributeRules = data.attributeRules && data.attributeRules.map(attributeRule => TicketAttributeRule.fromPOJO(attributeRule));
        return entity;
    }


}

export default TicketCreationDefinition;
