import {DocumentTitle} from "../DocumentTitle";
import {Breadcrumb, Button, Checkbox, Form, Input, message, Select, Switch} from "antd";
import * as globalStyles from "../App.module.css";
import * as styles from "./SbomProjectReportDefinitionAdd.module.css";
import * as tackStyles from "../vulsourceitem/Tack.module.css";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {AppContextContext, SbomProjectReportDefinitionServiceContext, SbomProjectServiceContext} from "../../Contexts";
import {useForm} from "antd/es/form/Form";
import 'react-querybuilder/dist/query-builder.scss';
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import _ from "lodash";
import * as descriptionStyles from "../SelectItemDescription.module.css";
import SbomProjectReportDefinition from "../../domain/SbomProjectReportDefinition";
import SbomProject from "../../domain/SbomProject";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function SbomProjectReportDefinitionAdd() {
    const appContext = useContext(AppContextContext);
    const sbomProjectReportDefinitionService = useContext(SbomProjectReportDefinitionServiceContext);
    const sbomProjectService = useContext(SbomProjectServiceContext);
    const navigate = useNavigate();
    const [form] = useForm();
    const {definitionId}: any = useParams();
    const [sbomProjects, setSbomProjects] = useState<SbomProject[]>();
    const [sbomProjectReportDefinition, setSbomProjectReportDefinition] = useState<SbomProjectReportDefinition>();

    useEffect(() => {
        sbomProjectService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => {
            setSbomProjects(value.data);
        });
    }, []);

    useEffect(() => {
        if (definitionId === undefined) {
            return;
        }

        sbomProjectReportDefinitionService.get(definitionId).then(definition => {
            setSbomProjectReportDefinition(definition);

            form.setFieldsValue({
                ...definition,
                sbomProjects: definition.sbomProjects.map(value => value.id)
            });
        });
    }, [definitionId]);

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    const title = (definitionId) ? <>Clone of definition <b>{sbomProjectReportDefinition?.name}</b></> : <>New SBOM report definition</>;

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/sbom-project-report-definitions"}>SBOM report definitions</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{title}</h1>

                <Form {...layout} form={form} className={globalStyles["common__form"]} onFinish={onFinish}>

                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."}
                        ]}>
                        <Input autoFocus={true} maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"sbomProjects"}
                        label={"SBOM projects"}
                        rules={[{required: true, message: "At least one SBOM project is required."}]}>
                        <Select
                            mode={"multiple"}
                            filterOption={(inputValue, option) => {
                                const normalizedInput = inputValue.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
                                const normalizedLabel = option.label?.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");

                                return normalizedLabel.includes(normalizedInput);
                            }}
                            optionLabelProp={"label"}
                            popupMatchSelectWidth={350}
                            virtual={false}
                            listHeight={300}
                        >
                            {sbomProjects?.map(sbomProject =>
                                <Select.Option key={sbomProject.id} value={sbomProject.id} label={sbomProject.name}>
                                    <div className={`${tackStyles.item}`}>
                                        <div className={descriptionStyles['name-container']}>
                                            <span className={descriptionStyles.name}>{sbomProject.name}</span>
                                        </div>
                                    </div>
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={"days"}
                        label={"Days"}
                        initialValue={["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"]}>
                        <Checkbox.Group className={styles.days}>
                            <Checkbox value={"MONDAY"}>Monday</Checkbox>
                            <Checkbox value={"TUESDAY"}>Tuesday</Checkbox>
                            <Checkbox value={"WEDNESDAY"}>Wednesday</Checkbox>
                            <Checkbox value={"THURSDAY"}>Thursday</Checkbox>
                            <Checkbox value={"FRIDAY"}>Friday</Checkbox>
                            <Checkbox value={"SATURDAY"}>Saturday</Checkbox>
                            <Checkbox value={"SUNDAY"}>Sunday</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>

                    <Form.Item
                        name={"times"}
                        label={"Hours"}
                        initialValue={["08:00"]}>
                        <Checkbox.Group
                            className={styles.times}
                            options={_.range(24).map(index => String(index).padStart(2, '0') + ":00")}
                        />
                    </Form.Item>

                    <Form.Item
                        name={"timeZone"}
                        label={"Time zone"}
                        initialValue={new Intl.DateTimeFormat().resolvedOptions().timeZone}
                        rules={[{required: true, message: "Time zone is required."}]}>
                        <Select
                            options={Intl.supportedValuesOf('timeZone').map(value => { return { label: value, value }})}
                            showSearch={true}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        name={"email"}
                        label={"E-mail"}
                        extra={"Comma separated list of email addresses."}
                        rules={[{required: true, message: "At least one e-mail address is required."}]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"enabled"}
                        label={"Enabled"}
                        valuePropName={"checked"}
                        initialValue={true}>
                        <Switch />
                    </Form.Item>

                    <Form.Item
                        name={"generateEmptyReport"}
                        label={"Generate empty report"}
                        valuePropName={"checked"}
                        initialValue={false}>
                        <Switch />
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate("/sbom-project-report-definitions")}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    );

    function onFinish(values: any) {
        sbomProjectReportDefinitionService.add(values)
            .then(
                (id) => {
                    message.success(<>SBOM report definition <b>{values.name}</b> added.</>);

                    navigate(`/sbom-project-report-definitions/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }

}

export default SbomProjectReportDefinitionAdd;