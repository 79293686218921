export enum RssGuidSource {
    GUID = "GUID",
    LINK = "LINK",
    TITLE = "TITLE",
    DESCRIPTION = "DESCRIPTION"
}

export function formatRssGuidSource(source: RssGuidSource) {
    switch (source) {
        case RssGuidSource.GUID:
            return "guid";
        case RssGuidSource.LINK:
            return "link";
        case RssGuidSource.TITLE:
            return "title";
        case RssGuidSource.DESCRIPTION:
            return "content / description";
    }
}
