import Axios from "axios";

class TagService {

    readonly basePath = "/tags";

    public getList(): Promise<string[]> {
        return Axios.get(`${this.basePath}`).then(response => response.data);
    }

}

export default TagService;
