import React from "react";
import {FilterItem, FilterItemProps, InputEditor, SelectEditor, TableFilter} from "../tablefilter/TableFilter";

interface CategoryProps extends FilterItemProps {
    categories: string[];
}

function CategoryItem(props: CategoryProps) {
    return (
        <SelectEditor
            width={120}
            multiple={true}
            options={props.categories?.map(category => ({
                value: category,
                label: <span style={{display: "inline-block"}} className={"selection-item-text"}>{category}</span>
            }))}
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

function NameItem(props: FilterItemProps) {
    return (
        <InputEditor
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

function SpdxIdItem(props: FilterItemProps) {
    return (
        <InputEditor
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

interface VulSourceItemFilterProps {
    className?: string;
    values?: any;
    onChange: (values: any) => void;
    readOnly: boolean;
    immediateMode?: boolean,
}

function SoftwareLicenseFilter(props: VulSourceItemFilterProps) {
    const items: FilterItem[] = [
        {
            name: "name",
            key: "name",
            label: <>Name</>,
            render: (props) => <NameItem {...props} />
        },
        {
            name: "spdxLicenseKey",
            key: "spdxLicenseKey",
            label: <>SPDX identifier</>,
            render: (props) => <NameItem {...props} />
        },
        {
            name: "categories",
            key: "categories",
            label: <>Category</>,
            render: (props) => <CategoryItem {...props} categories={[
                "CLA",
                "Commercial",
                "Copyleft",
                "Copyleft Limited",
                "Free Restricted",
                "Patent License",
                "Permissive",
                "Proprietary Free",
                "Public Domain",
                "Source-available",
                "Unstated License",
            ]} />
        },
    ];

    return (
        <TableFilter
            items={items}
            className={props.className}
            values={props.values}
            defaultValues={{name: '', spdxLicenseKey: '', categories: []}}
            readOnly={props.readOnly}
            immediateMode={props.immediateMode}
            onChange={onChange}
        />
    )

    function onChange(values: any) {
        props.onChange(values);
    }

}

export default SoftwareLicenseFilter;