.status:first-child {
    border-radius: 4px 0 0 4px;
}

.status:last-child {
    border-radius: 0 4px 4px 0;
}

.status:first-child.status:last-child {
    border-radius: 4px;
}

.status {
    display: inline-block;
    padding: 2px 8px;
    font-size: 12px;
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    vertical-align: middle;
}

.status.compact {
    padding: 0 4px;
}

.finding-NONE {
    background-color: #d9d9d9;
}

.finding-LOW {
    background-color: #91caff;
}

.finding-MEDIUM {
    background-color: #ffec3d;
}

.finding-HIGH {
    background-color: #ffa940;
}

.finding-CRITICAL {
    background-color: #f5222d;
    color: white;
}

.finding-NO_VULNERABLE {
    color: white;
    background-color: #52c41a;
}
