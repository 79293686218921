import _ from "lodash";
import {CopyOutlined, FilterOutlined, MinusSquareOutlined, PlusSquareOutlined, ReloadOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, Col, message, Row, Space, Table, Tooltip} from "antd";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {DocumentTitle} from "../DocumentTitle";
import {AppContextContext, AuditLogServiceContext} from "../../Contexts";
import AuditLog from "../../domain/AuditLog";
import {TableUiConfig, useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";
import {FilterBuilder} from "../../sal-ui/filter/FilterBuilder";
import {getEqualOrGreaterSeverities} from "../../domain/AuditLogSeverity";
import CopyToClipboard from "react-copy-to-clipboard";
import {FormPersister} from "../../sal-ui/FormPersister";
import * as styles from "./AuditLogList.module.css";
import *as globalStyles from "../App.module.css";
import {Link, useLocation} from "react-router-dom";
import {useNavigate} from "react-router";
import Flag from "../flag/Flag";
import AuditLogFilter from "./AuditLogFilter";
import {ColumnsType} from "antd/es/table";
import {TableConfig} from "../tableconfig/TableConfig";

const defaultVisibleColumns = ["timestamp", "severity", "ipAddress", "principalText", "message"];

const defaultTableUiConfig = {immediateMode: false, visibleColumns: defaultVisibleColumns};

function AuditLogList() {
    const persistentIdent = 'AuditLogList';
    const appContext = useContext(AppContextContext);
    const auditLogService = useContext(AuditLogServiceContext);
    const location = useLocation();
    const navigate = useNavigate();
    const tableHandler = useTableHandler("timestamp desc", {reloadFunction: reload, persistentIdent});
    const filterFormPersister = new FormPersister(persistentIdent);
    const [auditLogs, setAuditLogs] = useState<PagedResult<AuditLog>>();
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const [allRowsExpanded, setAllRowsExpanded] = useState<boolean>(false);
    const [auditLogDetailCache, setAuditLogDetailCache] = useState<any>({});
    const [filterValues, setFilterValues] = useState<any>();
    const [tableUiConfig, setTableUiConfig] = useState<TableUiConfig>(defaultTableUiConfig);
    const lastSearchValuesRef = useRef<any>();

    let detailLoadTimeout: any = undefined;

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        const values = (searchParams.has("filter"))
            ? filterFormPersister.loadValues(searchParams.get("filter"))
            : filterFormPersister.loadValues();

        setFilterValues(values);

        onFinishFilter(values);

        if (searchParams.has("filter")) {
            navigate(location.pathname, {replace: true});
        }

        setAuditLogDetailCache(Object.assign({}, auditLogService.cache));

        setTableUiConfig(tableHandler.loadUiConfig() || defaultTableUiConfig);
    }, [])

    useEffect(() => {
        if (allRowsExpanded) {
            expandAll();
        } else {
            collapseAll();
        }
    }, [auditLogs]);

    useEffect(() => {
        if (filterValues !== undefined) {
            debouncedOnFinishFilter(filterValues);
        }
    }, [filterValues]);

    const debouncedOnFinishFilter = useCallback(_.debounce(onFinishFilter, 500), []);

    const columns: ColumnsType<AuditLog> = [
        {
            dataIndex: "timestamp",
            title: "Timestamp",
            width: 140,
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "descend",
            render: value => dayjs(value).format("YYYY-MM-DD HH:mm:ss")
        },
        {
            dataIndex: "severity",
            title: "Severity",
            width: 80
        },
        {
            dataIndex: "ipAddress",
            title: "IP address",
            render: renderIpAddress
        },
        {
            dataIndex: "principalText",
            title: "User"
        },
        {
            dataIndex: "type",
            title: "Type"
        },
        {
            dataIndex: "message",
            title: "Message"
        }
    ];

    const title = "Audit logs";

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={"common__breadcrumb"}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{title}</h1>

                <AuditLogFilter
                    className={styles.filter}
                    onChange={values => {
                        setFilterValues(values);

                        debouncedOnFinishFilter(values);
                    }}
                    values={filterValues}
                    immediateMode={tableUiConfig.immediateMode}
                />

                <div className={`${globalStyles["common__top-button-bar"]}`}>
                    <Button icon={<ReloadOutlined/>} onClick={tableHandler.reload} className={"btn-seamless"}/>
                    <Button icon={<PlusSquareOutlined/>} onClick={expandAll} title={"Expand all"} className={"btn-seamless"}/>
                    <Button icon={<MinusSquareOutlined/>} onClick={collapseAll} title={"Collapse all"} className={"btn-seamless"}/>

                    <TableConfig
                        columns={columns}
                        value={tableUiConfig}
                        onChange={uiConfig => {
                            setTableUiConfig(uiConfig);

                            tableHandler.saveUiConfig(uiConfig);
                        }}
                    />
                </div>

                <Table className={styles.table}
                       showSorterTooltip={false}
                       loading={tableHandler.loading}
                       dataSource={auditLogs?.data}
                       size="middle"
                       onChange={tableHandler.onTableChange}
                       pagination={tableHandler.pagination}
                       expandable={{
                           expandedRowRender: renderRecord,
                           rowExpandable: record => (record.attributes !== undefined || record.changeLog !== undefined),
                           expandedRowKeys,
                           indentSize: 0,
                           onExpand: (expanded: boolean, record: AuditLog) => {

                               loadDetails(record);

                               if (expanded) {
                                   setExpandedRowKeys(prevState => prevState.concat(record.id!));
                               } else {
                                   setExpandedRowKeys(prevState => prevState.filter(value => value !== record.id));
                               }
                           }
                       }}
                       rowKey="id"
                       columns={columns.filter((value: any) => tableUiConfig.visibleColumns.includes(value.dataIndex))}
                />
            </>
        </DocumentTitle>
    )

    function loadDetails(record: AuditLog) {
        for (let attrName in record.attributes) {
            const entity = attrName.replace("_id", "");

            if (auditLogService.detailSupported.includes(attrName)) {
                auditLogService.loadDetail(entity, record.attributes[attrName]).then(() => {
                    if (detailLoadTimeout) {
                        clearTimeout(detailLoadTimeout);
                    }
                    detailLoadTimeout = setTimeout(() => {
                        setAuditLogDetailCache(Object.assign({}, auditLogService.cache));
                        detailLoadTimeout = undefined;
                    }, 100);
                });
            }
        }

        for (let attrName in record.changeLog) {
            const entity = attrName.replace(".id", "");

            if (auditLogService.detailSupported.includes(attrName.replace(".", "_"))) {

                if (record.changeLog[attrName].newValue) {
                    auditLogService.loadDetail(entity, record.changeLog[attrName].newValue).then(() => {
                        if (detailLoadTimeout) {
                            clearTimeout(detailLoadTimeout);
                        }
                        detailLoadTimeout = setTimeout(() => {
                            setAuditLogDetailCache(Object.assign({}, auditLogService.cache));
                            detailLoadTimeout = undefined;
                        }, 100);
                    });
                }

                if (record.changeLog[attrName].oldValue) {
                    auditLogService.loadDetail(entity, record.changeLog[attrName].oldValue).then(() => {
                        if (detailLoadTimeout) {
                            clearTimeout(detailLoadTimeout);
                        }
                        detailLoadTimeout = setTimeout(() => {
                            setAuditLogDetailCache(Object.assign({}, auditLogService.cache));
                            detailLoadTimeout = undefined;
                        }, 100);
                    });
                }
            }
        }
    }

    function renderIpAddress(value: any, record: AuditLog) {
        const ipAddress = record?.attributes?.['ip_address'] || record?.attributes?.['user_ip'];

        if (ipAddress === undefined || ipAddress === '') {
            return;
        }

        return <Flag cca2={record.attributes?.['geo_ident'] || 'xx'}>{ipAddress}</Flag>;
    }

    function reload() {
        return auditLogService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setAuditLogs(value);
        });
    }

    function expandAll() {
        let tmp: string[] = [];
        auditLogs?.data.forEach(record => {
            tmp.push(record.id!);
            loadDetails(record);
        })
        setExpandedRowKeys(tmp);
        setAllRowsExpanded(true);
    }

    function collapseAll() {
        setExpandedRowKeys([]);
        setAllRowsExpanded(false);
    }

    function renderRecord(record: AuditLog) {
        let attributes: any = (record.attributes !== undefined) ? record.attributes : {};

        attributes.type = record.type;

        if (record.targetId) {
            attributes.target_id = record.targetId;
        }

        if (record.targetText) {
            attributes.target_text = record.targetText;
        }

        return (
            <>
                <Row>
                    <Col span={12}>
                        <Table
                            dataSource={Object.entries(attributes).sort((a, b) => a[0].localeCompare(b[0]))}
                            showSorterTooltip={false}
                            size="small"
                            rowKey="0"
                            bordered={true}
                            tableLayout={"fixed"}
                            title={() => <h4>Attributes</h4>}
                            className={styles['inner-table']}
                            pagination={false}>
                            <Column dataIndex="0" title={"Attribute name"} className={styles["attribute-key"]}/>
                            <Column dataIndex="1" title={"Attribute value"} render={renderAttributeValue} className={styles["attribute-value"]}/>
                        </Table>
                    </Col>

                    <Col span={12}>
                        <Table
                            dataSource={(record.changeLog) ? Object.entries(record.changeLog).sort((a, b) => a[0].localeCompare(b[0])) : []}
                            showSorterTooltip={false}
                            size="small"
                            rowKey="0"
                            bordered={true}
                            tableLayout={"fixed"}
                            title={() => <h4>Changelog</h4>}
                            className={styles["inner-table"]}
                            pagination={false}>
                            <Column dataIndex="0" title={"Attribute name"} className={styles.attributeKey}/>
                            <Column dataIndex="1" title={"Old value"} ellipsis={true} className={"attribute-value"} render={(value, record: any) => renderChangelogValue(record, 'oldValue')}/>
                            <Column dataIndex="2" title={"New value"} ellipsis={true} className={"attribute-value"} render={(value, record: any) => renderChangelogValue(record, 'newValue')}/>
                        </Table>
                    </Col>
                </Row>
            </>
        )
    }

    function renderAttributeValue(value: any, record: any) {
        if (typeof value === 'object') {
            return JSON.stringify(value);
        }

        const attributeName = record[0];

        return (
            <Space size={"small"}>
                <CopyToClipboard text={value} onCopy={(text) => message.info(intlMessage("common.clipboard-text-copied", {text}))}>
                    <Button
                        size="small"
                        icon={<CopyOutlined/>}
                        title={intlMessage("common.clipboard-tool-tip")}
                    />
                </CopyToClipboard>

                <Button
                    size={"small"}
                    onClick={() => onClickAddAttributeFilter(record[0], record[1])}
                    icon={<FilterOutlined/>}
                    title={intlMessage("common.filter-by-this-value")}
                />

                <div className={"audit-log-detail__attribute-value"}>
                    {insertLinkToValue(attributeName, value)} <span style={{color: '#909090'}}>{auditLogDetailCache[record[0].replace("_id", "")] ? "(" + auditLogDetailCache[record[0].replace("_id", "")][value] + ")" : ""}</span>
                </div>
            </Space>
        )
    }

    function insertLinkToValue(attributeName: string, value: string) {
        switch (attributeName) {
            case 'user_id':
                return <Link to={`/users/${value}`} target={"_blank"}>{value}</Link>;
            case 'protected_application_id':
                return <Link to={`/protected-applications/${value}`} target={"_blank"}>{value}</Link>;
            case 'auth_provider_id':
                return <Link to={`/authentication-providers/${value}`} target={"_blank"}>{value}</Link>;
            case 'access_policy_id':
                return <Link to={`/access-policies?id=${value}`} target={"_blank"}>{value}</Link>;
            default:
                return value;
        }
    }

    function renderChangelogValue(record: any, state: 'oldValue' | 'newValue') {
        const attributeName = record[0];
        const changelog = record[1];

        if (changelog[state] === null) {
            return <i>{intlMessage("empty value")}</i>;
        }

        const value = changelog.hidden === true ? "*****" : JSON.stringify(changelog[state], undefined, 2);

        // cache všech zjištěných objektů daného typu; výsledkem je JS objekt kde klíčem je ID hledané entity
        const resolvedValues = auditLogDetailCache[attributeName.replace(".id", "")];

        if (resolvedValues !== undefined) {
            const resolvedValue = resolvedValues[value];

            return (
                <Tooltip placement="topLeft" title={<>{value} {resolvedValue && "(" + resolvedValue + ")"}</>}>
                    {value}

                    {resolvedValue && "(" + resolvedValue + ")"}
                </Tooltip>
            );
        } else {
            return <span className={styles["attribute-value"]}>{value}</span>;
        }
    }

    function onClickAddAttributeFilter(name: string, value: string) {
        if (name === 'type') {
            setFilterValues((prevState: any) => ({
                ...prevState,
                types: [value]
            }))
        } else {
            setFilterValues((prevState: any) => ({
                ...prevState,
                attributes: (prevState.attributes || []).concat({name, value})
            }))
        }

        window.scrollTo(0, 0);

        setExpandedRowKeys([]);
    }

    function onFinishFilter(values: any) {
        const searchValues = Object.assign({}, values);

        const builder = new FilterBuilder();

        searchValues.types && builder.in('type', searchValues.types);
        searchValues.timestampFrom && builder.ge('timestamp', searchValues.timestampFrom.utc().format());
        searchValues.timestampTo && builder.le('timestamp', searchValues.timestampTo.utc().format());
        searchValues.ipAddress && builder.jsonEq('attributes', 'ip_address', searchValues.ipAddress);
        searchValues.severity && builder.in('severity', getEqualOrGreaterSeverities(searchValues.severity));

        // workaround: do searchValues.attributes() se při validateFields() přidá prázdný objekt
        searchValues.attributes = (searchValues.attributes?.length > 0 && searchValues.attributes[0].name && searchValues.attributes[0].value) ? searchValues.attributes : undefined;

        searchValues.attributes = searchValues.attributes?.filter((value: any) => value.name !== undefined && value.value !== undefined);

        if (searchValues.attributes) {
            for (const attr of searchValues.attributes) {
                if (attr.name && attr.value) {
                    if (attr.name === "target_id") {
                        builder.eq("targetId", attr.value);
                    } else if (attr.name === "target_text") {
                        builder.eq("targetText", attr.value);
                    } else {
                        builder.jsonEq('attributes', attr.name, attr.value);
                    }
                }
            }
        }

        const filter = builder.build();

        if (filter !== lastSearchValuesRef.current) {
            filterFormPersister.saveState(searchValues);

            lastSearchValuesRef.current = filter;

            tableHandler.onSearchSubmit(filter);
        }
    }

    function intlMessage(s: string, p?: any) {
        return s;
    }

}

export default AuditLogList;