.table {
    max-width: 800px;
    margin-bottom: 2em;
}

.section {
    margin: 1.5em 0.5em 1.5em 0;
}

.section-title {
    font-size: 18px;
}

.button-bar {
    margin-top: 1em;
}

.transitive-permissions ul {
    margin-top: 1em;
    margin-bottom: 0em;
}