export enum UserRole {
    USER = "USER",
    SYSTEM_ADMIN = "SYSTEM_ADMIN"
}

export function formatUserRole(role?: UserRole) {
    switch (role) {
        case UserRole.USER:
            return "regular user";
        case UserRole.SYSTEM_ADMIN:
            return "system administrator";
        default:
            return role;
    }
}
