.table, .filter, .details {
    max-width: 1600px;
}

.button-bar :global(.ant-upload) :global(.ant-btn) {
    margin-left: 0;
}

.component-findings {
    margin-left: 8px;
}

.component-findings h2 {
    margin-top: 0;
}

.component-findings :global(.ant-card) {
    max-width: 1200px;
    padding-left: 8px;
    padding-bottom: 8px;
}

.component-findings-tack {
    width: 14px;
    border-radius: 2px 0 0 2px;
}

.component-findings :global(.ant-card-extra) {
    padding-left: 8px;
}

.component-findings :global(.ant-card-body) {
    padding: 12px 24px;
}

.graph-card {
    width: 400px;
    position: absolute;
    top: 48px;
    right: 20px;
}

.graph-card :global(.ant-descriptions) {
    margin-bottom: 0;
}

.graph-card :global(.ant-descriptions .ant-descriptions-item) {
    padding-bottom: 4px;
}

.column-status {
    min-width: 120px;
}

.column-status :global(.ant-tag) {
    margin: 0;
    font-size: 12px;
    padding: 2px 4px;
    border-radius: 4px;
}

.analysis-arrow {
    font-size: 14px;
    vertical-align: middle;
    margin: 8px;
}

.component-tag {
    cursor: pointer;
    margin-left: 8px;
    padding: 0 4px;
}

.statistics {
    display: inline-block;
    margin-left: 16px;
    vertical-align: text-top;
}

.statistics-label {
    vertical-align: middle;
}

.license-status {
    display: flex;
    align-items: center;
    gap: 4px;
}

.details {
    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: flex-start;
}

.details :global(.ant-descriptions) {
    flex: 1;
    margin-bottom: 0;
}

.status-form :global(.ant-collapse-content-box) {
    padding: 0 !important;
}

.status-form :global(.ant-form) {
    margin-bottom: 0;
    padding: 24px;
}

.status-form-buttons {
    margin-bottom: 0;
}

.count-badge :global(.ant-badge-count) {
    margin-left: 4px;
    background-color: #1677ff;
}

.overriden-licenses {
    display: flex;
    align-content: center;
    align-items: center;
}

.overriden-licenses-tooltip {
    margin-left: 12px;
}

.breadcrumb-version {
    height: 20px !important;
}
