import {Breadcrumb, Button, Form, Input, message, Select} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, SoftwareLicenseOverrideServiceContext, SoftwareLicenseServiceContext} from "../../Contexts";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import * as globalStyles from "../App.module.css";
import Organization from "../../domain/Organization";
import QueryOptions from "../../sal-ui/QueryOptions";
import PagedResult from "../../service/PagedResult";
import SoftwareLicense from "../../domain/SoftwareLicense";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function SoftwareLicenseOverride() {
    const appContext = useContext(AppContextContext);
    const softwareLicenseService = useContext(SoftwareLicenseServiceContext);
    const overrideService = useContext(SoftwareLicenseOverrideServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = useForm();
    const [organizations, setOrganizations] = useState<Organization[]>();
    const [licenses, setLicenses] = useState<PagedResult<SoftwareLicense>>();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 16},
    };

    const title = "Add new software license override";

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (appContext.user.isSystemAdmin()) {
            organizationService.getList(new QueryOptions("name", 100, 0)).then(value => {
                setOrganizations(value.data);
            });
        }

        softwareLicenseService.getList(QueryOptions.newUnlimitedOrderedInstance("shortName")).then(setLicenses);

        const searchParams = new URLSearchParams(location.search);

        if (searchParams.has("purl")) {
            form.setFieldValue("purl", searchParams.get("purl"));
            form.setFieldValue("vers", searchParams.get("vers"));
            form.setFieldValue("organization", searchParams.get("organizationId"));
        }
    }, [])

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb
                    items={[
                        {title: appContext.config?.appName},
                        {title: <Link to={"/software-license-overrides"}>{"Software license overrides"}</Link>},
                        {title: title}
                    ]}
                />

                <h1>{title}</h1>

                <Form {...layout} form={form} className={`${globalStyles["common__form"]}`} onFinish={onFinish}>
                    {appContext.user.isSystemAdmin() &&
                        <Form.Item
                            name={"organization"}
                            label={"Organization"}
                            rules={[{required: true, message: "Organization is required."}]}>
                            <Select>
                                {organizations && organizations!.map(organization => {
                                    return <Select.Option key={organization?.name} value={organization?.id!}>{organization?.name}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    }

                    <Form.Item
                        name={"purl"}
                        label={"Package URL"}
                        extra={<>
                            <p style={{marginTop: 12}}>Package identifier according to <a href={"https://github.com/package-url/purl-spec/blob/master/PURL-SPECIFICATION.rst"} target={"_blank"}>Package URL specification</a> without version.</p>
                            <p>Examples:</p>
                            <ul>
                                <li>pkg:maven/org.apache.xmlgraphics/batik-anim</li>
                                <li>pkg:npm/foobar</li>
                                <li>pkg:nuget/EnterpriseLibrary.Common</li>
                            </ul>
                        </>}
                        rules={[
                            {required: true, message: "Package URL is required."},
                            {validator: serverViolationsHolder.createServerValidator('UNKNOWN', 'purl', undefined), message: "Value is not valid Package URL expression."}
                        ]}>
                        <Input autoFocus={true} maxLength={300}/>
                    </Form.Item>

                    <Form.Item
                        name={"vers"}
                        label={"Version range"}
                        extra={<>
                            <p style={{marginTop: 12}}>
                                Version constraints according to <a href={"https://github.com/package-url/purl-spec/blob/version-range-spec/VERSION-RANGE-SPEC.rst"} target={"_blank"}>Package URL vers specification</a> without "vers" and versioning scheme prefix.
                                Versioning scheme from Package URL is used.
                            </p>
                            <p>Examples:</p>
                            <ul>
                                <li>0.0.0|0.0.1|0.0.2|0.0.3|1.0|2.0pre1</li>
                                <li>1.2.3|&gt;=2.0.0|&lt;5.0.0</li>
                                <li>*</li>
                            </ul>
                        </>}
                        rules={[
                            {required: true, message: "Version range is required."},
                            {validator: serverViolationsHolder.createServerValidator('UNKNOWN', 'vers', undefined), message: "Value is not valid vers expression."}
                        ]}>
                        <Input maxLength={500}/>
                    </Form.Item>

                    <Form.Item
                        name={"licenseKeys"}
                        label={"Licenses"}
                        rules={[{required: true, message: "Licenses are required."}]}>
                        <Select mode={"multiple"} placement={"topLeft"}>
                            {licenses?.data?.map(license => {
                                return <Select.Option key={license.licenseKey} value={license.licenseKey}>{license.shortName}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate("/software-license-overrides")}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    )

    function onFinish(values: any) {
        overrideService.add(values)
            .then(
                (id) => {
                    message.success(<>Software license override for <b>{values.purl}</b> successfully added.</>);

                    navigate(`/software-license-overrides/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }
}

export default SoftwareLicenseOverride;
