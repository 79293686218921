import {AppContext} from "./AppContext";
import AttributeDefinitionService from "./service/AttributeDefinitionService";
import AttributeExtractionDefinitionService from "./service/AttributeExtractionDefinitionService";
import AttributeExtractorService from "./service/AttributeExtractorService";
import AuditLogService from "./service/AuditLogService";
import AuthService from "./service/AuthService";
import React from "react";
import EnumService from "./service/EnumService";
import OrganizationService from "./service/OrganizationService";
import TicketAttributeRuleService from "./service/TicketAttributeRuleService";
import TicketCreationDefinitionService from "./service/TicketCreationDefinitionService";
import TicketService from "./service/TicketService";
import UserService from "./service/UserService";
import VulSourceItemService from "./service/VulSourceItemService";
import VulSourceService from "./service/VulSourceService";
import VulViewService from "./service/VulViewService";
import VulReportDefinitionService from "./service/VulReportDefinitionService";
import VulReportService from "./service/VulReportService";
import SbomProjectService from "./service/SbomProjectService";
import SbomProjectVersionService from "./service/SbomProjectVersionService";
import SbomComponentService from "./service/SbomComponentService";
import ImportExportService from "./service/ImportExportService";
import TagService from "./service/TagService";
import ApiTokenService from "./service/ApiTokenService";
import RoleService from "./service/RoleService";
import SbomProjectReportDefinitionService from "./service/SbomProjectReportDefinitionService";
import {ClientNotificationService} from "./sal-ui/ClientNotificationService";
import VulSourceHealthReportDefinitionService from "./service/VulSourceHealthReportDefinitionService";
import SoftwareLicenseService from "./service/SoftwareLicenseService";
import SoftwareLicensePolicyService from "./service/SoftwareLicensePolicyService";
import SoftwareLicenseOverrideService from "./service/SoftwareLicenseOverrideService";

export const appContext = new AppContext();
export const authService = new AuthService(appContext);

const userService = new UserService();
const organizationService = new OrganizationService();
const vulSourceService = new VulSourceService();
const vulSourceItemService = new VulSourceItemService();
const ticketService = new TicketService();
const ticketCreationDefinitionService = new TicketCreationDefinitionService();
const auditLogService = new AuditLogService();
const enumService = new EnumService(appContext);
const attributeDefinitionService = new AttributeDefinitionService();
const attributeExtractionDefinitionService = new AttributeExtractionDefinitionService();
const attributeExtractorService = new AttributeExtractorService();
const ticketAttributeRuleService = new TicketAttributeRuleService();
const vulViewService = new VulViewService();
const vulReportDefinitionService = new VulReportDefinitionService();
const vulReportService = new VulReportService();
const sbomProjectService = new SbomProjectService();
const sbomProjectVersionService = new SbomProjectVersionService();
const sbomComponentService = new SbomComponentService();
const importExportService = new ImportExportService();
const tagService = new TagService();
const apiTokenService = new ApiTokenService();
const roleService = new RoleService();
const sbomProjectReportDefinitionService = new SbomProjectReportDefinitionService();
const vulSourceHealthReportDefinitionService = new VulSourceHealthReportDefinitionService();
const clientNotificationService = new ClientNotificationService();
const softwareLicenseService = new SoftwareLicenseService();
const softwareLicensePolicyService = new SoftwareLicensePolicyService();
const softwareLicenseOverrideService = new SoftwareLicenseOverrideService();

// contexts
export const AppContextContext = React.createContext(appContext);
export const AuthServiceContext = React.createContext(authService);
export const UserServiceContext = React.createContext(userService);
export const OrganizationServiceContext = React.createContext(organizationService);
export const VulSourceServiceContext = React.createContext(vulSourceService);
export const VulSourceItemServiceContext = React.createContext(vulSourceItemService);
export const TicketServiceContext = React.createContext(ticketService);
export const TicketCreationDefinitionServiceContext = React.createContext(ticketCreationDefinitionService);
export const AuditLogServiceContext = React.createContext(auditLogService);
export const EnumServiceContext = React.createContext(enumService);
export const AttributeDefinitionServiceContext = React.createContext(attributeDefinitionService);
export const AttributeExtractionDefinitionServiceContext = React.createContext(attributeExtractionDefinitionService);
export const AttributeExtractorServiceContext = React.createContext(attributeExtractorService);
export const TicketAttributeRuleServiceContext = React.createContext(ticketAttributeRuleService);
export const VulViewServiceContext = React.createContext(vulViewService);
export const VulReportDefinitionServiceContext = React.createContext(vulReportDefinitionService);
export const VulReportServiceContext = React.createContext(vulReportService);
export const SbomProjectServiceContext = React.createContext(sbomProjectService);
export const SbomProjectVersionServiceContext = React.createContext(sbomProjectVersionService);
export const SbomComponentServiceContext = React.createContext(sbomComponentService);
export const ImportExportServiceContext = React.createContext(importExportService);
export const TagServiceContext = React.createContext(tagService);
export const ApiTokenServiceContext = React.createContext(apiTokenService);
export const RoleServiceContext = React.createContext(roleService);
export const SbomProjectReportDefinitionServiceContext = React.createContext(sbomProjectReportDefinitionService);
export const VulSourceHealthReportDefinitionServiceContext = React.createContext(vulSourceHealthReportDefinitionService);
export const ClientNotificationServiceContext = React.createContext(clientNotificationService);
export const SoftwareLicenseServiceContext = React.createContext(softwareLicenseService);
export const SoftwareLicensePolicyServiceContext = React.createContext(softwareLicensePolicyService);
export const SoftwareLicenseOverrideServiceContext = React.createContext(softwareLicenseOverrideService);
