import React, {useContext, useEffect, useState} from "react";
import {EnumServiceContext} from "../../Contexts";
import {AuditLogSeverity} from "../../domain/AuditLogSeverity";
import {
    AttributeEditor,
    DatePickerEditor,
    FilterItem,
    FilterItemProps,
    InputEditor,
    SelectEditor,
    TableFilter
} from "../tablefilter/TableFilter";
import QualifiedEnum from "../../domain/QualifiedEnum";

interface TypesItemProps extends FilterItemProps {
    options: string[]
}

function TypesItem(props: TypesItemProps) {
    return (
        <SelectEditor
            width={300}
            placeholder={"Type"}
            multiple={true}
            options={props.options.map(type => ({
                value: type,
                label: type
            }))}
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

function SeverityItem(props: FilterItemProps) {
    return (
        <SelectEditor
            width={120}
            multiple={false}
            options={Object.keys(AuditLogSeverity).map(severity => ({
                value: severity,
                label: <span style={{display: "inline-block"}} className={"selection-item-text"}>&gt;= {severity}</span>
            }))}
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

function TimestampItem(props: FilterItemProps) {
    return (
        <DatePickerEditor
            placeholder={"Timestamp"}
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

function IpAddressItem(props: FilterItemProps) {
    return (
        <InputEditor
            placeholder={"IP address"}
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

interface AttributeItemProps extends FilterItemProps {
    options: QualifiedEnum[];
}

function AttributeItem(props: AttributeItemProps) {
    return (
        <AttributeEditor
            options={props.options.map(option => ({
                value: option.name?.toLowerCase(),
                label: option.name?.toLowerCase()
            }))}
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

interface AuditLogFilterProps {
    className?: string;
    values?: any;
    onChange: (values: any) => void;
    immediateMode?: boolean;
}

function AuditLogFilter(props: AuditLogFilterProps) {
    const enumService = useContext(EnumServiceContext);
    const [auditLogAttributes, setAuditLogAttributes] = useState<QualifiedEnum[]>([]);
    const [auditLogTypes, setAuditLogTypes] = useState<string[]>([]);

    const items: FilterItem[] = [
        {
            name: "severity",
            key: "severity",
            label: "Severity",
            render: (props) => <SeverityItem {...props} />
        },
        {
            name: "types",
            key: "types",
            label: "Types",
            render: (props) => <TypesItem {...props} options={auditLogTypes}/>
        },
        {
            name: "timestampFrom",
            key: "timestampFrom",
            label: "Timestamp from",
            render: (props) => <TimestampItem {...props} />
        },
        {
            name: "timestampTo",
            key: "timestampTo",
            label: "Timestamp to",
            render: (props) => <TimestampItem {...props} />
        },
        {
            name: "ipAddress",
            key: "ipAddress",
            label: "IP address",
            render: (props) => <IpAddressItem {...props} />
        },
        {
            name: "attributes",
            key: "attributes",
            label: "Attribute",
            multiple: true,
            render: (props) => <AttributeItem {...props} options={auditLogAttributes}/>
        },
    ];

    useEffect(() => {
        enumService.getAuditLogTypes().then(setAuditLogTypes);
        enumService.getAuditLogAttributes().then(setAuditLogAttributes);
    }, []);

    return (
        <TableFilter
            items={items}
            className={props.className}
            values={props.values}
            onChange={onChange}
            immediateMode={props.immediateMode}
        />
    )

    function onChange(values: any) {
        props.onChange(values);
    }

}

export default AuditLogFilter;