export class FilterBuilder {

    private conditions: string[] = [];

    public jsonEq(attribute: string, jsonAttribute: string, value: string) {
        this.conditions.push(`${attribute} json-eq '${jsonAttribute}' '${value}'`);

        return this;
    }

    public jsonLikeRegex(attribute: string, value: string) {
        this.conditions.push(`${attribute} json-like-regex '${value}'`);

        return this;
    }

    public custom(attribute: string, value: string) {
        this.conditions.push(`${attribute} custom '${value}'`);

        return this;
    }

    public eq(attribute: string, value: string) {
        this.conditions.push(`${attribute} eq '${value}'`);

        return this;
    }

    public ge(attribute: string, value: string) {
        this.conditions.push(`${attribute} ge '${value}'`);

        return this;
    }

    public le(attribute: string, value: string) {
        this.conditions.push(`${attribute} le '${value}'`);

        return this;
    }

    public icontains(attribute: string, value: string) {
        this.conditions.push(`${attribute} icontains '${value}'`);

        return this;
    }

    public in(attribute: string, values: string[]) {
        this.conditions.push(`${attribute} in (${values.map(value => `'${value}'`).join(", ")})`);

        return this;
    }

    public inrange(attribute: string, value: string) {
        this.conditions.push(`${attribute} inrange '${value}'`);

        return this;
    }

    public isempty(attribute: string) {
        this.conditions.push(`isempty(${attribute})`);

        return this;
    }

    public quickSearch(value: string) {
        this.conditions.push(`$quickSearch$ ~ '${value}'`);

        return this;
    }

    public build() {
        return this.conditions.join(" and ");
    }

}
