import {DeleteFilled, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import Collapse from "@kunukn/react-collapse";
import {Breadcrumb, Button, Descriptions, Form, Input, message, Modal, Select, Spin, Table} from "antd";
import {useForm, useWatch} from "antd/es/form/Form";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, RoleServiceContext, SbomProjectServiceContext} from "../../Contexts";
import Organization from "../../domain/Organization";
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import * as styles from "./RoleDetail.module.css";
import * as globalStyles from "../App.module.css";
import Role from "../../domain/auth/Role";
import {ResourceOwnerType} from "../../domain/ResourceOwnerType";
import {formatPermission, Permission, scopesForPermission, transitivePermissions} from "../../domain/auth/Permission";
import {ColumnsType} from "antd/es/table";
import RolePermission from "../../domain/auth/RolePermission";
import SbomProject from "../../domain/SbomProject";
import {formatScope, Scope} from "../../domain/auth/Scope";
import RolePermissionFormatter from "./RolePermissionFormatter";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import TextArea from "antd/lib/input/TextArea";
import ReactMarkdown from "react-markdown";

function RoleDetail() {
    const appContext = useContext(AppContextContext);
    const roleService = useContext(RoleServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const sbomProjectService = useContext(SbomProjectServiceContext);
    const navigate = useNavigate();
    const {roleId}: any = useParams();
    const [editMode, setEditMode] = useState(false);
    const [role, setRole] = useState<Role>();
    const [editForm] = useForm();
    const [owner, setOwner] = useState<ResourceOwnerType>(ResourceOwnerType.SYSTEM);
    const [sbomProjects, setSbomProjects] = useState<SbomProject[]>();
    const [organizations, setOrganizations] = useState<Organization[]>();
    const queryClientRef = useRef(new QueryClient());

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    const serverViolationsHolder = new ServerConstraintViolationsHolder();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (appContext.user.isSystemAdmin()) {
            organizationService.getList(new QueryOptions("name", 100, 0)).then(value => {
                setOrganizations(value.data);
            });
        }

        sbomProjectService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setSbomProjects(value.data));

        reload();
    }, [])

    const allowModification = appContext.user.isSystemAdmin() || role?.owner === ResourceOwnerType.ORGANIZATION;

    return (
        <DocumentTitle title={role?.name}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}
                            items={[
                                {title: appContext.config?.appName},
                                {title: <Link to={"/roles"}>User roles</Link>},
                                {title: role?.name}
                            ]}
                />

                <Spin spinning={!role} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{role?.name}</h1>

                    {allowModification &&
                        <div className={globalStyles["common__top-button-bar"]}>
                            <Button onClick={() => setEditMode(!editMode)}>Edit</Button>

                            <Button danger={true}
                                    title={"Delete"}
                                    onClick={() => {
                                        Modal.confirm({
                                            content: "Do you really want to delete this role?",
                                            okText: "Delete",
                                            cancelText: "Cancel",
                                            okButtonProps: {danger: true},
                                            onOk: () => onDeleteConfirm()
                                        });
                                    }}>Delete</Button>
                        </div>
                    }

                    {renderEditForm()}

                    <Descriptions column={1} bordered={true} size="small" className={globalStyles.details}>
                        <Descriptions.Item label={"Name"}>{role?.name}</Descriptions.Item>
                        <Descriptions.Item label={"Description"}>
                            {role?.description && <ReactMarkdown className={globalStyles.markdown} children={role?.description}/>}
                            {!role?.description && <i>no description</i>}
                        </Descriptions.Item>
                        {appContext.user.isSystemAdmin() && <Descriptions.Item label={"Organization"}>{(role?.owner === ResourceOwnerType.SYSTEM) ? <i>System role</i> : <Link to={`/organizations/${role?.organization.id}`}>{role?.organization.name}</Link>}</Descriptions.Item>}
                    </Descriptions>

                    <h2>Permissions</h2>

                    <QueryClientProvider client={queryClientRef.current}>
                        <RolePermissionSection title={'Vulnerability sources'} role={role} permPrefix={'SOURCE'} allowModification={allowModification} onSave={() => reload()}/>
                        <RolePermissionSection title={'Tickets'} role={role} permPrefix={'TICKET'} allowModification={allowModification} onSave={() => reload()}/>
                        <RolePermissionSection title={'Reports'} role={role} permPrefix={'REPORT'} allowModification={allowModification} onSave={() => reload()}/>
                        <RolePermissionSection title={'Attributes'} role={role} permPrefix={'ATTRIBUTE'} allowModification={allowModification} onSave={() => reload()}/>
                        <RolePermissionSection title={'Vulnerability views'} role={role} permPrefix={'VIEW'} allowModification={allowModification} onSave={() => reload()}/>
                        <RolePermissionSection title={'SBOM projects'} role={role} permPrefix={'SBOM_PROJECT'} allowModification={allowModification} onSave={() => reload()} sbomProjects={sbomProjects}/>
                        <RolePermissionSection title={'Users'} role={role} permPrefix={'USER'} allowModification={allowModification} onSave={() => reload()}/>
                    </QueryClientProvider>
                </Spin>
            </>
        </DocumentTitle>
    )

    function renderEditForm() {
        return (
            <Collapse isOpen={editMode}>
                <h3>Edit of role {role?.name}</h3>

                <Form {...layout} form={editForm} className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`} onFinish={onFinishEdit}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                            {
                                validator: serverViolationsHolder.createServerValidator('UNIQUE', 'uq_role_name', undefined, {compareLowerCaseValues: true}),
                                message: "Name is already used."
                            }
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"description"}
                        label={"Description"}>
                        <TextArea maxLength={1000} autoSize={{minRows: 3, maxRows: 5}}/>
                    </Form.Item>

                    {appContext.user.isSystemAdmin() && <>
                        <Form.Item
                            name={["owner"]}
                            label={"Owner"}
                            rules={[{required: true, message: "Owner is required."}]}
                            initialValue={owner}>
                            <Select onChange={value => setOwner(value)}>
                                <Select.Option key={ResourceOwnerType.SYSTEM} value={ResourceOwnerType.SYSTEM}>system</Select.Option>
                                <Select.Option key={ResourceOwnerType.ORGANIZATION} value={ResourceOwnerType.ORGANIZATION}>organization</Select.Option>
                            </Select>
                        </Form.Item>

                        {owner === ResourceOwnerType.ORGANIZATION &&
                            <Form.Item
                                name={"organization"}
                                label={"Organization"}
                                rules={[{required: owner === ResourceOwnerType.ORGANIZATION, message: "Organization is required."}]}>
                                <Select allowClear={true}>
                                    {organizations && organizations!.map(organization => {
                                        return <Select.Option key={organization?.name} value={organization?.id!}>{organization?.name}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        }
                    </>}

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => setEditMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function onFinishEdit(values: any) {
        roleService.update(roleId, values)
            .then(
                () => {
                    message.success(<>Role <b>{values.name}</b> successfully updated.</>);

                    setEditMode(false);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }

    function onDeleteConfirm() {
        roleService.delete(role)
            .then(() => {
                message.success(<>User <b>{role.name}</b> successfully deleted.</>);

                navigate(`/roles`)
            });
    }

    function reload() {
        roleService.get(roleId).then(role => {
            setRole(role);

            editForm.setFieldsValue({
                ...role,
                owner: role?.owner,
                organization: role?.organization?.id
            });
        });

    }

}

interface RolePermissionSectionProps {
    title: string;
    role: Role;
    permPrefix: string;
    allowModification: boolean;
    onSave: () => void;
    sbomProjects?: SbomProject[];
}

function RolePermissionSection(props: RolePermissionSectionProps) {
    const roleService = useContext(RoleServiceContext);
    const [addMode, setAddMode] = useState(false);
    const [form] = useForm();
    const [inProgress, setInProgress] = useState(false);
    const permission = useWatch("permission", form);
    const scope = useWatch("scope", form);

    const rolePermissions = props.role?.permissions.filter(value => value.permission.startsWith(props.permPrefix)) || [];

    const scopeLessPermissions = rolePermissions
            ?.filter(value => value.expanded === false && value.scope === null)
            ?.map(value => value.permission)
        || [];

    const unassignedScopedPermissions = Object.keys(Permission)
        .filter(value => value.startsWith(props.permPrefix))
        .map(value => value as Permission)
        .filter(value => scopesForPermission(value).length !== 0);

    const unassignedScopeLessPermissions = Object.keys(Permission)
        .filter(value => value.startsWith(props.permPrefix))
        .map(value => value as Permission)
        .filter(value => scopesForPermission(value).length === 0)
        .filter(value => !scopeLessPermissions.includes(value));

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    const columns: ColumnsType<RolePermission> = [
        {
            dataIndex: "permission",
            title: "Permission",
            render: (_, rolePermission) => <>
                {!rolePermission.expanded && <RolePermissionFormatter rolePermission={rolePermission}/>}
                {rolePermission.expanded && <i title={"transitive permission"}><RolePermissionFormatter rolePermission={rolePermission}/></i>}
            </>
        }
    ];

    if (props.allowModification) {
        columns.push(
            {
                title: "Actions",
                key: "actions",
                width: 100,
                className: globalStyles["table-actions"],
                render: (_, rolePermission) => <>
                    {!rolePermission.expanded && renderRolePermissionAction(rolePermission)}
                </>
            }
        );
    }

    useEffect(() => {
        if (form.getFieldValue("scope") === undefined) {
            const scopes = scopesForPermission(permission);

            if (scopes?.length > 0) {
                form.setFieldValue("scope", scopes[0]);
            }
        }
    }, [permission]);

    return <>
        <div className={styles.section}>
            <h3 className={styles['section-title']}>
                {props.title}

                {props.allowModification &&
                    <Button
                        type={"default"}
                        size={"small"}
                        style={{marginLeft: '1em'}}
                        icon={<PlusOutlined/>}
                        disabled={unassignedScopeLessPermissions.length === 0 && unassignedScopedPermissions.length === 0}
                        onClick={() => {
                            form.resetFields();
                            setAddMode(true)
                        }}
                        title={"Add permission to this role"}>
                        Add
                    </Button>
                }
            </h3>
        </div>

        <Collapse isOpen={addMode}>
            <Form {...layout} form={form} className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`} onFinish={onFinishEdit}>
                <Form.Item
                    name={"permission"}
                    label={"Permission"}
                    rules={[
                        {required: true, message: 'Permission is required.'}
                    ]}>
                    <Select>
                        {unassignedScopeLessPermissions.map(value => (
                            <Select.Option key={value}>{formatPermission(value)}</Select.Option>
                        ))}
                        {unassignedScopedPermissions.map(value => (
                            <Select.Option key={value}>{formatPermission(value)}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {permission && scopesForPermission(permission).length > 0 && <Form.Item
                    name={"scope"}
                    label={"Scope"}
                    rules={[
                        {required: true, message: 'SBOM project is required.'}
                    ]}>
                    <Select options={scopesForPermission(permission)?.map(scope => {
                        return {
                            label: formatScope(scope),
                            value: scope
                        }
                    })}
                    />
                </Form.Item>}

                {scope && scope === Scope.SBOM_PROJECT && <>
                    <Form.Item
                        name={"objectId"}
                        label={"SBOM project"}
                        rules={[
                            {required: true, message: 'SBOM project is required.'}
                        ]}>
                        <Select options={props.sbomProjects
                            ?.filter(sbomProject => !rolePermissions.some(rp => rp.permission === permission && rp.scope === scope && sbomProject.id === rp.objectId))
                            ?.map(sbomProject => {
                                return {
                                    label: sbomProject.name,
                                    value: sbomProject.id
                                }
                            })}
                        />

                    </Form.Item>

                    <Form.Item
                        name={["tags"]}
                        label={"Version tags"}
                        extra={"Only allow access to project versions with selected tags. If no tags are selected, access is allowed for all project versions."}>
                        <Select mode="tags" placeholder={"enter one or more project version tags"}/>
                    </Form.Item>
                </>}

                {permission && transitivePermissions(permission).length > 0 && <>
                    <Form.Item
                        name={"transitivePermissions"}
                        colon={false}
                        label={<></>}>
                        <div className={styles['transitive-permissions']}>
                            <div>Granting this permission also grants following transitive permissions if not already present:</div>

                            <ul>
                                {transitivePermissions(permission).map(transitivePermission => <>
                                    <li>{formatPermission(transitivePermission)}</li>
                                </>)}
                            </ul>
                        </div>
                    </Form.Item>
                </>}

                <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                    <Button type={"primary"} htmlType={"submit"} disabled={inProgress}>{"Save"}</Button>
                    <Button onClick={() => setAddMode(false)}>{"Cancel"}</Button>
                </Form.Item>
            </Form>
        </Collapse>

        <Table className={styles.table}
               size={"small"}
               pagination={false}
               columns={columns}
               locale={{emptyText: 'No permissions assigned'}}
               dataSource={rolePermissions}
        />
    </>;

    function renderRolePermissionAction(rolePermission: RolePermission) {
        return (
            <>
                <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                        title={"Delete"}
                        onClick={() => {
                            Modal.confirm({
                                content: "Do you really want to delete this permission?",
                                okText: "Delete",
                                cancelText: "Cancel",
                                onOk: () => onDeleteRolePermissionConfirm(rolePermission)
                            });
                        }}/>
            </>
        )
    }

    function onFinishEdit(values: any) {
        setInProgress(true);

        roleService.addPermission(props.role, values)
            .then(() => {
                message.success("Permission added successfully.");

                setAddMode(false);

                props.onSave();
            })
            .finally(() => setInProgress(false))
    }

    function onDeleteRolePermissionConfirm(rolePermission: RolePermission) {
        roleService.deletePermission(props.role, rolePermission)
            .then(() => {
                message.success("Permission successfully deleted.");

                props.onSave();
            });
    }
}

export default RoleDetail;
