.description {
    margin-bottom: 8px;
}

.action-bar {
    margin: 0 16px 0 8px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    right: 0;
    position: absolute;
}

.table {
    max-width: 1800px;
}

.updated {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    transform:scale(-1);
    writing-mode: vertical-lr;
    text-transform: uppercase;
    background-color: #0066cc;
    color: white;
    font-size: 90%;
}

.table :global(.ant-table-cell) {
    word-wrap: break-word;
    word-break: break-word;
}

.column-source {
    min-width: 200px;
}

.column-attributes {
    min-width: 100px;
    max-width: 340px;
    padding: 12px 16px !important;
}

.hr {
    opacity: 0;
}

.update-alert {
    display: inline-block;
    padding: 2px 4px;
    margin-top: 8px;
}
