import Axios from "axios";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";
import SoftwareLicenseOverride from "../domain/SoftwareLicenseOverride";

class SoftwareLicenseOverrideService {

    readonly basePath = "/software-license-overrides";

    public add(override: SoftwareLicenseOverride): Promise<string> {
        return Axios.post(`${this.basePath}`, override).then(value => value.data.id)
    }

    public update(overrideId: string, override: SoftwareLicenseOverride): Promise<void> {
        return Axios.put(`${this.basePath}/${overrideId}`, override);
    }

    public get(id: string): Promise<SoftwareLicenseOverride> {
        return Axios.get(`${this.basePath}/${id}`).then(value => SoftwareLicenseOverride.fromPOJO(value.data))
    }

    public delete(override: SoftwareLicenseOverride): Promise<void> {
        return Axios.delete(`${this.basePath}/${override.id}`);
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<SoftwareLicenseOverride>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: SoftwareLicenseOverride[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(SoftwareLicenseOverride.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList, value.data.permissions);
            })
    }

}

export default SoftwareLicenseOverrideService;
