import SbomProjectVersion from "./SbomProjectVersion";
import SoftwareLicense from "./SoftwareLicense";

class SbomComponent {

    constructor(
        public id?: string,
        public projectVersion?: SbomProjectVersion,
        public type?: string,
        public bomRef?: string,
        public group?: string,
        public name?: string,
        public version?: string,
        public cpe?: string,
        public purl?: string,
        public latestVersion?: string,
        public publishedAt?: Date,
        public directDependency?: boolean,
        public duplicate?: boolean,
        public licenses?: SoftwareLicense[],
        public overridenLicenses?: SoftwareLicense[],
        public findingStatistics?: any,
        public analysisStatistics?: any,
        public analysedFindingStatistics?: any) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const sbomProject = new SbomComponent();

        sbomProject.id = data.id;
        sbomProject.projectVersion = (data.projectVersion) ? SbomProjectVersion.fromPOJO(data.projectVersion) : undefined;
        sbomProject.type = data.type;
        sbomProject.bomRef = data.bomRef;
        sbomProject.group = data.group;
        sbomProject.name = data.name;
        sbomProject.version = data.version;
        sbomProject.cpe = data.cpe;
        sbomProject.purl = data.purl;
        sbomProject.latestVersion = data.latestVersion;
        sbomProject.directDependency = data.directDependency;
        sbomProject.duplicate = data.duplicate;
        sbomProject.licenses = (data.licenses) ? data.licenses.map(license => SoftwareLicense.fromPOJO(license)) : [];
        sbomProject.overridenLicenses = (data.overridenLicenses) ? data.overridenLicenses.map(license => SoftwareLicense.fromPOJO(license)) : [];
        sbomProject.findingStatistics = data.findingStatistics;
        sbomProject.analysisStatistics = data.analysisStatistics;
        sbomProject.analysedFindingStatistics = data.analysedFindingStatistics;
        sbomProject.publishedAt = (data.publishedAt) ? new Date(data.publishedAt) : undefined;

        return sbomProject;
    }

}

export default SbomComponent;
