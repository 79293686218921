import {Breadcrumb, Button, Form, Input, message, Select, Switch} from "antd";
import {useForm, useWatch} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, RoleServiceContext, UserServiceContext} from "../../Contexts";
import Organization from "../../domain/Organization";
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import *as globalStyles from "../App.module.css";
import {formatUserRole, UserRole} from "../../domain/UserRole";
import Password from "antd/es/input/Password";
import Role from "../../domain/auth/Role";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function UserAdd() {
    const appContext = useContext(AppContextContext);
    const userService = useContext(UserServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const roleService = useContext(RoleServiceContext);
    const navigate = useNavigate();
    const [form] = useForm();
    const [organizations, setOrganizations] = useState<Organization[]>();
    const [roles, setRoles] = useState<Role[]>();
    const publicAccessAccount = useWatch("publicAccessAccount", form);

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (appContext.user.isSystemAdmin()) {
            organizationService.getList(new QueryOptions("name", 100, 0)).then(value => {
                setOrganizations(value.data);
            });
        }

        roleService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setRoles(value.data));
    }, [])

    const title = "Add new user";

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/users"}>{"Users"}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{title}</h1>

                <Form {...layout} form={form} className={globalStyles["common__form"]} onFinish={onFinish}>
                    <Form.Item
                        name={"username"}
                        label={"Username"}
                        rules={[
                            {required: true, message: "Username is required."},
                            {
                                validator: serverViolationsHolder.createServerValidator('UNIQUE', 'username', undefined, {compareLowerCaseValues: true}),
                                message: "Username is already used."
                            }
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    {appContext.user.isSystemAdmin() &&
                        <Form.Item
                            name={"organization"}
                            label={"Organization"}
                            rules={[{required: true, message: "Organization is required."}]}>
                            <Select>
                                {organizations && organizations!.map(organization => {
                                    return <Select.Option key={organization?.name}
                                                          value={organization?.id!}>{organization?.name}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    }

                    {appContext.user.isSystemAdmin() &&
                        <Form.Item
                            name={"role"}
                            label={"Role"}
                            rules={[{required: true, message: "Role is required."}]}
                            initialValue={UserRole.USER}>
                            <Select
                                options={Object.values(UserRole).map(role => ({
                                    label: formatUserRole(role),
                                    value: role
                                }))}
                            />
                        </Form.Item>
                    }

                    <Form.Item
                        name={"roles"}
                        label={"Roles"}
                        rules={[{required: true, message: "At least one role is required."}]}>
                        <Select
                            mode={"multiple"}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={roles?.map(role => ({
                                label: role.name,
                                value: role.id
                            }))}>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={"publicAccessAccount"}
                        label={"Public access account"}
                        extra={"Public access account is used to publicly publish content accessible to the user. Public access account is not permitted to login with password and content is accessible using special URL."}
                        valuePropName={"checked"}
                        initialValue={false}>
                        <Switch/>
                    </Form.Item>

                    {!publicAccessAccount &&
                        <Form.Item
                            name={"password"}
                            label={"Password"}
                            rules={[
                                {required: true, message: "Password is required."},
                                {
                                    validator: serverViolationsHolder.createServerValidator('CUSTOM'),
                                    message: serverViolationsHolder.violations.constraintViolations.password?.CUSTOM.message
                                }]}>
                            <Password/>
                        </Form.Item>
                    }

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate("/users")}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    );

    function onFinish(values: any) {
        userService.add(values)
            .then(
                (id) => {
                    message.success(<>User <b>{values.username}</b> successfully added.</>);

                    navigate(`/users/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }
}

export default UserAdd;
