import {DocumentTitle} from "../DocumentTitle";
import {Breadcrumb, Button, Checkbox, Form, Input, message, Select, Switch} from "antd";
import * as globalStyles from "../App.module.css";
import * as styles from "./VulSourceHealthReportDefinitionAdd.module.css";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {AppContextContext, VulSourceHealthReportDefinitionServiceContext} from "../../Contexts";
import {useForm} from "antd/es/form/Form";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import _ from "lodash";
import VulSourceHealthReportDefinition from "../../domain/VulSourceHealthReportDefinition";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function VulSourceHealthReportDefinitionAdd() {
    const appContext = useContext(AppContextContext);
    const vulSourceHealthReportDefinitionService = useContext(VulSourceHealthReportDefinitionServiceContext);
    const navigate = useNavigate();
    const [form] = useForm();
    const {definitionId}: any = useParams();
    const [vulSourceHealthReportDefinition, setVulSourceHealthReportDefinition] = useState<VulSourceHealthReportDefinition>();

    useEffect(() => {
        if (definitionId === undefined) {
            return;
        }

        vulSourceHealthReportDefinitionService.get(definitionId).then(definition => {
            setVulSourceHealthReportDefinition(definition);

            form.setFieldsValue(definition);
        });
    }, [definitionId]);

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    const title = (definitionId) ? <>Clone of definition <b>{vulSourceHealthReportDefinition?.name}</b></> : <>New source health report definition</>;

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/vul-source-health-report-definitions"}>Source health report definitions</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{title}</h1>

                <Form {...layout} form={form} className={globalStyles["common__form"]} onFinish={onFinish}>

                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."}
                        ]}>
                        <Input autoFocus={true} maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"days"}
                        label={"Days"}
                        initialValue={["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"]}>
                        <Checkbox.Group className={styles.days}>
                            <Checkbox value={"MONDAY"}>Monday</Checkbox>
                            <Checkbox value={"TUESDAY"}>Tuesday</Checkbox>
                            <Checkbox value={"WEDNESDAY"}>Wednesday</Checkbox>
                            <Checkbox value={"THURSDAY"}>Thursday</Checkbox>
                            <Checkbox value={"FRIDAY"}>Friday</Checkbox>
                            <Checkbox value={"SATURDAY"}>Saturday</Checkbox>
                            <Checkbox value={"SUNDAY"}>Sunday</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>

                    <Form.Item
                        name={"times"}
                        label={"Hours"}
                        initialValue={["08:00"]}>
                        <Checkbox.Group
                            className={styles.times}
                            options={_.range(24).map(index => String(index).padStart(2, '0') + ":00")}
                        />
                    </Form.Item>

                    <Form.Item
                        name={"timeZone"}
                        label={"Time zone"}
                        initialValue={new Intl.DateTimeFormat().resolvedOptions().timeZone}
                        rules={[{required: true, message: "Time zone is required."}]}>
                        <Select
                            options={Intl.supportedValuesOf('timeZone').map(value => {
                                return {label: value, value}
                            })}
                            showSearch={true}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        name={"email"}
                        label={"E-mail"}
                        extra={"Comma separated list of email addresses."}
                        rules={[{required: true, message: "At least one e-mail address is required."}]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"enabled"}
                        label={"Enabled"}
                        valuePropName={"checked"}
                        initialValue={true}>
                        <Switch/>
                    </Form.Item>

                    <Form.Item
                        name={"generateEmptyReport"}
                        label={"Generate healthy report"}
                        extra={"Generate report even if all sources are healthy."}
                        valuePropName={"checked"}
                        initialValue={false}>
                        <Switch/>
                    </Form.Item>

                    {appContext.user.isSystemAdmin() &&
                        <Form.Item
                            name={"includeSystemSources"}
                            label={"Include system sources"}
                            valuePropName={"checked"}
                            initialValue={true}>
                            <Switch/>
                        </Form.Item>
                    }

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate("/vul-source-health-report-definitions")}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    );

    function onFinish(values: any) {
        vulSourceHealthReportDefinitionService.add(values)
            .then(
                (id) => {
                    message.success(<>Source health report definition <b>{values.name}</b> added.</>);

                    navigate(`/vul-source-health-report-definitions/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }

}

export default VulSourceHealthReportDefinitionAdd;