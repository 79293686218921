import {LoadingOutlined} from "@ant-design/icons";
import Collapse from "@kunukn/react-collapse";
import {Breadcrumb, Button, Checkbox, Descriptions, Form, Input, InputNumber, message, Modal, Select, Spin, Switch, Tag} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, AttributeDefinitionServiceContext, TagServiceContext} from "../../Contexts";
import AttributeDefinition from "../../domain/AttributeDefinition";
import {AttributeValueType} from "../../domain/AttributeValueType";
import TicketCreationDefinition from "../../domain/TicketCreationDefinition";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import *as globalStyles from "../App.module.css";
import AttributeExtractionDefinitionList from "../attributeextractiondefinition/AttributeExtractionDefinitionList";
import ReactMarkdown from "react-markdown";
import TextArea from "antd/lib/input/TextArea";
import {formatDateTime} from "../../utils/FormatUtils";
import {AttributeValueTransformerType} from "../../domain/AttributeValueTransformerType";

function AttributeDefinitionDetail() {
    const appContext = useContext(AppContextContext);
    const attributeDefinitionService = useContext(AttributeDefinitionServiceContext);
    const tagService = useContext(TagServiceContext);
    const navigate = useNavigate();
    const {attributeDefinitionId}: any = useParams();
    const [editMode, setEditMode] = useState(false);
    const [attributeDefinition, setAttributeDefinition] = useState<AttributeDefinition>();
    const [tags, setTags] = useState<string[]>();
    const [editForm] = useForm();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    const serverViolationsHolder = new ServerConstraintViolationsHolder();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        tagService.getList().then(setTags);

        reload();
    }, [])

    const title = `Attribute ${attributeDefinition?.name}`;

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles.common__breadcrumb}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/attributes/definitions"}>Attribute definitions</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={!attributeDefinition} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{title}</h1>

                    {appContext.user.isSystemAdmin() && <>
                        <div className={globalStyles['common__top-button-bar']}>
                            <Button onClick={() => setEditMode(!editMode)}>Edit</Button>

                            <Button danger={true}
                                    title={"Delete"}
                                    onClick={() => {
                                        Modal.confirm({
                                            content: "Do you really want to delete this attribute?",
                                            okText: "Delete",
                                            cancelText: "Cancel",
                                            okButtonProps: {danger: true},
                                            onOk: () => onDeleteConfirm(attributeDefinition!)
                                        });
                                    }}>Delete</Button>
                        </div>

                        {renderEditForm()}
                    </>}

                    <Descriptions column={1} bordered={true} size="small" className={globalStyles.details}>
                        <Descriptions.Item label={"Name"}>{attributeDefinition?.name}</Descriptions.Item>
                        <Descriptions.Item label={"Description"}>
                            {attributeDefinition?.description && <ReactMarkdown className={globalStyles.markdown} children={attributeDefinition?.description} />}
                            {!attributeDefinition?.description && <i>no description</i>}
                        </Descriptions.Item>
                        <Descriptions.Item label={"Value type"}>{attributeDefinition?.valueType}</Descriptions.Item>
                        <Descriptions.Item label={"Value transformer"}>{attributeDefinition?.attributeValueTransformer}</Descriptions.Item>
                        <Descriptions.Item label={"Link items"}><Checkbox checked={attributeDefinition?.mergeTicket} disabled={true}/></Descriptions.Item>
                        <Descriptions.Item label={"Visible"}><Checkbox checked={attributeDefinition?.showTag} disabled={true}/></Descriptions.Item>
                        {appContext.user.isSystemAdmin() && <Descriptions.Item label={"Tags"}>{attributeDefinition?.tags?.map(value => <Tag>{value}</Tag>)}</Descriptions.Item>}
                        <Descriptions.Item label={"Priority"}>{attributeDefinition?.priority}</Descriptions.Item>
                        {appContext.user.isSystemAdmin() && <Descriptions.Item label={"Imported at"}>{formatDateTime(attributeDefinition?.importedAt)}</Descriptions.Item>}
                    </Descriptions>

                    <AttributeExtractionDefinitionList attributeId={attributeDefinitionId}/>
                </Spin>
            </>
        </DocumentTitle>
    )

    function renderEditForm() {
        return (
            <Collapse isOpen={editMode}>
                <h3>Edit of attribute definition</h3>

                <Form {...layout} form={editForm} className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`} onFinish={onFinishEdit}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"description"}
                        label={"Description"}
                        extra={"Description of attribute in CommonMark format."}>
                        <TextArea maxLength={1000} autoSize={{minRows: 4, maxRows: 8}}/>
                    </Form.Item>

                    <Form.Item
                        name={"priority"}
                        label={"Priority"}
                        rules={[
                            {required: true, message: "Priority is required."},
                        ]}>
                        <InputNumber />
                    </Form.Item>

                    <Form.Item
                        name={"mergeTicket"}
                        label={"Link items"}
                        valuePropName={"checked"}>
                        <Switch/>
                    </Form.Item>

                    <Form.Item
                        name={"showTag"}
                        label={"Visible"}
                        valuePropName={"checked"}>
                        <Switch/>
                    </Form.Item>

                    <Form.Item
                        name={["valueType"]}
                        label={"Value type"}
                        rules={[{required: true, message: "required"}]}>
                        <Select>
                            {Object.keys(AttributeValueType).map(value => <Select.Option key={value} value={value}>{value}</Select.Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={"attributeValueTransformer"}
                        label={"Value transformer"}
                        initialValue={AttributeValueTransformerType.NONE}
                        rules={[{required: true, message: "Value transformer is required."}]}>
                        <Select>
                            <Select.Option key={AttributeValueTransformerType.NONE} value={AttributeValueTransformerType.NONE}>{AttributeValueTransformerType.NONE}</Select.Option>
                            <Select.Option key={AttributeValueTransformerType.MAX_NUMERIC_VALUE} value={AttributeValueTransformerType.MAX_NUMERIC_VALUE}>{AttributeValueTransformerType.MAX_NUMERIC_VALUE}</Select.Option>
                        </Select>
                    </Form.Item>

                    {appContext.user.isSystemAdmin() && <Form.Item
                        name={["tags"]}
                        label={"Tags"}>
                        <Select mode="tags" options={tags?.map(tag => ({value: tag, label: tag}))} />
                    </Form.Item>}

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => setEditMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function onFinishEdit(values: any) {
        attributeDefinitionService.update(attributeDefinitionId!, values)
            .then(
                (id) => {
                    message.success("Attribute definition updated.");

                    setEditMode(false);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }

    function onDeleteConfirm(definition: TicketCreationDefinition) {
        attributeDefinitionService.delete(definition)
            .then(() => {
                message.success("Attribute definition deleted.");

                navigate(`/attributes/definitions`)
            });
    }


    function reload() {
        attributeDefinitionService.get(attributeDefinitionId!).then(ticket => {
            setAttributeDefinition(ticket);

            editForm.setFieldsValue(ticket);
        });

    }

}

export default AttributeDefinitionDetail;
