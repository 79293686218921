import _ from "lodash";
import {ApartmentOutlined, ArrowRightOutlined, LoadingOutlined, SwapOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, Card, Descriptions, message, Modal, Popover, Spin, Table, Tag} from "antd";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {AppContextContext, AttributeDefinitionServiceContext, SbomComponentServiceContext, SbomProjectVersionServiceContext} from "../../Contexts";
import {DocumentTitle} from "../DocumentTitle";
import * as globalStyles from "../App.module.css";
import QueryOptions from "../../sal-ui/QueryOptions";
import SbomProjectVersion from "../../domain/SbomProjectVersion";
import * as styles from "./SbomProjectVersionDetail.module.css";
import {ColumnsType} from "antd/es/table";
import SbomComponent from "../../domain/SbomComponent";
import SourceTag from "../vulsourceitem/SourceTag";
import SealScore from "../vulsourceitem/SealScore";
import VulSourceItemDescription from "../vulsourceitem/VulSourceItemDescription";
import {formatDate, formatDateTime} from "../../utils/FormatUtils";
import AttributeDefinition from "../../domain/AttributeDefinition";
import CopyToClipboard from "react-copy-to-clipboard";
import SbomComponentFinding from "../../domain/SbomComponentFinding";
import * as tackStyles from "../vulsourceitem/Tack.module.css";
import * as sealScoreStyles from "../vulsourceitem/SealScore.module.css";
import {VisGraph, VisGraphRef, VisSingleContainer} from '@unovis/react'
import {Graph, GraphLayoutType, GraphNode} from "@unovis/ts";
import {PackageURL} from 'packageurl-js';
import {Permission} from "../../domain/auth/Permission";
import SingleResult from "../../service/SingleResult";
import SbomProject from "../../domain/SbomProject";
import {evaluateSbomComponentHealth, SbomComponentHealth} from "../../domain/SbomComponentHealth";
import {green, orange, red} from '@ant-design/colors';
import {SoftwareLicenseOrigin} from "../../domain/SoftwareLicenseOrigin";
import {packageUrlToOsiUrl} from "../sbomcomponent/functions";
import SoftwareLicense from "../../domain/SoftwareLicense";
import {CompareResult} from "../../service/SbomProjectVersionService";

function SbomProjectVersionDetail() {
    const appContext = useContext(AppContextContext);
    const sbomProjectVersionService = useContext(SbomProjectVersionServiceContext);
    const sbomComponentService = useContext(SbomComponentServiceContext);
    const attributeDefinitionService = useContext(AttributeDefinitionServiceContext);
    const {sbomProjectVersionId, anotherProjectVersionId}: any = useParams();
    const [sbomProject, setSbomProject] = useState<SingleResult<SbomProject>>();
    const [sbomProjectVersion, setSbomProjectVersion] = useState<SbomProjectVersion>();
    const [anotherProjectVersion, setAnotherProjectVersion] = useState<SbomProjectVersion>();
    const [attributeDefinitions, setAttributeDefinitions] = useState<AttributeDefinition[]>();
    const visGraphRef = useRef<VisGraphRef<any, any>>();
    const [componentUsageModalOpen, setComponentUsageModalOpen] = useState(false);
    const [usageGraphData, setUsageGraphData] = useState<any>();
    const [usageModalTitle, setUsageModalTitle] = useState("");
    const [selectedNode, setSelectedNode] = useState<any>();
    const [compareResult, setCompareResult] = useState<CompareResult>();
    const [uniqueFindings, setUniqueFindings] = useState<SbomComponentFinding[]>([]);

    const columns: ColumnsType<SbomComponent> = [
        {
            dataIndex: "type",
            title: "Type",
            width: 100,
            align: "center",
            render: renderType
        },
        {
            dataIndex: "group",
            title: "Group",
        },
        {
            dataIndex: "name",
            title: "Name",
            sorter: true,
            defaultSortOrder: "ascend",
            sortDirections: ["ascend", "descend", "ascend"],
            render: renderName
        },
        {
            dataIndex: "version",
            title: "Version",
            render: renderVersion
        },
        {
            dataIndex: "publishedAt",
            title: "Published at",
            width: 120,
            align: "center",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: renderPublishedAt
        },
        {
            dataIndex: "licenses",
            title: "Licenses",
            render: renderLicenses
        },
    ]

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        attributeDefinitionService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setAttributeDefinitions(value.data));

        sbomProjectVersionService.compareVersions(sbomProjectVersionId, anotherProjectVersionId)
            .then(result => {
                setCompareResult(result);

                setSbomProject(result.project);
                setSbomProjectVersion(result.firstVersion);
                setAnotherProjectVersion(result.secondVersion);

                const findings: SbomComponentFinding[] = [];

                result.removedComponents.forEach(value => findings.push(...value.fixedFindings))
                result.updatedComponents.forEach(value => findings.push(...value.fixedFindings))

                const uniqueFindings = _.uniqBy(findings, value => value.vulSourceItem.id);

                const sortedUniqueFindings = _.sortBy(uniqueFindings, value => value.vulSourceItem.attributes['sealScore'] || 0);

                setUniqueFindings(sortedUniqueFindings.reverse());
            });
    }, [])

    const canAnalyze = appContext.user.hasPermission(Permission.SBOM_PROJECT__MANAGE_ORG) || sbomProject?.permissions?.includes(Permission.SBOM_PROJECT__ANALYZE);
    const canManage = appContext.user.hasPermission(Permission.SBOM_PROJECT__MANAGE_ORG) || sbomProject?.permissions?.includes(Permission.SBOM_PROJECT__MANAGE);

    return (
        <DocumentTitle title={`SBOM project ${sbomProject?.data?.name}: ${sbomProjectVersion?.name}`}>
            <>
                <Breadcrumb
                    className={globalStyles["common__breadcrumb"]}
                    items={[
                        {title: appContext.config?.appName},
                        {title: <Link to={"/sbom-projects"}>SBOM projects</Link>},
                        {title: <Link to={`/sbom-projects/${sbomProject?.data?.id}`}>{sbomProject?.data?.name}</Link>},
                        {
                            title: <>Changes from <Link to={`/sbom-projects/${sbomProject?.data?.id}/${sbomProjectVersion?.id}`}>{sbomProjectVersion?.name}</Link> to <Link
                                to={`/sbom-projects/${sbomProject?.data?.id}/${anotherProjectVersion?.id}`}>{anotherProjectVersion?.name}</Link></>
                        }
                    ]}
                />

                <Spin spinning={!sbomProjectVersion} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>
                        {sbomProject?.data?.name} - changes from <Link to={`/sbom-projects/${sbomProject?.data?.id}/${sbomProjectVersion?.id}`}>{sbomProjectVersion?.name}</Link> to <Link
                        to={`/sbom-projects/${sbomProject?.data?.id}/${anotherProjectVersion?.id}`}>{anotherProjectVersion?.name}</Link>
                    </h1>

                    <Modal open={componentUsageModalOpen}
                           title={usageModalTitle}
                           onCancel={() => {
                               setSelectedNode(undefined);
                               setComponentUsageModalOpen(false);
                           }}
                           destroyOnClose={true}
                           width={1000}
                           footer={
                               <Button type={"primary"}
                                       onClick={() => {
                                           setSelectedNode(undefined);
                                           setComponentUsageModalOpen(false);
                                       }}>
                                   Close
                               </Button>}
                           style={{top: 20}}
                    >
                        <SelectedComponent selectedNode={selectedNode}/>

                        <VisSingleContainer data={usageGraphData} style={{height: 'clamp(400px, 75vh, 1000px)'}}>
                            <VisGraph
                                ref={visGraphRef}
                                layoutType={GraphLayoutType.Dagre}
                                dagreLayoutSettings={{
                                    rankdir: 'TB',
                                    ranker: 'network-simplex',
                                    ranksep: 50
                                }}
                                nodeLabelTrim={false}
                                nodeSubLabelTrim={false}
                                nodeLabel={useCallback(n => {
                                    try {
                                        var purl = PackageURL.fromString(n.label);

                                        return purl.name + " (" + purl.version + ")";
                                    } catch (e) {
                                        return n.label;
                                    }
                                }, [])}
                                nodeSubLabel={useCallback(n => {
                                    try {
                                        var purl = PackageURL.fromString(n.label);

                                        return purl.namespace;
                                    } catch (e) {
                                        return n.label;
                                    }
                                }, [])}
                                linkStroke={useCallback(_ => "#35D068", [])}
                                selectedNodeId={selectedNode?.id}
                                events={{
                                    [Graph.selectors.node]: {
                                        click: (d: GraphNode) => {
                                            setSelectedNode(d);
                                        }
                                    },
                                    [Graph.selectors.background]: {
                                        click: (_: GraphNode) => {
                                            setSelectedNode(undefined);
                                        }
                                    },
                                }}
                            />
                        </VisSingleContainer>
                    </Modal>

                    <h2>Fixed vulnerabilities</h2>

                    <Table className={styles.table}
                           showSorterTooltip={false}
                           loading={compareResult === undefined}
                           dataSource={uniqueFindings}
                           size="middle"
                           pagination={false}
                           rowKey={record => record.id}
                           locale={{emptyText: 'No vulnerabilities fixed.'}}
                           columns={[
                               {
                                   dataIndex: "sealScore",
                                   title: "Score",
                                   align: "center",
                                   render: (_, finding) => <SealScore item={finding?.vulSourceItem}/>
                               },
                               {
                                   dataIndex: "cveId",
                                   title: "CVE",
                                   align: "center",
                                   render: (_, finding) => <>{finding?.vulSourceItem?.attributes['cveId']}</>
                               },
                               {
                                   dataIndex: "title",
                                   title: "Title",
                                   render: (_, finding) => <>
                                       {(canAnalyze || canManage) && <Link to={`/vul-source-items/${finding.vulSourceItem?.id}`} style={{color: "black"}}>
                                           {finding.vulSourceItem?.attributes?.title || finding.vulSourceItem?.attributes?.description}
                                       </Link>}
                                       {(!canAnalyze && !canManage) && <span style={{color: "black"}}>{finding.vulSourceItem?.attributes?.title || finding.vulSourceItem?.attributes?.description}</span>}
                                   </>
                               },
                           ]}
                    />

                    <h2>Added components</h2>

                    <Table className={styles.table}
                           showSorterTooltip={false}
                           loading={compareResult === undefined}
                           dataSource={compareResult?.addedComponents.map(value => value.component)}
                           size="middle"
                           pagination={false}
                           rowKey="id"
                           locale={{emptyText: 'No components added.'}}
                           columns={columns}
                    />

                    <h2>Removed components</h2>

                    <Table className={styles.table}
                           showSorterTooltip={false}
                           loading={compareResult === undefined}
                           dataSource={compareResult?.removedComponents}
                           size="middle"
                           pagination={false}
                           rowKey={record => record.component.id}
                           locale={{emptyText: 'No components removed.'}}
                           expandable={{
                               expandedRowKeys: compareResult?.removedComponents.map(value => value.component.id),
                               showExpandColumn: false,
                               rowExpandable: component => component.fixedFindings.length > 0,
                               expandedRowRender: record => renderExpandedComponent(record.fixedFindings)
                           }}
                           columns={[
                               {
                                   dataIndex: "type",
                                   title: "Type",
                                   width: 100,
                                   align: "center",
                                   render: (_, record) => renderType(record.component.type, record.component)
                               },
                               {
                                   dataIndex: "group",
                                   title: "Group",
                                   render: (_, record) => record.component.group
                               },
                               {
                                   dataIndex: "name",
                                   title: "Name",
                                   sorter: true,
                                   defaultSortOrder: "ascend",
                                   sortDirections: ["ascend", "descend", "ascend"],
                                   render: (_, record) => renderName(record.component.name, record.component)
                               },
                               {
                                   dataIndex: "version",
                                   title: "Version",
                                   render: (_, record) => renderVersion(record.component.version, record.component)
                               },
                               {
                                   dataIndex: "publishedAt",
                                   title: "Published at",
                                   width: 240,
                                   align: "center",
                                   sorter: true,
                                   sortDirections: ["ascend", "descend", "ascend"],
                                   render: (_, record) => renderPublishedAt(record.component.publishedAt, record.component)
                               },
                               {
                                   dataIndex: "licenses",
                                   title: "Licenses",
                                   render: (_, record) => renderLicenses(record.component.licenses, record.component)
                               },
                           ]}
                    />

                    <h2>Updated components</h2>

                    <Table className={styles.table}
                           showSorterTooltip={false}
                           loading={compareResult === undefined}
                           dataSource={compareResult?.updatedComponents}
                           size="middle"
                           pagination={false}
                           rowKey={record => record.firstVersionComponent.id}
                           locale={{emptyText: 'No components updated.'}}
                           expandable={{
                               expandedRowKeys: compareResult?.updatedComponents.map(value => value.firstVersionComponent.id),
                               showExpandColumn: false,
                               rowExpandable: component => component.fixedFindings.length > 0,
                               expandedRowRender: record => renderExpandedComponent(record.fixedFindings)
                           }}
                           columns={[
                               {
                                   dataIndex: "type",
                                   title: "Type",
                                   width: 100,
                                   align: "center",
                                   render: (_, record) => renderType(record.firstVersionComponent.type, record.firstVersionComponent)
                               },
                               {
                                   dataIndex: "group",
                                   title: "Group",
                                   render: (_, record) => record.firstVersionComponent.group
                               },
                               {
                                   dataIndex: "name",
                                   title: "Name",
                                   sorter: true,
                                   defaultSortOrder: "ascend",
                                   sortDirections: ["ascend", "descend", "ascend"],
                                   render: (_, record) => renderName(record.firstVersionComponent.name, record.firstVersionComponent)
                               },
                               {
                                   dataIndex: "version",
                                   title: "Version",
                                   render: (_, record) => <>
                                       {renderVersion(record.firstVersionComponent.version, record.firstVersionComponent)} <ArrowRightOutlined className={styles['analysis-arrow']}/> {renderVersion(record.secondVersionComponent.version, record.secondVersionComponent)}
                                   </>
                               },
                               {
                                   dataIndex: "publishedAt",
                                   title: "Published at",
                                   width: 240,
                                   align: "center",
                                   sorter: true,
                                   sortDirections: ["ascend", "descend", "ascend"],
                                   render: (_, record) => <>
                                       {renderPublishedAt(record.firstVersionComponent.publishedAt, record.firstVersionComponent)} <ArrowRightOutlined className={styles['analysis-arrow']}/> {renderPublishedAt(record.secondVersionComponent.publishedAt, record.secondVersionComponent)}
                                   </>
                               },
                               {
                                   dataIndex: "licenses",
                                   title: "Licenses",
                                   render: (_, record) => renderLicenses(record.firstVersionComponent.licenses, record.firstVersionComponent)
                               },
                           ]
                           }
                    />
                </Spin>
            </>
        </DocumentTitle>
    );

    function renderExpandedComponent(fixedFindings?: SbomComponentFinding[]) {
        return <div className={styles['component-findings']}>
            <h3>Fixed vulnerabilities</h3>

            {fixedFindings?.map(finding => {
                return <>
                    <Card className={sealScoreStyles['severity-border-' + finding.severity]}
                          title={<>
                              {(canAnalyze || canManage) && <Link to={`/vul-source-items/${finding.vulSourceItem?.id}`} style={{color: "black"}}>
                                  {finding.vulSourceItem?.attributes?.title || finding.vulSourceItem?.attributes?.description}
                              </Link>}
                              {(!canAnalyze && !canManage) && <span style={{color: "black"}}>{finding.vulSourceItem?.attributes?.title || finding.vulSourceItem?.attributes?.description}</span>}
                          </>}
                          extra={<>{formatDateTime(finding.vulSourceItem?.sourcePublished)}</>}>
                        <div className={`${tackStyles.tack} ${sealScoreStyles['severity-' + finding.severity]} ${styles['component-findings-tack']}`}><span className={tackStyles['tack-text']}>{finding.severity}</span></div>
                        <div>
                            <SourceTag source={finding?.vulSourceItem?.source}/>

                            <SealScore item={finding?.vulSourceItem}/>
                        </div>

                        <VulSourceItemDescription
                            sourceType={finding?.vulSourceItem.source.sourceType}
                            attributes={finding?.vulSourceItem?.attributes}
                            affected={finding?.vulSourceItem?.affected}
                            attributeDefinitions={attributeDefinitions}
                            hideSealScoreTag={true}
                            showDescription={true}
                            showTitle={false}
                            showAffected={false}
                        />
                    </Card>
                </>;
            })}
        </div>;
    }


    function renderName(value: any, sbomComponent: SbomComponent) {
        return <>
            {value}
            {sbomComponent.purl && <Tag title={`${sbomComponent.purl}`} color={"blue"} className={styles['component-tag']} onClick={event => {
                event.preventDefault();
                event.stopPropagation();

                navigator.clipboard.writeText(sbomComponent.purl);

                message.info("Package URL copied to clipboard.");
            }}>purl</Tag>}
            {sbomComponent.directDependency && <Tag title={"This component is a direct dependency of this project."}>direct</Tag>}
            {sbomComponent.cpe && <CopyToClipboard text={sbomComponent.cpe} onCopy={() => message.info("CPE name copied to clipboard.")}><Tag title={sbomComponent.cpe} color={"blue"} className={styles['component-tag']}>cpe</Tag></CopyToClipboard>}
            <Button size={"small"}
                    style={{marginLeft: 4}}
                    onClick={(e) => {
                        e.stopPropagation();

                        onShowUsagesClick(sbomComponent);
                    }} icon={<ApartmentOutlined/>}
                    title={"Show usages"}
            />
        </>;
    }

    function renderVersion(value: any, sbomComponent: SbomComponent) {
        let purl: PackageURL = null;
        let osiUrl: string = null;

        try {
            purl = PackageURL.fromString(sbomComponent.purl);

            osiUrl = packageUrlToOsiUrl(purl);
        } catch (e) {
            //
        }

        return <Link to={osiUrl} target={"_blank"} title={"Open at Open Source Insights"} onClick={e => e.stopPropagation()}>{value}</Link>;
    }

    function renderPublishedAt(value: any, sbomComponent: SbomComponent) {
        if (sbomComponent.publishedAt === undefined) {
            return <i>unknown</i>;
        }

        const health = evaluateSbomComponentHealth(sbomComponent, new Date());

        let color, title;

        switch (health) {
            case SbomComponentHealth.HEALTHY:
                title = "Component is not older than two years.";
                color = green.primary;
                break;
            case SbomComponentHealth.OUT_OF_DATE:
                title = "Component is more than two years old.";
                color = orange.primary;
                break;
            case SbomComponentHealth.OBSOLETE:
                title = "Component is more than four years old.";
                color = red.primary;
                break;
        }

        return <span title={title} style={{color: color}}>
            {formatDate(sbomComponent.publishedAt)}
        </span>;
    }

    function renderLicense(license: SoftwareLicense) {
        if (license.origin === SoftwareLicenseOrigin.UNKNOWN) {
            return <div key={license.name}>{license.name}</div>;
        }

        return <div key={license.name}><Link to={`/software-licenses/${license.id}`} target={"_blank"}>{license.shortName}</Link></div>;
    }

    function renderLicenses(licenses: SoftwareLicense[], component: SbomComponent) {
        if (component.overridenLicenses.length > 0) {
            return <div className={styles['overriden-licenses']}>
                <div>{licenses.map(renderLicense)}</div>
                <div className={styles['overriden-licenses-tooltip']}>
                    <Popover title={"Licenses in SBOM"} content={component.overridenLicenses.map(renderLicense)}>
                        <SwapOutlined/>
                    </Popover>
                </div>
            </div>
        }

        return <>
            {licenses.map(renderLicense)}
        </>;
    }

    function renderType(value: any, sbomComponent: SbomComponent) {
        if (sbomComponent.purl?.startsWith("pkg:maven")) {
            return <><img src={"/img/Apache_Maven_logo.svg"} style={{width: 40}} title={value}/></>;
        } else if (sbomComponent.purl?.startsWith("pkg:nuget")) {
            return <><img src={"/img/NuGet_logo.svg"} style={{width: 24}} title={value}/></>;
        } else if (sbomComponent.purl?.startsWith("pkg:npm")) {
            return <><img src={"/img/Npm_logo.svg"} style={{width: 32}} title={value}/></>;
        } else if (sbomComponent.type === 'operating-system') {
            return <span title={"operating system"}>OS</span>
        } else {
            return <>{value}</>;
        }
    }

    function onShowUsagesClick(sbomComponent: SbomComponent) {
        sbomComponentService.getUnovisGraph(sbomComponent.id)
            .then(response => {
                if (response.status === 204) {
                    message.info("Usage graph is not available for this component.");

                    return;
                }

                setUsageModalTitle(`Usage of ${sbomComponent.bomRef}`);
                setUsageGraphData(response.data);
                setComponentUsageModalOpen(true);
            })
    }

}

function SelectedComponent(props: { selectedNode: any }) {
    if (!props.selectedNode) {
        return;
    }

    let purl: PackageURL = null;

    try {
        purl = PackageURL.fromString(props.selectedNode.purl);
    } catch (e) {
        return;
    }

    if (purl.type === 'maven') {
        const osiUrl = packageUrlToOsiUrl(purl);

        return (
            <Card title={"Selected component (Apache Maven Artifact)"} size={"small"} className={styles['graph-card']}>
                <Descriptions column={1}>
                    <Descriptions.Item label={"Group"}>{purl.namespace}</Descriptions.Item>
                    <Descriptions.Item label={"Artifact"}>{purl.name}</Descriptions.Item>
                    <Descriptions.Item label={"Version"}>{purl.version}</Descriptions.Item>
                </Descriptions>

                <br/>

                <div>View at <Link to={osiUrl} target={"_blank"}>Open Source Insights</Link></div>
            </Card>
        );
    } else {
        const osiUrl = packageUrlToOsiUrl(purl);

        return (
            <Card title={`Selected component (${purl.type})`} size={"small"} className={styles['graph-card']}>
                <Descriptions column={1}>
                    <Descriptions.Item label={"Name"}>{(purl.namespace) ? purl.namespace + '/' : ''}{purl.name}</Descriptions.Item>
                    <Descriptions.Item label={"Version"}>{purl.version}</Descriptions.Item>
                </Descriptions>

                <br/>

                <div>View at <Link to={osiUrl} target={"_blank"}>Open Source Insights</Link></div>
            </Card>
        );
    }
}

export default SbomProjectVersionDetail;
