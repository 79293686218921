import {Permission} from "../domain/auth/Permission";

class PagedResult<T> {

    public total: number;

    public data: T[];

    public permissions?: Permission[]

    constructor(total: number, data: T[], permissions?: Permission[]) {
        this.total = total;
        this.data = data;
        this.permissions = permissions;
    }
}

export default PagedResult;
