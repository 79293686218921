import {DocumentTitle} from "../DocumentTitle";
import {Breadcrumb, Button, Form, Input, message, Select, Upload} from "antd";
import * as globalStyles from "../App.module.css";
import * as styles from "./VulViewAdd.module.css";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {AppContextContext, OrganizationServiceContext, TagServiceContext, VulViewServiceContext} from "../../Contexts";
import {useForm, useWatch} from "antd/es/form/Form";
import 'react-querybuilder/dist/query-builder.scss';
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import Organization from "../../domain/Organization";
import {ResourceOwnerType} from "../../domain/ResourceOwnerType";
import VulViewQueryBuilder from "./VulViewQueryBuilder";
import VulView from "../../domain/VulView";
import {UploadOutlined} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function VulViewAdd() {
    const appContext = useContext(AppContextContext);
    const organizationService = useContext(OrganizationServiceContext);
    const vulViewService = useContext(VulViewServiceContext);
    const tagService = useContext(TagServiceContext);
    const navigate = useNavigate();
    const [form] = useForm();
    const [organizations, setOrganizations] = useState<Organization[]>();
    const {vulViewId}: any = useParams();
    const [vulView, setVulView] = useState<VulView>();
    const owner = useWatch("owner", form);
    const [tags, setTags] = useState<string[]>();

    useEffect(() => {
        if (appContext.user.isSystemAdmin()) {
            organizationService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setOrganizations(value.data));

            tagService.getList().then(setTags);
        }
    }, []);

    useEffect(() => {
        if (vulViewId === undefined) {
            return;
        }

        vulViewService.get(vulViewId).then(vulView => {
            setVulView(vulView);

            form.setFieldsValue({
                ...vulView,
                name: `Clone of ${vulView.name}`,
                organization: vulView.organization?.id
            });
        });
    }, [vulViewId]);

    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 20},
    };

    const tailLayout = {
        wrapperCol: {offset: 4, span: 20},
    };

    const title = (vulViewId) ? <>Clone of view <b>{vulView?.name}</b></> : <>New vulnerability view</>;

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/vul-source-items/views"}>Vulnerability views</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{title}</h1>

                {appContext.user.isSystemAdmin() &&
                    <div className={`${globalStyles["common__top-button-bar"]} ${styles['button-bar']}`}>
                        <Upload accept="application/json" showUploadList={false} customRequest={handleImport}>
                            <Button icon={<UploadOutlined/>}>Import JSON</Button>
                        </Upload>
                    </div>
                }

                <Form {...layout} form={form} className={`${globalStyles["common__form"]} ${styles.form}`} onFinish={onFinish}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."}
                        ]}>
                        <Input autoFocus={true} maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"description"}
                        label={"Description"}
                        extra={"Description of vulnerability view in CommonMark format."}>
                        <TextArea maxLength={1000} autoSize={{minRows: 4, maxRows: 8}}/>
                    </Form.Item>

                    {appContext.user.isSystemAdmin() && <Form.Item
                        name={["tags"]}
                        label={"Tags"}>
                        <Select mode="tags" options={tags?.map(tag => ({value: tag, label: tag}))} />
                    </Form.Item>}

                    {appContext.user.isSystemAdmin() && <>
                        <Form.Item
                            name={["owner"]}
                            label={"Owner"}
                            rules={[{required: true, message: "Owner is required."}]}
                            initialValue={ResourceOwnerType.SYSTEM}>
                            <Select options={Object.keys(ResourceOwnerType).map(value => ({value: value, label: value}))}/>
                        </Form.Item>

                        {owner === ResourceOwnerType.ORGANIZATION && <Form.Item
                            name={"organization"}
                            label={"Organization"}
                            rules={[{required: true, message: "Organization is required."}]}>
                            <Select
                                allowClear={true}
                                options={organizations?.map(organization => ({value: organization.id, label: organization.name}))}
                            />
                        </Form.Item>}
                    </>}

                    <Form.Item
                        name={"query"}
                        label={"Query"}>
                        <VulViewQueryBuilder initialQuery={vulView?.query} form={form}/>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate("/vul-source-items/views")}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    );

    function handleImport(params: any) {
        const {file, onSuccess, onError} = params;

        const reader = new FileReader();

        reader.onloadend = () => {
            if (typeof reader.result === "string") {
                const data = JSON.parse(reader.result);

                const view = VulView.fromPOJO(data);

                setVulView(view);

                form.setFieldsValue({
                    ...view,
                    organization: view.organization?.id
                });
            } else {
                message.error("Cannot load JSON document.");
            }
        };

        reader.readAsText(file, "UTF-8");
    }

    function onFinish(values: any) {
        vulViewService.add(values)
            .then(
                (id) => {
                    message.success("Vulnerability view added.");

                    navigate(`/vul-source-items/views/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }

}

export default VulViewAdd;