import _ from "lodash";
import {SortOrder} from "antd/es/table/interface";
import {TablePaginationConfig} from "antd/lib/table";
import {useEffect, useRef, useState} from "react";
import QueryOptions from "./QueryOptions";

interface PersistentData {
    pageSize: number;
}

export interface TableUiConfig {
    immediateMode: boolean;
    autoRefreshEnabled?: boolean;
    autoRefreshInterval?: number;
    visibleColumns: string[];
}

export interface TableHandler {
    queryOptions: QueryOptions;

    onTableChange: (pagination: any, filters: any, sorter: any) => void;

    onSearchSubmit: (filter: string, values?: any) => void;

    onRqbSearchSubmit: (rqbQuery: any) => void;

    columnSortOrder: (column: string) => SortOrder | undefined;

    pagination: TablePaginationConfig;

    updateTotal: (total: number) => void;

    total: () => number;

    setLoading: (loading: boolean) => void;

    loading: boolean;

    reload: () => void;

    saveUiConfig: (config: TableUiConfig) => void;

    loadUiConfig: () => TableUiConfig | null;
}

export interface TableHandlerOptions {
    reloadFunction: () => Promise<void>;
    initialPageSize?: number;
    fixedFilter?: string;
    fixedRqbQuery?: any;
    persistentIdent?: string;
}

export function useTableHandler(defaultSortOrder: string, options: TableHandlerOptions): TableHandler {
    const initialPageSize = (options.initialPageSize) ? options.initialPageSize : 25;

    const queryOptions = useRef(new QueryOptions(defaultSortOrder, initialPageSize, 0, options.fixedFilter, undefined, options.fixedRqbQuery));

    const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
        pageSize: initialPageSize,
        pageSizeOptions: ['10', '25', '50', '100', '250', '500', '1000'],
        position: ['bottomRight', 'topRight'],
        showSizeChanger: true,
        hideOnSinglePage: false,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        current: 1,
    });

    const [loading, setLoading] = useState<boolean>(false);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => loadState(), []);

    function onTableChange(pagination: any, filters: any, sorter: any) {
        pagination.showTotal = (total, range) => `${range[0]}-${range[1]} of ${total} items`;

        if (sorter.field) {
            let key = (Array.isArray(sorter.field)) ? sorter.field.join(".") : sorter.field;

            if (sorter.columnKey) {
                key = sorter.columnKey;
            }

            queryOptions.current.orderBy = `${key} ${(sorter.order === 'descend') ? 'desc' : 'asc'}`;
        } else {
            queryOptions.current.orderBy = defaultSortOrder;
        }

        queryOptions.current.top = pagination.pageSize;
        queryOptions.current.skip = ((pagination.current - 1) * pagination.pageSize);

        const pageSizeChanged = (paginationConfig.pageSize !== pagination.pageSize);

        if (pageSizeChanged) {
            pagination.current = 1;

            queryOptions.current.skip = 0;

            saveState(pagination);
        }

        setPaginationConfig(pagination);

        reload();
    }

    function onSearchSubmit(filter: string) {
        const filterChanged = queryOptions.current.filter !== filter;

        if (filterChanged) {
            queryOptions.current.filter = (options.fixedFilter && filter) ? `${options.fixedFilter} and ${filter}` : filter;

            queryOptions.current.skip = 0;

            setPaginationConfig(prevState => Object.assign({}, prevState, {current: 1}))
        }

        reload();
    }

    function onRqbSearchSubmit(rqbQuery: any) {
        const queryChanged = !_.isEqual(queryOptions.current.rqbQuery, rqbQuery);

        if (queryChanged) {
            queryOptions.current.rqbQuery = (options.fixedRqbQuery && rqbQuery) ? {combinator: "and", rules: [options.fixedRqbQuery, rqbQuery]} : rqbQuery;

            queryOptions.current.skip = 0;

            setPaginationConfig(prevState => Object.assign({}, prevState, {current: 1}))
        }

        reload();
    }

    function reload() {
        setLoading(true);

        options.reloadFunction().finally(() => setLoading(false));
    }

    function saveState(pagination: TablePaginationConfig) {
        if (!options.persistentIdent) {
            return;
        }

        const data: PersistentData = {
            pageSize: pagination.pageSize || initialPageSize
        }

        localStorage.setItem(getQualifiedIdent(), JSON.stringify(data));
    }

    function loadState() {
        if (!options.persistentIdent) {
            return;
        }

        const serializedData = localStorage.getItem(getQualifiedIdent());

        if (!serializedData) {
            return;
        }

        const data: PersistentData = JSON.parse(serializedData);

        setPaginationConfig(prevState => Object.assign({}, prevState, {pageSize: data.pageSize}));

        queryOptions.current.top = data.pageSize;
    }

    function getQualifiedIdent() {
        return `Seal.Table.${options.persistentIdent}`;
    }

    function getStorageKeyForPaginationConfig() {
        return `Seal.Admin.Table.Pagination.${options.persistentIdent}`;
    }

    function getStorageKeyForUiConfig() {
        return `Seal.Admin.Table.Ui.${options.persistentIdent}`;
    }

    function saveUiConfig(config: TableUiConfig) {
        localStorage.setItem(getStorageKeyForUiConfig(), JSON.stringify(config));
    }

    function loadUiConfig(): TableUiConfig | null {
        const serializedData = localStorage.getItem(getStorageKeyForUiConfig());

        if (!serializedData) {
            return null;
        }

        return JSON.parse(serializedData);
    }

    function columnSortOrder(column: string): SortOrder | undefined {
        const [attribute, sortOrder] = queryOptions.current.orderBy.split(" ");

        if (column === attribute) {
            return sortOrder === "asc" ? 'ascend' : 'descend';
        }

        return undefined;
    }

    return {
        queryOptions: queryOptions.current,
        onTableChange: onTableChange,
        onSearchSubmit: onSearchSubmit,
        onRqbSearchSubmit: onRqbSearchSubmit,
        columnSortOrder: columnSortOrder,
        pagination: paginationConfig,
        updateTotal: (total: number) => setPaginationConfig(prevState => Object.assign({}, prevState, {total})),
        total: () => paginationConfig.total,
        setLoading: (state: boolean) => setLoading(state),
        loading: loading,
        reload,
        saveUiConfig,
        loadUiConfig,
    }
}
