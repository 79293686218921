import {LoadingOutlined} from "@ant-design/icons";
import Collapse from "@kunukn/react-collapse";
import {Breadcrumb, Button, Checkbox, Descriptions, Form, Input, message, Modal, Select, Spin, Switch} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, VulSourceHealthReportDefinitionServiceContext} from "../../Contexts";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import * as globalStyles from "../App.module.css";
import * as styles from "./VulSourceHealthReportDefinitionDetail.module.css";
import _ from "lodash";
import {capitalize, formatDateTime} from "../../utils/FormatUtils";
import {Permission} from "../../domain/auth/Permission";
import VulSourceHealthReportDefinition from "../../domain/VulSourceHealthReportDefinition";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function VulSourceHealthReportDefinitionDetail() {
    const appContext = useContext(AppContextContext);
    const vulSourceHealthReportDefinitionService = useContext(VulSourceHealthReportDefinitionServiceContext);
    const navigate = useNavigate();
    const {definitionId}: any = useParams();
    const [editMode, setEditMode] = useState(false);
    const [definition, setDefinition] = useState<VulSourceHealthReportDefinition>();
    const [editForm] = useForm();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        reload();
    }, [])

    return (
        <DocumentTitle title={definition?.name}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/vul-source-health-report-definitions"}>Source health report definitions</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{definition?.name}</Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={definition === undefined} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{definition?.name}</h1>

                    {appContext.user.hasPermission(Permission.REPORT__MANAGE) &&
                        <div className={globalStyles["common__top-button-bar"]}>
                            <Button onClick={() => setEditMode(!editMode)}>Edit</Button>

                            <Button danger={true}
                                    title={"Delete"}
                                    onClick={() => {
                                        Modal.confirm({
                                            content: "Do you really want to delete this report definition?",
                                            okText: "Delete",
                                            cancelText: "Cancel",
                                            okButtonProps: {danger: true},
                                            onOk: () => onDeleteConfirm()
                                        });
                                    }}>Delete</Button>

                            <Button title={"Clone"} onClick={() => navigate(`/vul-source-health-report-definitions/clone/${definition.id}`)}>Clone</Button>
                        </div>
                    }

                    {renderEditForm()}

                    <Descriptions column={1} bordered={true} size="small" className={globalStyles.details}>
                        {appContext.user.isSystemAdmin() && <Descriptions.Item label={"Organization"}>
                            <Link to={`/organizations/${definition?.organization?.id}`}>{definition?.organization?.name}</Link>
                        </Descriptions.Item>}
                        <Descriptions.Item label={"Days"}>{renderDays(definition?.days)}</Descriptions.Item>
                        <Descriptions.Item label={"Hours"}>{definition?.times?.join(", ")}</Descriptions.Item>
                        <Descriptions.Item label={"Time zone"}>{definition?.timeZone}</Descriptions.Item>
                        <Descriptions.Item label={"Enabled"}><Checkbox checked={definition?.enabled} disabled={true}/></Descriptions.Item>
                        <Descriptions.Item label={"Generate healthy report"}><Checkbox checked={definition?.generateEmptyReport} disabled={true}/></Descriptions.Item>
                        {appContext.user.isSystemAdmin() && <Descriptions.Item label={"Include system sources"}><Checkbox checked={definition?.includeSystemSources} disabled={true}/></Descriptions.Item>}
                        <Descriptions.Item label={"E-mail"}>{definition?.email}</Descriptions.Item>

                        {appContext.user.isSystemAdmin() && <Descriptions.Item label={"Created at"}>{formatDateTime(definition?.createdAt)}</Descriptions.Item>}
                    </Descriptions>
                </Spin>
            </>
        </DocumentTitle>
    )

    function renderEditForm() {
        return (
            <Collapse isOpen={editMode}>
                <h3>Edit of source health report definition</h3>

                <Form {...layout} form={editForm} className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`} onFinish={onFinishEdit}>

                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."}
                        ]}>
                        <Input autoFocus={true} maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"days"}
                        label={"Days"}>
                        <Checkbox.Group className={styles.days}>
                            <Checkbox value={"MONDAY"}>Monday</Checkbox>
                            <Checkbox value={"TUESDAY"}>Tuesday</Checkbox>
                            <Checkbox value={"WEDNESDAY"}>Wednesday</Checkbox>
                            <Checkbox value={"THURSDAY"}>Thursday</Checkbox>
                            <Checkbox value={"FRIDAY"}>Friday</Checkbox>
                            <Checkbox value={"SATURDAY"}>Saturday</Checkbox>
                            <Checkbox value={"SUNDAY"}>Sunday</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>

                    <Form.Item
                        name={"times"}
                        label={"Hours"}>
                        <Checkbox.Group
                            className={styles.times}
                            options={_.range(24).map(index => String(index).padStart(2, '0') + ":00")}
                        />
                    </Form.Item>

                    <Form.Item
                        name={"timeZone"}
                        label={"Time zone"}
                        initialValue={new Intl.DateTimeFormat().resolvedOptions().timeZone}
                        rules={[{required: true, message: "Time zone is required."}]}>
                        <Select
                            options={Intl.supportedValuesOf('timeZone').map(value => {
                                return {label: value, value}
                            })}
                            showSearch={true}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        name={"email"}
                        label={"E-mail"}
                        extra={"Comma separated list of email addresses."}
                        rules={[{required: true, message: "At least one e-mail address is required."}]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"enabled"}
                        label={"Enabled"}
                        valuePropName={"checked"}>
                        <Switch/>
                    </Form.Item>

                    <Form.Item
                        name={"generateEmptyReport"}
                        label={"Generate healthy report"}
                        extra={"Generate report even if all sources are healthy."}
                        valuePropName={"checked"}>
                        <Switch/>
                    </Form.Item>

                    {appContext.user.isSystemAdmin() &&
                        <Form.Item
                            name={"includeSystemSources"}
                            label={"Include system sources"}
                            valuePropName={"checked"}>
                            <Switch/>
                        </Form.Item>
                    }

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => setEditMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function renderDays(days?: string[]) {
        if (days?.length == 7) {
            return <i>every day</i>;
        }

        return days?.map(d => capitalize(d.toLowerCase())).join(", ");
    }

    function onFinishEdit(values: any) {
        vulSourceHealthReportDefinitionService.update(definitionId, values)
            .then(
                (id) => {
                    message.success(<>Source health report definition updated.</>);

                    setEditMode(false);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }

    function onDeleteConfirm() {
        vulSourceHealthReportDefinitionService.delete(definition)
            .then(() => {
                message.success(<>Source health report definition <b>{definition.name}</b> deleted.</>);

                navigate(`/vul-source-health-report-definitions`);
            });
    }

    function reload() {
        vulSourceHealthReportDefinitionService.get(definitionId).then(definition => {
            setDefinition(definition);

            editForm.setFieldsValue(definition);
        });
    }

}

export default VulSourceHealthReportDefinitionDetail;
