.descriptions {
    margin-bottom: 16px;
    max-width: 1200px;
}

.related-items-form:global(.ant-form) {
    padding: 12px;
    max-width: 1200px;
}

.vote {
    width: 100px;
    display: inline-block;
}

.descriptions :global(.ant-descriptions-item-label) {
    width: 160px;
}

.nested-descriptions:global(.ant-descriptions) {
    width: 340px;
    margin-bottom: 0;
}

.nested-descriptions :global(.ant-descriptions-item-label) {
    width: 180px;
}

.nested-descriptions :global(.ant-descriptions-item) {
    padding: 0 !important;
}

.diff pre {
    outline: 0;
}

.related-items-table {
    max-width: 1200px;
}

.change-log-table {
    display: inline-block;
    min-width: 1200px;
    max-width: 1800px;
}

.change-log-table :global(.ant-collapse) {
    min-width: 140px;
}

.change-log-table :global(.ant-collapse-header) {
    padding: 4px 8px !important;
}

.column-source {
    min-width: 200px;
}

.ticket-alert {
    display: inline-flex;
    margin-bottom: 1em;
}

.description {
    margin-bottom: 8px;
}
