import {Drawer, Table} from "antd";

import 'json-diff-kit/dist/viewer.css';
import {Differ, Viewer} from "json-diff-kit";
import React from "react";
import VulSourceItem from "../../domain/VulSourceItem";
import VulSourceItemChangeLog from "../../domain/VulSourceItemChangeLog";
import Column from "antd/es/table/Column";
import {formatDateTime} from "../../utils/FormatUtils";
import * as styles from "./VulSourceChangeLogListDrawer.module.css";

const differ = new Differ({
    detectCircular: true,    // default `true`
    maxDepth: Infinity,      // default `Infinity`
    showModifications: true, // default `true`
    arrayDiffMethod: 'lcs',  // default `"normal"`, but `"lcs"` may be more useful
});

const diffViewerProps = {
    indent: 4,
    highlightInlineDiff: true,
    hideUnchangedLines: true,
};

interface Props {
    item?: VulSourceItem;
    changeLogs?: VulSourceItemChangeLog[],
    open: boolean;
    onClose: () => void;
}

function VulSourceChangeLogListDrawer(props: Props) {
    return (
        <>
            <Drawer title={"Vulnerability item changes"} destroyOnClose={true} rootClassName={styles.drawer} open={props.open} onClose={props.onClose}>
                <Table
                       showSorterTooltip={false}
                       dataSource={props.changeLogs?.slice(0, props.changeLogs.length - 1)}
                       size="middle"
                       showHeader={false}
                       pagination={false}
                       rowKey="id">

                    <Column
                        dataIndex={"difference"}
                        title={"Difference"}
                        render={(value, record: VulSourceItemChangeLog) => renderChangeLogDifference(props.changeLogs, record)}
                    />

                </Table>
            </Drawer>
        </>
    );

    function renderChangeLogDifference(changeLogs: VulSourceItemChangeLog[], changeLog: VulSourceItemChangeLog) {
        const olderChangeLog = VulSourceItem.findOlderChangeLog(changeLogs, changeLog);

        if (olderChangeLog === undefined) {
            return <i>no difference available</i>;
        }

        return <>
            <div className={styles.timestamps}>
                <span><b>Timestamp:</b> <i>{formatDateTime(olderChangeLog.timestamp)}</i></span>
                <span><b>Timestamp:</b> <i>{formatDateTime(changeLog.timestamp)}</i></span>
            </div>
            <Viewer className={styles.diff} diff={differ.diff(olderChangeLog.attributes, changeLog.attributes)} {...diffViewerProps}/>
        </>;
    }
}

export default VulSourceChangeLogListDrawer;