.queryBuilder button {
    display: none;
}

.mask-container {
    position: relative;
}

.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: silver;
    opacity: 0.25;
    z-index: 1000;
}
