.container {
    width: 100%;
    margin-top: 0px;
}

.action {
    width: 50%;
    display: inline-block;
}

.action.like {
    text-align: right;
}

.action.like button {
    border: none;
    padding-left: 5px;
    background: transparent;
}

.action.dislike {
    text-align: left;
}

.action.dislike button {
    border: none;
    padding-right: 5px;
    background: transparent;
}


.graph {
    width: 100%;
    display: flex;
}

.graph.pending {
    opacity: 0.4;
}

.graph.pending .graph-like, .graph.pending .graph-dislike {
    opacity: 0.4;
    border-top: solid 5px lightgrey;
}


.graph-like {
    border-top: solid 5px cornflowerblue;
    display: inline-block;
}


.graph-dislike {
    border-top: solid 5px lightgrey;
    display: inline-block;
}

hr {
    margin-bottom: 0px;
    opacity: 0.4;
    margin-top: 32px;
}