.filter {
    max-width: 1800px;
}

.action-bar {
    margin-left: 16px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

.table {
    max-width: 1600px;
}

.table :global(.ant-table-cell) {
    word-wrap: break-word;
    word-break: break-word;
}

.column-source {
    min-width: 200px;
}

.hr {
    opacity: 0;
}