import Axios from "axios";
import Ticket from "../domain/Ticket";
import TicketComment from "../domain/TicketComment";
import User from "../domain/User";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";

class TicketService {

    readonly basePath = "/tickets";

    public add(ticket: Ticket): Promise<string> {
        return Axios.post(`${this.basePath}`, ticket).then(value => value.data.id)
    }

    public update(ticketId: string, ticket: Ticket): Promise<void> {
        return Axios.put(`${this.basePath}/${ticketId}`, ticket);
    }

    public get(id: string): Promise<Ticket> {
        return Axios.get(`${this.basePath}/${id}`)
            .then(value => Ticket.fromPOJO(value.data))
    }

    public delete(ticket: Ticket): Promise<void> {
        return Axios.delete(`${this.basePath}/${ticket.id}`);
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<Ticket>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: Ticket[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(Ticket.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public getAvailableUserList(queryOptions: QueryOptions): Promise<PagedResult<User>> {
        return Axios.get(`${this.basePath}/available-users`)
            .then(value => {
                const objectList: User[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(User.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public addComment(ticket: Ticket, comment: string): Promise<string> {
        return Axios.post(`${this.basePath}/${ticket.id}/add-comment`, {comment}).then(value => value.data.id)
    }

    public updateComment(comment: TicketComment, text: string) {
        return Axios.put(`${this.basePath}/comments/${comment.id}`, {comment: text}).then(value => value.data.id)
    }

    public deleteComment(comment: TicketComment) {
        return Axios.delete(`${this.basePath}/comments/${comment.id}`);
    }

}

export default TicketService;
