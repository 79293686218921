import {LoadingOutlined} from "@ant-design/icons";
import Collapse from "@kunukn/react-collapse";
import {Breadcrumb, Button, Descriptions, Form, Input, message, Modal, Select, Spin} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, SoftwareLicenseOverrideServiceContext, SoftwareLicenseServiceContext} from "../../Contexts";
import Organization from "../../domain/Organization";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import *as globalStyles from "../App.module.css";
import SoftwareLicenseOverride from "../../domain/SoftwareLicenseOverride";
import PagedResult from "../../service/PagedResult";
import SoftwareLicense from "../../domain/SoftwareLicense";
import QueryOptions from "../../sal-ui/QueryOptions";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function SoftwareLicenseOverrideDetail() {
    const appContext = useContext(AppContextContext);
    const softwareLicenseService = useContext(SoftwareLicenseServiceContext);
    const overrideService = useContext(SoftwareLicenseOverrideServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const navigate = useNavigate();
    const {overrideId}: any = useParams();
    const [editMode, setEditMode] = useState(false);
    const [override, setOverride] = useState<SoftwareLicenseOverride>();
    const [organizations, setOrganizations] = useState<Organization[]>();
    const [licenses, setLicenses] = useState<PagedResult<SoftwareLicense>>();
    const [editForm] = useForm();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (appContext.user.isSystemAdmin()) {
            organizationService.getList(new QueryOptions("name", 100, 0)).then(value => {
                setOrganizations(value.data);
            });
        }

        softwareLicenseService.getList(QueryOptions.newUnlimitedOrderedInstance("shortName")).then(setLicenses);

        reload();
    }, [])

    const title = `Software license override for ${override?.purl}`;

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/software-license-overrides"}>Software license overrides</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{override?.purl}</Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={!override} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{title}</h1>

                    <div className={globalStyles["common__top-button-bar"]}>
                        <Button onClick={() => setEditMode(!editMode)}>Edit</Button>

                        <Button danger={true}
                                title={"Delete"}
                                onClick={() => {
                                    Modal.confirm({
                                        content: "Do you really want to delete this license override?",
                                        okText: "Delete",
                                        cancelText: "Cancel",
                                        okButtonProps: {danger: true},
                                        onOk: () => onDeleteConfirm(override!)
                                    });
                                }}>Delete</Button>
                    </div>

                    {renderEditForm()}

                    <Descriptions column={1} bordered={true} size="small" className={globalStyles.details}>
                        {appContext.user.isSystemAdmin() && <Descriptions.Item label={"Organization"}>
                            <Link to={`/organizations/${override?.organization.id}`}>{override?.organization.name}</Link>
                        </Descriptions.Item>}
                        <Descriptions.Item label={"Package URL"}>{override?.purl}</Descriptions.Item>
                        <Descriptions.Item label={"Version range"}>{override?.vers}</Descriptions.Item>
                        <Descriptions.Item label={"Licenses"}>
                            {override?.licenses.map(license => <div>{license.shortName}</div>)}
                        </Descriptions.Item>
                    </Descriptions>

                </Spin>
            </>
        </DocumentTitle>
    )

    function renderEditForm() {
        return (
            <Collapse isOpen={editMode}>
                <h3>Edit of license overrride for {override?.purl}</h3>

                <Form {...layout} form={editForm} className={`${globalStyles["common__form"]}`} onFinish={onFinishEdit}>
                    {appContext.user.isSystemAdmin() &&
                        <Form.Item
                            name={"organization"}
                            label={"Organization"}
                            rules={[{required: true, message: "Organization is required."}]}>
                            <Select>
                                {organizations && organizations!.map(organization => {
                                    return <Select.Option key={organization?.name} value={organization?.id!}>{organization?.name}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    }

                    <Form.Item
                        name={"purl"}
                        label={"Package URL"}
                        extra={<>
                            <p style={{marginTop: 12}}>Package identifier according to <a href={"https://github.com/package-url/purl-spec/blob/master/PURL-SPECIFICATION.rst"} target={"_blank"}>Package URL specification</a> without version.</p>
                            <p>Examples:</p>
                            <ul>
                                <li>pkg:maven/org.apache.xmlgraphics/batik-anim</li>
                                <li>pkg:npm/foobar</li>
                                <li>pkg:nuget/EnterpriseLibrary.Common</li>
                            </ul>
                        </>}
                        rules={[
                            {required: true, message: "Package URL is required."},
                            {validator: serverViolationsHolder.createServerValidator('UNKNOWN', 'purl', undefined), message: "Value is not valid Package URL expression."}
                        ]}>
                        <Input autoFocus={true} maxLength={300}/>
                    </Form.Item>

                    <Form.Item
                        name={"vers"}
                        label={"Version range"}
                        extra={<>
                            <p style={{marginTop: 12}}>
                                Version constraints according to <a href={"https://github.com/package-url/purl-spec/blob/version-range-spec/VERSION-RANGE-SPEC.rst"} target={"_blank"}>Package URL vers specification</a> without "vers" and versioning scheme prefix.
                                Versioning scheme from Package URL is used.
                            </p>
                            <p>Examples:</p>
                            <ul>
                                <li>0.0.0|0.0.1|0.0.2|0.0.3|1.0|2.0pre1</li>
                                <li>1.2.3|&gt;=2.0.0|&lt;5.0.0</li>
                                <li>*</li>
                            </ul>
                        </>}
                        rules={[
                            {required: true, message: "Version range is required."},
                            {validator: serverViolationsHolder.createServerValidator('UNKNOWN', 'vers', undefined), message: "Value is not valid vers expression."}
                        ]}>
                        <Input maxLength={500}/>
                    </Form.Item>

                    <Form.Item
                        name={"licenseKeys"}
                        label={"Licenses"}
                        rules={[{required: true, message: "Licenses are required."}]}>
                        <Select mode={"multiple"}>
                            {licenses?.data?.map(license => {
                                return <Select.Option key={license.licenseKey} value={license.licenseKey}>{license.shortName}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => setEditMode(false)}>Cancel</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function onFinishEdit(values: any) {
        overrideService.update(overrideId, values)
            .then(
                (id) => {
                    message.success(<>Software license override for <b>{values.purl}</b> successfully updated.</>);

                    setEditMode(false);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }

    function onDeleteConfirm(override: SoftwareLicenseOverride) {
        overrideService.delete(override)
            .then(() => {
                message.success(<>Software license override for <b>{override.purl}</b> successfully deleted.</>);

                navigate(`/software-license-overrides`)
            });
    }

    function reload() {
        overrideService.get(overrideId).then(override => {
            setOverride(override);

            editForm.setFieldsValue({
                ...override,
                organization: override.organization?.id
            });
        });
    }

}

export default SoftwareLicenseOverrideDetail;
