export enum TicketAttributeRuleOperationType {
    EQUALS = "EQUALS",
    CONTAINS = "CONTAINS"
}

export function getEqualOrGreaterSeverities(severity: TicketAttributeRuleOperationType) {
    const index = Object.keys(TicketAttributeRuleOperationType).indexOf(severity);

    return Object.keys(TicketAttributeRuleOperationType).filter((value, idx) => idx >= index);
}
