.card {
    min-width: 600px;
    max-width: 1000px;
    margin-bottom: 8px;
}

.form {
    max-width: 600px;
}

.complex-value {
    display: inline-flex;
    outline: none;
    padding: 0;
    margin: 0;
    white-space: pre;
}

.attribute-label {
    font-style: italic;
    min-width: 200px;
    display: inline-block;
}

.attribute-label:after {
    content: ':'
}

.diff pre {
    outline: 0;
    padding: 2;
}