import VulSource from "./VulSource";
import Ticket from "./Ticket";

class VulSourceItemFlags {
    constructor(
        public important?: boolean,
        public notImportant?: boolean) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const entity = new VulSourceItemFlags();

        entity.important = data.important;
        entity.notImportant = data.notImportant;

        return entity;
    }
}

export default VulSourceItemFlags;
