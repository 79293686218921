.filter {
    width: 1100px;
}

.table {
    width: 1100px;
}

.column-name {
    width: 30%;
}
