import {QueryBuilderAntD} from "@react-querybuilder/antd";
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import {Field, QueryBuilder, RuleGroupType, transformQuery} from "react-querybuilder";
import React, {useContext, useEffect, useState} from "react";
import {formatVulSourceType, VulSourceType} from "../../domain/VulSourceType";
import AttributeDefinition from "../../domain/AttributeDefinition";
import {AttributeDefinitionServiceContext, VulSourceServiceContext} from "../../Contexts";
import QueryOptions from "../../sal-ui/QueryOptions";
import VulSource from "../../domain/VulSource";
import {FormInstance} from "antd";
import {QueryBuilderDnD} from "@react-querybuilder/dnd";
import * as styles from "./VulViewQueryBuilder.module.css";
import {AttributeValueType} from "../../domain/AttributeValueType";
import CustomValueEditor from "./CustomValueEditor";

interface Props {
    form?: FormInstance;
    initialQuery?: string;
    disabled?: boolean;
}

function VulViewQueryBuilder(props: Props) {
    const attributeDefinitionService = useContext(AttributeDefinitionServiceContext);
    const vulSourceService = useContext(VulSourceServiceContext);
    const [attributeDefinitions, setAttributeDefinitions] = useState<AttributeDefinition[]>([]);
    const [vulSources, setVulSources] = useState<VulSource[]>();
    const [query, setQuery] = useState<RuleGroupType>({
        combinator: 'or',
        rules: [{field: "text", operator: "jsonRegex", value: undefined}]
    });

    useEffect(() => {
        if (props.initialQuery !== undefined) {
            const initialQuery = JSON.parse(props.initialQuery);

            const transformedQuery = transformQuery(initialQuery, {
                ruleProcessor: rule => {
                    if (rule.field === "attributes") {
                        return {
                            ...rule,
                            field: `attributes-${rule.value[0]}`,
                            value: rule.value[1]
                        }
                    }

                    return rule;
                }
            });

            setQuery(transformedQuery);
        }
    }, [props.initialQuery]);

    useEffect(() => {
        vulSourceService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setVulSources(value.data));
        attributeDefinitionService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setAttributeDefinitions(value.data));
    }, []);

    const numericOperators = [
        {name: "jsonGe", label: "≥"},
        {name: "jsonEq", label: "="},
        {name: "jsonLe", label: "≤"},
    ];

    const textOperators = [
        {name: "jsonRegex", label: "matches"},
        {name: "not(jsonRegex)", label: "not matches"},
    ];

    const fields: Field[] = [
        {
            name: 'text',
            label: 'Text',
            operators: [
                {name: 'jsonRegex', label: 'matches'},
                {name: 'not(jsonRegex)', label: 'not matches'}
            ]
        },
        {
            name: 'source.type',
            label: 'Source type',
            operators: [
                {name: 'in', label: 'in'},
                {name: 'notIn', label: 'not in'}
            ],
            valueEditorType: 'multiselect',
            values: Object.values(VulSourceType).map(value => ({
                name: value,
                label: formatVulSourceType(value)
            })),
            validator: rule => rule.value !== undefined && rule.value !== null && rule.value.length !== 0
        },
        {
            name: 'source.id',
            label: 'Source',
            operators: [
                {name: 'in', label: 'in'},
                {name: 'notIn', label: 'not in'}
            ],
            valueEditorType: 'multiselect',
            values: vulSources,
            validator: rule => rule.value !== undefined && rule.value !== null && rule.value.length !== 0
        },
        ...attributeDefinitions.map(definition => {
            return {
                name: `attributes-${definition.name}`,
                label: `Attribute: ${definition.name}`,
                operators: (definition.valueType === AttributeValueType.NUMBER) ? numericOperators : textOperators
            }
        })
    ];

    if (props.disabled === true) {
        return (
            <div className={styles['mask-container']}>
                <QueryBuilderAntD>
                    <QueryBuilder
                        fields={fields}
                        query={query}
                        controlClassnames={{
                            queryBuilder: styles.queryBuilder
                        }}
                    />
                </QueryBuilderAntD>
                <div className={styles.mask}>
                </div>
            </div>
        )
    } else {
        return (
            <QueryBuilderDnD dnd={{...ReactDnD, ...ReactDndHtml5Backend}}>
                <QueryBuilderAntD>
                    <QueryBuilder
                        fields={fields}
                        query={query}
                        onQueryChange={q => {
                            setQuery(q);

                            const transformedQuery = transformQuery(q, {
                                ruleProcessor: rule => {
                                    if (rule.field.startsWith("attributes-")) {
                                        const tokens = rule.field.split("-");

                                        return {
                                            ...rule,
                                            field: tokens[0],
                                            value: [tokens[1], rule.value]
                                        }
                                    }

                                    return rule;
                                }
                            });

                            if (props.form) {
                                props.form.setFieldValue("query", JSON.stringify(transformedQuery));
                            }
                        }}
                        controlElements={{ valueEditor: CustomValueEditor }}
                        listsAsArrays={true}
                        getDefaultValue={() => undefined}
                    />
                </QueryBuilderAntD>
            </QueryBuilderDnD>
        )
    }

}

export default VulViewQueryBuilder;