.tack {
    display: inline-block;
    height: 100%;
    width: 12px;
    text-align: center;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0;
}

.tack-square-border {
    border-radius: 0;
}

.item {
    margin-left: 10px;
}

.tack-seal {
    background-color: #440084;
    color: white;
}

.tack-grey {
    background-color: #f0f0f0;
    color: black;
}

.tack-green {
    background-color: #d9f7be;
    color: black;
}

.tack-orange {
    background-color: #ffe7ba;
    color: black;
}

.tack-red {
    background-color: #ffccc7;
    color: black;
}

.tack-org {
    background-color: #0066cc;
    color: white;
}

.tack-text {
    font-size: 10px;
    font-weight: normal !important;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    height: 100%;
    line-height: 12px;
}