import {ApartmentOutlined, ArrowRightOutlined, ArrowUpOutlined, CheckCircleOutlined, CloseSquareOutlined, DownloadOutlined, LoadingOutlined, MenuOutlined, ReloadOutlined, SwapOutlined, UploadOutlined} from "@ant-design/icons";
import {Badge, Breadcrumb, Button, Card, Checkbox, Descriptions, Dropdown, Form, Input, MenuProps, message, Modal, Popover, Select, Spin, Switch, Table, Tabs, TabsProps, Tag, Upload} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {memo, useCallback, useContext, useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, AttributeDefinitionServiceContext, ClientNotificationServiceContext, SbomComponentServiceContext, SbomProjectServiceContext, SbomProjectVersionServiceContext} from "../../Contexts";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import * as globalStyles from "../App.module.css";
import QueryOptions from "../../sal-ui/QueryOptions";
import SbomProjectVersion from "../../domain/SbomProjectVersion";
import PagedResult from "../../service/PagedResult";
import * as styles from "./SbomProjectVersionDetail.module.css";
import {ColumnsType} from "antd/es/table";
import SbomComponent from "../../domain/SbomComponent";
import SourceTag from "../vulsourceitem/SourceTag";
import SealScore from "../vulsourceitem/SealScore";
import VulSourceItemDescription from "../vulsourceitem/VulSourceItemDescription";
import {formatDate, formatDateTime} from "../../utils/FormatUtils";
import AttributeDefinition from "../../domain/AttributeDefinition";
import {TableHandler, TableUiConfig, useTableHandler} from "../../sal-ui/TableHandler";
import FindingStatistics from "./FindingStatistics";
import CopyToClipboard from "react-copy-to-clipboard";
import SbomProjectVersionComponentFilter from "./SbomProjectVersionComponentFilter";
import _ from "lodash";
import {FindingSeverity, getEqualOrGreaterSeverities} from "../../domain/FindingSeverity";
import {isColumnVisible, TableConfig} from "../tableconfig/TableConfig";
import SbomComponentFinding from "../../domain/SbomComponentFinding";
import * as tackStyles from "../vulsourceitem/Tack.module.css";
import * as sealScoreStyles from "../vulsourceitem/SealScore.module.css";
import FindingAnalysisStatistics, {AnalysisStyle} from "./FindingAnalysisStatistics";
import Collapse from "@kunukn/react-collapse";
import MoreLess from "../common/MoreLess";
import {VisGraph, VisGraphRef, VisSingleContainer} from '@unovis/react'
import {Graph, GraphLayoutType, GraphNode} from "@unovis/ts";
import {PackageURL} from 'packageurl-js';
import SbomProjectVersionVulnerableComponentFilter from "./SbomProjectVersionVulnerableComponentFilter";
import {Permission} from "../../domain/auth/Permission";
import SingleResult from "../../service/SingleResult";
import SbomProject from "../../domain/SbomProject";
import {colorForSbomProjectVersionStatus, formatSbomProjectVersionStatus, SbomProjectVersionStatus} from "../../domain/SbomProjectVersionStatus";
import {evaluateSbomComponentHealth, SbomComponentHealth} from "../../domain/SbomComponentHealth";
import {green, orange, red} from '@ant-design/colors';
import {SoftwareLicenseOrigin} from "../../domain/SoftwareLicenseOrigin";
import {packageUrlToOsiUrl} from "../sbomcomponent/functions";
import {formatSbomType} from "../../domain/SbomType";
import SoftwareLicense from "../../domain/SoftwareLicense";
import SelectedComponent from "./SelectedComponent";
import CompareModal from "./CompareModal";
import AdoptAnalysisModal from "./AdoptAnalysisModal";
import FindingStatusCollapse from "./FindingStatusCollapse";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

const defaultVisibleColumns = ["type", "group", "name", "version", "publishedAt", "licenses", "analysisStatistics", "findingStatistics", "actions"];

const defaultTableUiConfig = {immediateMode: true, visibleColumns: defaultVisibleColumns};

const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};

const tailLayout = {
    wrapperCol: {offset: 6, span: 18},
};

function createRqbQuery(values: any) {
    const rqbQuery = {
        combinator: "and",
        rules: []
    }

    values.severity && values.severity.length > 0 && rqbQuery.rules.push({
        field: "severity",
        operator: "in",
        value: getEqualOrGreaterSeverities(values.severity)
    });

    values.licenses && values.licenses.length > 0 && rqbQuery.rules.push({
        field: "license_keys",
        operator: "arrayIn",
        value: values.licenses
    });

    values.name && rqbQuery.rules.push({
        field: "name",
        operator: "icontains",
        value: values.name
    });

    values.hideNotAffected && rqbQuery.rules.push({
        field: "analysedFindingStatistics",
        operator: "hideNotAffected",
    });

    values.hideAnalysed && rqbQuery.rules.push({
        field: "analysisStatistics",
        operator: "hideAnalysed"
    });

    values.onlyDirect && rqbQuery.rules.push({
        field: "directDependency",
        operator: "eq",
        value: true
    });

    return rqbQuery;
}

const ProjectVersionLicenses = function ({sbomProjectVersion}: { sbomProjectVersion?: SbomProjectVersion }) {
    if (sbomProjectVersion?.licenses === undefined) {
        return;
    }

    if (sbomProjectVersion?.licenses?.length === 0) {
        return <i>no licenses</i>;
    }

    return <MoreLess textMore={"« show more licenses »"} textLess={"» hide licenses «"}>
        {sbomProjectVersion?.licenses.map(license => {
            if (license.origin === SoftwareLicenseOrigin.UNKNOWN) {
                return <div key={license.shortName} className={styles['license-status']}>
                    {license.shortName} {sbomProjectVersion?.project?.softwareLicensePolicy && <Badge count={<CloseSquareOutlined/>} style={{color: '#f5222d'}}/>}
                </div>;
            }

            return <div key={license.shortName} className={styles['license-status']}>
                <Link to={`/software-licenses/${license.id}`} target={"_blank"}>{license.shortName}</Link>

                {sbomProjectVersion?.project?.softwareLicensePolicy && <>
                    {sbomProjectVersion?.licenseViolations?.includes(license.licenseKey) && <Badge count={<CloseSquareOutlined/>} style={{color: '#f5222d'}}/>}
                    {!sbomProjectVersion?.licenseViolations?.includes(license.licenseKey) && <Badge count={<CheckCircleOutlined/>} style={{color: '#52c41a'}}/>}
                </>}
            </div>;
        })}
    </MoreLess>;
};

function SbomProjectVersionDetail() {
    const persistentIdent = 'SbomProjectVersionDetail';
    const appContext = useContext(AppContextContext);
    const sbomProjectService = useContext(SbomProjectServiceContext);
    const sbomProjectVersionService = useContext(SbomProjectVersionServiceContext);
    const sbomComponentService = useContext(SbomComponentServiceContext);
    const attributeDefinitionService = useContext(AttributeDefinitionServiceContext);
    const clientNotificationService = useContext(ClientNotificationServiceContext);
    const location = useLocation();
    const navigate = useNavigate();
    const {sbomProjectVersionId}: any = useParams();
    const sbomProjectVersionIdRef = useRef<string>(sbomProjectVersionId);
    const [editMode, setEditMode] = useState(false);
    const [sbomProject, setSbomProject] = useState<SingleResult<SbomProject>>();
    const [sbomProjectVersion, setSbomProjectVersion] = useState<SbomProjectVersion>();
    const [sbomComponents, setSbomComponents] = useState<PagedResult<SbomComponent>>();
    const [vulnerableSbomComponents, setVulnerableSbomComponents] = useState<PagedResult<SbomComponent>>();
    const [duplicateComponents, setDuplicateComponents] = useState<PagedResult<SbomComponent>>();
    const [unknownLicensesComponents, setUnknownLicensesComponents] = useState<PagedResult<SbomComponent>>();
    const [outdatedComponents, setOutdatedComponents] = useState<PagedResult<SbomComponent>>();
    const [componentsFindings, setComponentsFindings] = useState<any>({});
    const [attributeDefinitions, setAttributeDefinitions] = useState<AttributeDefinition[]>();
    const [filterValues, setFilterValues] = useState<any>();
    const [vulnerableFilterValues, setVulnerableFilterValues] = useState<any>();
    const [duplicateFilterValues, setDuplicateFilterValues] = useState<any>();
    const [unknownLicensesFilterValues, setUnknownLicensesFilterValues] = useState<any>();
    const [outdatedFilterValues, setOutdatedFilterValues] = useState<any>();
    const [tableUiConfig, setTableUiConfig] = useState<TableUiConfig>(defaultTableUiConfig);
    const [usageModalOpen, setUsageModalOpen] = useState(false);
    const [usageGraphData, setUsageGraphData] = useState<any>();
    const [usageModalTitle, setUsageModalTitle] = useState("");
    const [adoptModalOpen, setAdoptModalOpen] = useState(false);
    const [compareModalOpen, setCompareModalOpen] = useState(false);
    const [versions, setVersions] = useState<PagedResult<SbomProjectVersion>>();

    const tableHandler = useTableHandler("name asc", {
        reloadFunction: reloadComponents,
        initialPageSize: 25,
        persistentIdent: `${persistentIdent}`
    });

    const vulnerableTableHandler = useTableHandler("name asc", {
        reloadFunction: reloadVulnerableComponents,
        initialPageSize: 25,
        persistentIdent: `${persistentIdent}`,
        fixedRqbQuery: {
            combinator: "and",
            rules: [{field: "severity", operator: "in", value: getEqualOrGreaterSeverities(FindingSeverity.UNKNOWN)}]
        }
    });

    const duplicateTableHandler = useTableHandler("name asc", {
        reloadFunction: reloadDuplicateComponents,
        initialPageSize: 25,
        persistentIdent: `${persistentIdent}`,
        fixedRqbQuery: {
            combinator: "and",
            rules: [{field: "duplicate", operator: "eq", value: true}]
        }
    });

    const unknownLicensesTableHandler = useTableHandler("name asc", {
        reloadFunction: reloadUnknownLicenseComponents,
        initialPageSize: 25,
        persistentIdent: `${persistentIdent}`,
        fixedRqbQuery: {
            combinator: "and",
            rules: [{field: "licenses", operator: "unknownLicenses"}]
        }
    });

    const outdatedTableHandler = useTableHandler("name asc", {
        reloadFunction: reloadOutdatedComponents,
        initialPageSize: 25,
        persistentIdent: `${persistentIdent}`,
        fixedRqbQuery: {
            combinator: "and",
            rules: [{field: "latestVersion", operator: "outdated"}]
        }
    });

    const columns: ColumnsType<SbomComponent> = [
        {
            dataIndex: "type",
            title: "Type",
            width: 100,
            align: "center",
            render: renderType
        },
        {
            dataIndex: "group",
            title: "Group",
        },
        {
            dataIndex: "name",
            title: "Name",
            sorter: true,
            defaultSortOrder: "ascend",
            sortDirections: ["ascend", "descend", "ascend"],
            render: renderName
        },
        {
            dataIndex: "version",
            title: "Version",
            render: renderVersion
        },
        {
            dataIndex: "publishedAt",
            title: "Published at",
            width: 120,
            align: "center",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: renderPublishedAt
        },
        {
            dataIndex: "licenses",
            title: "Licenses",
            render: renderLicenses
        },
        {
            dataIndex: "analysisStatistics",
            title: "Analysis",
            align: "center",
            render: renderAnalysisStatistics
        },
        {
            dataIndex: "findingStatistics",
            title: "Vulnerability findings",
            render: renderStatistics
        },
        {
            dataIndex: "actions",
            title: "Actions",
            width: 80,
            align: "center",
            render: renderAction
        }
    ]

    const debouncedOnFinishFilter = useCallback(_.debounce(onFinishFilter, 500), []);
    const debouncedOnFinishVulnerableFilter = useCallback(_.debounce(onFinishVulnerableFilter, 500), []);
    const debouncedOnFinishDuplicateFilter = useCallback(_.debounce(onFinishDuplicateFilter, 500), []);
    const debouncedOnFinishUnknownLicensesFilter = useCallback(_.debounce(onFinishUnknownLicensesFilter, 500), []);
    const debouncedOnFinishOutdatedFilter = useCallback(_.debounce(onFinishOutdatedFilter, 500), []);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        attributeDefinitionService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setAttributeDefinitions(value.data));

        setTableUiConfig(tableHandler.loadUiConfig() || defaultTableUiConfig);
    }, [])

    useEffect(() => {
        if (sbomProjectVersionId === undefined) {
            return;
        }

        sbomProjectVersionIdRef.current = sbomProjectVersionId;

        reload().then(reloadAllComponents);

        clientNotificationService.subscribe("seal:SBOM_PROJECT_VERSION_EVALUATION_COMPLETED", () => onEvaluationCompleted(), sbomProjectVersionId)
        clientNotificationService.subscribe("seal:SBOM_PROJECT_VERSION_PUBLISHED_AT_UPDATE_COMPLETED", () => onPublishedAtUpdateCompleted(), sbomProjectVersionId)

        return function cleanup() {
            clientNotificationService.unsubscribe("seal:SBOM_PROJECT_VERSION_EVALUATION_COMPLETED", sbomProjectVersionId);
            clientNotificationService.unsubscribe("seal:SBOM_PROJECT_VERSION_PUBLISHED_AT_UPDATE_COMPLETED", sbomProjectVersionId);
        }
    }, [sbomProjectVersionId])

    useEffect(() => {
        if (sbomProject === undefined) {
            return;
        }

        sbomProjectVersionService.getList(sbomProject.data.id, QueryOptions.newUnlimitedOrderedInstance("name desc")).then(setVersions);
    }, [sbomProject]);

    const closeUsageModalCallback = useCallback(() => setUsageModalOpen(false), []);

    const editCancelCallback = useCallback(() => setEditMode(false), []);

    const editFinishCallback = useCallback(() => {
        setEditMode(false);

        reload();
    }, []);

    const canRead = appContext.user.hasPermission(Permission.SBOM_PROJECT__MANAGE_ORG) || sbomProject?.permissions?.includes(Permission.SBOM_PROJECT__READ);
    const canAnalyze = appContext.user.hasPermission(Permission.SBOM_PROJECT__MANAGE_ORG) || sbomProject?.permissions?.includes(Permission.SBOM_PROJECT__ANALYZE);
    const canManage = appContext.user.hasPermission(Permission.SBOM_PROJECT__MANAGE_ORG) || sbomProject?.permissions?.includes(Permission.SBOM_PROJECT__MANAGE);

    const tabAll = {
        key: 'all',
        label: <>All components <Badge className={styles['count-badge']} showZero={true} overflowCount={10000} count={sbomProjectVersion?.componentsTotal}/></>,
        children: renderComponents([filterValues, setFilterValues], debouncedOnFinishFilter, tableHandler, sbomComponents)
    };

    const tabVulnerable = {
        key: 'vulnerable',
        label: <>Vulnerable components <Badge className={styles['count-badge']} showZero={true} overflowCount={10000} count={sbomProjectVersion?.componentsVulnerable}/></>,
        children: renderComponents([vulnerableFilterValues, setVulnerableFilterValues], debouncedOnFinishVulnerableFilter, vulnerableTableHandler, vulnerableSbomComponents, <SbomProjectVersionVulnerableComponentFilter
                className={styles['filter']}
                values={vulnerableFilterValues}
                onChange={values => {
                    setVulnerableFilterValues(values);

                    debouncedOnFinishVulnerableFilter(values);
                }}
                immediateMode={tableUiConfig.immediateMode}
                readOnly={false}
            />
        )
    };

    const tabDuplicate = {
        key: 'duplicate',
        label: <>Duplicate components <Badge className={styles['count-badge']} showZero={true} overflowCount={10000} count={sbomProjectVersion?.componentsDuplicate}/></>,
        children: renderComponents([duplicateFilterValues, setDuplicateFilterValues], debouncedOnFinishDuplicateFilter, duplicateTableHandler, duplicateComponents)
    };

    const tabUnknownLicenses = {
        key: 'unknownLicenses',
        label: <>Unknown licenses <Badge className={styles['count-badge']} showZero={true} overflowCount={10000} count={sbomProjectVersion?.componentsUnknownLicenses}/></>,
        children: renderComponents([unknownLicensesFilterValues, setUnknownLicensesFilterValues], debouncedOnFinishUnknownLicensesFilter, unknownLicensesTableHandler, unknownLicensesComponents)
    };

    const tabOutdated = {
        key: 'outdated',
        label: <>Outdated components <Badge className={styles['count-badge']} showZero={true} overflowCount={10000} count={sbomProjectVersion?.componentsOutdated}/></>,
        children: renderComponents([outdatedFilterValues, setOutdatedFilterValues], debouncedOnFinishOutdatedFilter, outdatedTableHandler, outdatedComponents)
    };

    const tabItems: TabsProps['items'] = [tabAll];

    if (sbomProjectVersion?.componentsVulnerable > 0) {
        tabItems.push(tabVulnerable);
    }

    if (sbomProjectVersion?.componentsDuplicate > 0) {
        tabItems.push(tabDuplicate);
    }

    if (sbomProjectVersion?.componentsUnknownLicenses > 0) {
        tabItems.push(tabUnknownLicenses);
    }

    if (sbomProjectVersion?.componentsOutdated > 0) {
        tabItems.push(tabOutdated);
    }

    return (
        <DocumentTitle title={`SBOM project ${sbomProjectVersion?.project?.name || sbomProjectVersionIdRef.current}: ${sbomProjectVersion?.name}`}>
            <>
                <Breadcrumb
                    className={globalStyles["common__breadcrumb"]}
                    items={[
                        {title: appContext.config?.appName},
                        {title: <Link to={"/sbom-projects"}>SBOM projects</Link>},
                        {title: <Link to={`/sbom-projects/${sbomProjectVersion?.project?.id}`}>{sbomProjectVersion?.project?.name}</Link>},
                        {
                            title: <>
                                {versions === undefined && <>{sbomProjectVersion?.name}</>}
                                {versions && <Select variant={"borderless"}
                                                     className={styles['breadcrumb-version']}
                                                     size={"small"}
                                                     defaultValue={sbomProjectVersionIdRef.current}
                                                     popupMatchSelectWidth={false}
                                                     onChange={value => navigate(`/sbom-projects/${sbomProject.data.id}/${value}${location.hash}`)}
                                                     options={versions?.data?.map(version => ({
                                                         label: version.name,
                                                         value: version.id
                                                     }))}
                                />}
                            </>
                        }
                    ]}
                />

                <Spin spinning={!sbomProjectVersion} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <Title
                        sbomProjectVersion={sbomProjectVersion}
                        usageModalOpen={usageModalOpen}
                        usageModalTitle={usageModalTitle}
                        usageGraphData={usageGraphData}
                        onClose={closeUsageModalCallback}
                    />

                    {(canRead || canManage) &&
                        <div className={globalStyles["common__top-button-bar"]}>
                            {canManage && <>
                                <Button onClick={() => setEditMode(!editMode)}>Edit</Button>

                                <Button danger={true}
                                        title={"Delete"}
                                        onClick={() => {
                                            Modal.confirm({
                                                content: "Do you really want to delete this SBOM project version?",
                                                okText: "Delete",
                                                cancelText: "Cancel",
                                                okButtonProps: {danger: true},
                                                onOk: () => onDeleteConfirm()
                                            });
                                        }}>Delete</Button>

                                <Upload accept="application/json,text/xml" showUploadList={false} customRequest={handleImport}>
                                    <Button icon={<UploadOutlined/>} style={{marginLeft: 8}}>Import SBOM</Button>
                                </Upload>
                            </>}

                            {canRead && sbomProjectVersion?.sbomImportedAt && <Button icon={<DownloadOutlined/>} onClick={onDownloadBom}>Download SBOM</Button>}

                            {(canAnalyze || canManage) && <>
                                <Button onClick={() => {
                                    Modal.confirm({
                                        title: "Evaluation of components",
                                        content: "Do you really want to re-evaluate vulnerability of components?",
                                        okText: "Evaluate",
                                        cancelText: "Cancel",
                                        onOk: () => evaluateComponents()
                                    });
                                }}>
                                    Evaluate components
                                </Button>
                            </>}

                            {(canAnalyze || canManage) && <Button onClick={() => setAdoptModalOpen(true)}>
                                Adopt analysis
                            </Button>}

                            {(canAnalyze || canManage) && sbomProjectVersion?.comparable && <>
                                <Button onClick={() => setCompareModalOpen(true)}>Compare</Button>
                            </>}
                        </div>
                    }

                    <EditForm sbomProjectVersion={sbomProjectVersion} editMode={editMode} onCancel={editCancelCallback} onFinish={editFinishCallback}/>

                    <ProjectVersionDetails sbomProjectVersion={sbomProjectVersion} canManage={canManage}/>

                    <ComponentTabs items={tabItems}/>
                </Spin>

                <AdoptAnalysisModal open={adoptModalOpen}
                                    sbomProjectId={sbomProjectVersion?.project?.id}
                                    sbomProjectVersionId={sbomProjectVersionIdRef.current}
                                    onClose={() => {
                                        setAdoptModalOpen(false);

                                        reload();
                                        reloadAllComponents();
                                    }}
                />

                <CompareModal open={compareModalOpen}
                              sbomProjectId={sbomProjectVersion?.project?.id}
                              sbomProjectVersionId={sbomProjectVersionIdRef.current}
                              onClose={() => {
                                  setCompareModalOpen(false);
                              }}
                />
            </>
        </DocumentTitle>
    );

    function renderExpandedComponent(sbomComponent: SbomComponent) {
        return <div className={styles['component-findings']}>
            <h2>Vulnerability items for {sbomComponent.name} {sbomComponent.version}</h2>

            {componentsFindings[sbomComponent.id]?.map(finding => {
                return <>
                    <Card className={sealScoreStyles['severity-border-' + finding.severity]}
                          title={<>
                              {(canAnalyze || canManage) && <Link to={`/vul-source-items/${finding.vulSourceItem?.id}`} style={{color: "black"}}>
                                  {finding.vulSourceItem?.attributes?.title || finding.vulSourceItem?.attributes?.description}
                              </Link>}
                              {(!canAnalyze && !canManage) && <span style={{color: "black"}}>{finding.vulSourceItem?.attributes?.title || finding.vulSourceItem?.attributes?.description}</span>}
                          </>}
                          extra={<>{formatDateTime(finding.vulSourceItem?.sourcePublished)}</>}>
                        <div className={`${tackStyles.tack} ${sealScoreStyles['severity-' + finding.severity]} ${styles['component-findings-tack']}`}><span className={tackStyles['tack-text']}>{finding.severity}</span></div>
                        <div>
                            <SourceTag source={finding?.vulSourceItem?.source}/>

                            <SealScore item={finding?.vulSourceItem}/>
                        </div>

                        <VulSourceItemDescription
                            sourceType={finding?.vulSourceItem.source.sourceType}
                            attributes={finding?.vulSourceItem?.attributes}
                            affected={finding?.vulSourceItem?.affected}
                            showTitle={false}
                            attributeDefinitions={attributeDefinitions}
                            hideSealScoreTag={true}
                        />

                        <FindingStatusCollapse sbomProject={sbomProject} finding={finding} onFindingStatusSave={onFindingStatusSave}/>
                    </Card>
                </>;
            })}
        </div>;
    }

    function renderName(value: any, sbomComponent: SbomComponent) {
        return <>
            {value}
            {sbomComponent.purl && <Tag title={`${sbomComponent.purl}`} color={"blue"} className={styles['component-tag']} onClick={event => {
                event.preventDefault();
                event.stopPropagation();

                navigator.clipboard.writeText(sbomComponent.purl);

                message.info("Package URL copied to clipboard.");
            }}>purl</Tag>}
            {sbomComponent.directDependency && <Tag title={"Component is a direct dependency of this project."}>direct</Tag>}
            {sbomComponent.duplicate && <Tag title={"Component is included in this project version multiple times."} color={"orange"}>duplicate</Tag>}
            {sbomComponent.cpe && <CopyToClipboard text={sbomComponent.cpe} onCopy={() => message.info("CPE name copied to clipboard.")}><Tag title={sbomComponent.cpe} color={"blue"} className={styles['component-tag']}>cpe</Tag></CopyToClipboard>}
            <Button size={"small"}
                    style={{marginLeft: 4}}
                    onClick={(e) => {
                        e.stopPropagation();

                        onShowUsagesClick(sbomComponent);
                    }} icon={<ApartmentOutlined/>}
                    title={"Show usages"}
            />
        </>;
    }

    function renderVersion(value: any, sbomComponent: SbomComponent) {
        let purl: PackageURL = null;
        let osiUrl: string = null;
        let latestOsiUrl: string = null;

        try {
            purl = PackageURL.fromString(sbomComponent.purl);

            osiUrl = packageUrlToOsiUrl(purl);

            if (sbomComponent.latestVersion) {
                purl.version = sbomComponent.latestVersion;

                latestOsiUrl = packageUrlToOsiUrl(purl);
            }
        } catch (e) {
            //
        }

        if (sbomComponent.latestVersion && sbomComponent.latestVersion !== sbomComponent.version) {
            if (purl != null) {
                return <><Link to={osiUrl} target={"_blank"} title={"Open at Open Source Insights"} onClick={e => e.stopPropagation()}>{value}</Link> <span style={{color: '#73d13d'}}> <ArrowUpOutlined title={"Update is available."}/> <Link to={latestOsiUrl} target={"_blank"}
                                                                                                                                                                                                                                                title={"Open at Open Source Insights"}
                                                                                                                                                                                                                                                onClick={e => e.stopPropagation()}>{sbomComponent.latestVersion}</Link></span></>;
            } else {
                return <>{value} <span style={{color: '#73d13d'}}><ArrowUpOutlined title={"Update is available."}/> {sbomComponent.latestVersion}</span></>;
            }
        } else {
            return <Link to={osiUrl} target={"_blank"} title={"Open at Open Source Insights"} onClick={e => e.stopPropagation()}>{value}</Link>;
        }
    }

    function renderPublishedAt(value: any, sbomComponent: SbomComponent) {
        if (sbomComponent.publishedAt === undefined) {
            return <i>unknown</i>;
        }

        const health = evaluateSbomComponentHealth(sbomComponent, new Date());

        let color, title;

        switch (health) {
            case SbomComponentHealth.HEALTHY:
                title = "Component is not older than two years.";
                color = green.primary;
                break;
            case SbomComponentHealth.OUT_OF_DATE:
                title = "Component is more than two years old.";
                color = orange.primary;
                break;
            case SbomComponentHealth.OBSOLETE:
                title = "Component is more than four years old.";
                color = red.primary;
                break;
        }

        return <span title={title} style={{color: color}}>
            {formatDate(sbomComponent.publishedAt)}
        </span>;
    }

    function renderLicense(license: SoftwareLicense) {
        if (license.origin === SoftwareLicenseOrigin.UNKNOWN) {
            return <div key={license.name}>{license.name}</div>;
        }

        return <div key={license.name}><Link to={`/software-licenses/${license.id}`} target={"_blank"}>{license.shortName}</Link></div>;
    }

    function renderLicenses(licenses: SoftwareLicense[], component: SbomComponent) {
        if (component.overridenLicenses.length > 0) {
            return <div className={styles['overriden-licenses']}>
                <div>{licenses.map(renderLicense)}</div>
                <div className={styles['overriden-licenses-tooltip']}>
                    <Popover title={"Licenses in SBOM"} content={component.overridenLicenses.map(renderLicense)}>
                        <SwapOutlined/>
                    </Popover>
                </div>
            </div>
        }

        return <>
            {licenses.map(renderLicense)}
        </>;
    }

    function renderType(value: any, sbomComponent: SbomComponent) {
        if (sbomComponent.purl?.startsWith("pkg:maven")) {
            return <><img src={"/img/Apache_Maven_logo.svg"} style={{width: 40}} title={value}/></>;
        } else if (sbomComponent.purl?.startsWith("pkg:nuget")) {
            return <><img src={"/img/NuGet_logo.svg"} style={{width: 24}} title={value}/></>;
        } else if (sbomComponent.purl?.startsWith("pkg:npm")) {
            return <><img src={"/img/Npm_logo.svg"} style={{width: 32}} title={value}/></>;
        } else if (sbomComponent.type === 'operating-system') {
            return <span title={"operating system"}>OS</span>
        } else {
            return <>{value}</>;
        }
    }

    function renderAnalysisStatistics(findingStatistics: any, sbomComponent: SbomComponent) {
        if (_.isEqual(findingStatistics, sbomComponent?.analysedFindingStatistics)) {
            return <>
                <FindingAnalysisStatistics analysisStatistics={sbomComponent?.analysisStatistics} style={AnalysisStyle.PROGRESS}/>
            </>;
        } else {
            return <>
                <FindingAnalysisStatistics analysisStatistics={sbomComponent?.analysisStatistics} style={AnalysisStyle.PROGRESS}/>
            </>;
        }
    }

    function renderStatistics(findingStatistics: any, sbomComponent: SbomComponent) {
        if (_.isEqual(findingStatistics, sbomComponent?.analysedFindingStatistics)) {
            return <>
                <FindingStatistics findingStatistics={findingStatistics}/>
            </>;
        } else {
            return <>
                <FindingStatistics findingStatistics={findingStatistics}/>
                <ArrowRightOutlined className={styles['analysis-arrow']} title={"Impact of analysis"}/>
                <FindingStatistics findingStatistics={sbomComponent?.analysedFindingStatistics}/>
            </>;
        }
    }

    function renderAction(value: any, component: SbomComponent) {
        const items: MenuProps['items'] = [
            {
                label: 'Override license',
                key: 'override-license'
            }
        ];

        const onClick: MenuProps['onClick'] = (e) => {
            e.domEvent.stopPropagation();

            if (e.key === 'override-license') {
                if (component.purl === undefined || component.purl === '') {
                    return;
                }

                const atIndex = component.purl.indexOf('@');

                const searchParams = new URLSearchParams();

                searchParams.set("purl", component.purl.substring(0, atIndex));
                searchParams.set("vers", component.version);

                if (appContext.user.isSystemAdmin()) {
                    searchParams.set("organizationId", sbomProjectVersion.project.organization.id);
                }

                window.open(`/software-license-overrides/add?${searchParams.toString()}`, "_blank");
            }
        };

        return (
            <>
                <Dropdown menu={{items, onClick}} trigger={["click"]}>
                    <Button icon={<MenuOutlined/>} onClick={e => e.stopPropagation()}/>
                </Dropdown>
            </>
        )
    }

    function renderComponents([filterValues, setFilterValues], debounceCallback: any, tableHandler: TableHandler, components: PagedResult<SbomComponent>, customFilterComponent?: any) {
        const filterComponent = (customFilterComponent !== undefined)
            ? customFilterComponent
            : <SbomProjectVersionComponentFilter
                className={styles['filter']}
                values={filterValues}
                onChange={values => {
                    setFilterValues(values);

                    debounceCallback(values);
                }}
                immediateMode={tableUiConfig.immediateMode}
                readOnly={false}
            />;

        return <>
            {filterComponent}

            <div className={`${globalStyles["common__top-button-bar"]} ${styles['button-bar']}`}>
                <Button className={"btn-seamless"} icon={<ReloadOutlined/>} title={"Reload"} onClick={tableHandler.reload}/>

                <TableConfig
                    columns={columns}
                    value={tableUiConfig}
                    onChange={uiConfig => {
                        setTableUiConfig(uiConfig);

                        tableHandler.saveUiConfig(uiConfig);
                    }}
                />
            </div>

            <Table className={styles.table}
                   showSorterTooltip={false}
                   loading={tableHandler.loading}
                   dataSource={components?.data}
                   size="middle"
                   onChange={tableHandler.onTableChange}
                   pagination={tableHandler.pagination}
                   rowKey="id"
                   expandable={{
                       showExpandColumn: true,
                       expandRowByClick: true,
                       rowExpandable: component => component.findingStatistics,
                       onExpand: onExpandComponent,
                       expandedRowRender: renderExpandedComponent
                   }}
                   columns={columns.filter(column => isColumnVisible(tableUiConfig.visibleColumns, column))}
            />
        </>;
    }

    function onShowUsagesClick(sbomComponent: SbomComponent) {
        sbomComponentService.getUnovisGraph(sbomComponent.id)
            .then(response => {
                if (response.status === 204) {
                    message.info("Usage graph is not available for this component.");

                    return;
                }

                setUsageModalTitle(`Usage of ${sbomComponent.bomRef}`);
                setUsageGraphData(response.data);
                setUsageModalOpen(true);
            })
    }

    function onExpandComponent(expanded: boolean, sbomComponent: SbomComponent) {
        if (expanded === false) {
            return;
        }

        if (componentsFindings[sbomComponent.id] === undefined) {
            reloadComponentFindings(sbomComponent.id);
        }
    }

    function onDownloadBom() {
        window.location.href = `/api/sbom-project-versions/${sbomProjectVersionIdRef.current}/sbom`;
    }

    function reloadComponentFindings(sbomComponentId: string) {
        sbomComponentService.getFindings(sbomComponentId)
            .then(findings => {
                setComponentsFindings(prevState => {
                    return {
                        ...prevState,
                        [sbomComponentId]: findings
                    }
                })
            })
            .catch(reason => {
                message.error("Cannot retrieve SBOM component findings.");
            })
    }

    function onFinishFilter(values: any) {
        tableHandler.onRqbSearchSubmit(createRqbQuery(values));
    }

    function onFinishVulnerableFilter(values: any) {
        vulnerableTableHandler.onRqbSearchSubmit(createRqbQuery(values));
    }

    function onFinishDuplicateFilter(values: any) {
        duplicateTableHandler.onRqbSearchSubmit(createRqbQuery(values));
    }

    function onFinishUnknownLicensesFilter(values: any) {
        unknownLicensesTableHandler.onRqbSearchSubmit(createRqbQuery(values));
    }

    function onFinishOutdatedFilter(values: any) {
        outdatedTableHandler.onRqbSearchSubmit(createRqbQuery(values));
    }

    function onDeleteConfirm() {
        sbomProjectVersionService.delete(sbomProjectVersion)
            .then(() => {
                message.success(<>SBOM project version <b>{sbomProjectVersion.name}</b> successfully deleted.</>);

                navigate(`/sbom-projects/${sbomProjectVersion.project.id}`)
            });
    }

    function onFindingStatusSave(finding: SbomComponentFinding) {
        reload().then(reloadAllComponents);

        sbomComponentService.getFindings(finding.component.id)
            .then(findings => {
                setComponentsFindings(prevState => {
                    return {
                        ...prevState,
                        [finding.component.id]: findings
                    }
                })
            })
            .catch(reason => {
                message.error("Cannot retrieve SBOM component findings.");
            })
    }

    function onEvaluationCompleted() {
        message.success('Evaluation of components completed.');

        reload().then(reloadAllComponents);
    }

    function onPublishedAtUpdateCompleted() {
        reloadAllComponents();
    }

    function reloadAllComponents() {
        tableHandler.reload();
        vulnerableTableHandler.reload();
        duplicateTableHandler.reload();
        unknownLicensesTableHandler.reload();
        outdatedTableHandler.reload();
    }

    function reload() {
        return sbomProjectVersionService.get(sbomProjectVersionIdRef.current).then(projectVersion => {
            setSbomProjectVersion(projectVersion);

            sbomProjectService.get(projectVersion.project.id).then(setSbomProject);

            console.log(location);

            if (location.hash === '#vulnerable' && projectVersion.componentsVulnerable === 0) {
                navigate(location.pathname, {replace: true});
            }

            if (location.hash === '#duplicate' && projectVersion?.componentsDuplicate === 0) {
                navigate(location.pathname, {replace: true});
            }

            if (location.hash === '#unknownLicenses' && projectVersion?.componentsUnknownLicenses === 0) {
                navigate(location.pathname, {replace: true});
            }

            if (location.hash === '#outdated' && projectVersion?.componentsOutdated === 0) {
                navigate(location.pathname, {replace: true});
            }
        });
    }

    function evaluateComponents() {
        sbomComponentService.evaluateComponents(sbomProjectVersionId)
            .then(() => {
                message.success("Evaluation of SBOM components queued successfully. Evaluation will be completed shortly.", 5);
            })
            .catch(() => {
                message.error("SBOM components evaluation failed.");
            })
    }

    function handleImport(params: any) {
        const {file, onSuccess, onError} = params;

        const reader = new FileReader();

        reader.onloadend = () => {
            if (typeof reader.result === "string") {
                sbomComponentService.importCycloneDxJsonBom(sbomProjectVersionId, reader.result, file.type)
                    .then(value => {
                        message.success("SBOM imported succesfully. Evaluation of vulnerable components will be completed shortly.", 5);

                        reload().then(reloadAllComponents);
                    })
                    .catch(() => {
                        message.error("Import of SBOM failed.");
                    })

            } else {
                message.error("Cannot load JSON document.");
            }
        };

        reader.readAsText(file, "UTF-8");
    }

    function reloadComponents() {
        return sbomComponentService.getList(sbomProjectVersionIdRef.current, tableHandler.queryOptions).then(components => {
            tableHandler.updateTotal(components.total);

            setSbomComponents(components);

            // reload already loaded findings

            Object.keys(componentsFindings).forEach(componentId => {
                sbomComponentService.getFindings(componentId)
                    .then(findings => {
                        setComponentsFindings(prevState => {
                            return {
                                ...prevState,
                                [componentId]: findings
                            }
                        })
                    })
                    .catch(() => {
                        message.error("Cannot retrieve SBOM component findings.");
                    })
            });
        });
    }

    function reloadVulnerableComponents() {
        return sbomComponentService.getList(sbomProjectVersionIdRef.current, vulnerableTableHandler.queryOptions).then(components => {
            vulnerableTableHandler.updateTotal(components.total);

            setVulnerableSbomComponents(components);
        });
    }

    function reloadDuplicateComponents() {
        return sbomComponentService.getList(sbomProjectVersionIdRef.current, duplicateTableHandler.queryOptions).then(components => {
            duplicateTableHandler.updateTotal(components.total);

            setDuplicateComponents(components);
        });
    }

    function reloadUnknownLicenseComponents() {
        return sbomComponentService.getList(sbomProjectVersionIdRef.current, unknownLicensesTableHandler.queryOptions).then(components => {
            unknownLicensesTableHandler.updateTotal(components.total);

            setUnknownLicensesComponents(components);
        });
    }

    function reloadOutdatedComponents() {
        return sbomComponentService.getList(sbomProjectVersionIdRef.current, outdatedTableHandler.queryOptions).then(components => {
            outdatedTableHandler.updateTotal(components.total);

            setOutdatedComponents(components);
        });
    }

}

const ProjectVersionDetails = memo(function ({sbomProjectVersion, canManage}: { sbomProjectVersion?: SbomProjectVersion, canManage?: boolean }) {
    return <div className={styles['details']}>
        <Descriptions column={1} bordered={true} size="small" className={`${globalStyles.details}`}>
            <Descriptions.Item label={"SBOM format"}>
                {formatSbomType(sbomProjectVersion?.sbomType, sbomProjectVersion?.sbomTypeVersion)}
            </Descriptions.Item>
            <Descriptions.Item label={"SBOM generated at"}>
                {formatDateTime(sbomProjectVersion?.sbomGeneratedAt) || <i>never</i>}
            </Descriptions.Item>
            <Descriptions.Item label={"SBOM imported at"}>
                {formatDateTime(sbomProjectVersion?.sbomImportedAt) || <i>never</i>}
            </Descriptions.Item>
            <Descriptions.Item label={"Last component evaluation"}>
                {formatDateTime(sbomProjectVersion?.componentEvaluatedAt) || <i>never</i>}
            </Descriptions.Item>
            <Descriptions.Item label={"Tags"}>
                {sbomProjectVersion?.tags?.map(value => <Tag key={value}>{value}</Tag>)}
            </Descriptions.Item>
            {canManage && <Descriptions.Item label={"Suppress in reports"}>
                <Checkbox checked={sbomProjectVersion?.suppressInReports} disabled={true}/>
            </Descriptions.Item>}
        </Descriptions>

        <Descriptions column={1} bordered={true} size="small" className={`${globalStyles.details}`}>
            <Descriptions.Item label={<>
                Licenses

                {sbomProjectVersion?.licenseViolations?.length > 0 && <>
                    <Tag className={styles['statistics-label']} style={{marginLeft: 8}} color={"orange"} title={"One or more licenses violates project's software license policy."}>policy violation</Tag>
                </>}
            </>}>
                <ProjectVersionLicenses sbomProjectVersion={sbomProjectVersion}/>
            </Descriptions.Item>
        </Descriptions>
    </div>;
});

interface TitleProps {
    sbomProjectVersion?: SbomProjectVersion;
    usageModalOpen: boolean;
    usageModalTitle: string;
    usageGraphData: any;
    onClose: () => void;
}

const Title = memo(function ({sbomProjectVersion, usageModalOpen, usageModalTitle, usageGraphData, onClose}: TitleProps) {
    const [selectedNode, setSelectedNode] = useState<any>();
    const visGraphRef = useRef<VisGraphRef<any, any>>();

    return <h1>
        {sbomProjectVersion?.project?.name}: <b>{sbomProjectVersion?.name}</b>

        <Modal open={usageModalOpen}
               title={usageModalTitle}
               onCancel={() => {
                   setSelectedNode(undefined);

                   onClose();
               }}
               destroyOnClose={true}
               width={1000}
               footer={
                   <Button type={"primary"}
                           onClick={() => {
                               setSelectedNode(undefined);

                               onClose();
                           }}>
                       Close
                   </Button>}
               style={{top: 50}}
        >
            <SelectedComponent selectedNode={selectedNode}/>

            <VisSingleContainer data={usageGraphData} style={{height: 'clamp(400px, 75vh, 1000px)'}}>
                <VisGraph
                    ref={visGraphRef}
                    layoutType={GraphLayoutType.Dagre}
                    dagreLayoutSettings={{
                        rankdir: 'TB',
                        ranker: 'network-simplex',
                        ranksep: 50
                    }}
                    nodeLabelTrim={false}
                    nodeSubLabelTrim={false}
                    nodeLabel={useCallback(n => {
                        try {
                            var purl = PackageURL.fromString(n.label);

                            return purl.name + " (" + purl.version + ")";
                        } catch (e) {
                            return n.label;
                        }
                    }, [])}
                    nodeSubLabel={useCallback(n => {
                        try {
                            var purl = PackageURL.fromString(n.label);

                            return purl.namespace;
                        } catch (e) {
                            return n.label;
                        }
                    }, [])}
                    linkStroke={useCallback(l => "#35D068", [])}
                    selectedNodeId={selectedNode?.id}
                    events={{
                        [Graph.selectors.node]: {
                            click: (d: GraphNode) => {
                                setSelectedNode(d);
                            }
                        },
                        [Graph.selectors.background]: {
                            click: (d: GraphNode) => {
                                setSelectedNode(undefined);
                            }
                        },
                    }}
                />
            </VisSingleContainer>
        </Modal>

        <div className={styles['statistics']}>
            {(sbomProjectVersion?.status === SbomProjectVersionStatus.WAITING_FOR_SBOM || sbomProjectVersion?.status === SbomProjectVersionStatus.WAITING_FOR_EVALUATION || sbomProjectVersion?.status === SbomProjectVersionStatus.NOT_VULNERABLE) && <>
                <Tag className={styles['statistics-label']} color={colorForSbomProjectVersionStatus(sbomProjectVersion.status)}>
                    {formatSbomProjectVersionStatus(sbomProjectVersion.status)}
                </Tag>
            </>}
            {_.isEqual(sbomProjectVersion?.findingStatistics, sbomProjectVersion?.analysedFindingStatistics) && <>
                <FindingStatistics findingStatistics={sbomProjectVersion?.findingStatistics}/>

                <FindingAnalysisStatistics analysisStatistics={sbomProjectVersion?.analysisStatistics}/>
            </>}
            {!_.isEqual(sbomProjectVersion?.findingStatistics, sbomProjectVersion?.analysedFindingStatistics) && <>
                <Popover title="State before analysis" content={<FindingStatistics findingStatistics={sbomProjectVersion.findingStatistics}/>} placement={"right"}>
                    <div style={{display: 'inline-block'}}>
                        <FindingStatistics findingStatistics={sbomProjectVersion.analysedFindingStatistics}/>
                    </div>
                </Popover>

                <FindingAnalysisStatistics analysisStatistics={sbomProjectVersion?.analysisStatistics}/>
            </>}
        </div>
    </h1>;
});

interface EditFormProps {
    sbomProjectVersion?: SbomProjectVersion;
    editMode: boolean;
    onCancel: () => void;
    onFinish: () => void;
}

const EditForm = memo(function ({sbomProjectVersion, editMode, onCancel, onFinish}: EditFormProps) {
    const sbomProjectVersionService = useContext(SbomProjectVersionServiceContext);
    const [editForm] = useForm();

    useEffect(() => {
        editForm.setFieldsValue(sbomProjectVersion);
    }, [sbomProjectVersion]);

    return <Collapse isOpen={editMode}>
        <h3>Edit of SBOM project version {sbomProjectVersion?.name} of project {sbomProjectVersion?.project?.name}</h3>

        <Form {...layout} form={editForm} className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`} onFinish={onFinishEdit}>
            <Form.Item
                name={"name"}
                label={"Name"}
                rules={[
                    {required: true, message: "Name is required."},
                    {validator: serverViolationsHolder.createServerValidator('UNIQUE', 'name, project_id', undefined, {compareLowerCaseValues: true}), message: "Name is already used."}
                ]}>
                <Input maxLength={100}/>
            </Form.Item>

            <Form.Item
                name={["tags"]}
                label={"Tags"}>
                <Select mode="tags"/>
            </Form.Item>

            <Form.Item
                name={"suppressInReports"}
                label={"Suppress in reports"}
                valuePropName={"checked"}>
                <Switch/>
            </Form.Item>

            <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                <Button onClick={onCancel}>{"Cancel"}</Button>
            </Form.Item>
        </Form>
    </Collapse>;

    function onFinishEdit(values: any) {
        sbomProjectVersionService.update(sbomProjectVersion.id, values)
            .then(
                () => {
                    message.success(<>SBOM project version <b>{values.name}</b> successfully updated.</>);

                    onFinish();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }
});

const ComponentTabs = function ({items}: { items: TabsProps['items'] }) {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("all");

    useEffect(() => {
        setActiveTab((location.hash !== '') ? location.hash.substring(1) : "all");
    }, [location]);

    return <Tabs
        style={{marginTop: 8}}
        items={items}
        animated={false}
        activeKey={activeTab}
        onChange={key => {
            setActiveTab(key);

            window.history.replaceState(null, '', `#${key}`)
        }}
    />;
}

export default SbomProjectVersionDetail;
