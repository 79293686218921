.days, .times {
    flex-direction: row;
    flex-wrap: wrap;
}

.days :global(.ant-checkbox-wrapper) {
    width: 120px;
    margin-inline-start: 0;
}

.times :global(.ant-checkbox-wrapper) {
    width: 80px;
    margin-inline-start: 0;
}
