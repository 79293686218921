.table {
    max-width: 1200px;
}

.extractors {
    margin: 0 96px 8px 24px;
}

.extractors h2 {
    margin-top: 0;
}
