import React, {useState} from "react";
import {Button, Checkbox, Col, InputNumber, Popover, Row, Space} from "antd";
import * as styles from "./TableConfig.module.css";
import {SettingOutlined} from "@ant-design/icons";
import {TableUiConfig} from "../../sal-ui/TableHandler";
import _ from "lodash";
import {ColumnType} from "antd/es/table";

interface TableConfigProps {
    columns: ColumnType<any>[];
    value: TableUiConfig;
    onChange: (values: TableUiConfig) => void;
}

export function TableConfig(props: TableConfigProps) {
    const [userMenuVisible, setUserMenuVisible] = useState(false);

    const columns: any = props.columns.map(column => ({
        label: column.title,
        value: _.isArray(column.dataIndex) ? column.dataIndex.join("_") : column.dataIndex
    }));

    const visibleColumns = props.value.visibleColumns.map(value => _.isArray(value) ? value.join("_") : value);

    return (
        <>
            <Popover trigger={"click"}
                     placement={"bottomLeft"}
                     open={userMenuVisible}
                     destroyTooltipOnHide={true}
                     onOpenChange={visible => setUserMenuVisible(visible)}
                     content={
                         <div className={styles.content}>
                             <Row>
                                 <Col span={11}>
                                     <div className={styles["section-title"]}>Table columns</div>

                                     <Checkbox.Group
                                         className={styles.columns}
                                         options={columns}
                                         value={visibleColumns}
                                         onChange={values => {
                                             props.onChange(Object.assign({}, props.value, {visibleColumns: values as string[]}))
                                         }}
                                     />
                                 </Col>
                                 <Col span={13}>
                                     <div className={styles["section-title"]}>Search</div>

                                     <Checkbox
                                         checked={props.value.immediateMode}
                                         onChange={e => {
                                             props.onChange(Object.assign({}, props.value, {immediateMode: e.target.checked}))
                                         }}>
                                         Immediate mode
                                     </Checkbox>
                                     <div className={styles["section-title"]}>Auto-refresh</div>

                                     <Space.Compact style={{alignItems: 'baseline'}}>
                                         <Checkbox
                                             style={{marginRight: '0.5em'}}
                                             checked={props.value.autoRefreshEnabled}
                                             onChange={e => {
                                                 props.onChange(Object.assign({}, props.value, {autoRefreshEnabled: e.target.checked}))
                                             }}
                                         />

                                         Refresh every
                                         <InputNumber className={styles['refresh-interval-input']}
                                                      defaultValue={180}
                                                      min={5}
                                                      max={3600}
                                                      step={15}
                                                      value={props.value.autoRefreshInterval}
                                                      onChange={value => {
                                                          props.onChange(Object.assign({}, props.value, {autoRefreshInterval: value}))
                                                      }}
                                                      size={"small"}/>
                                         seconds
                                     </Space.Compact>

                                 </Col>
                             </Row>
                         </div>
                     }>
                <Button icon={<SettingOutlined/>} className={"btn-seamless"} title={"Table configuration"}/>
            </Popover>
        </>
    )
}

export function isColumnVisible(visibleColumns: string[], column: ColumnType<any>) {
    return visibleColumns.includes(_.isArray(column.dataIndex) ? column.dataIndex.join("_") : column.dataIndex as string);
}
