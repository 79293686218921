import Axios from "axios";
import AttributeExtractor from "../domain/AttributeExtractor";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";

class AttributeExtractorService {

    readonly basePath = "/attribute-extractors";

    public add(extractor: AttributeExtractor): Promise<string> {
        return Axios.post(`${this.basePath}`, extractor).then(value => value.data.id)
    }

    public update(id: string, extractor: AttributeExtractor): Promise<void> {
        return Axios.put(`${this.basePath}/${id}`, extractor);
    }

    public get(id: string): Promise<AttributeExtractor> {
        return Axios.get(`${this.basePath}/${id}`)
            .then(value => AttributeExtractor.fromPOJO(value.data))
    }

    public delete(extractor: AttributeExtractor): Promise<void> {
        return Axios.delete(`${this.basePath}/${extractor.id}`);
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<AttributeExtractor>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: AttributeExtractor[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(AttributeExtractor.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }


}

export default AttributeExtractorService;
