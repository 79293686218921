import AttributeDefinition from "./AttributeDefinition";
import AttributeExtractor from "./AttributeExtractor";
import Organization from "./Organization";
import {ResourceOwnerType} from "./ResourceOwnerType";
import VulSource from "./VulSource";
import {VulSourceType} from "./VulSourceType";
import {AttributeExtractionSource} from "./AttributeExtractionSource";

class AttributeExtractionDefinition {
    constructor(
        public id?: string,
        public enabled?: boolean,
        public attributeDefinition?: AttributeDefinition,
        public owner?: ResourceOwnerType,
        public organization?: Organization,
        public sourceTypes?: VulSourceType[],
        public sources?: VulSource[],
        public extractionSource?: AttributeExtractionSource,
        public extractors?: AttributeExtractor[],
        public importedAt?: Date,
        public priority?: number
    ) {
        //

    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const entity = new AttributeExtractionDefinition();

        entity.id = data.id;
        entity.enabled = data.enabled;
        entity.owner = data.owner;
        entity.attributeDefinition = data.attributeDefinition;
        entity.organization = data.organization;
        entity.sourceTypes = data.sourceTypes;
        entity.sources = data.sources;
        entity.extractionSource = data.extractionSource;
        entity.extractors = data.extractors;
        entity.importedAt = (data.importedAt) ? new Date(data.importedAt) : undefined;
        entity.priority = data.priority;

        return entity;
    }


}

export default AttributeExtractionDefinition;
