import {Breadcrumb, Button, message, Modal, Table} from "antd";
import {TableUiConfig, useTableHandler} from "../../sal-ui/TableHandler";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {AppContextContext, SbomComponentServiceContext, SoftwareLicenseOverrideServiceContext} from "../../Contexts";
import {Link, useNavigate} from "react-router-dom";
import PagedResult from "../../service/PagedResult";
import * as styles from "./SoftwareLicenseOverrideList.module.css";
import * as globalStyles from "../App.module.css";
import {DeleteFilled, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {isColumnVisible, TableConfig} from "../tableconfig/TableConfig";
import {DocumentTitle} from "../DocumentTitle";
import {ColumnsType} from "antd/es/table";
import _ from "lodash";
import SoftwareLicenseOverrideFilter from "./SoftwareLicenseOverrideFilter";
import {SoftwareLicenseOrigin} from "../../domain/SoftwareLicenseOrigin";
import SoftwareLicenseOverride from "../../domain/SoftwareLicenseOverride";

const defaultVisibleColumns = ["organization", "purl", "vers", "licenses", "actions"];

const defaultTableUiConfig = {immediateMode: true, visibleColumns: defaultVisibleColumns};

function SoftwareLicenseOverrideList() {
    const persistentIdent = 'SoftwareLicenseOverrideList';
    const appContext = useContext(AppContextContext);
    const sbomComponentService = useContext(SbomComponentServiceContext);
    const licenseOverrideService = useContext(SoftwareLicenseOverrideServiceContext);
    const [licenseOverrides, setLicenseOverrides] = useState<PagedResult<SoftwareLicenseOverride>>();
    const [licenses, setLicenses] = useState<string[]>([]);
    const [filterValues, setFilterValues] = useState<any>();
    const [tableUiConfig, setTableUiConfig] = useState<TableUiConfig>(defaultTableUiConfig);
    const navigate = useNavigate();

    const debouncedOnFinishFilter = useCallback(_.debounce(onFinishFilter, 500), []);

    const tableHandler = useTableHandler("purl asc", {
        reloadFunction: reload,
        initialPageSize: 25,
        persistentIdent: `${persistentIdent}`
    });

    const columns: ColumnsType<SoftwareLicenseOverride> = [];

    if (appContext.user.isSystemAdmin()) {
        columns.push({
            dataIndex: "organization",
            title: "Organization",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: renderOrganization
        });
    }

    columns.push(
        {
            dataIndex: "purl",
            title: "Package URL",
            render: renderPurl
        },
        {
            dataIndex: "vers",
            title: "Version range",
        },
        {
            dataIndex: "licenses",
            title: "Licenses",
            render: licenses => licenses.map(license => {
                if (license.origin === SoftwareLicenseOrigin.UNKNOWN) {
                    return <div key={license.name}>{license.name}</div>;
                }

                return <div key={license.name}><Link to={`/software-licenses/${license.id}`} target={"_blank"}>{license.shortName}</Link></div>;
            })
        },
        {
            dataIndex: "actions",
            title: "Actions",
            width: 100,
            className: globalStyles["table-actions"],
            render: renderAction
        }
    );

    useEffect(() => {
        sbomComponentService.getLicenses().then(setLicenses);
    }, []);

    const title = `Software license overrides`;

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb
                    className={globalStyles["common__breadcrumb"]}
                    items={[
                        {title: appContext.config?.appName},
                        {title}
                    ]}
                />

                <h1>{title}</h1>

                <SoftwareLicenseOverrideFilter
                    className={styles['filter']}
                    values={filterValues}
                    onChange={values => {
                        setFilterValues(values);

                        debouncedOnFinishFilter(values);
                    }}
                    immediateMode={tableUiConfig.immediateMode}
                    readOnly={false}
                    licenses={licenses}
                />

                <div className={`${globalStyles["common__top-button-bar"]} ${styles['button-bar']}`}>
                    <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>

                    <TableConfig
                        columns={columns}
                        value={tableUiConfig}
                        onChange={uiConfig => {
                            setTableUiConfig(uiConfig);

                            tableHandler.saveUiConfig(uiConfig);
                        }}
                    />

                    <Button type={"primary"}
                            icon={<PlusOutlined/>}
                            onClick={() => navigate("/software-license-overrides/add")}>Add</Button>
                </div>

                <Table className={styles.table}
                       showSorterTooltip={false}
                       loading={tableHandler.loading}
                       dataSource={licenseOverrides?.data}
                       size="middle"
                       onChange={tableHandler.onTableChange}
                       pagination={tableHandler.pagination}
                       rowKey="id"
                       onRow={onRow}
                       expandable={{
                           showExpandColumn: true,
                           expandRowByClick: true,
                       }}
                       columns={columns.filter(column => isColumnVisible(tableUiConfig.visibleColumns, column))}
                />
            </>
        </DocumentTitle>
    );

    function renderOrganization(value: any, override: SoftwareLicenseOverride) {
        return <>
            <Link to={`/organizations/${override.organization.id}`}>{override.organization.name}</Link>
        </>;
    }

    function renderPurl(value: any, override: SoftwareLicenseOverride) {
        return <>
            <Link to={`/software-license-overrides/${override.id}`}>{override.purl}</Link>
        </>;
    }

    function renderAction(value: any, override: SoftwareLicenseOverride) {
        return (
            <>
                <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                        title={"Delete"}
                        onClick={() => {
                            Modal.confirm({
                                content: <>Do you really want to delete license override for <b>{override.purl}</b>?</>,
                                okText: "Delete",
                                cancelText: "Cancel",
                                onOk: () => onDeleteConfirm(override)
                            });
                        }}/>
            </>
        )
    }

    function onDeleteConfirm(override: SoftwareLicenseOverride) {
        licenseOverrideService.delete(override)
            .then(() => {
                message.success(<>Software license override for <b>{override.purl}</b> successfully deleted.</>);

                reload();
            });
    }

    function onRow(override: SoftwareLicenseOverride, index?: number) {
        return {onDoubleClick: () => navigate(`/software-license-overrides/${override.id}`)}
    }

    function createRqbQuery(values: any) {
        const rqbQuery = {
            combinator: "and",
            rules: []
        }

        values.licenses && values.licenses.length > 0 && rqbQuery.rules.push({
            field: "licenses",
            operator: "arrayIn",
            value: values.licenses
        });

        values.purl && rqbQuery.rules.push({
            field: "purl",
            operator: "icontains",
            value: values.purl
        });

        return rqbQuery;
    }

    function onFinishFilter(values: any) {
        tableHandler.onRqbSearchSubmit(createRqbQuery(values));
    }

    function reload() {
        return licenseOverrideService.getList(tableHandler.queryOptions).then(overrides => {
            tableHandler.updateTotal(overrides.total);

            setLicenseOverrides(overrides);
        });
    }
}

export default SoftwareLicenseOverrideList;
