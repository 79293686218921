import Axios from "axios";
import VulReportDefinition from "../domain/VulReportDefinition";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";

class VulReportDefinitionService {

    readonly basePath = "/vul-report-definitions";

    public add(definition: VulReportDefinition): Promise<string> {
        return Axios.post(`${this.basePath}`, definition).then(value => value.data.id)
    }

    public update(vulReportDefinitionId: string, definition: VulReportDefinition): Promise<void> {
        return Axios.put(`${this.basePath}/${vulReportDefinitionId}`, definition);
    }

    public get(id: string): Promise<VulReportDefinition> {
        return Axios.get(`${this.basePath}/${id}`)
            .then(value => VulReportDefinition.fromPOJO(value.data))
    }

    public delete(definition: VulReportDefinition): Promise<void> {
        return Axios.delete(`${this.basePath}/${definition.id}`);
    }

    public getListOfUsedForView(queryOptions: QueryOptions, viewId: string): Promise<PagedResult<VulReportDefinition>> {
        return Axios.get(`${this.basePath}/for-view/${viewId}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: VulReportDefinition[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(VulReportDefinition.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<VulReportDefinition>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: VulReportDefinition[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(VulReportDefinition.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

}

export default VulReportDefinitionService;
