import * as globalStyles from "../App.module.css";
import {syntaxHighlight} from "../../utils/FormatUtils";
import {Filter, Interweave} from "interweave";
import ItemAttribute from "./ItemAttribute";
import React from "react";
import AttributeDefinition from "../../domain/AttributeDefinition";
import AttributeBox from "./AttributeBox";
import {VulSourceType} from "../../domain/VulSourceType";
import {HashtagMatcher} from "interweave-autolink";
import * as styles from "./VulSourceItemDescription.module.css";
import _ from "lodash";
import MoreLess from "../common/MoreLess";
import ReactMarkdown from "react-markdown";
import {Descriptions} from "antd";
import 'property-information';

class ImgFilter extends Filter {
    // @ts-ignore
    attribute(name: string, value: string): string {
        return value;
    }

    node(name: string, node: HTMLElement): HTMLElement {
        if (name === 'img') {
            return null;
        }

        return node;
    }
}

class WidthFilter extends Filter {
    // @ts-ignore
    attribute(name: string, value: string): string {
        if (name === "width") {
            return null;
        }

        return value;
    }

    node(name: string, node: HTMLElement): HTMLElement {
        return node;
    }
}

interface Props {
    sourceType: VulSourceType;
    attributes?: any;
    affected?: any;
    attributeDefinitions?: AttributeDefinition[];
    showDescription?: boolean;
    showAttributes?: boolean;
    showAffected?: boolean;
    showTitle?: boolean;
    hideSealScoreTag?: boolean;
    hideLink?: boolean;
    attributeLayout?: "horizontal" | "vertical"
}

function VulSourceItemDescription(props: Props) {
    const attributes = props.attributes;

    if (!attributes?.title && !attributes?.description) {
        return <div className={globalStyles["formatted-json"]} dangerouslySetInnerHTML={{__html: `${attributes && syntaxHighlight(attributes)}`}}/>
    }

    let interweaveProps = {};

    switch (props.sourceType) {
        case VulSourceType.TWITTER:
            interweaveProps = {
                ...interweaveProps,
                matchers: [new HashtagMatcher('hashtag')],
                hashtagUrl: "https://twitter.com/hashtag/{{hashtag}}"
            }
            break;
        case VulSourceType.RSS:
            interweaveProps = {
                ...interweaveProps,
                filters: [new ImgFilter(), new WidthFilter()]
            }
            break;
    }

    const definitions: any = {};

    props.attributeDefinitions?.forEach(defition => definitions[defition.name] = defition);

    return (
        <div className={styles.content}>
            {renderDescription()}
            {(props.showAffected === undefined || props.showAffected === true) && renderAffected()}
            {renderAttributes()}
        </div>
    );

    function renderDescription() {
        if (props.showDescription === undefined || props.showDescription === true) {
            return <>
                {attributes.title && (props.showTitle === undefined || props.showTitle === true) && attributes.description && <div><b>{attributes.title}</b></div>}
                {attributes.title && (props.showTitle === undefined || props.showTitle === true) && !attributes.description && <div>{attributes.title}</div>}
                <MoreLess textMore={"« show full description »"} textLess={"» hide full description «"}>
                    {attributes.description && props.sourceType !== VulSourceType.OSV && <div><Interweave content={attributes.description} {...interweaveProps} /></div>}
                    {attributes.description && props.sourceType === VulSourceType.OSV && <div><ReactMarkdown children={attributes.description}/></div>}
                </MoreLess>
            </>
        }
    }

    function renderAttributes() {
        if (props.showAttributes === undefined || props.showAttributes === true) {
            return <>
                {(attributes.link || !_.isEmpty(attributes)) && <>
                    <div className={`${styles.attributes} ${(props.attributeLayout === 'vertical') ? styles['attributes-vertical'] : ''}`}>
                        {!props.hideLink && attributes.link && <AttributeBox value={"External link"} href={attributes.link} showSearch={false}/>}
                        {Object.keys(props.attributes).filter(key => !props.hideSealScoreTag || key !== 'sealScore').map(key => {
                            return <ItemAttribute key={key} definition={definitions[key]} value={attributes[key]}/>
                        })}
                    </div>
                </>}
            </>
        }
    }

    function renderAffected() {
        if (props.affected === undefined || props.affected === null) {
            return;
        }

        return <>
            <b>Affects:</b>
            <Descriptions column={1} className={styles['affected-packages']} bordered={false}>
                {props.affected.map(item => <Descriptions.Item key={item?.package?.purl} label={<span title={item?.package?.purl}>{item?.package?.name}{item?.package?.ecosystem ? ` (${item?.package?.ecosystem})` : ''}</span>}>
                    {createAffectsVersionText(item)}
                </Descriptions.Item>)}
            </Descriptions>
        </>
    }

    function createAffectsVersionText(affectedPackage: any) {
        if (affectedPackage.ranges === undefined) {
            return <>{affectedPackage?.versions?.sort()?.join(', ')}</>;
        }

        const ranges = [];

        for (const range of affectedPackage.ranges) {
            let tokens = [];

            for (const event of range.events) {
                const introduced = event['introduced'];
                const introducedZero = introduced !== undefined && introduced === "0";
                const fixed = event['fixed'];
                const lastAffected = event['last_affected'];

                if (introduced != undefined) {
                    if (!introducedZero) {
                        tokens.push(`>= ${introduced}`);
                    }
                }

                if (fixed !== undefined) {
                    tokens.push(`< ${fixed}`);
                }

                if (lastAffected !== undefined) {
                    if (tokens.length !== 0 && tokens[tokens.length - 1] === `>= ${lastAffected}`) {
                        tokens.pop();

                        tokens.push(`${lastAffected}`);
                    } else {
                        tokens.push(`<= ${lastAffected}`);
                    }
                }
            }

            ranges.push(tokens.join(", "));
        }

        return <>{ranges.join(", ")}</>;
    }

}

export default VulSourceItemDescription;
