export enum ResourceOwnerType {
    SYSTEM = "SYSTEM",
    ORGANIZATION = "ORGANIZATION"
}

export function getEqualOrGreaterSeverities(severity: ResourceOwnerType) {
    const index = Object.keys(ResourceOwnerType).indexOf(severity);

    return Object.keys(ResourceOwnerType).filter((value, idx) => idx >= index);
}
