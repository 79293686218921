import {PackageURL} from "packageurl-js";
import {packageUrlToOsiUrl} from "../sbomcomponent/functions";
import {Card, Descriptions} from "antd";
import * as styles from "./SbomProjectVersionDetail.module.css";
import {Link} from "react-router-dom";
import React from "react";

function SelectedComponent(props: { selectedNode: any }) {
    if (!props.selectedNode) {
        return;
    }

    let purl: PackageURL = null;

    try {
        purl = PackageURL.fromString(props.selectedNode.purl);
    } catch (e) {
        return;
    }

    if (purl.type === 'maven') {
        const osiUrl = packageUrlToOsiUrl(purl);

        return (
            <Card title={"Selected component (Apache Maven Artifact)"} size={"small"} className={styles['graph-card']}>
                <Descriptions column={1}>
                    <Descriptions.Item label={"Group"}>{purl.namespace}</Descriptions.Item>
                    <Descriptions.Item label={"Artifact"}>{purl.name}</Descriptions.Item>
                    <Descriptions.Item label={"Version"}>{purl.version}</Descriptions.Item>
                </Descriptions>

                <br/>

                <div>View at <Link to={osiUrl} target={"_blank"}>Open Source Insights</Link></div>
            </Card>
        );
    } else {
        const osiUrl = packageUrlToOsiUrl(purl);

        return (
            <Card title={`Selected component (${purl.type})`} size={"small"} className={styles['graph-card']}>
                <Descriptions column={1}>
                    <Descriptions.Item label={"Name"}>{(purl.namespace) ? purl.namespace + '/' : ''}{purl.name}</Descriptions.Item>
                    <Descriptions.Item label={"Version"}>{purl.version}</Descriptions.Item>
                </Descriptions>

                <br/>

                <div>View at <Link to={osiUrl} target={"_blank"}>Open Source Insights</Link></div>
            </Card>
        );
    }
}

export default SelectedComponent;