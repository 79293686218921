import Axios from "axios";
import User from "../domain/User";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";

class UserService {

    readonly basePath = "/users";

    public add(user: User): Promise<string> {
        return Axios.post(`${this.basePath}`, user).then(value => value.data.id)
    }

    public update(userId: string, user: User): Promise<void> {
        return Axios.put(`${this.basePath}/${userId}`, user);
    }

    public updatePublicAccessAccount(userId: string, publicAccessAccount: boolean): Promise<void> {
        return Axios.put(`${this.basePath}/${userId}/public-access-account`, JSON.stringify(publicAccessAccount));
    }

    public setPassword(userId: string, password: string): Promise<void> {
        return Axios.put(`${this.basePath}/${userId}/set-password`, {password});
    }

    public get(id: string): Promise<User> {
        return Axios.get(`${this.basePath}/${id}`)
            .then(value => User.fromPOJO(value.data))
    }

    public delete(user: User): Promise<void> {
        return Axios.delete(`${this.basePath}/${user.id}`);
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<User>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: User[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(User.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public searchByUsername(username: string): Promise<PagedResult<User>> {
        return Axios.get(`${this.basePath}/search?username=${username}`)
            .then(value => {
                const objectList: User[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(User.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }
}

export default UserService;
