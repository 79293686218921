import {LoadingOutlined} from "@ant-design/icons";
import Collapse from "@kunukn/react-collapse";
import {Breadcrumb, Button, Checkbox, Descriptions, Form, Input, InputNumber, message, Modal, Select, Skeleton, Spin, Switch, Table, Tag} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, TagServiceContext, VulSourceServiceContext, VulViewServiceContext} from "../../Contexts";
import Organization from "../../domain/Organization";
import {ResourceOwnerType} from "../../domain/ResourceOwnerType";
import VulSource from "../../domain/VulSource";
import {formatVulSourceType, VulSourceType} from "../../domain/VulSourceType";
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {formatDateTime, syntaxHighlight} from "../../utils/FormatUtils";
import *as globalStyles from "../App.module.css";
import {DocumentTitle} from "../DocumentTitle";
import "./VulSourceDetail.module.css";
import {formatRssGuidSource, RssGuidSource} from "../../domain/RssGuidSource";
import * as styles from "./VulSourceDetail.module.css";
import {useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";
import VulView from "../../domain/VulView";
import {ColumnsType} from "antd/es/table";
import ReadOnlyQueryView from "../vulview/ReadOnlyQueryView";
import TextArea from "antd/lib/input/TextArea";
import ReactMarkdown from "react-markdown";
import {Permission} from "../../domain/auth/Permission";
import {formatVulSourceHealth, VulSourceHealth} from "../../domain/VulSourceHealth";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function VulSourceDetail() {
    const persistentIdent = 'VulSourceDetailViews';
    const appContext = useContext(AppContextContext);
    const vulSourceService = useContext(VulSourceServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const vulViewService = useContext(VulViewServiceContext);
    const tagService = useContext(TagServiceContext);
    const navigate = useNavigate();
    const {vulSourceId}: any = useParams();
    const [editMode, setEditMode] = useState(false);
    const [source, setSource] = useState<VulSource>();
    const [editForm] = useForm();
    const [owner, setOwner] = useState<ResourceOwnerType>(ResourceOwnerType.SYSTEM);
    const [organizations, setOrganizations] = useState<Organization[]>();
    const [views, setViews] = useState<PagedResult<VulView>>();
    const [sources, setSources] = useState<VulSource[]>();
    const [tags, setTags] = useState<string[]>();

    const tableHandler = useTableHandler("name asc", {
        reloadFunction: reloadViews,
        initialPageSize: 25,
        persistentIdent: `${persistentIdent}`
    });

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 20},
    };

    const tailLayout = {
        wrapperCol: {offset: 8, span: 20},
    };

    const columns: ColumnsType<VulView> = [
        {
            dataIndex: "name",
            title: "Name",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "ascend",
            render: (value, vulView) => <>
                <Link to={`/vul-source-items/views/${vulView.id}`}>{value}</Link>

                <ReactMarkdown className={`${globalStyles.markdown} ${styles['view-description']}`} children={vulView.description}/>
            </>
        },
        {
            dataIndex: "query",
            title: "Query",
            width: 600,
            render: value => <ReadOnlyQueryView sources={sources} query={JSON.parse(value)}/>
        },
        {
            dataIndex: ["organization", "name"],
            title: "Organization",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            align: "left",
            render: renderOrganization
        },
    ];

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        vulSourceService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setSources(value.data));

        if (appContext.user.isSystemAdmin()) {
            organizationService.getList(new QueryOptions("name", 100, 0)).then(value => {
                setOrganizations(value.data);
            });

            tagService.getList().then(setTags);
        }

        reload();

        if (appContext.user.hasOneOfPermission([Permission.VIEW__READ_ORG, Permission.VIEW__READ_SEAL])) {
            reloadViews();
        }
    }, [vulSourceId])

    if (!source) return <Skeleton/>;

    return (
        <DocumentTitle title={source.name}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/vul-sources"}>Vulnerability sources</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{source.name}</Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={!source} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{source.name}</h1>

                    {(appContext.user.isSystemAdmin() || (source.owner === ResourceOwnerType.ORGANIZATION && appContext.user.hasPermission(Permission.SOURCE__MANAGE_ORG))) &&
                        <div className={globalStyles["common__top-button-bar"]}>
                            <Button onClick={() => setEditMode(!editMode)}>Edit</Button>

                            <Button danger={true}
                                    title={"Delete"}
                                    onClick={() => {
                                        Modal.confirm({
                                            content: "Do you really want to delete this source?",
                                            okText: "Delete",
                                            cancelText: "Cancel",
                                            okButtonProps: {danger: true},
                                            onOk: () => onDeleteConfirm(source!)
                                        });
                                    }}>Delete
                            </Button>

                            {(appContext.user.isSystemAdmin() || source.owner === ResourceOwnerType.ORGANIZATION) &&
                                <Button
                                    onClick={() => {
                                        Modal.confirm({
                                            content: "Do you really want to collect vulnerability items for this source?",
                                            okText: "Collect",
                                            cancelText: "Cancel",
                                            onOk: () => onRunConfirm(source)
                                        });
                                    }}>
                                    Collect
                                </Button>
                            }

                            {appContext.user.isSystemAdmin() &&
                                <>
                                    <Button
                                        onClick={() => {
                                            Modal.confirm({
                                                content: "Do you really want to update vulnerability items in OpenSearch?",
                                                okText: "Update",
                                                cancelText: "Cancel",
                                                onOk: () => onUpdateInOpenSearchConfirm(source)
                                            });
                                        }}>
                                        Update in OpenSearch
                                    </Button>

                                    <Button
                                        danger={true}
                                        onClick={() => {
                                            Modal.confirm({
                                                content: "Do you really want to purge items for this source? All items will be deleted (without audit log).",
                                                okText: "Purge",
                                                cancelText: "Cancel",
                                                onOk: () => onPurgeConfirm(source),
                                            });
                                        }}>
                                        Purge
                                    </Button>
                                </>
                            }
                        </div>
                    }

                    {renderEditForm()}

                    <div className={styles['details']}>
                        <Descriptions column={1} bordered={true} size="small" className={globalStyles.details} title={"Source properties"}>
                            {source.owner === ResourceOwnerType.ORGANIZATION && <Descriptions.Item label={"Owner"}>{source.organization?.name}</Descriptions.Item>}
                            {source.owner === ResourceOwnerType.SYSTEM && <Descriptions.Item label={"Owner"}><i>System source</i></Descriptions.Item>}

                            <Descriptions.Item label={"Description"}>
                                {source?.description && <ReactMarkdown className={globalStyles.markdown} children={source?.description}/>}
                                {!source?.description && <i>no description</i>}
                            </Descriptions.Item>

                            <Descriptions.Item label={"Type"}>{formatVulSourceType(source.sourceType)}</Descriptions.Item>

                            {source.sourceType === VulSourceType.NVD20 &&
                                <Descriptions.Item label={"API key"}>{source.apiKey}</Descriptions.Item>
                            }

                            {source.sourceType === VulSourceType.RSS &&
                                <>
                                    <Descriptions.Item label={"URL"}>{source.url}</Descriptions.Item>
                                    <Descriptions.Item label={"GUID source"}>{formatRssGuidSource(source.guidSource)}</Descriptions.Item>
                                    <Descriptions.Item label={"User agent"}>{source.userAgent || <i>default</i>}</Descriptions.Item>
                                </>
                            }

                            {source.sourceType === VulSourceType.TWITTER &&
                                <Descriptions.Item label={"Username"}>{source.username}</Descriptions.Item>
                            }

                            <Descriptions.Item label={"Enabled"}><Checkbox checked={source.enabled} disabled={true}/></Descriptions.Item>
                            <Descriptions.Item label={"Last collection"}>{formatDateTime(source.lastRun)}</Descriptions.Item>
                            <Descriptions.Item label={"Latest item published at"}>{formatDateTime(source.latestItemPublishedAt)}</Descriptions.Item>
                            <Descriptions.Item label={"Health status"}>
                                {source.health === VulSourceHealth.HEALTHY && <Tag color="green">{formatVulSourceHealth(source.health)}</Tag>}
                                {source.health === VulSourceHealth.LAST_RUN_OUT_OF_DATE && <Tag color="red">{formatVulSourceHealth(source.health)}</Tag>}
                                {source.health === VulSourceHealth.LATEST_ITEM_PUBLISHED_AT_OUT_OF_DATE && <Tag color="orange">{formatVulSourceHealth(source.health)}</Tag>}
                            </Descriptions.Item>
                            <Descriptions.Item label={"Items"}>{renderItemCount()}</Descriptions.Item>
                        </Descriptions>

                        {appContext.user.isSystemAdmin() && <>
                            <Descriptions column={1} bordered={true} size="small" className={globalStyles.details} title={"System (hidden) properties"}>
                                <Descriptions.Item label={"Tags"}>{source.tags?.map(value => <Tag>{value}</Tag>)}</Descriptions.Item>
                                <Descriptions.Item label={"Imported at"}>{formatDateTime(source.importedAt)}</Descriptions.Item>
                                <Descriptions.Item label={"Initial collection done"}><Checkbox checked={source.initialCollectionDone} disabled={true}/>{source.initialCollectionDoneAt && <span style={{marginLeft: 8}}>{formatDateTime(source.initialCollectionDoneAt)}</span>}</Descriptions.Item>
                                <Descriptions.Item label={"Poll state"}>
                                    <div className={globalStyles["formatted-json"]} dangerouslySetInnerHTML={{__html: `${source?.pollState && syntaxHighlight(source.pollState)}`}}/>
                                </Descriptions.Item>
                            </Descriptions>
                        </>}
                    </div>

                    {appContext.user.hasOneOfPermission([Permission.VIEW__READ_ORG, Permission.VIEW__READ_SEAL]) && <>
                        <h2>Usage in views</h2>

                        <Table className={styles.table}
                               showSorterTooltip={false}
                               dataSource={views?.data}
                               locale={{emptyText: 'This source is not used in any view.'}}
                               size="middle"
                               onChange={tableHandler.onTableChange}
                               pagination={tableHandler.pagination}
                               rowKey="id"
                               columns={columns}
                        />
                    </>}
                </Spin>
            </>
        </DocumentTitle>
    )

    function onRunConfirm(vulSource: VulSource) {
        vulSourceService.run(vulSource)
            .then(() => {
                message.success("Collection of vulnerability items started.");

                reload();
            });
    }

    function onPurgeConfirm(vulSource: VulSource) {
        vulSourceService.purge(vulSource)
            .then(() => {
                message.success("All items purged successfully.");

                reload();
            });
    }

    function onUpdateInOpenSearchConfirm(vulSource: VulSource) {
        vulSourceService.updateInOpenSearch(vulSource)
            .then(() => {
                message.success("Vulnerability items updated in OpenSearch successfully.");

                reload();
            });
    }

    function renderEditForm() {
        if (!source) return <Skeleton/>;

        return (
            <Collapse isOpen={editMode}>
                <h3>Edit of vulnerability source</h3>

                <Form {...layout} form={editForm} className={`${globalStyles["common__form"]} globalStyles["common_form--edit"]}`} onFinish={onFinishEdit}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                            {validator: serverViolationsHolder.createServerValidator('UNIQUE', 'name', undefined, {compareLowerCaseValues: true}), message: "name-not-unique"}
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"description"}
                        label={"Description"}
                        extra={"Description of vulnerability source in CommonMark format."}>
                        <TextArea maxLength={1000} autoSize={{minRows: 4, maxRows: 8}}/>
                    </Form.Item>

                    {source.sourceType === VulSourceType.NVD20 &&
                        <Form.Item
                            name={"apiKey"}
                            label={"API key"}
                            rules={[
                                {required: true, message: "API key is required."},
                            ]}>
                            <Input maxLength={100}/>
                        </Form.Item>
                    }

                    {source.sourceType === VulSourceType.RSS &&
                        <>
                            <Form.Item
                                name={"url"}
                                label={"URL"}
                                extra={"HTTP or HTTPS address of Atom / RSS feed."}
                                rules={[
                                    {required: true, message: "URL is required."},
                                ]}>
                                <Input maxLength={1000}/>
                            </Form.Item>

                            <Form.Item
                                name={["guidSource"]}
                                label={"GUID source"}
                                extra={"RSS item attribute used for deduplication."}
                                rules={[{required: true, message: "GUID source is required."}]}>
                                <Select
                                    options={[
                                        {label: formatRssGuidSource(RssGuidSource.GUID), value: 'GUID'},
                                        {label: formatRssGuidSource(RssGuidSource.LINK), value: 'LINK'},
                                        {label: formatRssGuidSource(RssGuidSource.TITLE), value: 'TITLE'},
                                        {label: formatRssGuidSource(RssGuidSource.DESCRIPTION), value: 'DESCRIPTION'},
                                    ]}
                                />
                            </Form.Item>

                            <Form.Item
                                name={"userAgent"}
                                label={"User agent"}
                                extra={"Optional content of User-Agent HTTP header."}>
                                <Input maxLength={1000}/>
                            </Form.Item>
                        </>
                    }

                    {source.sourceType === VulSourceType.TWITTER &&
                        <Form.Item
                            name={"username"}
                            label={"Username"}
                            rules={[
                                {required: true, message: "Username is required."},
                            ]}>
                            <Input maxLength={100}/>
                        </Form.Item>
                    }

                    {source.sourceType === VulSourceType.CISCO_OPENVULN && <>
                        <Form.Item
                            name={"apiKey"}
                            label={"API key"}
                            rules={[
                                {required: true, message: "API key is required."},
                            ]}>
                            <Input maxLength={100}/>
                        </Form.Item>

                        <Form.Item
                            name={"apiClientSecret"}
                            label={"API client secret"}
                            rules={[
                                {required: true, message: "API client secret is required."},
                            ]}>
                            <Input maxLength={100}/>
                        </Form.Item>
                    </>
                    }

                    {appContext.user.isSystemAdmin() && <>
                        <Form.Item
                            name={["owner"]}
                            label={"Owner"}
                            rules={[{required: true, message: "Owner is required."}]}>
                            <Select onChange={value => setOwner(value)}>
                                <Select.Option key={ResourceOwnerType.SYSTEM} value={ResourceOwnerType.SYSTEM}>system</Select.Option>
                                <Select.Option key={ResourceOwnerType.ORGANIZATION} value={ResourceOwnerType.ORGANIZATION}>organization</Select.Option>
                            </Select>
                        </Form.Item>

                        {owner === ResourceOwnerType.ORGANIZATION &&
                            <Form.Item
                                name={"organization"}
                                label={"Organization"}
                                rules={[{required: source.owner !== ResourceOwnerType.SYSTEM, message: "Organization is required."}]}>

                                <Select allowClear={true}>
                                    {organizations && organizations!.map(organization => {
                                        return <Select.Option key={organization?.name} value={organization?.id!}>{organization?.name}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        }
                    </>}

                    {appContext.user.isSystemAdmin() && <Form.Item
                        name={["tags"]}
                        label={"Tags"}>
                        <Select mode="tags" options={tags?.map(tag => ({value: tag, label: tag}))}/>
                    </Form.Item>}

                    <Form.Item
                        name={["enabled"]}
                        label={"Enabled"}
                        extra={"Source is polled periodically if enabled."}
                        valuePropName={"checked"}>
                        <Switch/>
                    </Form.Item>

                    {appContext.user.isSystemAdmin() && <>
                        <Form.Item
                            name={["initialCollectionDone"]}
                            label={"Initial collection done"}
                            valuePropName={"checked"}>
                            <Switch/>
                        </Form.Item>
                    </>}

                    <Form.Item
                        name={"maxLastRunAge"}
                        label={"Max. last collection age"}
                        extra={"Duration in hours since the last collection that is still considered healthy."}
                        initialValue={24}
                        rules={[
                            {required: true, message: "Max. last collection age is required."},
                        ]}>
                        <InputNumber min={6} max={87600}/>
                    </Form.Item>

                    <Form.Item
                        name={"maxLatestItemPublishedAtAge"}
                        label={"Max. latest published item age"}
                        extra={"Duration in hours since the latest published item that is still considered healthy."}
                        initialValue={168}
                        rules={[
                            {required: true, message: "Max. latest published item age is required."},
                        ]}>
                        <InputNumber min={6} max={87600}/>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => setEditMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function renderItemCount() {
        const filter: any = {
            typeHints: {},
            values: {
                sources: [source.id]
            }
        }

        const searchParams = new URLSearchParams();

        searchParams.set("filter", window.btoa(JSON.stringify(filter)))
        searchParams.set("$readOnly", "");

        return <a href={`/vul-source-items?${searchParams.toString()}`} target={"_blank"}>{source.itemCount}</a>;
    }

    function renderOrganization(value: any, vulView: VulView) {
        if (vulView.owner === ResourceOwnerType.SYSTEM) {
            return <i>System view</i>;
        } else {
            return <Link to={`/organizations/${vulView.organization.id}`}>{value}</Link>;
        }
    }

    function onFinishEdit(values: any) {
        vulSourceService.update(vulSourceId!, values)
            .then(
                (id) => {
                    message.success("Vulnerability source updated.");

                    setEditMode(false);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }

    function onDeleteConfirm(vulSource: VulSource) {
        vulSourceService.delete(vulSource)
            .then(() => {
                message.success("Vulnerability source deleted.");

                navigate(`/vul-sources`)
            });
    }

    function reload() {
        vulSourceService.get(vulSourceId!).then(vulSource => {
            setSource(vulSource);

            editForm.setFieldsValue({
                ...vulSource,
                organization: vulSource.organization?.id
            });

            setOwner(vulSource.owner);
        });
    }

    function reloadViews() {
        return vulViewService.getListOfUsedForSource(tableHandler.queryOptions, vulSourceId).then(vulViews => {
            tableHandler.updateTotal(vulViews.total);

            setViews(vulViews);
        })
    }

}

export default VulSourceDetail;
