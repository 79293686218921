import User from "./User";

class ApiToken {
    constructor(
        public id?: string,
        public name?: string,
        public user?: User,
        public token?: string) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const token = new ApiToken();

        token.id = data.id;
        token.name = data.name;
        token.user = (data.user) ? User.fromPOJO(data.user) : undefined;
        token.token = data.token;

        return token;
    }

}

export default ApiToken;
