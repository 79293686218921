import {Breadcrumb, Button, Card, Drawer, Form, message, Modal, Select, Space, Tree, Upload} from "antd";
import * as globalStyles from "../App.module.css";
import * as styles from "./ImportExport.module.css";
import React, {useContext, useEffect, useState} from "react";
import {DocumentTitle} from "../DocumentTitle";
import {AppContextContext, ImportExportServiceContext, TagServiceContext} from "../../Contexts";
import {UploadOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import {downloadFile} from "../../utils/DownloadUtils";
import {formatObjectsDifference, ImportAnalysis, ObjectsDifference} from "../../domain/ImportAnalysis";
import {Differ, Viewer} from "json-diff-kit";

const differ = new Differ({
    detectCircular: true,    // default `true`
    maxDepth: Infinity,      // default `Infinity`
    showModifications: true, // default `true`
    arrayDiffMethod: 'lcs',  // default `"normal"`, but `"lcs"` may be more useful
});

const diffViewerProps = {
    indent: 4,
    highlightInlineDiff: true,
    hideUnchangedLines: false,
};

function ImportExport() {
    const appContext = useContext(AppContextContext);
    const importExportService = useContext(ImportExportServiceContext);
    const tagService = useContext(TagServiceContext);
    const [tags, setTags] = useState<string[]>();
    const [form] = useForm();
    const [importData, setImportData] = useState();
    const [importAnalysis, setImportAnalysis] = useState<ImportAnalysis>();
    const [importAnalysisTreeData, setImportAnalysisTreeData] = useState<any>();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerContent, setDrawerContent] = useState<React.ReactNode>();
    const [drawerWidth, setDrawerWidth] = useState(700);

    useEffect(() => {
        tagService.getList().then(setTags);
    }, []);

    return (
        <DocumentTitle title={`Import / Export`}>
            <>
                <Breadcrumb
                    className={globalStyles["common__breadcrumb"]}
                    items={[
                        {title: appContext.config?.appName},
                        {title: "Import / Export"}
                    ]}
                />

                <h1>Import</h1>

                <p>Import previously exported data in JSON format as system resources.</p>

                <div className={`${globalStyles["common__top-button-bar"]}`}>
                    <Upload accept="application/json" showUploadList={false} customRequest={handleAnalyseImport}>
                        <Button icon={<UploadOutlined/>}>Import data</Button>
                    </Upload>
                </div>

                {importAnalysis && <>
                    <Card className={styles.card}
                          title={"Import analysis"}
                          bordered={true}>
                        <Tree treeData={importAnalysisTreeData}/>

                        <Space style={{marginTop: 16}}>
                            <Button type={"primary"}
                                    onClick={() => {
                                        Modal.confirm({
                                            content: "Do you really want to run this import?",
                                            okText: "Import",
                                            cancelText: "Cancel",
                                            onOk: () => onImport()
                                        });
                                    }}>Run import</Button>

                            <Button onClick={() => setImportAnalysis(undefined)}>Close</Button>
                        </Space>
                    </Card>

                    <Drawer title={"Attribute value"} width={drawerWidth} open={drawerOpen} onClose={() => setDrawerOpen(false)} children={drawerContent}/>
                </>}

                <h1>Export</h1>

                <p>Export data in JSON format. Only system resources are exported.</p>

                <Form labelCol={{span: 4}} form={form} className={`${globalStyles["common__form"]} ${styles.form}`} onFinish={onExport}>
                    <Form.Item name={"tag"} label="Tag" rules={[{required: true, message: "Please select a tag."}]}>
                        <Select options={tags?.map(tag => ({label: tag, value: tag}))}/>
                    </Form.Item>

                    <Form.Item wrapperCol={{offset: 4}} className={globalStyles["common__form-buttons"]} style={{marginBottom: 0}}>
                        <Button type={"primary"} htmlType={"submit"}>Export data</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    );

    function buildImportAnalysisTreeData(importAnalysis: ImportAnalysis) {
        const buildAttributes = (parentKey: string, object: any) => {
            return Object.keys(object).map(key => {
                const changeLogItem = importAnalysis.changeLog[object.id] && importAnalysis.changeLog[object.id][key];

                const renderValue = (value: any) => {
                    if (Array.isArray(value) && JSON.stringify(value).length > 100) {
                        return <Button size={"small"}
                                       style={{margin: '8px 4px 8px 0'}}
                                       onClick={() => {
                                           setDrawerOpen(true);
                                           setDrawerWidth(600);
                                           setDrawerContent(<pre>{JSON.stringify(value, undefined, 4)}</pre>);
                                       }}>
                            show
                        </Button>;
                    } else {
                        return <>{JSON.stringify(value)}</>;
                    }
                };

                if (changeLogItem !== undefined) {
                    return ({
                        key: `${parentKey}-${key}`,
                        title: <div>
                            <div><b><span className={styles['attribute-label']}>{key} (diff)</span></b><>
                                <Button size={"small"}
                                        style={{margin: '8px 4px 8px 0'}}
                                        onClick={() => {
                                            setDrawerOpen(true);
                                            setDrawerWidth(1000);
                                            setDrawerContent(<Viewer className={styles.diff} diff={differ.diff(changeLogItem.oldValue, changeLogItem.newValue)} {...diffViewerProps}/>);
                                        }}>
                                    show
                                </Button>

                            </>
                            </div>
                        </div>
                    });
                } else {
                    return ({
                        key: `${parentKey}-${key}`,
                        title: <div><span className={styles['attribute-label']}>{key}</span>{renderValue(object[key])}</div>
                    });
                }
            });
        }

        const buildDifferenceBranch = (objects: any[], keyFactory: (object: any) => string, titleFactory: (object: any) => string) => {
            return objects.map(value => ({
                key: keyFactory(value),
                title: titleFactory(value),
                children: buildAttributes(keyFactory(value), value)
            }));
        }

        const buildImportAnalysisBranch = (difference: ObjectsDifference<any>, label: string) => {
            const children: any = [];

            if (difference.newObjects.length > 0) {
                children.push({
                    key: `${label}-new`,
                    title: <i>new items ({difference.newObjects.length})</i>,
                    children: buildDifferenceBranch(difference.newObjects, object => object.id, object => object.name)
                });
            }

            if (difference.changedObjects.length > 0) {
                children.push({
                    key: `${label}-changed`,
                    title: <i>changed items ({difference.changedObjects.length})</i>,
                    children: buildDifferenceBranch(difference.changedObjects, object => object.id, object => object.name)
                });
            }

            if (difference.unchangedObjects.length > 0) {
                children.push({
                    key: `${label}-unchanged `,
                    title: <i>unchanged items ({difference.unchangedObjects.length})</i>,
                    children: buildDifferenceBranch(difference.unchangedObjects, object => object.id, object => object.name)
                });
            }

            return {
                key: label,
                title: `${label} (${formatObjectsDifference(difference)})`,
                children
            };
        }

        return [
            buildImportAnalysisBranch(importAnalysis.sourceDifferences, "Sources"),
            buildImportAnalysisBranch(importAnalysis.attributeDifferences, "Attributes"),
            buildImportAnalysisBranch(importAnalysis.viewDifferences, "Views"),
        ];
    }

    function onExport(values: any) {
        importExportService.export(values)
            .then(data => {
                message.success(`Data exported successfully (${data.sources.length} sources, ${data.attributes.length} attributes, ${data.views.length} views).`)

                downloadFile("export.json", JSON.stringify(data, undefined, 4));
            })
            .catch(error => {
                message.error(`Export failed: ${error.response.data.message}`, 5);
            });
    }

    function onImport() {
        importExportService.import(importData)
            .then(() => {
                message.success("Data imported successfully.");

                setImportData(undefined);
                setImportAnalysis(undefined);
                setImportAnalysisTreeData(undefined);
            })
            .catch(() => {
                message.error("Data import failed.");
            });
    }

    function handleAnalyseImport(params: any) {
        const {file, onSuccess, onError} = params;

        const reader = new FileReader();

        reader.onloadend = () => {
            if (typeof reader.result === "string") {
                const data = JSON.parse(reader.result);

                setImportData(data);

                importExportService.importAnalysis(data)
                    .then(data => {
                        setImportAnalysis(data);
                        setImportAnalysisTreeData(buildImportAnalysisTreeData(data));
                    })
                    .catch(() => {
                        message.error("Data import analysis failed.");
                    })
            } else {
                message.error("Cannot load JSON document.");
            }
        };

        reader.readAsText(file, "UTF-8");
    }

}

export default ImportExport;
