import {VulSourceItemServiceContext} from "../../Contexts";
import VulSourceItem from "../../domain/VulSourceItem";
import * as styles from "./ItemVoteCompact.module.css";
import {Badge, Button} from "antd";
import {DislikeFilled, DislikeOutlined, LikeFilled, LikeOutlined} from "@ant-design/icons";
import React, {useContext} from "react";

function ItemVoteCompact(props: { item: VulSourceItem, onVote: any }) {
    const vulSourceItemService = useContext(VulSourceItemServiceContext);

    const item = props.item;

    return (
        <div className={styles['container']}>
            <div>
                <Badge count={item.flagStats?.notImportant} color={"blue"} size={"small"} offset={[-5, 5]}>
                    <Button icon={item.flags && item.flags.notImportant ? <DislikeFilled/> : <DislikeOutlined/>}
                            title={"Dislike item"}
                            className={"btn-seamless"}
                            onClick={() => {
                                vulSourceItemService.thumbDown(item).then(props.onVote);

                                if (!item.flags) {
                                    item.flags = {important: false, notImportant: false};
                                }

                                const notImportant = item.flags ? !item.flags.notImportant : true;
                                const important = false;

                                if (item.flags.notImportant && !notImportant) {
                                    item.flagStats.notImportant--;
                                }
                                if (!item.flags.notImportant && notImportant) {
                                    item.flagStats.notImportant++;
                                }
                                if (item.flags.important && !important) {
                                    item.flagStats.important--;
                                }
                                item.flags.important = important;
                                item.flags.notImportant = notImportant;
                            }}/>
                </Badge>
            </div>
            <div>
                <Badge count={item.flagStats?.important} color={"blue"} size={"small"} offset={[-5, 5]}>
                    <Button icon={item.flags && item.flags.important ? <LikeFilled/> : <LikeOutlined/>}
                            title={"Like item"}
                            className={"btn-seamless"}
                            onClick={() => {
                                vulSourceItemService.thumbUp(item).then(props.onVote);

                                if (!item.flags) {
                                    item.flags = {important: false, notImportant: false};
                                }

                                const important = item.flags ? !item.flags.important : true;
                                const notImportant = false;

                                if (item.flags.important && !important) {
                                    item.flagStats.important--;
                                }
                                if (!item.flags.important && important) {
                                    item.flagStats.important++;
                                }
                                if (item.flags.notImportant && !notImportant) {
                                    item.flagStats.notImportant--;
                                }
                                item.flags.important = important;
                                item.flags.notImportant = notImportant;
                            }}/>
                </Badge>
            </div>
        </div>
    );
}

export default ItemVoteCompact;