import * as styles from "./FindingAnalysisStatistics.module.css";
import {colorForFindingstatus, FindingStatus, formatFindingStatus} from "../../domain/FindingStatus";
import {Popover, Progress, Tag} from "antd";
import {VisBulletLegend, VisDonut, VisSingleContainer} from "@unovis/react";
import {useEffect, useRef} from "react";

export enum AnalysisStyle {
    TAG = "TAG",
    PROGRESS = "PROGRESS"
}

interface IProps {
    analysisStatistics?: any;
    style?: AnalysisStyle
}

function FindingAnalysisStatistics(props: IProps) {
    const spanRef = useRef<HTMLDivElement>();

    useEffect(() => {
        spanRef.current?.getElementsByClassName("ant-progress-text")[0].removeAttribute("title");
    }, [spanRef.current]);

    if (props.analysisStatistics === undefined || props.analysisStatistics === null) {
        return;
    }

    const style = (props.style) ? props.style : AnalysisStyle.PROGRESS;

    const allComponentCount = Object.values(props.analysisStatistics).reduce((previousValue: number, currentValue: number) => previousValue + currentValue, 0) as number;

    const analysedComponentCount = Object.entries(props.analysisStatistics)
        .filter(entry => entry[0] === FindingStatus.AFFECTED || entry[0] === FindingStatus.NOT_AFFECTED)
        .map(entry => entry[1] as number)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

    const percent = Math.ceil(analysedComponentCount / (allComponentCount / 100)) || 0;

    const component = (style === AnalysisStyle.PROGRESS)
        ? <Progress className={styles.progress} type={"circle"} percent={percent} size={32} ref={spanRef}/>
        : <Tag bordered={false} className={styles[statusClass(percent)]}>{percent}% complete</Tag>;

    return (
        <span className={styles['statistics']} ref={spanRef}>
            <Popover title={"Analysis statistics"} placement={"right"} content={<>
                <div className={styles['chart-container']}>
                    <VisSingleContainer height={140} data={Object.keys(props.analysisStatistics)}>
                        <VisDonut radius={50} value={(d: any) => props.analysisStatistics[d]} color={d => colorForFindingstatus(d as FindingStatus)}/>
                    </VisSingleContainer>
                    <VisBulletLegend items={Object.keys(props.analysisStatistics).map(value => {
                        return {
                            name: `${formatFindingStatus(value as FindingStatus)} (${props.analysisStatistics[value]})`,
                            color: colorForFindingstatus(value as FindingStatus)
                        }
                    })}
                    />
                </div>
            </>}>
                {component}
            </Popover>
        </span>
    );

    function statusClass(percent: number) {
        if (percent === 0) {
            return "status-UNKNOWN";
        } else if (percent === 100) {
            return "status-COMPLETE";
        } else {
            return "status-INCOMPLETE";
        }
    }
}

export default FindingAnalysisStatistics;