.input, .input:focus, .input:hover {
    margin: 0;
    padding: 0;
    background: transparent;
    border: 1px solid red;
    outline: none;
    appearance: none;
    width: 100%;
}

.attribute-editor {
    width: auto;
    display: inline-flex;
}

.attribute-names {
    width: 100px !important;
}

.attribute-operators {
    width: 50px !important;
}

.attribute-value {
    width: 100px !important;
}
