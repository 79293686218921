import Axios from "axios";
import QualifiedEnum from "../domain/QualifiedEnum";
import Country from "../domain/Country";
import {AppContext} from "../AppContext";
import {reaction} from "mobx";
import _ from "lodash";

class EnumService {

    private countries: Country[] = [];

    private auditLogAttributes: QualifiedEnum[] = [];

    private countriesPromise: Promise<Country[]> = new Promise(resolve => {
        Axios.get("/api/enums/countries")
            .then(value => {
                this.countries = value.data;

                this.countries.sort((a, b) => a.translations[this.appContext.language].localeCompare(b.translations[this.appContext.language]));

                resolve(this.countries);
            })
            .catch(() => {
                resolve(this.countries);
            })
    })

    private auditLogAttributesPromise: Promise<QualifiedEnum[]> = new Promise(resolve => {
        Axios.get("/api/enums/audit-log-attributes")
            .then(value => {
                const objectList: QualifiedEnum[] = [];

                value.data.forEach((objectJson: object) => {
                    objectList.push(QualifiedEnum.fromPOJO(objectJson));
                });

                resolve(_.sortBy(_.uniqBy(objectList, "name"), "name"));
            })
            .catch(() => {
                resolve(this.auditLogAttributes);
            })
    });

    constructor(private appContext: AppContext) {
        reaction(() => appContext.language, language => {
            this.countries.sort((a, b) => a.translations[language].localeCompare(b.translations[language]));
        });
    }

    public getAuditLogTypes(): Promise<string[]> {
        return Axios.get("/enums/audit-log-types").then(value => {
            return value.data;
        })
    }

    public getAuditLogAttributes(): Promise<QualifiedEnum[]> {
        return this.auditLogAttributes.length === 0 ? this.auditLogAttributesPromise : Promise.resolve(this.auditLogAttributes);
    }

    public getCountries(): Promise<Country[]> {
        return this.countries.length === 0 ? this.countriesPromise : Promise.resolve(this.countries);
    }

}

export default EnumService;