import Organization from "./Organization";
import SbomProjectVersion from "./SbomProjectVersion";
import SoftwareLicensePolicy from "./SoftwareLicensePolicy";

class SbomProject {

    constructor(
        public id?: string,
        public name?: string,
        public organization?: Organization,
        public softwareLicensePolicy?: SoftwareLicensePolicy,
        public versions?: SbomProjectVersion[]) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const sbomProject = new SbomProject();

        sbomProject.id = data.id;
        sbomProject.name = data.name;
        sbomProject.organization = (data.organization) ? Organization.fromPOJO(data.organization) : undefined;
        sbomProject.softwareLicensePolicy = (data.softwareLicensePolicy) ? SoftwareLicensePolicy.fromPOJO(data.softwareLicensePolicy) : undefined;
        sbomProject.versions = (data.versions) ? data.versions.map(data => SbomProjectVersion.fromPOJO(data)) : undefined;

        return sbomProject;
    }

}

export default SbomProject;
