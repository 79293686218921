.container {
    display: inline-flex;
    align-items: center;
    margin: 3px 0;
    word-break: normal;
    white-space: nowrap;
    gap: 4px;
}

.container :global(.anticon) {
    margin-left: 4px;
}

.container:global(.ant-tag) {
    font-size: 13px;
    padding: 1px 6px;
}

.multi-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding: 0;
}

.clipboardable {
    cursor: pointer;
}