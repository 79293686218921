import React, {useState} from "react";
import _ from "lodash";
import AttributeBox from "./AttributeBox";
import AttributeDefinition from "../../domain/AttributeDefinition";
import {Button} from "antd";

const MAX_ATTRIBUTE_VALUES = 5;

interface Props {
    definition?: AttributeDefinition;
    value: any;
}

function ItemAttribute(props: Props) {
    const [showAllValues, setShowAllValues] = useState(false);

    if (_.isArray(props.value)) {
        if (props.value.length > MAX_ATTRIBUTE_VALUES) {
            if (showAllValues) {
                return <>
                    {props.value.map((val, index) => <ItemAttribute key={`${props.definition?.name}_${index}`} definition={props.definition} value={val}/>)}

                    <Button type={"link"} size={"small"} onClick={(prev) => setShowAllValues(false)} ><i>« show only {MAX_ATTRIBUTE_VALUES} {props.definition.name} values »</i></Button>
                </>;
            } else {
                return <>
                    {props.value.slice(0, MAX_ATTRIBUTE_VALUES).map((val, index) => <ItemAttribute key={`${props.definition?.name}_${index}`} definition={props.definition} value={val}/>)}

                    <Button type={"link"} size={"small"} onClick={(prev) => setShowAllValues(true)}><i>« show all {props.value.length} {props.definition.name} values »</i></Button>
                </>;
            }
        } else {
            return <>
                {props.value.map((val, index) => <ItemAttribute key={`${props.definition?.name}_${index}`} definition={props.definition} value={val}/>)}
            </>;
        }
    }

    /* nezapomenout na podobné formátování v e-mailových šablonách */

    switch (props.definition?.name) {
        case "sealScore":
            return <AttributeBox value={<>Score: {props.value}</>} showSearch={false}/>;
        case "cvssBaseScore":
            return <AttributeBox value={<>CVSS: {props.value}</>} showSearch={false}/>;
        case "cveId":
            return <AttributeBox value={props.value} href={`https://nvd.nist.gov/vuln/detail/${props.value}`} clipboardText={props.value}/>;
        case "rlsa":
            return <AttributeBox value={props.value} href={`https://errata.build.resf.org/${props.value}`}/>;
        case "fgIr":
            return <AttributeBox value={props.value} href={`https://fortiguard.fortinet.com/psirt/${props.value}`}/>;
        case "ciscoSa":
            return <AttributeBox value={props.value} href={`https://sec.cloudapps.cisco.com/security/center/content/CiscoSecurityAdvisory/${props.value}`}/>;
        default:
            if (props.definition?.showTag) {
                if (props.definition?.mergeTicket) {
                    return <AttributeBox value={<>{props.value}</>} showSearch={true}/>;
                } else {
                    return <AttributeBox value={<>{props.definition?.name}: {props.value}</>} showSearch={false}/>;
                }
            }

            return;
    }
}

export default ItemAttribute;