import {Breadcrumb} from "antd";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {AppContextContext, AttributeDefinitionServiceContext, VulSourceItemServiceContext} from "../../Contexts";
import VulSourceItem from "../../domain/VulSourceItem";
import {TableUiConfig, useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";
import {DocumentTitle} from "../DocumentTitle";
import * as styles from "../vulsourceitem/VulSourceItemList.module.css";
import * as globalStyles from "../App.module.css";
import VulSourceItemFilter from "./VulSourceItemFilter";
import _ from "lodash";
import {FormPersister} from "../../sal-ui/FormPersister";
import AttributeDefinition from "../../domain/AttributeDefinition";
import QueryOptions from "../../sal-ui/QueryOptions";
import VulSourceItemListTable from "./VulSourceItemListTable";
import {observer} from "mobx-react-lite";
import {Permission} from "../../domain/auth/Permission";

const defaultVisibleColumns = ["id", "description", "attributes", "sourcePublished"];

const defaultTableUiConfig = {immediateMode: false, visibleColumns: defaultVisibleColumns};

const VulSourceItemList = observer(() => {
    const persistentIdent = 'VulSourceItemList';
    const appContext = useContext(AppContextContext);
    const vulSourceItemService = useContext(VulSourceItemServiceContext);
    const attributeDefinitionService = useContext(AttributeDefinitionServiceContext);
    const location = useLocation();
    const navigate = useNavigate();
    const filterFormPersister = new FormPersister(persistentIdent);
    const tableHandler = useTableHandler("sourcePublished desc", {
        reloadFunction: reload,
        initialPageSize: 50,
        persistentIdent
    });
    const [filterValues, setFilterValues] = useState<any>();
    const readOnly = useRef(false);
    const [vulSourceItems, setVulSourceItems] = useState<PagedResult<VulSourceItem>>();
    const [tableUiConfig, setTableUiConfig] = useState<TableUiConfig>(defaultTableUiConfig);
    const [attributeDefinitions, setAttributeDefinitions] = useState<AttributeDefinition[]>();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        attributeDefinitionService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setAttributeDefinitions(value.data));

        const searchParams = new URLSearchParams(location.search);

        readOnly.current = searchParams.has('$readOnly');

        const loadedValues = (searchParams.has("filter"))
            ? filterFormPersister.loadValues(window.atob(searchParams.get("filter")!))
            : filterFormPersister.loadValues();

        const values = (_.isEmpty(loadedValues)) ? {attributeValue: '', vulView: [], sources: []} : loadedValues;

        setFilterValues(values);

        if (searchParams.has("filter")) {
            navigate(location.pathname, {replace: true});
        }

        setTableUiConfig(tableHandler.loadUiConfig() || defaultTableUiConfig);
    }, [])

    useEffect(() => {
        if (filterValues !== undefined) {
            debouncedOnFinishFilter(filterValues);
        }
    }, [filterValues]);

    const debouncedOnFinishFilter = useCallback(_.debounce(onFinishFilter, 500), []);

    const title = "Vulnerability items";

    return (
        <DocumentTitle title={title}>
            {!appContext.presentationMode && <Breadcrumb
                className={globalStyles["common__breadcrumb"]}
                items={[
                    {title: appContext.config?.appName},
                    {title}
                ]}
            />}

            <h1>{title}</h1>

            <div style={{display: (appContext.presentationMode) ? 'none' : 'inherit'}}>
                <VulSourceItemFilter
                    className={styles.filter}
                    onChange={values => {
                        setFilterValues(values);

                        debouncedOnFinishFilter(values);
                    }}
                    values={filterValues}
                    readOnly={readOnly.current}
                    showViews={appContext.user.hasOneOfPermission([Permission.VIEW__READ_ORG, Permission.VIEW__READ_SEAL])}
                    immediateMode={tableUiConfig.immediateMode}
                    attributeDefinitions={attributeDefinitions}
                />
            </div>

            <VulSourceItemListTable
                persistentIdent={persistentIdent}
                items={vulSourceItems}
                tableHandler={tableHandler}
                tableUiConfig={tableUiConfig}
                setTableUiConfig={setTableUiConfig}
            />
        </DocumentTitle>
    )

    function createRqbQuery(values: any) {
        const rqbQuery = {
            combinator: "and",
            rules: []
        }

        values.sources && values.sources.length > 0 && rqbQuery.rules.push({
            field: "source.id",
            operator: "in",
            value: values.sources
        });

        values.attributeValue && rqbQuery.rules.push({
            field: "attributes",
            operator: "jsonRegex",
            value: values.attributeValue
        });

        values.cveId && rqbQuery.rules.push({
            field: "attributes",
            operator: "jsonEq",
            value: ["cveId", values.cveId]
        });

        values.sourcePublishedFrom && rqbQuery.rules.push({
            field: "sourcePublished",
            operator: "ge",
            value: values.sourcePublishedFrom.utc().format()
        });

        values.sourcePublishedTo && rqbQuery.rules.push({
            field: "sourcePublished",
            operator: "le",
            value: values.sourcePublishedTo.utc().format()
        });

        values.lastModifiedFrom && rqbQuery.rules.push({
            field: "lastModified",
            operator: "ge",
            value: values.lastModifiedFrom.utc().format()
        });

        values.lastModifiedTo && rqbQuery.rules.push({
            field: "lastModified",
            operator: "le",
            value: values.lastModifiedTo.utc().format()
        });

        values.vulView && rqbQuery.rules.push({
            field: "vulView.id",
            operator: "in",
            value: values.vulView
        });

        values.attributes && values.attributes.length > 0 && values.attributes.forEach(filter => {
            filter.name && rqbQuery.rules.push({
                field: "attributes",
                operator: filter.operator,
                value: [filter.name, filter.value]
            })
        });

        return rqbQuery;
    }

    function onFinishFilter(values: any) {
        const rqbQuery = createRqbQuery(values);

        if (!readOnly.current) {
            filterFormPersister.saveState(values);
        }

        tableHandler.onRqbSearchSubmit(rqbQuery);
    }

    function reload() {
        return vulSourceItemService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setVulSourceItems(value);
        });
    }

});

export default VulSourceItemList;
