import React, {useContext} from "react";
import {SbomProjectServiceContext} from "../../Contexts";
import {formatScope, Scope} from "../../domain/auth/Scope";
import {formatPermission} from "../../domain/auth/Permission";
import RolePermission from "../../domain/auth/RolePermission";
import {useQuery} from "@tanstack/react-query";
import {Link} from "react-router-dom";
import {Tag} from "antd";

function RolePermissionFormatter(props: { rolePermission: RolePermission }) {
    if (props.rolePermission.scope && props.rolePermission.objectId) {
        if (props.rolePermission.scope === Scope.SBOM_PROJECT) {
            return <>
                {formatPermission(props.rolePermission.permission)} <b>on</b> <SbomProjectFormatter objectId={props.rolePermission.objectId} />
                {props.rolePermission.tags?.length > 0 && <> and versions tagged as {props.rolePermission.tags?.map(value => <Tag>{value}</Tag>)}</>}
            </>;
        } else {
            return <>{formatPermission(props.rolePermission.permission)} <b>on</b> <i>{formatScope(props.rolePermission.scope)}</i> {props.rolePermission.objectId}</>;
        }
    } else if (props.rolePermission.scope) {
        return <>{formatPermission(props.rolePermission.permission)} <b>on</b> {formatScope(props.rolePermission.scope)}</>;
    } else {
        return <>{formatPermission(props.rolePermission.permission)}</>;
    }
}

function SbomProjectFormatter(props: { objectId: string }) {
    const sbomProjectService = useContext(SbomProjectServiceContext);

    const query = useQuery({
        queryKey: ["sbomProject", props.objectId],
        queryFn: () => sbomProjectService.get(props.objectId)
    });

    switch (query.status) {
        case "pending":
            return <i>Loading...</i>;
        case "error":
            return <i>Fetch failed</i>;
        case "success":
            return <Link to={`/sbom-projects/${props.objectId}`}>{query.data.data.name}</Link>;
    }
}

export default RolePermissionFormatter;
