import {Breadcrumb, Button, Form, Input, InputNumber, message, Select, Switch} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, TagServiceContext, VulSourceServiceContext} from "../../Contexts";
import Organization from "../../domain/Organization";
import {ResourceOwnerType} from "../../domain/ResourceOwnerType";
import {formatVulSourceType, VulSourceType} from "../../domain/VulSourceType";
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import *as globalStyles from "../App.module.css";
import {DocumentTitle} from "../DocumentTitle";
import {formatRssGuidSource, RssGuidSource} from "../../domain/RssGuidSource";
import TextArea from "antd/lib/input/TextArea";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function VulSourceAdd() {
    const appContext = useContext(AppContextContext);
    const vulSourceService = useContext(VulSourceServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const tagService = useContext(TagServiceContext);
    const navigate = useNavigate();
    const [form] = useForm();
    const [sourceType, setSourceType] = useState<VulSourceType>();
    const [owner, setOwner] = useState<ResourceOwnerType>(ResourceOwnerType.SYSTEM);
    const [organizations, setOrganizations] = useState<Organization[]>();
    const [tags, setTags] = useState<string[]>();

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 20},
    };

    const tailLayout = {
        wrapperCol: {offset: 8, span: 20},
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (appContext.user.isSystemAdmin()) {
            organizationService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setOrganizations(value.data));

            tagService.getList().then(setTags);
        }
    }, [])

    const title = "New vulnerability source"

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}
                            items={[
                                {title:appContext.config?.appName},
                                {title:<Link to={"/vul-sources"}>Vulnerability sources</Link>},
                                {title}
                            ]}
                />

                <h1>{title}</h1>

                <Form {...layout} form={form}
                      className={`${globalStyles["common__form"]} ${globalStyles["common_form--add"]}`}
                      onFinish={onFinish}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                            {
                                validator: serverViolationsHolder.createServerValidator('UNIQUE', 'name', undefined, {compareLowerCaseValues: true}),
                                message: "name-not-unique"
                            }
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"description"}
                        label={"Description"}
                        extra={"Description of vulnerability source in CommonMark format."}>
                        <TextArea maxLength={1000} autoSize={{minRows: 4, maxRows: 8}}/>
                    </Form.Item>

                    <Form.Item
                        name={["sourceType"]}
                        label={"Type"}
                        rules={[{required: true, message: "required"}]}>

                        <Select
                            onChange={value => setSourceType(value)}
                            options={Object.values(VulSourceType)
                                .filter(value => appContext.user.isSystemAdmin() || (value === VulSourceType.RSS || value === VulSourceType.TWITTER))
                                .map(type => ({
                                    label: formatVulSourceType(type),
                                    value: type
                                }))}
                        />
                    </Form.Item>

                    {sourceType === VulSourceType.NVD20 &&
                        <Form.Item
                            name={"apiKey"}
                            label={"API key"}
                            rules={[
                                {required: true, message: "API key is required."},
                            ]}>
                            <Input maxLength={100}/>
                        </Form.Item>
                    }

                    {sourceType === VulSourceType.RSS &&
                        <>
                            <Form.Item
                                name={"url"}
                                label={"URL"}
                                extra={"HTTP or HTTPS address of Atom / RSS feed."}
                                rules={[
                                    {required: true, message: "URL is required."},
                                    {validator: serverViolationsHolder.createServerValidator('NOT_NULL', 'url'), message: "URL is not valid."}
                                ]}>
                                <Input maxLength={1000}/>
                            </Form.Item>

                            <Form.Item
                                name={["guidSource"]}
                                label={"GUID source"}
                                initialValue={'GUID'}
                                extra={"RSS item attribute used for deduplication."}
                                rules={[{required: true, message: "GUID source is required."}]}>
                                <Select
                                    options={[
                                        {label: formatRssGuidSource(RssGuidSource.GUID), value: 'GUID'},
                                        {label: formatRssGuidSource(RssGuidSource.LINK), value: 'LINK'},
                                        {label: formatRssGuidSource(RssGuidSource.TITLE), value: 'TITLE'},
                                        {label: formatRssGuidSource(RssGuidSource.DESCRIPTION), value: 'DESCRIPTION'},
                                    ]}
                                />
                            </Form.Item>

                            <Form.Item
                                name={"userAgent"}
                                label={"User agent"}
                                extra={"Optional content of User-Agent HTTP header."}>
                                <Input maxLength={1000}/>
                            </Form.Item>
                        </>
                    }

                    {sourceType === VulSourceType.TWITTER &&
                        <Form.Item
                            name={"username"}
                            label={"Username"}
                            rules={[
                                {required: true, message: "Username is required."},
                            ]}>
                            <Input maxLength={100}/>
                        </Form.Item>
                    }

                    {sourceType === VulSourceType.CISCO_OPENVULN && <>
                        <Form.Item
                            name={"apiKey"}
                            label={"API key"}
                            rules={[
                                {required: true, message: "API key is required."},
                            ]}>
                            <Input maxLength={100}/>
                        </Form.Item>

                        <Form.Item
                            name={"apiClientSecret"}
                            label={"API client secret"}
                            rules={[
                                {required: true, message: "API client secret is required."},
                            ]}>
                            <Input maxLength={100}/>
                        </Form.Item>
                    </>
                    }

                    {appContext.user.isSystemAdmin() && <>
                        <Form.Item
                            name={["tags"]}
                            label={"Tags"}>
                            <Select mode="tags" options={tags?.map(tag => ({value: tag, label: tag}))} />
                        </Form.Item>

                        <Form.Item
                            name={["owner"]}
                            label={"Owner"}
                            rules={[{required: true, message: "Owner is required."}]}
                            initialValue={owner}>
                            <Select onChange={value => setOwner(value)}>
                                <Select.Option key={ResourceOwnerType.SYSTEM} value={ResourceOwnerType.SYSTEM}>system</Select.Option>
                                <Select.Option key={ResourceOwnerType.ORGANIZATION} value={ResourceOwnerType.ORGANIZATION}>organization</Select.Option>
                            </Select>
                        </Form.Item>

                        {owner === ResourceOwnerType.ORGANIZATION &&
                            <Form.Item
                                name={"organization"}
                                label={"Organization"}
                                rules={[{required: owner === ResourceOwnerType.ORGANIZATION, message: "Organization is required."}]}>
                                <Select allowClear={true}>
                                    {organizations && organizations!.map(organization => {
                                        return <Select.Option key={organization?.name} value={organization?.id!}>{organization?.name}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        }
                    </>}

                    <Form.Item
                        name={["enabled"]}
                        label={"Enabled"}
                        extra={"Source is polled periodically if enabled."}
                        valuePropName={"checked"}>
                        <Switch/>
                    </Form.Item>

                    <Form.Item
                        name={"maxLastRunAge"}
                        label={"Max. last collection age"}
                        extra={"Duration in hours since the last collection that is still considered healthy."}
                        initialValue={24}
                        rules={[
                            {required: true, message: "Max. last collection age is required."},
                        ]}>
                        <InputNumber min={6} max={87600}/>
                    </Form.Item>

                    <Form.Item
                        name={"maxLatestItemPublishedAtAge"}
                        label={"Max. latest published item age"}
                        extra={"Duration in hours since the latest published item that is still considered healthy."}
                        initialValue={168}
                        rules={[
                            {required: true, message: "Max. latest published item age is required."},
                        ]}>
                        <InputNumber min={6} max={87600} />
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate("/vul-sources")}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    )

    function onFinish(values: any) {
        vulSourceService.add(values)
            .then(
                (id) => {
                    message.success("Vulnerability source added.");

                    navigate(`/vul-sources/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }
}

export default VulSourceAdd;
