import {DeleteFilled, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, ConfigProvider, message, Modal, Table, Tag} from "antd";
import React, {useContext, useEffect, useState} from "react";
import Organization from "../../domain/Organization";
import {DocumentTitle} from "../DocumentTitle";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, SbomProjectServiceContext} from "../../Contexts";
import {useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";

import * as styles from "./SbomProjectList.module.css";
import * as globalStyles from "../App.module.css";
import {ColumnsType} from "antd/es/table";
import SbomProject from "../../domain/SbomProject";
import SbomProjectVersion from "../../domain/SbomProjectVersion";
import _ from "lodash";
import FindingStatistics from "./FindingStatistics";
import {Permission} from "../../domain/auth/Permission";
import FindingAnalysisStatistics, {AnalysisStyle} from "./FindingAnalysisStatistics";
import {colorForSbomProjectVersionStatus, formatSbomProjectVersionStatus, SbomProjectVersionStatus} from "../../domain/SbomProjectVersionStatus";

function SbomProjectList() {
    const appContext = useContext(AppContextContext);
    const organizationService = useContext(SbomProjectServiceContext);
    const navigate = useNavigate();
    const tableHandler = useTableHandler("name", {reloadFunction: reload});
    const [sbomProjects, setSbomProjects] = useState<PagedResult<SbomProject>>();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        tableHandler.reload();
    }, [])

    const columns: ColumnsType<SbomProject> = [
        {
            dataIndex: "name",
            title: "Name",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "ascend",
            render: renderName
        },
        {
            dataIndex: "versions",
            title: "Versions",
            render: (_, sbomProject) => <SbomProjectVersions sbomProject={sbomProject} versions={sbomProject.versions}/>
        }
    ];

    if (appContext.user.isSystemAdmin()) {
        columns.push({
            dataIndex: "organization",
            title: "Organization",
            render: renderOrganization
        });
    }

    if (appContext.user.hasPermission(Permission.SBOM_PROJECT__MANAGE_ORG)) {
        columns.push({
            title: "Actions",
            width: 100,
            align: "center",
            className: globalStyles["table-actions"],
            render: renderAction
        });
    }

    const title = "SBOM projects";

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}
                            items={[
                                {title: appContext.config?.appName},
                                {title}
                            ]}
                />

                <h1>{title}</h1>

                <div className={globalStyles["common__top-button-bar"]}>
                    <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>

                    {appContext.user.hasPermission(Permission.SBOM_PROJECT__MANAGE_ORG) &&
                        <Button type={"primary"}
                                icon={<PlusOutlined/>}
                                onClick={() => navigate("/sbom-projects/add")}>Add</Button>
                    }
                </div>

                <Table className={styles.table}
                       showSorterTooltip={false}
                       loading={tableHandler.loading}
                       dataSource={sbomProjects?.data}
                       size="middle"
                       onChange={tableHandler.onTableChange}
                       pagination={tableHandler.pagination}
                       onRow={onRow}
                       rowKey="id"
                       columns={columns}
                />
            </>
        </DocumentTitle>
    )

    function renderName(value: any, sbomProject: SbomProject) {
        return <Link to={`/sbom-projects/${sbomProject.id}`}>{value}</Link>;
    }

    function renderOrganization(value: Organization) {
        return <Link to={`/organizations/${value.id}`}>{value.name}</Link>;
    }

    function renderAction(value: any, sbomProject: SbomProject) {
        return (
            <>
                <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                        title={"Delete"}
                        onClick={() => {
                            Modal.confirm({
                                content: <>Do you really want to delete SBOM project <b>{sbomProject.name}</b>?</>,
                                okText: "Delete",
                                cancelText: "Cancel",
                                onOk: () => onDeleteConfirm(sbomProject)
                            });
                        }}/>
            </>
        )
    }

    function onDeleteConfirm(sbomProject: SbomProject) {
        organizationService.delete(sbomProject)
            .then(() => {
                message.success(<>SBOM project <b>{sbomProject.name}</b> successfully deleted.</>);

                reload();
            });
    }

    function reload() {
        return organizationService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setSbomProjects(value);
        });
    }

    function onRow(sbomProject: SbomProject) {
        return {onDoubleClick: () => navigate(`/sbom-projects/${sbomProject.id}`)}
    }
}

function SbomProjectVersions(props: { sbomProject: SbomProject, versions: SbomProjectVersion[] }) {
    const navigate = useNavigate();
    const [showAllValues, setShowAllValues] = useState(false);

    if (props.versions === undefined) {
        return;
    }

    const MAX_VERSIONS = 10;

    const renderTable = (versions: SbomProjectVersion[], footer?: () => React.ReactNode) => (
        <ConfigProvider theme={{components: {Table: {headerBg: 'rgba(0, 0, 0, 0.02)', headerColor: 'rgba(0, 0, 0, 0.65)'}}}}>
            <Table className={styles['versions-table']}
                   showSorterTooltip={false}
                   dataSource={versions}
                   size="small"
                   bordered={false}
                   pagination={false}
                   rowKey="id"
                   footer={footer}
                   onRow={(projectVersion: SbomProjectVersion) => {
                       return {
                           onDoubleClick: (e) => {
                               e.stopPropagation();
                               navigate(`/sbom-projects/${props.sbomProject.id}/${projectVersion.id}`)
                           }
                       }
                   }}
                   columns={[
                       {
                           dataIndex: "name",
                           className: styles['column-name'],
                           title: "Name",
                           render: (_, version) => <>
                               <Link to={`/sbom-projects/${props.sbomProject.id}/${version.id}`}>{version.name}</Link>

                               {version?.tags?.length > 0 && <span style={{marginLeft: 8}}>{version?.tags?.map(value => <Tag>{value}</Tag>)}</span>}

                               {version?.licenseViolations?.length > 0 && <Tag style={{marginLeft: 8}} color={"orange"} title={"One or more licenses violates project's software license policy."}>license violation</Tag>}
                           </>
                       },
                       {
                           dataIndex: "statistics",
                           title: "Vulnerability findings",
                           render: (_, version) => renderStatistics(version)
                       },
                       {
                           dataIndex: "analysisStatistics",
                           title: "Analysis",
                           width: 100,
                           render: (_, version) => renderAnalysisStatistics(version)
                       }
                   ]}
            />
        </ConfigProvider>
    );

    if (props.versions.length > MAX_VERSIONS) {
        if (showAllValues) {
            const footer = () => <Button type={"link"} size={"small"} onClick={(prev) => setShowAllValues(false)}><i>« show only {MAX_VERSIONS} versions »</i></Button>

            return renderTable(props.versions, footer);
        } else {
            const footer = () => <Button type={"link"} size={"small"} onClick={(prev) => setShowAllValues(true)}><i>« show all {props.versions.length} versions »</i></Button>

            return renderTable(props.versions.slice(0, MAX_VERSIONS), footer);
        }
    } else {
        return renderTable(props.versions);
    }

    function renderStatistics(sbomProjectVersion: SbomProjectVersion) {
        switch (sbomProjectVersion.status) {
            case SbomProjectVersionStatus.WAITING_FOR_SBOM:
            case SbomProjectVersionStatus.WAITING_FOR_EVALUATION:
            case SbomProjectVersionStatus.NOT_VULNERABLE:
                return <Tag color={colorForSbomProjectVersionStatus(sbomProjectVersion.status)}>
                    {formatSbomProjectVersionStatus(sbomProjectVersion.status)}
                </Tag>;
            case SbomProjectVersionStatus.VULNERABLE:
                if (_.isEqual(sbomProjectVersion.findingStatistics, sbomProjectVersion.analysedFindingStatistics)) {
                    return <FindingStatistics findingStatistics={sbomProjectVersion.findingStatistics} compact={true}/>;
                } else {
                    return <FindingStatistics findingStatistics={sbomProjectVersion.analysedFindingStatistics} compact={true}/>;
                }
        }
    }

    function renderAnalysisStatistics(sbomProjectVersion: SbomProjectVersion) {
        return <FindingAnalysisStatistics analysisStatistics={sbomProjectVersion?.analysisStatistics} style={AnalysisStyle.PROGRESS}/>;
    }

}

export default SbomProjectList;
