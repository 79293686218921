export enum SbomProjectVersionStatus {
    WAITING_FOR_SBOM = "WAITING_FOR_SBOM",
    WAITING_FOR_EVALUATION = "WAITING_FOR_EVALUATION",
    NOT_VULNERABLE = "NOT_VULNERABLE",
    VULNERABLE = "VULNERABLE"
}

export function formatSbomProjectVersionStatus(status?: SbomProjectVersionStatus) {
    switch (status) {
        case SbomProjectVersionStatus.WAITING_FOR_SBOM:
            return "waiting for SBOM import";
        case SbomProjectVersionStatus.WAITING_FOR_EVALUATION:
            return "waiting for evaluation";
        case SbomProjectVersionStatus.NOT_VULNERABLE:
            return "no known vulnerabilities";
        case SbomProjectVersionStatus.VULNERABLE:
            return "vulnerable";
    }
}

export function colorForSbomProjectVersionStatus(status?: SbomProjectVersionStatus) {
    switch (status) {
        case SbomProjectVersionStatus.WAITING_FOR_SBOM:
        case SbomProjectVersionStatus.WAITING_FOR_EVALUATION:
            return "default";
        case SbomProjectVersionStatus.NOT_VULNERABLE:
            return "green";
        case SbomProjectVersionStatus.VULNERABLE:
            return "red";
    }
}
