
export enum VulSourceType {
    RSS = "RSS",
    TWITTER = "TWITTER",
    NVD20 = "NVD20",
    OSV = "OSV",
    CISA_KEV = "CISA_KEV",
    ROCKY_APOLLO = "ROCKY_APOLLO",
    CISCO_OPENVULN = "CISCO_OPENVULN",
    FORTINET_PSIRT = "FORTINET_PSIRT",
    CHECKPOINT_SUPPORT_CENTER = "CHECKPOINT_SUPPORT_CENTER"
}

export function formatVulSourceType(type?: VulSourceType) {
    switch (type) {
        case VulSourceType.NVD20:
            return "NIST NVD CVE API";
        case VulSourceType.OSV:
            return "OSV (osv.dev)";
        case VulSourceType.RSS:
            return "Atom / RSS";
        case VulSourceType.TWITTER:
            return "Twitter";
        case VulSourceType.CISA_KEV:
            return "CISA KEV";
        case VulSourceType.ROCKY_APOLLO:
            return "Rocky Linux Errata API";
        case VulSourceType.CISCO_OPENVULN:
            return "Cisco OpenVuln API";
        case VulSourceType.FORTINET_PSIRT:
            return "Fortinet PSIRT";
        case VulSourceType.CHECKPOINT_SUPPORT_CENTER:
            return "Check Point Support Center";
    }
}
