export enum FindingStatus {
    UNKNOWN = "UNKNOWN",
    NOT_AFFECTED = "NOT_AFFECTED",
    AFFECTED = "AFFECTED",
    UNDER_INVESTIGATION = "UNDER_INVESTIGATION"
}

export function formatFindingStatus(status?: FindingStatus) {
    switch (status) {
        case FindingStatus.UNKNOWN:
            return "unknown";
        case FindingStatus.NOT_AFFECTED:
            return "not affected";
        case FindingStatus.AFFECTED:
            return "affected";
        case FindingStatus.UNDER_INVESTIGATION:
            return "under investigation";
    }
}

export function colorForFindingstatus(status?: FindingStatus) {
    switch (status) {
        case FindingStatus.UNKNOWN:
            return "#d9d9d9";
        case FindingStatus.NOT_AFFECTED:
            return "#52c41a";
        case FindingStatus.AFFECTED:
            return "#f5222d";
        case FindingStatus.UNDER_INVESTIGATION:
            return "#fadb14";
    }
}
