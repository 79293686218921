import Organization from "./Organization";
import {UserRole} from "./UserRole";
import Role from "./auth/Role";
import {Permission} from "./auth/Permission";
import {Feature} from "./Feature";

class User {
    constructor(
        public id?: string,
        public friendlyId?: string,
        public username?: string,
        public role?: UserRole,
        public organization?: Organization,
        public roles?: Role[],
        public publicAccessAccount?: boolean,
        public scopelessPermissions?: Permission[],
        public features?: Feature[]) {
        //
    }

    public static fromPOJO(data: any) {
        const user = new User();

        user.id = data.id;
        user.friendlyId = data.friendlyId;
        user.username = data.username;
        user.role = data.role;
        user.organization = Organization.fromPOJO(data.organization);
        user.roles = (data.roles) ? data.roles.map(role => Role.fromPOJO(role)) : undefined;
        user.publicAccessAccount = data.publicAccessAccount;
        user.scopelessPermissions = data.scopelessPermissions;
        user.features = data.features;

        return user;
    }

    public isSystemAdmin(): boolean {
        return this.role === UserRole.SYSTEM_ADMIN;
    }

    public hasPermission(permission: Permission) {
        return this.isSystemAdmin() || this.scopelessPermissions.includes(permission);
    }

    public hasFeature(feature: Feature) {
        return this.isSystemAdmin() || this.features.includes(feature);
    }

    public hasOneOfPermission(permissions: Permission[]) {
        return this.isSystemAdmin() || permissions.some(permission => this.scopelessPermissions.includes(permission));
    }

    // @ts-ignore
    public get initials() {
        return this.username?.at(0)?.toUpperCase();
    }

    public get publicAccessUrl() {
        const url = new URL(window.location.href);

        return `${url.protocol}//${url.hostname}${url.port ? `:${url.port}` : ''}/p/${this.friendlyId}/`;
    }

}

export default User;
