.card {
    margin-bottom: 8px;
}

.card :global(.ant-card-body) {
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.label {
    font-weight: bold;
}

.line {
    margin-left: 8px;
    flex: 1;
    cursor: text;
}

.editor-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.add-link {
    padding-left: 6px;
}

.no-filter-applied {
    font-style: italic;
}

.editor {
    border: 1px solid #d9d9d9;
    background-color: #fafafa;;
    display: inline-flex;
    padding: 6px;
    border-radius: 4px;
    gap: 6px;
    align-items: center;
}

.editor :global(.ant-picker), .editor :global(.ant-input) {
    width: auto;
}

.editor-label {
    font-weight: bold;
}

.editor-clear {
    cursor: pointer;
}

.editor-select :global(.ant-select-selection-item-content), .editor-select :global(.ant-select-selection-item) {
    overflow: visible;
    max-width: 250px;
}

.editor-select {
    min-width: 80px;
    max-width: 300px;
}

.editor-attribute {
    display: flex;
    min-width: 160px;
    max-width: 300px;
}

.editor-input:global(.ant-input) {
    width: 120px;
}

.editor-input:global(.ant-input-affix-wrapper) {
    width: auto;
}

.editor-attribute :global(.ant-input) {
    min-width: 60px;
}

.editor-attribute :global(.ant-select) {
    min-width: 100px;
    overflow: hidden;
}

.editor-date-picker :global(.ant-picker-input){
    width: 150px;
}

.editor-attribute :global(.ant-select-selection-item) {
    padding-right: 24px !important;
}

.input-measure-node {
    position: absolute;
    top: 0;
    left: 0;
    white-space: pre;
    visibility: hidden;
}
