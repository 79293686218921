import SoftwareLicense from "./SoftwareLicense";
import Organization from "./Organization";

class SoftwareLicenseOverride {

    constructor(
        public id?: string,
        public organization?: Organization,
        public purl?: string,
        public vers?: string,
        public licenseKeys?: string[],
        public licenses?: SoftwareLicense[]) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const override = new SoftwareLicenseOverride();

        override.id = data.id;
        override.organization = Organization.fromPOJO(data.organization);
        override.purl = data.purl;
        override.vers = data.vers;
        override.licenseKeys = data.licenseKeys;
        override.licenses = (data.licenses) ? data.licenses.map(license => SoftwareLicense.fromPOJO(license)) : [];

        return override;
    }

}

export default SoftwareLicenseOverride;
