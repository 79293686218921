import {Breadcrumb, Button, Form, Input, message, Popover, Select} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, SoftwareLicensePolicyServiceContext, SoftwareLicenseServiceContext} from "../../Contexts";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import * as globalStyles from "../App.module.css";
import Organization from "../../domain/Organization";
import QueryOptions from "../../sal-ui/QueryOptions";
import {formatSoftwareLicenseCategory} from "../softwarelicense/functions";
import {QuestionCircleTwoTone} from "@ant-design/icons";
import PagedResult from "../../service/PagedResult";
import SoftwareLicense from "../../domain/SoftwareLicense";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function SoftwareLicensePolicyAdd() {
    const appContext = useContext(AppContextContext);
    const softwareLicenseService = useContext(SoftwareLicenseServiceContext);
    const softwareLicensePolicyService = useContext(SoftwareLicensePolicyServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const navigate = useNavigate();
    const [form] = useForm();
    const [organizations, setOrganizations] = useState<Organization[]>();
    const [categories, setCategories] = useState<string[]>();
    const [licenses, setLicenses] = useState<PagedResult<SoftwareLicense>>();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 16},
    };

    const title = "Add new software license policy";

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (appContext.user.isSystemAdmin()) {
            organizationService.getList(new QueryOptions("name", 100, 0)).then(value => {
                setOrganizations(value.data);
            });
        }

        softwareLicenseService.getCategories().then(setCategories);

        softwareLicenseService.getList(QueryOptions.newUnlimitedOrderedInstance("shortName")).then(setLicenses);
    }, [])

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb
                    items={[
                        {title: appContext.config?.appName},
                        {title: <Link to={"/software-license-policies"}>{"Software license policies"}</Link>},
                        {title: title}
                    ]}
                />

                <h1>{title}</h1>

                <Form {...layout} form={form} className={`${globalStyles["common__form"]}`} onFinish={onFinish}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                            {validator: serverViolationsHolder.createServerValidator('UNIQUE', 'name, organization_id', undefined, {compareLowerCaseValues: true}), message: "Name is already used."}
                        ]}>
                        <Input autoFocus={true} maxLength={100}/>
                    </Form.Item>

                    {appContext.user.isSystemAdmin() &&
                        <Form.Item
                            name={"organization"}
                            label={"Organization"}
                            rules={[{required: true, message: "Organization is required."}]}>
                            <Select>
                                {organizations && organizations!.map(organization => {
                                    return <Select.Option key={organization?.name} value={organization?.id!}>{organization?.name}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    }

                    <Form.Item
                        name={"categories"}
                        label={"Categories"}>
                        <Select mode={"multiple"}>
                            {categories?.map(category => {
                                return <Select.Option key={category}
                                                      value={category}>{category} <Popover title={category} content={formatSoftwareLicenseCategory(category)} overlayStyle={{maxWidth: 400}}><QuestionCircleTwoTone/></Popover></Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={"licenseKeys"}
                        label={"Licenses"}>
                        <Select mode={"multiple"}>
                            {licenses?.data?.map(license => {
                                return <Select.Option key={license.licenseKey} value={license.licenseKey}>{license.shortName}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate("/software-license-policies")}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    )

    function onFinish(values: any) {
        softwareLicensePolicyService.add(values)
            .then(
                (id) => {
                    message.success(<>Software license policy <b>{values.name}</b> successfully added.</>);

                    navigate(`/software-license-policies/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }
}

export default SoftwareLicensePolicyAdd;
