.statistics {
    display: inline-flex;
    vertical-align: middle;
    margin: 0 8px 0 16px;
}

.statistics :global(.ant-tag) {
    border-radius: 12px;
}

.chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.status-UNKNOWN {
    background-color: #f5222d;
    color: white;
}

.status-INCOMPLETE {
    background-color: #ffec3d;
}

.status-COMPLETE {
    color: white;
    background-color: #52c41a;
}

.progress {
    margin-right: 8px;
}