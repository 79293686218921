import {Breadcrumb, Button, Form, Input, message, Select, Switch} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, SbomProjectServiceContext, SbomProjectVersionServiceContext} from "../../Contexts";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import * as globalStyles from "../App.module.css";
import SbomProject from "../../domain/SbomProject";
import SingleResult from "../../service/SingleResult";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function SbomProjectVersionAdd() {
    const appContext = useContext(AppContextContext);
    const sbomProjectService = useContext(SbomProjectServiceContext);
    const sbomProjectVersionService = useContext(SbomProjectVersionServiceContext);
    const {sbomProjectId}: any = useParams();
    const [sbomProject, setSbomProject] = useState<SingleResult<SbomProject>>();
    const navigate = useNavigate();
    const [form] = useForm();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 16},
    };

    const title = <>New project version</>;

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        sbomProjectService.get(sbomProjectId!).then(setSbomProject);
    }, [])

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb
                    items={[
                        {title: appContext.config?.appName},
                        {title: <Link to={"/sbom-projects"}>{"SBOM projects"}</Link>},
                        {title: <Link to={`/sbom-projects/${sbomProjectId}`}>{sbomProject?.data?.name}</Link>},
                        {title: title}
                    ]}
                />

                <h1>{title}</h1>

                <Form {...layout} form={form} className={`${globalStyles["common__form"]}`} onFinish={onFinish}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                            {validator: serverViolationsHolder.createServerValidator('UNIQUE', 'name, project_id', undefined, {compareLowerCaseValues: true}), message: "Name is already used."}
                        ]}>
                        <Input autoFocus={true} maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={["tags"]}
                        label={"Tags"}>
                        <Select mode="tags" />
                    </Form.Item>

                    <Form.Item
                        name={"suppressInReports"}
                        label={"Suppress in reports"}
                        valuePropName={"checked"}
                        initialValue={false}>
                        <Switch />
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate(`/sbom-projects/${sbomProjectId}`)}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    )

    function onFinish(values: any) {
        sbomProjectVersionService.add(sbomProjectId, values)
            .then(
                (id) => {
                    message.success(<>Project version <b>{values.name}</b> of SBOM project <b>{sbomProject?.data?.name}</b> successfully added.</>);

                    navigate(`/sbom-projects/${sbomProjectId}/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }
}

export default SbomProjectVersionAdd;
