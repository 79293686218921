import React, {useContext, useState} from "react";
import {AppContextContext, AuthServiceContext} from "../../Contexts";
import {Avatar, Menu, Popover} from "antd";
import * as styles from "./ProfileMenu.module.css";
import {DesktopOutlined, LogoutOutlined} from "@ant-design/icons";
import {formatDateTime} from "../../utils/FormatUtils";
import {runInAction} from "mobx";
import {useNavigate} from "react-router-dom";

function ProfileMenu() {
    const appContext = useContext(AppContextContext);
    const authService = useContext(AuthServiceContext);
    const navigate = useNavigate();
    const [userMenuVisible, setUserMenuVisible] = useState(false);

    const profileMenuItems = [
        {
            key: "presentation-mode",
            icon: <DesktopOutlined/>,
            label: "Presentation mode",
            onClick: () => runInAction(() => appContext.presentationMode = true)
        },
        {
            key: "logout",
            icon: <LogoutOutlined/>,
            label: "Logout",
            onClick: () => {
                logout();

                setUserMenuVisible(false);
            }
        }
    ];

    return (
        <Popover trigger={"click"}
                 placement={"bottomRight"}
                 open={userMenuVisible}
                 destroyTooltipOnHide={true}
                 onOpenChange={visible => setUserMenuVisible(visible)}
                 overlayClassName={styles['avatar-popover']}
                 content={
                     <>
                         <div className={styles['avatar-popover-header']}>
                             <div className={styles['avatar-popover-title']}>Logged in as:</div>
                             <div><b>{appContext.user?.username}</b></div>

                             {appContext.jwtTokenExpiresAt &&
                                 <>
                                     <div className={styles['avatar-popover-title']}>Session expires at:</div>
                                     <div>{formatDateTime(appContext.jwtTokenExpiresAt)}</div>
                                 </>
                             }
                         </div>

                         <Menu mode={"inline"} items={profileMenuItems}/>
                     </>

                 }>
            <Avatar className={styles.avatar}>{appContext.user?.initials}</Avatar>
        </Popover>
    );

    function logout() {
        authService.logout().then(() => navigate("/"))
    }

}

export default ProfileMenu;