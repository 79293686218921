import {VulSourceItemServiceContext} from "../../Contexts";
import VulSourceItem from "../../domain/VulSourceItem";
import VulSourceItemFlagStats from "../../domain/VulSourceItemFlagStats";
import * as styles from "./ItemVote.module.css";
import {Button} from "antd";
import {DislikeFilled, DislikeOutlined, LikeFilled, LikeOutlined} from "@ant-design/icons";
import React, {useContext, useEffect, useState} from "react";

function ItemVote(props: { item: VulSourceItem, onVote: any }) {
    const vulSourceItemService = useContext(VulSourceItemServiceContext);

    const item = props.item;

    const [importance, setImportance] = useState<number>();
    const [pending, setPending] = useState<boolean>();

    // v ms
    const onVoteTimeout = 10;

    useEffect(() => {
        setImportance(calculateImportance(props.item.flagStats));
        setPending(isPending(props.item.flagStats));
    }, [props]);

    function calculateImportance(flagStats: VulSourceItemFlagStats) {

        if (flagStats.important === 0 && flagStats.notImportant === 0) {
            return 50;
        }
        if (flagStats.important === 0 && flagStats.notImportant !== 0) return 0;
        if (flagStats.important !== 0 && flagStats.notImportant === 0) return 100;
        if (flagStats.important !== 0 && flagStats.notImportant !== 0) {
            return (flagStats.important * 100) / (flagStats.important + flagStats.notImportant);
        }

        return 50;
    }

    function isPending(flagStats: VulSourceItemFlagStats) {
        return item.flagStats.important === 0 && item.flagStats.notImportant === 0;
    }

    return (
        <div className={styles['container']}>
            <div className={`${styles['action']} ${styles['dislike']}`}>
                <Button icon={item.flags && item.flags.notImportant ? <DislikeFilled/> : <DislikeOutlined/>}
                        title={"Dislike"}
                        onClick={() => {
                            vulSourceItemService.thumbDown(item);
                            if (!item.flags) {
                                item.flags = {important: false, notImportant: false};
                            }

                            const notImportant = item.flags ? !item.flags.notImportant : true;
                            const important = false;

                            if (item.flags.notImportant && !notImportant) {
                                item.flagStats.notImportant--;
                            }
                            if (!item.flags.notImportant && notImportant) {
                                item.flagStats.notImportant++;
                            }
                            if (item.flags.important && !important) {
                                item.flagStats.important--;
                            }
                            item.flags.important = important;
                            item.flags.notImportant = notImportant;

                            setTimeout(props.onVote, onVoteTimeout, item);
                        }}/>
                {item.flagStats.notImportant}
            </div>
            <div className={`${styles['action']} ${styles['like']}`}>
                {item.flagStats.important}
                <Button icon={item.flags && item.flags.important ? <LikeFilled/> : <LikeOutlined/>}
                        title={"Like"}
                        onClick={() => {
                            vulSourceItemService.thumbUp(item);

                            if (!item.flags) {
                                item.flags = {important: false, notImportant: false};
                            }

                            const important = item.flags ? !item.flags.important : true;
                            const notImportant = false;

                            if (item.flags.important && !important) {
                                item.flagStats.important--;
                            }
                            if (!item.flags.important && important) {
                                item.flagStats.important++;
                            }
                            if (item.flags.notImportant && !notImportant) {
                                item.flagStats.notImportant--;
                            }
                            item.flags.important = important;
                            item.flags.notImportant = notImportant;

                            setTimeout(props.onVote, onVoteTimeout, item);
                        }}/>
            </div>
            <div className={`${styles['graph']} ${pending ? styles['pending'] : ''}`}>
                <div className={styles['graph-dislike']} style={{width: `${100 - importance}%`}}></div>
                <div className={styles['graph-like']} style={{width: `${importance}%`}}></div>
            </div>
        </div>
    );
}

export default ItemVote;