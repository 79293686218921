export enum AttributeExtractionSource {
    JSON_DATA = "JSON_DATA",
    ATTRIBUTES = "ATTRIBUTES"
}

export function formatAttributeExtractionSource(source: AttributeExtractionSource) {
    switch (source) {
        case AttributeExtractionSource.JSON_DATA:
            return "JSON data";
        case AttributeExtractionSource.ATTRIBUTES:
            return "attributes";
        default:
            return source;
    }
}
