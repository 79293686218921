import Axios from "axios";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";
import SbomProject from "../domain/SbomProject";
import SingleResult from "./SingleResult";

class SbomProjectService {

    readonly basePath = "/sbom-projects";

    public add(sbomProject: SbomProject): Promise<string> {
        return Axios.post(`${this.basePath}`, sbomProject).then(value => value.data.id)
    }

    public update(sbomProjectId: string, sbomProject: SbomProject): Promise<void> {
        return Axios.put(`${this.basePath}/${sbomProjectId}`, sbomProject);
    }

    public get(id: string): Promise<SingleResult<SbomProject>> {
        return Axios.get(`${this.basePath}/${id}`)
            .then(value => {
                return new SingleResult(SbomProject.fromPOJO(value.data.data), value.data.permissions);
            })
    }

    public delete(sbomProject: SbomProject): Promise<void> {
        return Axios.delete(`${this.basePath}/${sbomProject.id}`);
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<SbomProject>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: SbomProject[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(SbomProject.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList, value.data.permissions);
            })
    }

}

export default SbomProjectService;
