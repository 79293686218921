export enum VulSourceHealth {
    HEALTHY = "HEALTHY",
    LAST_RUN_OUT_OF_DATE = "LAST_RUN_OUT_OF_DATE",
    LATEST_ITEM_PUBLISHED_AT_OUT_OF_DATE = "LATEST_ITEM_PUBLISHED_AT_OUT_OF_DATE"
}

export function formatVulSourceHealth(health: VulSourceHealth) {
    switch (health) {
        case VulSourceHealth.HEALTHY:
            return 'healthy';
        case VulSourceHealth.LAST_RUN_OUT_OF_DATE:
            return 'last collection out of date';
        case VulSourceHealth.LATEST_ITEM_PUBLISHED_AT_OUT_OF_DATE:
            return 'latest item out of date';

    }
}