import VulSource from "./VulSource";
import AttributeDefinition from "./AttributeDefinition";
import VulView from "./VulView";

export interface ObjectsDifference<T> {
    newObjects: T[];
    unchangedObjects: T[];
    changedObjects: T[];
}

export function formatObjectsDifference<T>(difference: ObjectsDifference<T>) {
    const tokens: string[] = [];

    if (difference.newObjects.length > 0) {
        tokens.push(`${difference.newObjects.length} new`);
    }

    if (difference.changedObjects.length > 0) {
        tokens.push(`${difference.changedObjects.length} changed`);
    }

    if (difference.unchangedObjects.length > 0) {
        tokens.push(`${difference.unchangedObjects.length} unchanged`);
    }

    return (tokens.length === 0) ? "no items" : tokens.join(", ");
}

export interface ImportAnalysis {
    sourceDifferences: ObjectsDifference<VulSource>;
    attributeDifferences: ObjectsDifference<AttributeDefinition>;
    viewDifferences: ObjectsDifference<VulView>;
    changeLog: any;
}
