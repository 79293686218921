export enum FindingSeverity {
    UNKNOWN = "UNKNOWN",
    NONE = "NONE",
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
    CRITICAL = "CRITICAL"
}

export function getEqualOrGreaterSeverities(severity: FindingSeverity) {
    const index = Object.keys(FindingSeverity).indexOf(severity);

    return Object.keys(FindingSeverity).filter((value, idx) => idx >= index);
}

export function findingSeverityForScore(score: number) {
    if (score == 0) {
        return FindingSeverity.NONE;
    } else if (score > 0 && score < 4) {
        return FindingSeverity.LOW;
    } else if (score >= 4 && score < 7) {
        return FindingSeverity.MEDIUM;
    } else if (score >= 7 && score < 9) {
        return FindingSeverity.HIGH;
    } else if (score >= 9 && score <= 10) {
        return FindingSeverity.CRITICAL;
    } else {
        return FindingSeverity.UNKNOWN;
    }
}
