.query-view {
    display: block;
}

.query-view :global(.ant-card-head-title) {
    font-weight: normal;
}

.card-content:not(:first-child) {
    margin-top: 0.5em;
}