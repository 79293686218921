import {ValueEditorProps} from "react-querybuilder";
import * as tackStyles from "../vulsourceitem/Tack.module.css";
import {Select, Tooltip} from "antd";
import {AntDValueEditor} from "@react-querybuilder/antd";
import React from "react";
import ReactMarkdown from "react-markdown";
import * as globalStyles from "../App.module.css";
import {QuestionCircleTwoTone} from "@ant-design/icons";
import {vulSourceSystemLastComparator} from "../../domain/VulSource";
import * as descriptionStyles from "../SelectItemDescription.module.css";

function CustomValueEditor(props: ValueEditorProps) {
    if (props.fieldData.name === 'source.id') {
        const sources = props.fieldData.values?.sort(vulSourceSystemLastComparator);

        return (
            <Select mode={"multiple"}
                    filterOption={(inputValue, option) => {
                        const normalizedInput = inputValue.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
                        const normalizedLabel = option.label?.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");

                        return normalizedLabel.includes(normalizedInput);
                    }}
                    popupMatchSelectWidth={350}
                    optionLabelProp={"label"}
                    virtual={false}
                    value={props.value}
                    onChange={value => props.handleOnChange(value)}>

                {sources?.map(source =>
                    <Select.Option key={source.id} value={source.id} label={source.name}>
                        {source.owner === 'SYSTEM' && <div className={`${tackStyles.tack} ${tackStyles['tack-seal']}`}><span className={tackStyles['tack-text']} color={"#440084"}>SEAL</span></div>}
                        {source.owner === 'ORGANIZATION' && <div className={`${tackStyles.tack} ${tackStyles['tack-org']}`}><span className={tackStyles['tack-text']} color={"#87d068"}>ORG</span></div>}

                        <div className={`${tackStyles.item}`}>
                            <div className={descriptionStyles['name-container']}>
                                <span className={descriptionStyles.name}>{source.name}</span>
                                {source.description && <Tooltip title={<ReactMarkdown className={globalStyles.markdown} children={source.description}/>}><QuestionCircleTwoTone/></Tooltip>}
                            </div>
                            <div className={descriptionStyles.description}>{source.description}</div>
                        </div>
                    </Select.Option>
                )}

            </Select>
        );
    }

    return (
        <AntDValueEditor {...props} />
    );
}

export default CustomValueEditor;