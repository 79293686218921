.filter {
    max-width: 1500px;
}

.table {
    max-width: 1500px;
}

.description {
    margin-top: 8px;
    font-size: 12px;
}

.column-name {
    padding-left: 2em !important;
}
