import React, {useContext, useEffect, useState} from "react";
import {ApiTokenServiceContext, AppContextContext, UserServiceContext} from "../../Contexts";
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "antd/es/form/Form";
import {DocumentTitle} from "../DocumentTitle";
import {Breadcrumb, Button, Card, Form, Input, message, Select} from "antd";
import * as globalStyles from "../App.module.css";
import * as styles from "./ApiTokenAdd.module.css";
import User from "../../domain/User";
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import ApiToken from "../../domain/ApiToken";
import {CopyOutlined} from "@ant-design/icons";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function ApiTokenAdd() {
    const appContext = useContext(AppContextContext);
    const userService = useContext(UserServiceContext);
    const apiTokenService = useContext(ApiTokenServiceContext);
    const navigate = useNavigate();
    const [users, setUsers] = useState<User[]>();
    const [form] = useForm();
    const [apiToken, setApiToken] = useState<ApiToken>();
    const [inProgress, setInProgress] = useState(false);

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    const title = "New API token";

    useEffect(() => {
        userService
            .getList(QueryOptions.newUnlimitedOrderedRqbQueryInstance("username", {combinator: "and", rules: [{field: "role", operator: "eq", value: "USER"}]}))
            .then(result => setUsers(result.data));
    }, []);

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={"common__breadcrumb"} items={[
                    {title: appContext.config?.appName},
                    {title: <Link to={"/api-tokens"}>API tokens</Link>},
                    {title}
                ]}/>

                <h1>{title}</h1>

                {apiToken &&
                    <Card title={"API token created"} className={styles.card}>
                        <p>API token <b>{apiToken.name}</b> was successfully created and bound to user <b>{apiToken.user.username}</b>:</p>

                        <div className={styles.token}>
                            <Input.TextArea readOnly={true} autoSize={true} value={apiToken.token}/>

                            <Button className={styles.copy} icon={<CopyOutlined/>} type={"text"} onClick={() => {
                                navigator.clipboard.writeText(apiToken.token);

                                message.info("API token copied to clipboard.");
                            }}/>
                        </div>

                        <p style={{marginTop: 8}}>Please write it down now, it will not be possible to retrieve it later in the future.</p>

                        <div style={{marginTop: 8}}>
                            <Button type={"primary"} onClick={() => navigate("/api-tokens")}>Close</Button>
                        </div>
                    </Card>
                }

                {apiToken === undefined &&
                    <Form {...layout} form={form} className={`${globalStyles["common__form"]} ${globalStyles["common_form--add"]}`} onFinish={onFinish}>
                        <Form.Item
                            name={"name"}
                            label={"Name"}
                            rules={[
                                {required: true, message: "Name is required."},
                            ]}>
                            <Input maxLength={100} autoFocus={true}/>
                        </Form.Item>

                        <Form.Item
                            name={"user"}
                            label={"User"}
                            extra={"All API calls authenticated with this API token are performed as selected user."}
                            rules={[
                                {required: true, message: "User is required."}
                            ]}>
                            <Select options={users?.map(user => ({
                                label: user.username,
                                value: user.id
                            }))}/>
                        </Form.Item>

                        <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                            <Button type={"primary"} htmlType={"submit"} disabled={inProgress}>Add</Button>
                            <Button onClick={() => navigate("/api-tokens")}>Cancel</Button>
                        </Form.Item>
                    </Form>
                }
            </>
        </DocumentTitle>
    )

    function onFinish(values: any) {
        setInProgress(true);

        apiTokenService.add(values)
            .then(
                (response) => {
                    message.success(<>API token <b>{values.name}</b> successfully created.</>);

                    setApiToken(response);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            )
            .finally(() => setInProgress(false));
    }

}

export default ApiTokenAdd;
