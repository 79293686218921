import {DeleteFilled, EditFilled, LoadingOutlined} from "@ant-design/icons";
import Collapse from "@kunukn/react-collapse";
import {Breadcrumb, Button, Col, Descriptions, Form, Input, message, Modal, Row, Select, Spin, Table, Timeline} from "antd";
import {useForm} from "antd/es/form/Form";
import Column from "antd/es/table/Column";
import React, {useContext, useEffect, useState} from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, AttributeDefinitionServiceContext, TicketServiceContext} from "../../Contexts";
import Ticket from "../../domain/Ticket";
import TicketComment from "../../domain/TicketComment";
import {TicketSeverity} from "../../domain/TicketSeverity";
import {TicketState} from "../../domain/TicketState";
import User from "../../domain/User";
import VulSourceItem from "../../domain/VulSourceItem";
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {formatDateTime} from "../../utils/FormatUtils";
import {DocumentTitle} from "../DocumentTitle";
import * as styles from "./TicketDetail.module.css";
import * as globalStyles from "../App.module.css";
import VulSourceItemDescription from "../vulsourceitem/VulSourceItemDescription";
import AttributeDefinition from "../../domain/AttributeDefinition";

function TicketDetail() {
    const appContext = useContext(AppContextContext);
    const ticketService = useContext(TicketServiceContext);
    const attributeDefinitionService = useContext(AttributeDefinitionServiceContext);
    const navigate = useNavigate();
    const {ticketId}: any = useParams();
    const [editMode, setEditMode] = useState(false);
    const [ticket, setTicket] = useState<Ticket>();
    const [editForm] = useForm();
    const [commentForm] = useForm();
    const [editCommentForm] = useForm();
    const [attributeDefinitions, setAttributeDefinitions] = useState<AttributeDefinition[]>();
    const [users, setUsers] = useState<User[]>();
    const [editedComment, setEditedComment] = useState<TicketComment>();
    const [addInProgress, setAddInProgress] = useState(false);

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 8, span: 16},
    };

    const serverViolationsHolder = new ServerConstraintViolationsHolder();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        attributeDefinitionService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setAttributeDefinitions(value.data));

        reload();
    }, [])

    return (
        <DocumentTitle title={ticket?.title}>
            <>
                <Breadcrumb className={globalStyles.common__breadcrumb}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/tickets"}>Tickets</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{ticket?.title}</Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={!ticket} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{ticket?.title}</h1>

                    <div className={globalStyles['common__top-button-bar']}>
                        <Button onClick={() => setEditMode(!editMode)}>Edit</Button>

                        <Button danger={true}
                                title={"Delete"}
                                onClick={() => {
                                    Modal.confirm({
                                        content: "Do you really want to delete this ticket?",
                                        okText: "Delete",
                                        cancelText: "Cancel",
                                        okButtonProps: {danger: true},
                                        onOk: () => onDeleteConfirm(ticket!)
                                    });
                                }}>Delete</Button>
                    </div>

                    {renderEditForm()}

                    <Descriptions column={1} bordered={true} size="small" className={globalStyles.details}>
                        <Descriptions.Item label={"Title"}>{ticket?.title}</Descriptions.Item>
                        <Descriptions.Item label={"Description"}>
                            {ticket?.description && <div dangerouslySetInnerHTML={{__html: `${ticket?.description}`}}/>}
                        </Descriptions.Item>
                        <Descriptions.Item label={"Author"}>{ticket?.author.username} ({ticket?.author.organization.name})</Descriptions.Item>
                        <Descriptions.Item label={"Created"}>{formatDateTime(ticket?.created)}</Descriptions.Item>
                        <Descriptions.Item label={"Severity"}>{ticket?.severity}</Descriptions.Item>
                        <Descriptions.Item label={"State"}>{ticket?.state}</Descriptions.Item>
                        <Descriptions.Item label={"Assignees"}>{ticket?.assignees.map(user => user.username).join(", ")}</Descriptions.Item>
                    </Descriptions>

                    {ticket?.comments && <>
                        <h2>Comments</h2>

                        <div className={styles['timeline']}>
                            <Timeline
                                items={ticket?.comments.map(comment => {
                                    return {
                                        children:
                                            <Row>
                                                <Col span={3}>
                                                    <b>{comment.user.username}</b><br/>
                                                    <span style={{opacity: 0.6}}>{formatDateTime(comment.created)}</span>
                                                </Col>
                                                <Col span={19}>
                                                    {editedComment && editedComment.id === comment.id ?
                                                        <Form form={editCommentForm}
                                                              layout={"vertical"}
                                                              onFinish={onFinishEditComment}
                                                              style={{paddingRight: 0, paddingTop: 0, paddingBottom: 0, margin: 0}}
                                                        >
                                                            <Row>
                                                                <Col span={18}>
                                                                    <Form.Item
                                                                        name={"content"}
                                                                        rules={[
                                                                            {required: true, message: "Text required"},
                                                                        ]}>
                                                                        <ReactQuill theme="snow"/>
                                                                    </Form.Item>

                                                                </Col>
                                                                <Col span={6} style={{textAlign: "right"}}>
                                                                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]} style={{paddingRight: 0}}>
                                                                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                                                                        <Button onClick={() => setEditedComment(undefined)}>{"Cancel"}</Button>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>

                                                        </Form>
                                                        : <div dangerouslySetInnerHTML={{__html: `${comment.content}`}}/>
                                                    }
                                                </Col>
                                                <Col span={2} style={{textAlign: "right"}}>
                                                    {appContext.user.username === comment.user.username && <>
                                                        <Button icon={<EditFilled/>} className={globalStyles["ant-btn-icon-only"]}
                                                                title={"Edit"}
                                                                onClick={() => {
                                                                    setEditedComment(comment);
                                                                    editCommentForm.setFieldValue("content", comment.content);
                                                                }}/>

                                                        <Button icon={<DeleteFilled/>} className={globalStyles["ant-btn-icon-only"]}
                                                                title={"Delete"}
                                                                onClick={() => {
                                                                    Modal.confirm({
                                                                        content: "Do you really want to delete this comment?",
                                                                        okText: "Delete",
                                                                        cancelText: "Cancel",
                                                                        onOk: () => onCommentDeleteConfirm(comment)
                                                                    });
                                                                }}/>
                                                    </>
                                                    }
                                                </Col>
                                            </Row>
                                    };
                                })}
                            />
                            {renderCommentForm()}
                        </div>

                    </>}

                    {ticket?.originalItem && <>
                        <h2>Original item</h2>

                        <Table className={styles['item-table']}
                               dataSource={[ticket?.originalItem]}
                               size="middle"
                               pagination={false}
                               rowKey="id">

                            <Column dataIndex="id" title={"ID"} width={80} align={"center"} render={renderId}/>
                            <Column dataIndex={["source", "name"]} title={"Source"} className={styles['column-source']} render={renderName}/>
                            <Column dataIndex={"attributes"} title={"Description"}
                                    render={(value, item: VulSourceItem) => <VulSourceItemDescription sourceType={item.source.sourceType} attributes={value}
                                                                                                      attributeDefinitions={attributeDefinitions}/>}/>
                            <Column dataIndex="lastModified" title={"Last modification"}
                                    width={140} align={"left"} render={value => formatDateTime(value)}/>
                        </Table>
                    </>}

                    {ticket?.relatedItems && <>
                        <h2>Related items</h2>

                        <Table className={styles['item-table']}
                               dataSource={ticket?.relatedItems}
                               size="middle"
                               pagination={false}
                               rowKey="id">

                            <Column dataIndex="id" title={"ID"} width={80} align={"center"} render={renderId}/>
                            <Column dataIndex={["source", "name"]} title={"Source"} className={styles['column-source']} render={renderName}/>
                            <Column dataIndex={"attributes"} title={"Description"}
                                    render={(value, item: VulSourceItem) => <VulSourceItemDescription sourceType={item.source.sourceType} attributes={value}
                                                                                                      attributeDefinitions={attributeDefinitions}/>}/>
                            <Column dataIndex="lastModified" title={"Last modification"}
                                    width={140} align={"left"} render={value => formatDateTime(value)}/>
                        </Table>
                    </>}
                </Spin>
            </>
        </DocumentTitle>
    )

    function renderName(value: any, vulSourceItem: VulSourceItem) {
        return <Link to={`/vul-source-items/${vulSourceItem.id}`}>{value}</Link>;
    }

    function renderId(value: string) {
        return <Link to={`/vul-source-items/${value}`}>
            <span title={value}>{value.substring(0, 2) + '...' + value.substring(value.length - 2)}</span>
        </Link>
    }

    function renderEditForm() {
        return (
            <Collapse isOpen={editMode}>
                <h3>Edit ticket</h3>

                <Form {...layout} form={editForm}
                      className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`}
                      onFinish={onFinishEdit}>
                    <Form.Item
                        name={"title"}
                        label={"Title"}
                        rules={[
                            {required: true, message: "Name is required."},
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={["assignees"]}
                        label={"Assignees"}
                    >

                        <Select showSearch={true} mode={"multiple"}
                                options={users?.map(user => ({
                                    value: user.id,
                                    label: user.username
                                }))}>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={["severity"]}
                        label={"Severity"}
                        rules={[{required: true, message: "required"}]}>

                        <Select>
                            {Object.keys(TicketSeverity).map(value => {
                                return <Select.Option key={value} value={value}>{value}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={["state"]}
                        label={"State"}
                        rules={[{required: true, message: "required"}]}>

                        <Select>
                            {Object.keys(TicketState).map(value => {
                                return <Select.Option key={value} value={value}>{value}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={"description"}
                        label={"Description"}>
                        <ReactQuill theme="snow" />
                    </Form.Item>


                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => setEditMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function renderCommentForm() {
        return (
            <Form form={commentForm}
                  layout={"vertical"}
                  onFinish={onFinishComment}
                  style={{paddingRight: 0, paddingTop: 0, paddingBottom: 0}}>
                <Row>
                    <Col span={22}>
                        <Form.Item
                            name={"content"}
                            rules={[
                                {required: true, message: "Text required"},
                            ]}>
                            <ReactQuill theme="snow"/>
                        </Form.Item>
                    </Col>

                    <Col span={2} style={{textAlign: "right"}}>
                        <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]} style={{paddingRight: 0}}>
                            <Button type={"primary"} htmlType={"submit"} disabled={addInProgress}>Add</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }

    function onFinishComment(values: any) {
        setAddInProgress(true)

        ticketService.addComment(ticket!, values.content)
            .then(
                () => {
                    message.success("Comment added successfully.");

                    commentForm.resetFields();

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            )
            .finally(() => setAddInProgress(false));
    }

    function onFinishEditComment(values: any) {
        ticketService.updateComment(editedComment, values.content)
            .then(
                () => {
                    message.success("Comment updated successfully.");

                    editCommentForm.resetFields();

                    setEditedComment(undefined);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }

    function onFinishEdit(values: any) {
        ticketService.update(ticketId!, values)
            .then(
                () => {
                    message.success("Ticket updated successfully.");

                    setEditMode(false);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }

    function onDeleteConfirm(ticket: Ticket) {
        ticketService.delete(ticket)
            .then(() => {
                message.success("Ticket deleted successfully.");

                navigate(`/tickets`)
            });
    }

    function onCommentDeleteConfirm(comment: TicketComment) {
        ticketService.deleteComment(comment)
            .then(() => {
                message.success("Comment deleted successfully.");

                reload();
            });
    }

    function reload() {
        ticketService.getAvailableUserList(QueryOptions.newUnlimitedOrderedInstance("username")).then(value => {
                setUsers(value.data);

                ticketService.get(ticketId!).then(ticket => {
                    setTicket(ticket);

                    editForm.setFieldsValue(ticket);

                    editForm.setFieldValue("assignees", ticket.assignees.map(user => user.id));
                });
            }
        );
    }

}

export default TicketDetail;
