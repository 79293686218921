.score {
    display: inline-block;
    padding: 2px 8px;
    font-size: 12px;
    border-radius: 4px;
    margin-left: 4px;
}

.severity-UNKNOWN {
    color: black;
    background-color: #f5f5f5;
}

.severity-border-UNKNOWN {
    border-color: #cccccc;
}

.severity-NONE {
    color: black;
    background-color: #d9d9d9;
}

.severity-border-NONE {
    border-color: #d9d9d9;
}

.severity-LOW {
    color: black;
    background-color: #91caff;
}

.severity-border-LOW {
    border-color: #91caff;
}

.severity-MEDIUM {
    color: black;
    background-color: #ffec3d;
}

.severity-border-MEDIUM {
    border-color: #ffec3d;
}

.severity-HIGH {
    color: black;
    background-color: #ffa940;
}

.severity-border-HIGH {
    border-color: #ffa940;
}

.severity-CRITICAL {
    color: white;
    background-color: #f5222d;
}

.severity-border-CRITICAL {
    border-color: #f5222d;
}
