import {LoadingOutlined, QuestionCircleTwoTone} from "@ant-design/icons";
import Collapse from "@kunukn/react-collapse";
import {Breadcrumb, Button, Card, Descriptions, Form, Input, Popover, Select, Spin} from "antd";
import {useForm, useWatch} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, SoftwareLicenseServiceContext} from "../../Contexts";
import Organization from "../../domain/Organization";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import "./SoftwareLicenseDetail.module.css";
import *as globalStyles from "../App.module.css";
import SoftwareLicense from "../../domain/SoftwareLicense";
import {ResourceOwnerType} from "../../domain/ResourceOwnerType";
import QueryOptions from "../../sal-ui/QueryOptions";
import {formatSoftwareLicenseOrigin} from "../../domain/SoftwareLicenseOrigin";
import * as styles from "./SoftwareLicenseDetail.module.css";
import {formatSoftwareLicenseCategory} from "./functions";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function SoftwareLicenseDetail() {
    const appContext = useContext(AppContextContext);
    const softwareLicenseService = useContext(SoftwareLicenseServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const navigate = useNavigate();
    const {licenseId}: any = useParams();
    const [editMode, setEditMode] = useState(false);
    const [license, setLicense] = useState<SoftwareLicense>();
    const [organizations, setOrganizations] = useState<Organization[]>();
    const [editForm] = useForm();
    const owner = useWatch("owner", editForm);

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (appContext.user.isSystemAdmin()) {
            organizationService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setOrganizations(value.data));
        }

        reload();
    }, [licenseId])

    return (
        <DocumentTitle title={license?.name}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/software-licenses"}>Software licenses</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{license?.name}</Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={!license} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{license?.name}</h1>

                    {renderEditForm()}

                    <Descriptions column={1} bordered={true} size="small" className={globalStyles.details}>
                        {license?.owner === ResourceOwnerType.ORGANIZATION && <Descriptions.Item label={"Owner"}>{license?.organization?.name}</Descriptions.Item>}
                        {license?.owner === ResourceOwnerType.SYSTEM && <Descriptions.Item label={"Owner"}><i>System license</i></Descriptions.Item>}
                        <Descriptions.Item label={"Origin"}>{formatSoftwareLicenseOrigin(license)}</Descriptions.Item>
                        {appContext.user.isSystemAdmin() && <Descriptions.Item label={"License key"}>{license?.licenseKey}</Descriptions.Item>}
                        <Descriptions.Item label={"Short name"}>{license?.shortName}</Descriptions.Item>
                        <Descriptions.Item label={"Name"}>{license?.name}</Descriptions.Item>
                        <Descriptions.Item label={"SPDX identifiers"}>{license?.allSpdxLicenseKeys?.map(license => <div>{license}</div>)}</Descriptions.Item>
                        {license?.aliases?.length > 0 && <Descriptions.Item label={"Aliases"}>{license?.aliases.map(alias => <div>{alias}</div>)}</Descriptions.Item>}
                        <Descriptions.Item label={"License owner"}>{license?.licenseOwner}</Descriptions.Item>
                        <Descriptions.Item label={"Category"}>
                            {license?.category} <Popover title={license?.category} content={formatSoftwareLicenseCategory(license?.category)} overlayStyle={{maxWidth: 400}}><QuestionCircleTwoTone/></Popover>
                        </Descriptions.Item>
                        {license?.homepageUrl && <Descriptions.Item label={"Homepage"}><Link to={license?.homepageUrl}>{license?.homepageUrl}</Link></Descriptions.Item>}
                        {appContext.user.isSystemAdmin() && <Descriptions.Item label={"Last modified"}>{license?.lastModified}</Descriptions.Item>}
                    </Descriptions>

                    <Card title="License text" className={styles.card}>
                        {license?.text && <code className={` ${styles.text}`}>{license?.text}</code>}
                    </Card>
                </Spin>
            </>
        </DocumentTitle>
    )

    function renderEditForm() {
        return (
            <Collapse isOpen={editMode}>
                <h3>Edit of organization {license?.name}</h3>

                <Form {...layout} form={editForm} className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`} onFinish={onFinishEdit}>
                    {appContext.user.isSystemAdmin() && <>
                        <Form.Item
                            name={["owner"]}
                            label={"Owner"}
                            rules={[{required: true, message: "Owner is required."}]}
                            initialValue={ResourceOwnerType.SYSTEM}>
                            <Select options={Object.keys(ResourceOwnerType).map(value => ({value: value, label: value}))}/>
                        </Form.Item>

                        {owner === ResourceOwnerType.ORGANIZATION && <Form.Item
                            name={"organization"}
                            label={"Organization"}
                            rules={[{required: true, message: "Organization is required."}]}>
                            <Select
                                allowClear={true}
                                options={organizations?.map(organization => ({value: organization.id, label: organization.name}))}
                            />
                        </Form.Item>}
                    </>}

                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                            {validator: serverViolationsHolder.createServerValidator('UNIQUE', 'name', undefined, {compareLowerCaseValues: true}), message: "Name is already used."}
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => setEditMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function onFinishEdit(values: any) {
        /*
                softwareLicenseService.update(organizationId!, values)
                    .then(
                        (id) => {
                            message.success(`Organization ${values.name} successfully updated.`);

                            setEditMode(false);

                            reload();
                        },
                        error => serverViolationsHolder.handleServerError(error, editForm)
                    );
        */
    }

    function onDeleteConfirm(organization: Organization) {
        /*
                softwareLicenseService.delete(organization)
                    .then(() => {
                        message.success(`Organization ${organization.name} successfully deleted.`);

                        navigate(`/organizations`)
                    });
        */
    }

    function reload() {
        softwareLicenseService.get(licenseId!).then(license => {
            setLicense(license);

            editForm.setFieldsValue(license);
        });
    }

}

export default SoftwareLicenseDetail;
