.content {
    padding: 8px;
    width: 450px;
}

.content :global(.ant-checkbox-wrapper) {
    margin-inline-start: 0;
}

.section-title {
    font-weight: bold;
    font-size: 14px;
    margin: 8px 0;
}

.columns {
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.button-bar {
    text-align: right;
    padding: 8px;
}

.refresh-interval-input {
    width: 50px;
    margin-left: 0.5em;
    margin-right: 0.5em !important;
}
