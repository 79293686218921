import SbomComponent from "./SbomComponent";
import VulSourceItem from "./VulSourceItem";
import {FindingSeverity} from "./FindingSeverity";
import {FindingStatus} from "./FindingStatus";
import {FindingJustification} from "./FindingJustification";

class SbomComponentFinding {

    constructor(
        public id?: string,
        public component?: SbomComponent,
        public vulSourceItem?: VulSourceItem,
        public severity?: FindingSeverity,
        public status?: FindingStatus,
        public justification?: FindingJustification,
        public impactStatement?: string,
        public actionStatement?: string) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const sbomProjectFinding = new SbomComponentFinding();

        sbomProjectFinding.id = data.id;
        sbomProjectFinding.component = (data.component) ? SbomComponent.fromPOJO(data.component) : undefined;
        sbomProjectFinding.vulSourceItem = (data.vulSourceItem) ? VulSourceItem.fromPOJO(data.vulSourceItem) : undefined;
        sbomProjectFinding.severity = data.severity;
        sbomProjectFinding.status = data.status;
        sbomProjectFinding.justification = data.justification;
        sbomProjectFinding.impactStatement = data.impactStatement;
        sbomProjectFinding.actionStatement = data.actionStatement;

        return sbomProjectFinding;
    }

}

export default SbomComponentFinding;
