import React, {useContext, useEffect, useState} from "react";
import {SbomProjectVersionServiceContext} from "../../Contexts";
import SbomProjectVersion from "../../domain/SbomProjectVersion";
import {useForm} from "antd/es/form/Form";
import QueryOptions from "../../sal-ui/QueryOptions";
import {Checkbox, ConfigProvider, Form, message, Modal, Select} from "antd";
import * as globalStyles from "../App.module.css";

function AdoptAnalysisModal(props: { open: boolean, sbomProjectId: string, sbomProjectVersionId: string, onClose: () => void }) {
    const sbomProjectVersionService = useContext(SbomProjectVersionServiceContext);
    const [projectVersions, setProjectVersions] = useState<SbomProjectVersion[]>();
    const [form] = useForm();
    const [adoptInProgress, setAdoptInProgress] = useState(false);

    useEffect(() => {
        if (props.sbomProjectId === undefined) {
            return;
        }

        sbomProjectVersionService.getList(props.sbomProjectId, QueryOptions.newUnlimitedOrderedInstance("name desc")).then(value => setProjectVersions(value.data));
    }, [props.sbomProjectId]);

    return (
        <Modal open={props.open}
               title={"Adopt analysis from another project version"}
               okText={"Adopt"}
               cancelText={"Cancel"}
               destroyOnClose={true}
               okButtonProps={{disabled: adoptInProgress}}
               onOk={() => {
                   form.validateFields().then((values: any) => {
                       setAdoptInProgress(true);

                       sbomProjectVersionService.adopt(props.sbomProjectVersionId, {sourceSbomProjectVersionId: values.sourceSbomProjectVersionId, overwriteAllItems: values.overwriteAllItems})
                           .then(response => {
                               if (response.data.adoptedItems === 0) {
                                   message.success(<>No analysed items were adopted.</>);
                               } else {
                                   message.success(<>Successfully adopted <b>{response.data.adoptedItems}</b> analysed items.</>);
                               }

                               props.onClose();

                               form.resetFields();
                           })
                           .finally(() => setAdoptInProgress(false))
                   });
               }}
               onCancel={() => {
                   props.onClose();

                   form.resetFields();
               }}
        >
            You can adopt analysis from another project version for identical components. Only analysis for items with <i>unknown</i> status are adopted by default but it can be overriden below.

            <ConfigProvider theme={{components: {Form: {itemMarginBottom: 8}}}}>
                <Form form={form} className={globalStyles["common__form"]} labelCol={{span: 8}}>
                    <Form.Item name={"sourceSbomProjectVersionId"}
                               label={"Project version"}
                               rules={[{required: true, message: "Project version is required."}]}>
                        <Select options={projectVersions?.filter(version => version.id !== props.sbomProjectVersionId)?.map(version => {
                            return {
                                label: version.name,
                                value: version.id
                            }
                        })}/>
                    </Form.Item>

                    <Form.Item name="overwriteAllItems"
                               label={"Overwrite all items"}
                               valuePropName={"checked"}
                               initialValue={false}>
                        <Checkbox/>
                    </Form.Item>
                </Form>
            </ConfigProvider>

        </Modal>
    );
}

export default AdoptAnalysisModal;
