import Axios from "axios";
import {AppContext} from "../AppContext";
import User from "../domain/User";

class AuthService {

    constructor(private appContext: AppContext) {
        //
    }

    public getLoggedInUser(): Promise<User> {
        return Axios.get("/logged-in-user")
            .then((data) => {
                return User.fromPOJO(data.data);
            });
    }

    public authenticate(username: string, password: string): Promise<any> {
        return Axios.post("/authenticate", {username, password});
    }

    public logout() {
        return Axios.get("/logout").then(() => {
            this.appContext.user = undefined;
        });
    }

}

export default AuthService;
