import SoftwareLicense from "./SoftwareLicense";

export enum SoftwareLicenseOrigin {
    LICENSE_DB = "LICENSE_DB",
    CUSTOM = "CUSTOM",
    UNKNOWN = "UNKNOWN"
}

export function formatSoftwareLicenseOrigin(license: SoftwareLicense) {
    switch (license?.origin) {
        case SoftwareLicenseOrigin.LICENSE_DB:
            return <a href={`https://scancode-licensedb.aboutcode.org/${license.licenseKey}.html`} target={"_blank"}>ScanCode LicenseDB</a>;
        case SoftwareLicenseOrigin.UNKNOWN:
            return "Unknown";
        case SoftwareLicenseOrigin.CUSTOM:
            return "SEAL";
    }
}
