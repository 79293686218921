import React, {useContext, useEffect, useState} from "react";
import {VulSourceServiceContext, VulViewServiceContext} from "../../Contexts";
import {DatePickerEditor, FilterItem, FilterItemProps, InputEditor, SelectEditor, TableFilter} from "../tablefilter/TableFilter";
import QueryOptions from "../../sal-ui/QueryOptions";
import VulSource, {vulSourceSystemLastComparator} from "../../domain/VulSource";
import VulView, {vulViewSystemLastComparator} from "../../domain/VulView";
import {Input, InputNumber, Select, Tooltip} from "antd";
import * as globalStyles from "../App.module.css";
import * as styles from "./VulSourceItemFilter.module.css";
import * as tackStyles from "./Tack.module.css";
import * as descriptionStyles from "../SelectItemDescription.module.css";
import AttributeDefinition from "../../domain/AttributeDefinition";
import {AttributeValueType} from "../../domain/AttributeValueType";
import _ from "lodash";
import {QuestionCircleTwoTone} from "@ant-design/icons";
import ReactMarkdown from "react-markdown";

interface VulSourceItemProps extends FilterItemProps {
    sources: VulSource[];
}

function VulSourceItem(props: VulSourceItemProps) {
    const sources = props.sources?.sort(vulSourceSystemLastComparator);

    return (
        <SelectEditor width={120}
                      multiple={true}
                      filterOption={(inputValue, option) => {
                          const normalizedInput = inputValue.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
                          const normalizedLabel = option.label?.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");

                          return normalizedLabel.includes(normalizedInput);
                      }}
                      value={props.value}
                      optionLabelProp="label"
                      onChange={value => props.onChange(value)}
                      virtual={false}>

            {sources?.map(source =>
                <Select.Option key={source.id} value={source.id} label={source.name}>
                    {source.owner === 'SYSTEM' && <div className={`${tackStyles.tack} ${tackStyles['tack-seal']}`}><span className={tackStyles['tack-text']} color={"#440084"}>SEAL</span></div>}
                    {source.owner === 'ORGANIZATION' && <div className={`${tackStyles.tack} ${tackStyles['tack-org']}`}><span className={tackStyles['tack-text']} color={"#87d068"}>ORG</span></div>}

                    <div className={`${tackStyles.item}`}>
                        <div className={descriptionStyles['name-container']}>
                            <span className={descriptionStyles.name}>{source.name}</span>
                            {source.description && <Tooltip title={<ReactMarkdown className={globalStyles.markdown} children={source.description}/>}><QuestionCircleTwoTone/></Tooltip>}
                        </div>
                        <div className={descriptionStyles.description}>{source.description}</div>
                    </div>
                </Select.Option>
            )}

        </SelectEditor>
    )
}

interface VulViewItemProps extends FilterItemProps {
    vulViews: VulView[];
}

function VulViewItem(props: VulViewItemProps) {
    const views = props.vulViews?.sort(vulViewSystemLastComparator);

    return (
        <SelectEditor
            width={120}
            multiple={true}
            filterOption={(inputValue, option) => {
                const normalizedInput = inputValue.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
                const normalizedLabel = option.label?.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");

                return normalizedLabel.includes(normalizedInput);
            }}
            value={props.value}
            optionLabelProp="label"
            onChange={value => props.onChange(value)}
        >
            {views?.map(vulView =>
                <Select.Option key={vulView.id} value={vulView.id} label={vulView.name}>
                    {vulView.owner === 'SYSTEM' && <div className={`${tackStyles.tack} ${tackStyles['tack-seal']}`}><span className={tackStyles['tack-text']} color={"#440084"}>SEAL</span></div>}
                    {vulView.owner === 'ORGANIZATION' && <div className={`${tackStyles.tack} ${tackStyles['tack-org']}`}><span className={tackStyles['tack-text']} color={"#87d068"}>ORG</span></div>}

                    <div className={`${tackStyles.item}`}>
                        <div className={descriptionStyles['name-container']}>
                            <span className={descriptionStyles.name}>{vulView.name}</span>
                            {vulView.description && <Tooltip title={<ReactMarkdown className={globalStyles.markdown} children={vulView.description}/>}><QuestionCircleTwoTone/></Tooltip>}
                        </div>
                        <div className={descriptionStyles.description}>{vulView.description}</div>
                    </div>
                </Select.Option>
            )}
        </SelectEditor>
    )
}

function TimestampItem(props: FilterItemProps) {
    return (
        <DatePickerEditor
            placeholder={"Timestamp"}
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

function AttributeValueItem(props: FilterItemProps) {
    return (
        <InputEditor
            placeholder={"Regular expression"}
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

function CveIdItem(props: FilterItemProps) {
    return (
        <InputEditor
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

interface AttributeItemProps extends FilterItemProps {
    attributeDefinitions?: AttributeDefinition[];
}

function AttributeItem(props: AttributeItemProps) {
    const numericOperators = [
        {value: "jsonGe", label: "≥"},
        {value: "jsonEq", label: "="},
        {value: "jsonLe", label: "≤"},
    ];

    const textOperators = [
        {value: "jsonRegex", label: "matches"},
        {value: "not(jsonRegex)", label: "not matches"},
    ];

    const [definition, setDefinition] = useState<AttributeDefinition>();
    const [attributeName, setAttributeName] = useState<string>();
    const [operator, setOperator] = useState<string>();
    const [attributeValue, setAttributeValue] = useState();
    const [operators, setOperators] = useState<{ value: string, label: string }[]>([]);

    useEffect(() => {
        if (props.value === undefined || props.attributeDefinitions === undefined) {
            return;
        }

        setDefinition(props.attributeDefinitions.find(definition => definition.name === props.value.name));
        setAttributeName(props.value.name);
        setOperator(props.value.operator);
        setAttributeValue(props.value.value);
    }, [props.value, props.attributeDefinitions]);

    useEffect(() => {
        const definition = props.attributeDefinitions?.find(definition => definition.name === attributeName);

        setDefinition(definition);

        if (definition === undefined) {
            return;
        }

        const attributeOperators = (definition.valueType === AttributeValueType.NUMBER) ? numericOperators : textOperators;

        if (!_.isEqual(operators, attributeOperators)) {
            if (operators !== undefined) {
                setOperator(undefined);
            }

            setOperators(attributeOperators);
        }
    }, [attributeName]);

    useEffect(() => {
        if (attributeName !== undefined && operator !== undefined && attributeValue !== undefined) {
            props.onChange({
                name: attributeName,
                value: (definition?.valueType === AttributeValueType.NUMBER) ? parseFloat(attributeValue) : attributeValue,
                operator
            });
        }
    }, [attributeName, operator, attributeValue]);

    return (
        <div className={styles['attribute-editor']}>
            <Select
                size={"small"}
                value={attributeName}
                popupMatchSelectWidth={false}
                onChange={value => setAttributeName(value)}
                options={props.attributeDefinitions?.map(definition => ({
                    value: definition.name,
                    label: definition.name
                }))}
            />

            <Select
                size={"small"}
                value={operator}
                popupMatchSelectWidth={false}
                onChange={value => setOperator(value)}
                options={operators}
            />

            {definition?.valueType === AttributeValueType.NUMBER && <InputNumber
                className={styles['attribute-value']}
                size={"small"}
                value={attributeValue}
                onChange={value => setAttributeValue(value)}
            />}

            {definition?.valueType !== AttributeValueType.NUMBER && <Input
                className={styles['attribute-value']}
                size={"small"}
                value={attributeValue}
                onChange={(e: any) => setAttributeValue(e.target.value)}
            />}

        </div>
    )
}

interface VulSourceItemFilterProps {
    className?: string;
    values?: any;
    onChange: (values: any) => void;
    readOnly: boolean;
    showViews?: boolean;
    immediateMode?: boolean,
    attributeDefinitions?: AttributeDefinition[];
}

function VulSourceItemFilter(props: VulSourceItemFilterProps) {
    const vulViewService = useContext(VulViewServiceContext);
    const vulSourceService = useContext(VulSourceServiceContext);
    const [vulViews, setVulViews] = useState<VulView[]>([]);
    const [sources, setSources] = useState<VulSource[]>([]);

    useEffect(() => {
        if (props.showViews === undefined || props.showViews === true) {
            vulViewService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setVulViews(value.data));
        }

        vulSourceService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => setSources(value.data));
    }, []);

    const vulViewItem = {
        name: "vulView",
        key: "vulView",
        label: "View",
        render: (props) => <VulViewItem {...props} vulViews={vulViews}/>
    };

    const items: FilterItem[] = [];

    if (props.showViews === undefined || props.showViews === true) {
        items.push(vulViewItem);
    }

    items.push(
        {
            name: "sources",
            key: "sources",
            label: "Sources",
            render: (props) => <VulSourceItem {...props} sources={sources}/>
        },
        {
            name: "cveId",
            key: "cveId",
            label: <>CVE ID</>,
            render: (props) => <CveIdItem {...props} />
        },
        {
            name: "attributeValue",
            key: "attributeValue",
            label: <>Attribute value</>,
            render: (props) => <AttributeValueItem {...props} />
        },
        {
            name: "attributes",
            key: "attributes",
            label: <>Attribute</>,
            multiple: true,
            render: (itemProps) => <AttributeItem attributeDefinitions={props.attributeDefinitions} {...itemProps} />
        },
        {
            name: "sourcePublishedFrom",
            key: "sourcePublishedFrom",
            label: "Published from",
            render: (props) => <TimestampItem {...props} />
        },
        {
            name: "sourcePublishedTo",
            key: "sourcePublishedTo",
            label: "Published to",
            render: (props) => <TimestampItem {...props} />
        },
        {
            name: "lastModifiedFrom",
            key: "lastModifiedFrom",
            label: "Last modified from",
            render: (props) => <TimestampItem {...props} />
        },
        {
            name: "lastModifiedTo",
            key: "lastModifiedTo",
            label: "Last modified to",
            render: (props) => <TimestampItem {...props} />
        }
    );

    return (
        <TableFilter
            items={items}
            className={props.className}
            values={props.values}
            defaultValues={{attributeValue: '', cveId: '', vulView: [], sources: []}}
            readOnly={props.readOnly}
            immediateMode={props.immediateMode}
            onChange={onChange}
        />
    )

    function onChange(values: any) {
        props.onChange(values);
    }

}

export default VulSourceItemFilter;