import {makeAutoObservable} from "mobx";
import {AppConfig} from "./AppConfig";
import User from "./domain/User";

export class AppContext {

    public language = "en";

    public user: User | null = null;

    public jwtTokenExpiresAt: Date | null = null;

    public config: AppConfig | null = null;

    public presentationMode = false;

    public publicAccess = false;

    public publicAccessUserId: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

}
