import {Permission} from "./Permission";
import {Scope} from "./Scope";

class RolePermission {
    constructor(
        public id?: string,
        public permission?: Permission,
        public scope?: Scope,
        public objectId?: string,
        public tags?: string[],
        public expanded?: boolean) {
        //
    }

    public static fromPOJO(data: any) {
        const rolePermission = new RolePermission();

        rolePermission.id = data.id;
        rolePermission.permission = data.permission;
        rolePermission.scope = data.scope;
        rolePermission.objectId = data.objectId;
        rolePermission.tags = data.tags;
        rolePermission.expanded = data.expanded;

        return rolePermission;
    }

}

export default RolePermission;
