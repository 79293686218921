import {DeleteFilled, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, message, Modal, Table} from "antd";
import React, {useContext, useEffect, useState} from "react";
import Organization from "../../domain/Organization";
import {DocumentTitle} from "../DocumentTitle";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext} from "../../Contexts";
import {useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";

import * as styles from "./OrganizationList.module.css";
import *as globalStyles from "../App.module.css";
import {ColumnsType} from "antd/es/table";
import {Feature, formatFeature} from "../../domain/Feature";

function OrganizationList() {
    const appContext = useContext(AppContextContext);
    const organizationService = useContext(OrganizationServiceContext);
    const navigate = useNavigate();
    const tableHandler = useTableHandler("name", {reloadFunction: reload});
    const [organizations, setOrganizations] = useState<PagedResult<Organization>>();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        reload();
    }, [])

    const columns: ColumnsType<Organization> = [
        {
            dataIndex: "name",
            title: "Name",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "ascend",
            render: renderName
        },
        {
            dataIndex: "organizationId",
            title: "Organization ID"
        },
        {
            dataIndex: "features",
            title: "Features",
            render: features =>  features?.map((value: Feature) => <div>{formatFeature(value)}</div>)
        },
        {
            title: "Actions",
            width: 100,
            className: globalStyles["table-actions"],
            render: renderAction
        }
    ]

    const title = "Organizations";

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{title}</h1>

                <div className={globalStyles["common__top-button-bar"]}>
                    <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>
                    <Button type={"primary"}
                            icon={<PlusOutlined/>}
                            onClick={() => navigate("/organizations/add")}>Add</Button>
                </div>

                <Table className={styles.table}
                       showSorterTooltip={false}
                       loading={tableHandler.loading}
                       dataSource={organizations?.data}
                       size="middle"
                       onChange={tableHandler.onTableChange}
                       pagination={tableHandler.pagination}
                       onRow={onRow}
                       rowKey="id"
                       columns={columns}
                />
            </>
        </DocumentTitle>
    )

    function renderName(value: any, organization: Organization, index: number) {
        return <Link to={`/organizations/${organization.id}`}>{value}</Link>;
    }

    function renderAction(value: any, organization: Organization, index: number) {
        return (
            <>
                <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                        title={"Delete"}
                        onClick={() => {
                            Modal.confirm({
                                content: "Do you really want to delete this organization?",
                                okText: "Delete",
                                cancelText: "Cancel",
                                onOk: () => onDeleteConfirm(organization)
                            });
                        }}/>
            </>
        )
    }

    function onDeleteConfirm(organization: Organization) {
        organizationService.delete(organization)
            .then(() => {
                message.success("Item successfully deleted.");

                reload();
            });
    }

    function reload() {
        return organizationService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setOrganizations(value);
        });
    }

    function onRow(organization: Organization, index?: number) {
        return {onDoubleClick: () => navigate(`/organizations/${organization.id}`)}
    }
}

export default OrganizationList;
