import {Breadcrumb, Button, message, Table, Tag} from "antd";
import {TableUiConfig, useTableHandler} from "../../sal-ui/TableHandler";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {AppContextContext, SbomComponentServiceContext} from "../../Contexts";
import {Link} from "react-router-dom";
import PagedResult from "../../service/PagedResult";
import SbomComponent from "../../domain/SbomComponent";
import * as styles from "./SbomComponentList.module.css";
import * as globalStyles from "../App.module.css";
import {ArrowRightOutlined, ArrowUpOutlined, ReloadOutlined} from "@ant-design/icons";
import {isColumnVisible, TableConfig} from "../tableconfig/TableConfig";
import {DocumentTitle} from "../DocumentTitle";
import {ColumnsType} from "antd/es/table";
import {PackageURL} from "packageurl-js";
import CopyToClipboard from "react-copy-to-clipboard";
import _ from "lodash";
import {getEqualOrGreaterSeverities} from "../../domain/FindingSeverity";
import FindingStatistics from "../sbomproject/FindingStatistics";
import SbomComponentFilter from "./SbomComponentFilter";
import {formatDate} from "../../utils/FormatUtils";
import {evaluateSbomComponentHealth, SbomComponentHealth} from "../../domain/SbomComponentHealth";
import {green, orange, red} from "@ant-design/colors";
import {SoftwareLicenseOrigin} from "../../domain/SoftwareLicenseOrigin";
import {packageUrlToOsiUrl} from "./functions";

const defaultVisibleColumns = ["type", "group", "name", "version", "publishedAt", "licenses", "findingStatistics", "projectVersion"];

const defaultTableUiConfig = {immediateMode: true, visibleColumns: defaultVisibleColumns};

function SbomComponentList() {
    const persistentIdent = 'SbomComponentList';
    const appContext = useContext(AppContextContext);
    const sbomComponentService = useContext(SbomComponentServiceContext);
    const [sbomComponents, setSbomComponents] = useState<PagedResult<SbomComponent>>();
    const [licenses, setLicenses] = useState<string[]>([]);
    const [filterValues, setFilterValues] = useState<any>();
    const [tableUiConfig, setTableUiConfig] = useState<TableUiConfig>(defaultTableUiConfig);

    const debouncedOnFinishFilter = useCallback(_.debounce(onFinishFilter, 500), []);

    const tableHandler = useTableHandler("name asc", {
        reloadFunction: reloadComponents,
        initialPageSize: 25,
        persistentIdent: `${persistentIdent}`
    });

    const columns: ColumnsType<SbomComponent> = [
        {
            dataIndex: "type",
            title: "Type",
            width: 100,
            align: "center",
            render: renderType
        },
        {
            dataIndex: "group",
            title: "Group",
        },
        {
            dataIndex: "name",
            title: "Name",
            sorter: true,
            defaultSortOrder: "ascend",
            sortDirections: ["ascend", "descend", "ascend"],
            render: renderName
        },
        {
            dataIndex: "version",
            title: "Version",
            render: renderVersion
        },
        {
            dataIndex: "publishedAt",
            title: "Published at",
            width: 120,
            align: "center",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: renderPublishedAt
        },
        {
            dataIndex: "licenses",
            title: "Licenses",
            render: licenses => licenses.map(license => {
                if (license.origin === SoftwareLicenseOrigin.UNKNOWN) {
                    return <div key={license.name}>{license.name}</div>;
                }

                return <div key={license.name}><Link to={`/software-licenses/${license.id}`} target={"_blank"}>{license.shortName}</Link></div>;
            })
        },
        {
            dataIndex: "findingStatistics",
            title: "Vulnerability findings",
            render: renderStatistics
        },
        {
            dataIndex: "projectVersion",
            title: "Project",
            render: renderProjectVersion
        }
    ]

    useEffect(() => {
        sbomComponentService.getLicenses().then(setLicenses);
    }, []);

    return (
        <DocumentTitle title={`SBOM components`}>
            <>
                <Breadcrumb
                    className={globalStyles["common__breadcrumb"]}
                    items={[
                        {title: appContext.config?.appName},
                        {title: "SBOM components"}
                    ]}
                />

                <h1>SBOM components</h1>

                <SbomComponentFilter
                    className={styles['filter']}
                    values={filterValues}
                    onChange={values => {
                        setFilterValues(values);

                        debouncedOnFinishFilter(values);
                    }}
                    immediateMode={tableUiConfig.immediateMode}
                    readOnly={false}
                    licenses={licenses}
                />

                <div className={`${globalStyles["common__top-button-bar"]} ${styles['button-bar']}`}>
                    <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>

                    <TableConfig
                        columns={columns}
                        value={tableUiConfig}
                        onChange={uiConfig => {
                            setTableUiConfig(uiConfig);

                            tableHandler.saveUiConfig(uiConfig);
                        }}
                    />
                </div>

                <Table className={styles.table}
                       showSorterTooltip={false}
                       loading={tableHandler.loading}
                       dataSource={sbomComponents?.data}
                       size="middle"
                       onChange={tableHandler.onTableChange}
                       pagination={tableHandler.pagination}
                       rowKey="id"
                       expandable={{
                           showExpandColumn: true,
                           expandRowByClick: true,
                       }}
                       columns={columns.filter(column => isColumnVisible(tableUiConfig.visibleColumns, column))}
                />
            </>
        </DocumentTitle>
    );

    function renderType(value: any, sbomComponent: SbomComponent) {
        if (sbomComponent.purl?.startsWith("pkg:maven")) {
            return <><img src={"/img/Apache_Maven_logo.svg"} style={{width: 40}} title={value}/></>;
        } else if (sbomComponent.purl?.startsWith("pkg:nuget")) {
            return <><img src={"/img/NuGet_logo.svg"} style={{width: 24}} title={value}/></>;
        } else if (sbomComponent.purl?.startsWith("pkg:npm")) {
            return <><img src={"/img/Npm_logo.svg"} style={{width: 32}} title={value}/></>;
        } else if (sbomComponent.type === 'operating-system') {
            return <span title={"operating system"}>OS</span>
        } else {
            return <>{value}</>;
        }
    }

    function renderName(value: any, sbomComponent: SbomComponent) {
        return <>
            {value}
            {sbomComponent.purl && <Tag title={`${sbomComponent.purl}`} color={"blue"} className={styles['component-tag']} onClick={event => {
                event.preventDefault();
                event.stopPropagation();

                navigator.clipboard.writeText(sbomComponent.purl);

                message.info("Package URL copied to clipboard.");
            }}>purl</Tag>}
            {sbomComponent.directDependency && <Tag title={"This component is a direct dependency of this project."}>direct</Tag>}
            {sbomComponent.cpe && <CopyToClipboard text={sbomComponent.cpe} onCopy={() => message.info("CPE name copied to clipboard.")}><Tag title={sbomComponent.cpe} color={"blue"} className={styles['component-tag']}>cpe</Tag></CopyToClipboard>}
        </>;
    }

    function renderVersion(value: any, sbomComponent: SbomComponent) {
        if (sbomComponent.latestVersion && sbomComponent.latestVersion !== sbomComponent.version) {
            let purl: PackageURL = null;

            try {
                purl = PackageURL.fromString(sbomComponent.purl);

                purl.version = sbomComponent.latestVersion;

                const osiUrl = packageUrlToOsiUrl(purl);

                return <>{value} <span style={{color: '#73d13d'}}> <ArrowUpOutlined title={"Update is available."}/> <Link to={osiUrl} target={"_blank"} title={"Open at Open Source Insights"}>{sbomComponent.latestVersion}</Link></span></>;
            } catch (e) {
                return <>{value} <span style={{color: '#73d13d'}}><ArrowUpOutlined title={"Update is available."}/> {sbomComponent.latestVersion}</span></>;
            }
        } else {
            return value;
        }
    }

    function renderPublishedAt(value: any, sbomComponent: SbomComponent) {
        if (sbomComponent.publishedAt === undefined) {
            return <i>unknown</i>;
        }

        const health = evaluateSbomComponentHealth(sbomComponent, new Date());

        let color, title;

        switch (health) {
            case SbomComponentHealth.HEALTHY:
                title = "Component is not older than two years.";
                color = green.primary;
                break;
            case SbomComponentHealth.OUT_OF_DATE:
                title = "Component is more than two years old.";
                color = orange.primary;
                break;
            case SbomComponentHealth.OBSOLETE:
                title = "Component is more than four years old.";
                color = red.primary;
                break;
        }

        return <span title={title} style={{color: color}}>
            {formatDate(sbomComponent.publishedAt)}
        </span>;
    }

    function renderProjectVersion(value: any, sbomComponent: SbomComponent) {
        return <>
            <Link to={`/sbom-projects/${sbomComponent.projectVersion.project.id}`}>{sbomComponent.projectVersion.project.name}</Link>: <Link to={`/sbom-projects/${sbomComponent.projectVersion.project.id}/${sbomComponent.projectVersion.id}`}>{sbomComponent.projectVersion.name}</Link>
        </>;
    }

    function renderStatistics(findingStatistics: any, sbomComponent: SbomComponent) {
        if (_.isEqual(findingStatistics, sbomComponent?.analysedFindingStatistics)) {
            return <>
                <FindingStatistics findingStatistics={findingStatistics}/>
            </>;
        } else {
            return <>
                <FindingStatistics findingStatistics={findingStatistics}/>
                <ArrowRightOutlined className={styles['analysis-arrow']} title={"Impact of analysis"}/>
                <FindingStatistics findingStatistics={sbomComponent?.analysedFindingStatistics}/>
            </>;
        }
    }

    function createRqbQuery(values: any) {
        const rqbQuery = {
            combinator: "and",
            rules: []
        }

        values.severity && values.severity.length > 0 && rqbQuery.rules.push({
            field: "severity",
            operator: "in",
            value: getEqualOrGreaterSeverities(values.severity)
        });

        values.licenses && values.licenses.length > 0 && rqbQuery.rules.push({
            field: "licenses",
            operator: "arrayIn",
            value: values.licenses
        });

        values.name && rqbQuery.rules.push({
            field: "name",
            operator: "icontains",
            value: values.name
        });

        return rqbQuery;
    }

    function onFinishFilter(values: any) {
        tableHandler.onRqbSearchSubmit(createRqbQuery(values));
    }

    function reloadComponents() {
        return sbomComponentService.getListAll(tableHandler.queryOptions).then(components => {
            tableHandler.updateTotal(components.total);

            setSbomComponents(components);
        });
    }
}

export default SbomComponentList;
