import Axios from "axios";
import {ImportAnalysis} from "../domain/ImportAnalysis";

class ImportExportService {

    public importAnalysis(data: any): Promise<ImportAnalysis> {
        return Axios.post('/data-import-analysis', data).then(response => response.data);
    }

    public import(data: any) {
        return Axios.post('/data-import', data).then(response => response.data);
    }

    public export(data: any) {
        return Axios.post('/data-export', data).then(response => response.data);
    }

}

export default ImportExportService;