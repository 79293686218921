import {Breadcrumb, Button, Form, InputNumber, message, Select, Switch} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, AttributeDefinitionServiceContext, AttributeExtractionDefinitionServiceContext, VulSourceServiceContext} from "../../Contexts";
import AttributeDefinition from "../../domain/AttributeDefinition";
import VulSource from "../../domain/VulSource";
import {formatVulSourceType, VulSourceType} from "../../domain/VulSourceType";
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import *as globalStyles from "../App.module.css";
import {DocumentTitle} from "../DocumentTitle";
import {AttributeExtractionSource, formatAttributeExtractionSource} from "../../domain/AttributeExtractionSource";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function AttributeExtractionDefinitionAdd() {
    const appContext = useContext(AppContextContext);
    const attributeExtractionDefinitionService = useContext(AttributeExtractionDefinitionServiceContext);
    const attributeDefinitionService = useContext(AttributeDefinitionServiceContext);
    const vulSourceService = useContext(VulSourceServiceContext);
    const {attributeDefinitionId} = useParams();
    const navigate = useNavigate();
    const [form] = useForm();
    const [sourceTypes, setSourceTypes] = useState<VulSourceType[]>([]);
    const [attributeDefinitions, setAttributeDefinitions] = useState<AttributeDefinition[]>();
    const [vulSources, setVulSources] = useState<VulSource[]>();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 8, span: 16},
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        attributeDefinitionService.getList(new QueryOptions("name", 100, 0)).then(value => {
            setAttributeDefinitions(value.data);
        });

        vulSourceService.getList(new QueryOptions("name", 100, 0)).then(value => {
            setVulSources(value.data);
        });

    }, [])

    const title = "New attribute extraction";

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={"common__breadcrumb"}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/attributes/extraction-definitions"}>Attribute extractions</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{title}</h1>

                <Form {...layout} form={form} className={`${globalStyles["common__form"]} ${globalStyles["common_form--add"]}`} onFinish={onFinish}>
                    <Form.Item
                        name={["attributeDefinition", "id"]}
                        label={"Attribute"}
                        rules={[{required: true, message: "Attribute is required."}]}
                        initialValue={attributeDefinitionId}>
                        <Select disabled={true}>
                            {attributeDefinitions && attributeDefinitions!.map(attributeDefinition => {
                                return <Select.Option key={attributeDefinition?.name} value={attributeDefinition?.id!}>{attributeDefinition?.name}: {attributeDefinition?.valueType}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={["sources"]}
                        label={"Sources"}>
                        <Select
                            mode="multiple"
                            allowClear>
                            {vulSources && vulSources!.map(vulSource => {
                                return <Select.Option key={vulSource?.name} value={vulSource?.id!}>{vulSource?.name}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={["sourceTypes"]}
                        label={"Source types"}
                        rules={[{required: false, message: "required"}]}
                        initialValue={sourceTypes}>
                        <Select
                            mode="multiple"
                            allowClear
                            onChange={value => setSourceTypes(value)}
                            options={Object.values(VulSourceType).map(type => ({
                                label: formatVulSourceType(type),
                                value: type
                            }))}
                        />
                    </Form.Item>

                    <Form.Item
                        name={"extractionSource"}
                        label={"Extraction source"}
                        rules={[{required: true, message: "Extraction source is required."}]}
                        initialValue={AttributeExtractionSource.JSON_DATA}>
                        <Select
                            options={Object.values(AttributeExtractionSource).map(source => ({
                                label: formatAttributeExtractionSource(source),
                                value: source
                            }))}
                        />
                    </Form.Item>

                    <Form.Item
                        name={"priority"}
                        label={"Priority"}
                        initialValue={0}
                        rules={[
                            {required: true, message: "Priority is required."},
                        ]}>
                        <InputNumber/>
                    </Form.Item>

                    <Form.Item
                        name={["enabled"]}
                        label={"Enabled"}
                        valuePropName={"checked"}
                        initialValue={true}>
                        <Switch/>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate(`/attributes/definitions/${attributeDefinitionId}`)}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    )

    function onFinish(values: any) {
        attributeExtractionDefinitionService.add(values)
            .then(
                (id) => {
                    message.success("Attribute extraction definition added.");

                    navigate(`/attributes/extraction-definitions/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }
}

export default AttributeExtractionDefinitionAdd;
