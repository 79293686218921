.layout {
    min-height: 100vh;
}

.header:global(.ant-layout-header) {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 8px 16px;
    height: 60px;
    background: #fff;
    text-align: center;
}

.logo {
    height: 60px;
    width: 240px;
    text-align: left;
    display: flex;
    align-items: center;
}

.logo img {
    max-height: 40px;
    max-width: 180px;
    margin-top: 8px;
}

.toolbar {
    text-align: right;
    padding-left: 16px;
    height: 60px;
    overflow: hidden;
}

.toolbar :global(.ant-menu) {
    color: #434656;
    min-width: 200px;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 30px;
    border-bottom: 0;
}

:global(.ant-menu-dark) {
    background: #333343 !important;
}

.sider  {
    width: 200px;
    background: #333343 !important;
    min-width: 0;
    position: relative;
    transition: all .2s;
}

.sider :global(.ant-layout-sider-children) {
    padding-top: 16px;
}

.sider :global(.ant-menu-item) {
    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0;
    height: 48px;
    line-height: 48px;
}

.sider :global(.ant-menu-submenu .ant-menu-submenu-title) {
    margin: 0;
}

.sider :global(.ant-menu-item-divider) {
    padding: 8px 0;
}

.center {
    flex: auto;
}

.content {
    padding: 16px 16px 50px;
}

.exit-presentation-mode {
    border-radius: 4px;
    position: fixed;
    top: 16px;
    right: 16px;
    width: 200px;
}
