import AttributeDefinition from "./AttributeDefinition";
import Organization from "./Organization";
import {TicketAttributeRuleOperationType} from "./TicketAttributeRuleOperationType";
import TicketCreationDefinition from "./TicketCreationDefinition";

class TicketAttributeRule {
    constructor(
        public id?: string,
        public attributeDefinition?: AttributeDefinition,
        public ticketCreationDefinition?: TicketCreationDefinition,
        public operationType?: TicketAttributeRuleOperationType,
        public value?: string) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const entity = new TicketAttributeRule();

        entity.id = data.id;
        entity.attributeDefinition = AttributeDefinition.fromPOJO(data.attributeDefinition);
        entity.ticketCreationDefinition = TicketCreationDefinition.fromPOJO(data.ticketCreationDefinition);
        entity.operationType = data.operationType;
        entity.value = data.value;

        return entity;
    }


}

export default TicketAttributeRule;
