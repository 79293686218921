import {Breadcrumb, Button, Form, Input, InputNumber, message, Select, Switch} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, AttributeDefinitionServiceContext, TagServiceContext} from "../../Contexts";
import {AttributeValueType} from "../../domain/AttributeValueType";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import *as globalStyles from "../App.module.css";
import TextArea from "antd/lib/input/TextArea";
import {AttributeValueTransformerType} from "../../domain/AttributeValueTransformerType";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function AttributeDefinitionAdd() {
    const appContext = useContext(AppContextContext);
    const attributeDefinitionService = useContext(AttributeDefinitionServiceContext);
    const tagService = useContext(TagServiceContext);
    const [tags, setTags] = useState<string[]>();
    const navigate = useNavigate();
    const [form] = useForm();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    useEffect(() => {
        tagService.getList().then(setTags);
    }, [])

    const title = "New attribute definition";

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={"common__breadcrumb"} items={[
                    {title: appContext.config?.appName},
                    {title: <Link to={"/attributes/definitions"}>Attribute definitions</Link>},
                    {title}
                ]}/>

                <h1>{title}</h1>

                <Form {...layout} form={form} className={`${globalStyles["common__form"]} ${globalStyles["common_form--add"]}`} onFinish={onFinish}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                        ]}>
                        <Input maxLength={100} autoFocus={true}/>
                    </Form.Item>

                    <Form.Item
                        name={"description"}
                        label={"Description"}
                        extra={"Description of attribute in CommonMark format."}>
                        <TextArea maxLength={1000} autoSize={{minRows: 4, maxRows: 8}}/>
                    </Form.Item>

                    <Form.Item
                        name={"priority"}
                        label={"Priority"}
                        initialValue={0}
                        rules={[
                            {required: true, message: "Priority is required."},
                        ]}>
                        <InputNumber/>
                    </Form.Item>

                    <Form.Item
                        name={"mergeTicket"}
                        label={"Link items"}
                        valuePropName={"checked"}>
                        <Switch/>
                    </Form.Item>

                    <Form.Item
                        name={"showTag"}
                        label={"Visible"}
                        valuePropName={"checked"}>
                        <Switch/>
                    </Form.Item>

                    <Form.Item
                        name={["valueType"]}
                        label={"Value type"}
                        rules={[{required: true, message: "required"}]}>
                        <Select>
                            {Object.keys(AttributeValueType).map(value => <Select.Option key={value} value={value}>{value}</Select.Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={"attributeValueTransformer"}
                        label={"Value transformer"}
                        initialValue={AttributeValueTransformerType.NONE}
                        rules={[{required: true, message: "Value transformer is required."}]}>
                        <Select>
                            <Select.Option key={AttributeValueTransformerType.NONE} value={AttributeValueTransformerType.NONE}>{AttributeValueTransformerType.NONE}</Select.Option>
                            <Select.Option key={AttributeValueTransformerType.MAX_NUMERIC_VALUE} value={AttributeValueTransformerType.MAX_NUMERIC_VALUE}>{AttributeValueTransformerType.MAX_NUMERIC_VALUE}</Select.Option>
                        </Select>
                    </Form.Item>

                    {appContext.user.isSystemAdmin() && <Form.Item
                        name={["tags"]}
                        label={"Tags"}>
                        <Select mode="tags" options={tags?.map(tag => ({value: tag, label: tag}))}/>
                    </Form.Item>}

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate("/attributes/definitions")}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    )

    function onFinish(values: any) {
        attributeDefinitionService.add(values)
            .then(
                (id) => {
                    message.success("Attribute definition added.");

                    navigate(`/attributes/definitions/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }
}

export default AttributeDefinitionAdd;
