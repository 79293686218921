import {QuestionCircleTwoTone, ReloadOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, Popover, Table} from "antd";
import React, {useCallback, useContext, useEffect, useState} from "react";
import Organization from "../../domain/Organization";
import {DocumentTitle} from "../DocumentTitle";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, SoftwareLicenseServiceContext} from "../../Contexts";
import {TableUiConfig, useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";
import * as styles from "./SoftwareLicenseList.module.css";
import * as globalStyles from "../App.module.css";
import {ColumnsType} from "antd/es/table";
import SoftwareLicense from "../../domain/SoftwareLicense";
import SoftwareLicenseFilter from "./SoftwareLicenseFilter";
import _ from "lodash";
import {TableConfig} from "../tableconfig/TableConfig";
import {formatSoftwareLicenseCategory} from "./functions";

const defaultVisibleColumns = ["shortName", "spdxLicenseKey", "category", "organization"];

const defaultTableUiConfig = {immediateMode: true, visibleColumns: defaultVisibleColumns};

function SoftwareLicenseList() {
    const appContext = useContext(AppContextContext);
    const softwareLicenseService = useContext(SoftwareLicenseServiceContext);
    const navigate = useNavigate();
    const [filterValues, setFilterValues] = useState<any>();
    const tableHandler = useTableHandler("name", {reloadFunction: reload});
    const [tableUiConfig, setTableUiConfig] = useState<TableUiConfig>(defaultTableUiConfig);
    const [licenses, setLicenses] = useState<PagedResult<SoftwareLicense>>();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setTableUiConfig(tableHandler.loadUiConfig() || defaultTableUiConfig);

        tableHandler.reload();
    }, [])

    useEffect(() => {
        if (filterValues !== undefined) {
            debouncedOnFinishFilter(filterValues);
        }
    }, [filterValues]);

    const debouncedOnFinishFilter = useCallback(_.debounce(onFinishFilter, 500), []);

    const columns: ColumnsType<SoftwareLicense> = [
        {
            dataIndex: "shortName",
            title: "Name",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "ascend",
            render: renderShortName
        },
        {
            dataIndex: "spdxLicenseKey",
            title: "SPDX identifier",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: (_, license) => license.allSpdxLicenseKeys.map(license => <div>{license}</div>)
        },
        {
            dataIndex: "category",
            title: "Category",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: category => <>{category} <Popover title={category} content={formatSoftwareLicenseCategory(category)} overlayStyle={{maxWidth: 400}}><QuestionCircleTwoTone/></Popover></>
        }
    ];

    if (appContext.user.isSystemAdmin()) {
        columns.push({
            dataIndex: "organization",
            title: "Organization",
            render: renderOrganization
        });
    }

    const title = "Software licenses";

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}
                            items={[
                                {title: appContext.config?.appName},
                                {title}
                            ]}
                />

                <h1>{title}</h1>

                <p>Licenses are acquired from <a href={"https://scancode-licensedb.aboutcode.org/help.html"} target={"_blank"}>ScanCode LicenseDB</a> database.</p>

                <SoftwareLicenseFilter
                    className={styles.filter}
                    onChange={values => {
                        setFilterValues(values);

                        debouncedOnFinishFilter(values);
                    }}
                    values={filterValues}
                    readOnly={false}
                    immediateMode={tableUiConfig.immediateMode}
                />

                <div className={globalStyles["common__top-button-bar"]}>
                    <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>

                    <TableConfig
                        columns={columns}
                        value={tableUiConfig}
                        onChange={uiConfig => {
                            setTableUiConfig(uiConfig);

                            tableHandler.saveUiConfig(uiConfig);
                        }}
                    />

                </div>

                <Table className={styles.table}
                       showSorterTooltip={false}
                       loading={tableHandler.loading}
                       dataSource={licenses?.data}
                       size="middle"
                       onChange={tableHandler.onTableChange}
                       pagination={tableHandler.pagination}
                       onRow={onRow}
                       rowKey="id"
                       columns={columns}
                />
            </>
        </DocumentTitle>
    )

    function createRqbQuery(values: any) {
        const rqbQuery = {
            combinator: "and",
            rules: []
        }

        values.name && rqbQuery.rules.push({
            field: "name",
            operator: "icontains",
            value: values.name
        });

        values.spdxLicenseKey && rqbQuery.rules.push({
            field: "spdxLicenseKey",
            operator: "icontains",
            value: values.spdxLicenseKey
        });

        values.categories && values.categories.length > 0 && rqbQuery.rules.push({
            field: "category",
            operator: "in",
            value: values.categories
        });

        return rqbQuery;
    }

    function onFinishFilter(values: any) {
        const rqbQuery = createRqbQuery(values);

        tableHandler.onRqbSearchSubmit(rqbQuery);
    }

    function renderShortName(value: any, license: SoftwareLicense) {
        return <Link title={license.name} to={`/software-licenses/${license.id}`}>{value}</Link>;
    }

    function renderOrganization(value: Organization) {
        if (value === undefined) {
            return <i>system resource</i>;
        }

        return <Link to={`/organizations/${value.id}`}>{value.name}</Link>;
    }

    function reload() {
        return softwareLicenseService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setLicenses(value);
        });
    }

    function onRow(license: SoftwareLicense) {
        return {onDoubleClick: () => navigate(`/software-licenses/${license.id}`)}
    }
}

export default SoftwareLicenseList;
