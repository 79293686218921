import {DocumentTitle} from "../DocumentTitle";
import {Breadcrumb, Button, DatePicker, Form, Input, message, Select, Switch, Tooltip} from "antd";
import * as globalStyles from "../App.module.css";
import * as tackStyles from "../vulsourceitem/Tack.module.css";
import * as styles from "./VulReportAdd.module.css";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {AppContextContext, VulReportServiceContext, VulViewServiceContext} from "../../Contexts";
import {useForm, useWatch} from "antd/es/form/Form";
import 'react-querybuilder/dist/query-builder.scss';
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import VulView, {vulViewSystemLastComparator} from "../../domain/VulView";
import dayjs from "dayjs";
import VulReport from "../../domain/VulReport";
import * as descriptionStyles from "../SelectItemDescription.module.css";
import ReactMarkdown from "react-markdown";
import {QuestionCircleTwoTone} from "@ant-design/icons";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function VulReportAdd() {
    const appContext = useContext(AppContextContext);
    const vulViewService = useContext(VulViewServiceContext);
    const vulReportService = useContext(VulReportServiceContext);
    const navigate = useNavigate();
    const [form] = useForm();
    const [vulViews, setVulViews] = useState<VulView[]>();
    const {reportId}: any = useParams();
    const [vulReport, setVulReport] = useState<VulReport>();
    const includeTime = useWatch("includeTime", form);

    useEffect(() => {
        vulViewService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => {
            setVulViews(value.data?.sort(vulViewSystemLastComparator));
        });
    }, []);

    useEffect(() => {
        if (reportId === undefined) {
            return;
        }

        vulReportService.get(reportId).then(report => {
            setVulReport(report);

            form.setFieldsValue({
                ...report,
                name: `Clone of ${report.name}`,
                timestampFrom: dayjs(report.timestampFrom),
                timestampTo: dayjs(report.timestampTo),
                vulViews: report.vulViews.map(value => value.id)
            });
        });
    }, [reportId]);

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    const title = (reportId) ? <>Clone of report <b>{vulReport?.name}</b></> : <>New vulnerability report</>;

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/vul-reports"}>Vulnerability reports</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{title}</h1>

                <Form {...layout} form={form} className={globalStyles["common__form"]} onFinish={onFinish}>

                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."}
                        ]}>
                        <Input autoFocus={true} maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"vulViews"}
                        label={"View"}
                        rules={[{required: true, message: "View is required."}]}>
                        <Select
                            mode={"multiple"}
                            filterOption={(inputValue, option) => {
                                const normalizedInput = inputValue.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
                                const normalizedLabel = option.label?.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");

                                return normalizedLabel.includes(normalizedInput);
                            }}
                            optionLabelProp={"label"}
                            popupMatchSelectWidth={350}
                            virtual={false}
                            listHeight={300}
                        >
                            {vulViews?.map(vulView =>
                                <Select.Option key={vulView.id} value={vulView.id} label={vulView.name}>
                                    {vulView.owner === 'SYSTEM' && <div className={`${tackStyles.tack} ${tackStyles['tack-seal']}`}><span className={tackStyles['tack-text']} color={"#440084"}>SEAL</span></div>}
                                    {vulView.owner === 'ORGANIZATION' && <div className={`${tackStyles.tack} ${tackStyles['tack-org']}`}><span className={tackStyles['tack-text']} color={"#87d068"}>ORG</span></div>}

                                    <div className={`${tackStyles.item}`}>
                                        <div className={descriptionStyles['name-container']}>
                                            <span className={descriptionStyles.name}>{vulView.name}</span>
                                            {vulView.description && <Tooltip title={<ReactMarkdown className={globalStyles.markdown} children={vulView.description}/>}><QuestionCircleTwoTone/></Tooltip>}
                                        </div>
                                        <div className={descriptionStyles.description}>{vulView.description}</div>
                                    </div>
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>

                    <Form.Item label={"Start date"}>
                        <Form.Item
                            noStyle={true}
                            name={"timestampFrom"}
                            rules={[{required: true, message: "Start date is required."}]}>
                            <DatePicker
                                onChange={value => {
                                    if (!includeTime) {
                                        form.setFieldValue("timestampFrom", value.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0));
                                    }
                                }}
                                allowClear={false}
                                showToday={false}
                                showTime={(includeTime) ? {defaultValue: dayjs('00:00:00', 'HH:mm:ss')} : false}
                            />
                        </Form.Item>

                        <Form.Item noStyle={true}>
                            {!includeTime && <div className={styles['time-hint']}>00:00:00.000</div>}
                        </Form.Item>
                    </Form.Item>

                    <Form.Item label={"End date"}>
                        <Form.Item
                            noStyle={true}
                            name={"timestampTo"}
                            rules={[
                                {required: true, message: "End date is required."},
                                {
                                    validator: (_, value) => {
                                        if (!form.getFieldValue("timestampFrom").isBefore(value)) {
                                            return Promise.reject("End date must be after start date.");
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            ]}>
                            <DatePicker
                                onChange={value => {
                                    if (!includeTime) {
                                        form.setFieldValue("timestampTo", value.set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999));
                                    }
                                }}
                                allowClear={false}
                                showToday={false}
                                showTime={(includeTime) ? {defaultValue: dayjs('00:00:00', 'HH:mm:ss')} : false}
                            />
                        </Form.Item>

                        <Form.Item noStyle={true}>
                            {!includeTime && <div className={styles['time-hint']}>23:59:59.999</div>}
                        </Form.Item>
                    </Form.Item>

                    <Form.Item
                        name={"includeTime"}
                        label={"Set time"}
                        extra={"Check this switch if you want to set time above."}
                        valuePropName={"checked"}>
                        <Switch/>
                    </Form.Item>

                    <Form.Item
                        name={"timeZone"}
                        label={"Time zone"}
                        initialValue={new Intl.DateTimeFormat().resolvedOptions().timeZone}
                        rules={[{required: true, message: "Time zone is required."}]}>
                        <Select
                            options={Intl.supportedValuesOf('timeZone').map(value => {
                                return {label: value, value}
                            })}
                            showSearch={true}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate("/vul-reports")}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    );

    function onFinish(values: any) {
        if (!includeTime) {
            values.timestampFrom = values.timestampFrom.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
            values.timestampTo = values.timestampTo.set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999);
        }

        vulReportService.add(values)
            .then(
                (id) => {
                    message.success("Vulnerability report added.");

                    navigate(`/vul-reports/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }

}

export default VulReportAdd;