import {DeleteFilled, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, message, Modal, Table} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {DocumentTitle} from "../DocumentTitle";
import {Link, useNavigate} from "react-router-dom";
import {ApiTokenServiceContext, AppContextContext} from "../../Contexts";
import {useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";
import * as styles from "./ApiTokenList.module.css";
import *as globalStyles from "../App.module.css";
import {ColumnsType} from "antd/es/table";
import ApiToken from "../../domain/ApiToken";

function ApiTokenList() {
    const appContext = useContext(AppContextContext);
    const apiTokenService = useContext(ApiTokenServiceContext);
    const navigate = useNavigate();
    const tableHandler = useTableHandler("name", {reloadFunction: reload});
    const [apiTokens, setApiTokens] = useState<PagedResult<ApiToken>>();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        reload();
    }, [])

    const columns: ColumnsType<ApiToken> = [
        {
            dataIndex: "name",
            title: "Name",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "ascend",
            render: value => value
        },
        {
            dataIndex: "token",
            title: "Token"
        },
        {
            dataIndex: "user",
            title: "User",
            render: user => <Link to={`/users/${user.id}`}>{user.username}</Link>
        },
        {
            title: "Actions",
            width: 100,
            className: globalStyles["table-actions"],
            render: renderAction
        }
    ]

    const title = "API tokens";

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{title}</h1>

                <div className={globalStyles["common__top-button-bar"]}>
                    <Button type="ghost" icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>
                    <Button type={"primary"}
                            icon={<PlusOutlined/>}
                            onClick={() => navigate("/api-tokens/add")}>Add</Button>
                </div>

                <Table className={styles.table}
                       showSorterTooltip={false}
                       loading={tableHandler.loading}
                       dataSource={apiTokens?.data}
                       size="middle"
                       onChange={tableHandler.onTableChange}
                       pagination={tableHandler.pagination}
                       rowKey="id"
                       columns={columns}
                />
            </>
        </DocumentTitle>
    )

    function renderAction(value: any, apiToken: ApiToken, index: number) {
        return (
            <>
                <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                        title={"Delete"}
                        onClick={() => {
                            Modal.confirm({
                                content: <>Do you really want to delete API token <b>{apiToken.name}</b>?</>,
                                okText: "Delete",
                                cancelText: "Cancel",
                                onOk: () => onDeleteConfirm(apiToken)
                            });
                        }}/>
            </>
        )
    }

    function onDeleteConfirm(apiToken: ApiToken) {
        apiTokenService.delete(apiToken)
            .then(() => {
                message.success(<>API token <b>{apiToken.name}</b> successfully deleted.</>);

                reload();
            });
    }

    function reload() {
        return apiTokenService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setApiTokens(value);
        });
    }

}

export default ApiTokenList;
