import Axios, {AxiosResponse} from "axios";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";
import SbomProjectVersion from "../domain/SbomProjectVersion";
import SbomComponent from "../domain/SbomComponent";
import SbomComponentFinding from "../domain/SbomComponentFinding";
import SbomProject from "../domain/SbomProject";
import SingleResult from "./SingleResult";

class SbomProjectVersionService {

    readonly basePath = "/sbom-project-versions";

    public add(sbomProjectId: string, sbomProjectVersion: SbomProjectVersion): Promise<string> {
        return Axios.post(`/sbom-projects/${sbomProjectId}/versions`, sbomProjectVersion).then(value => value.data.id)
    }

    public update(sbomProjectVersionId: string, sbomProjectVersion: SbomProjectVersion): Promise<void> {
        return Axios.put(`${this.basePath}/${sbomProjectVersionId}`, sbomProjectVersion);
    }

    public get(id: string): Promise<SbomProjectVersion> {
        return Axios.get(`${this.basePath}/${id}`)
            .then(value => SbomProjectVersion.fromPOJO(value.data))
    }

    public delete(sbomProjectVersion: SbomProjectVersion): Promise<void> {
        return Axios.delete(`${this.basePath}/${sbomProjectVersion.id}`);
    }

    public getList(sbomProjectId: string, queryOptions: QueryOptions): Promise<PagedResult<SbomProjectVersion>> {
        return Axios.get(`/sbom-projects/${sbomProjectId}/versions?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: SbomProjectVersion[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(SbomProjectVersion.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public getComparableList(sbomProjectId: string): Promise<PagedResult<SbomProjectVersion>> {
        return Axios.get(`/sbom-projects/${sbomProjectId}/comparable-versions`)
            .then(value => {
                const objectList: SbomProjectVersion[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(SbomProjectVersion.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public adopt(sbomProjectVersionId: string, data: { sourceSbomProjectVersionId: string, overwriteAllItems: string }): Promise<AxiosResponse<{ adoptedItems: number }>> {
        return Axios.post(`/sbom-project-versions/${sbomProjectVersionId}/adopt`, data);
    }

    public compareVersions(sbomProjectVersionId: string, anotherProjectVersionId: string): Promise<CompareResult> {
        return Axios.get(`/sbom-project-versions/${sbomProjectVersionId}/change-log/${anotherProjectVersionId}`)
            .then(value => {
                return CompareResult.fromPOJO(value.data);
            });
    }

}

export class CompareResult {
    constructor(
        public project: SingleResult<SbomProject>,
        public firstVersion: SbomProjectVersion,
        public secondVersion: SbomProjectVersion,
        public addedComponents: AddedComponent[],
        public updatedComponents: UpdatedComponent[],
        public removedComponents: RemovedComponent[]) {
        //
    }

    public static fromPOJO(data: any) {
        return new CompareResult(
            new SingleResult(SbomProject.fromPOJO(data.project.data), data.project.permissions),
            SbomProjectVersion.fromPOJO(data.firstVersion),
            SbomProjectVersion.fromPOJO(data.secondVersion),
            data.addedComponents.map(value => AddedComponent.fromPOJO(value)),
            data.updatedComponents.map(value => UpdatedComponent.fromPOJO(value)),
            data.removedComponents.map(value => RemovedComponent.fromPOJO(value))
        );
    }
}

class AddedComponent {
    constructor(
        public component: SbomComponent) {
        //
    }

    public static fromPOJO(data: any) {
        return new AddedComponent(SbomComponent.fromPOJO(data.component));
    }
}

class RemovedComponent {
    constructor(
        public component: SbomComponent,
        public fixedFindings: SbomComponentFinding[]) {
        //
    }

    public static fromPOJO(data: any) {
        return new RemovedComponent(
            SbomComponent.fromPOJO(data.component),
            data.fixedFindings.map(value => SbomComponentFinding.fromPOJO(value))
        );
    }
}

class UpdatedComponent {
    constructor(
        public firstVersionComponent: SbomComponent,
        public secondVersionComponent: SbomComponent,
        public fixedFindings: SbomComponentFinding[]) {
        //
    }

    public static fromPOJO(data: any) {
        return new UpdatedComponent(
            SbomComponent.fromPOJO(data.firstVersionComponent),
            SbomComponent.fromPOJO(data.secondVersionComponent),
            data.fixedFindings.map(value => SbomComponentFinding.fromPOJO(value))
        );
    }
}

export default SbomProjectVersionService;
