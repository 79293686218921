.card {
    max-width: 800px;
}

.token {
    display: flex;
    margin: 16px 0;
}

.token textarea {
    width: 100%;
    resize: none;
    word-break: break-all;
}

.token :global(.ant-btn) {
    margin: 0 0 0 4px;
}

.token :global(.ant-btn .anticon) {
    font-size: 18px;
}

.copy {
    padding: 0;
    margin: 4px 0;
}
