import Axios from "axios";
import VulSource from "../domain/VulSource";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";

class VulSourceService {

    readonly basePath = "/vul-sources";

    public add(vulSource: VulSource): Promise<string> {
        return Axios.post(`${this.basePath}`, vulSource).then(value => value.data.id)
    }

    public update(vulSourceId: string, vulSource: VulSource): Promise<void> {
        return Axios.put(`${this.basePath}/${vulSourceId}`, vulSource);
    }

    public get(id: string): Promise<VulSource> {
        return Axios.get(`${this.basePath}/${id}`)
            .then(value => VulSource.fromPOJO(value.data))
    }

    public delete(vulSource: VulSource): Promise<void> {
        return Axios.delete(`${this.basePath}/${vulSource.id}`);
    }

    public runAll(): Promise<void> {
        return Axios.post(`${this.basePath}/run-all`);
    }

    public run(vulSource: VulSource): Promise<void> {
        return Axios.post(`${this.basePath}/${vulSource.id}/run`);
    }

    public purge(vulSource: VulSource): Promise<void> {
        return Axios.post(`${this.basePath}/${vulSource.id}/purge`);
    }

    public updateAllSourcesInOpenSearch(): Promise<void> {
        return Axios.post(`${this.basePath}/update-in-open-search`);
    }

    public updateInOpenSearch(vulSource: VulSource): Promise<void> {
        return Axios.post(`${this.basePath}/${vulSource.id}/update-in-open-search`);
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<VulSource>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: VulSource[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(VulSource.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

}

export default VulSourceService;
