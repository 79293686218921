import * as styles from "./SealScore.module.css";
import VulSourceItem from "../../domain/VulSourceItem";
import React from "react";
import {findingSeverityForScore} from "../../domain/FindingSeverity";

interface IProps {
    item?: VulSourceItem;
    label?: string;
    score?: number;
}

function SealScore(props: IProps) {
    const item = props.item;

    const score = props.score || item?.attributes?.['sealScore'];

    const severity = findingSeverityForScore(score);

    if (score !== undefined) {
        return <div className={`${styles['score']} ${styles['severity-' + severity]}`}>{props.label || 'Score'}: {score}</div>
    } else {
        return <></>;
    }
}

export default SealScore;