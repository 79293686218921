.filter {
    width: 1200px;
}

.table {
    width: 1200px;
}

.extraction-definitions {
    margin: 0 96px 8px 24px;
}

.extraction-definitions h2 {
    margin-top: 0;
}

.description {
    margin-top: 8px;
    font-size: 12px;
}
