import {CopyOutlined, DeleteFilled, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, Divider, message, Modal, Space, Table} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, VulReportServiceContext} from "../../Contexts";
import {useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";
import {DocumentTitle} from "../DocumentTitle";

import * as styles from "./VulReportList.module.css";
import * as globalStyles from "../App.module.css";
import {ColumnType} from "antd/es/table";
import {formatDateTime} from "../../utils/FormatUtils";
import VulReport from "../../domain/VulReport";
import VulView from "../../domain/VulView";
import {ResourceOwnerType} from "../../domain/ResourceOwnerType";
import {Permission} from "../../domain/auth/Permission";

function VulReportList() {
    const appContext = useContext(AppContextContext);
    const vulReportService = useContext(VulReportServiceContext);
    const navigate = useNavigate();
    const tableHandler = useTableHandler("timestampTo desc", {reloadFunction: reload});
    const [reports, setReports] = useState<PagedResult<VulReport>>();
    const [itemCount, setItemCount] = useState<any>({});

    useEffect(() => tableHandler.reload(), [])

    const title = "Vulnerability reports";

    const columns: ColumnType<VulReport>[] = [
        {
            dataIndex: "name",
            title: "Name",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: renderName
        },
        {
            dataIndex: "timestampFrom",
            title: "Start date",
            width: 140,
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: formatDateTime
        },
        {
            dataIndex: "timestampTo",
            title: "End date",
            width: 140,
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "descend",
            render: formatDateTime
        },
        {
            dataIndex: ["definition", "name"],
            title: "Report definition",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            align: "left",
            render: renderDefinition
        },
        {
            dataIndex: "vulViews",
            title: "Vulnerability views",
            align: "left",
            render: renderVulViews
        },
        {
            title: "Items (new / modified)",
            align: "center",
            render: renderItemCount
        }
    ];

    if (appContext.user.hasPermission(Permission.REPORT__MANAGE)) {
        columns.push(
            {
                title: "Actions",
                width: 100,
                className: globalStyles["table-actions"],
                render: renderAction
            }
        );
    }

    if (appContext.user.isSystemAdmin()) {
        columns.splice(3, 0, {
                dataIndex: ["organization", "name"],
                title: "Organization",
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
                align: "left",
                render: renderOrganization
            },
        );
    }

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{title}</h1>

                <div className={globalStyles["common__top-button-bar"]}>
                    <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>

                    {appContext.user.hasPermission(Permission.REPORT__MANAGE) &&
                        <Button type={"primary"}
                                icon={<PlusOutlined/>}
                                onClick={() => navigate("/vul-reports/add")}>Add</Button>
                    }
                </div>

                <Table className={styles.table}
                       showSorterTooltip={false}
                       dataSource={reports?.data}
                       size="middle"
                       loading={tableHandler.loading}
                       onChange={tableHandler.onTableChange}
                       pagination={tableHandler.pagination}
                       onRow={onRow}
                       rowKey="id"
                       columns={columns}
                />
            </>
        </DocumentTitle>
    );

    function renderDefinition(value: any, report: VulReport) {
        if (report.definition) {
            return <Link to={`/definitions/vul-reports/${report.definition.id}`}>{report.definition.name}</Link>;
        } else {
            return <i>manual report</i>;
        }
    }

    function renderOrganization(value: any, vulView: VulView) {
        if (vulView.owner === ResourceOwnerType.SYSTEM) {
            return <i>System view</i>;
        } else {
            return <Link to={`/organizations/${vulView.organization.id}`}>{value}</Link>;
        }
    }

    function renderVulViews(value: any, report: VulReport) {
        return (
            <Space wrap={true} split={<Divider type="vertical"/>}>
                {report.vulViews?.map(view => (
                    <Link to={`/vul-source-items/views/${view.id}`}>{view.name}</Link>
                ))}
            </Space>
        );
    }

    function renderItemCount(value: any, report: VulReport) {
        return (
            <>{itemCount[report.id]?.newItems} / {itemCount[report.id]?.modifiedItems}</>
        );
    }

    function renderAction(value: any, report: VulReport, index: number) {
        return (
            <Space>
                <Button icon={<CopyOutlined/>} className={"ant-btn-icon-only"}
                        title={"Clone"}
                        onClick={() => navigate(`/vul-reports/clone/${report.id}`)}/>

                <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                        title={"Delete"}
                        onClick={() => {
                            Modal.confirm({
                                content: "Do you really want to delete this report?",
                                okText: "Delete",
                                cancelText: "Cancel",
                                onOk: () => onDeleteConfirm(report)
                            });
                        }}/>
            </Space>
        )
    }

    function onDeleteConfirm(report: VulReport) {
        vulReportService.delete(report)
            .then(() => {
                message.success("Vulnerability report deleted.");

                reload();
            });
    }

    function onRow(report: VulReport, index?: number) {
        return {onDoubleClick: () => navigate(`/vul-reports/${report.id}`)}
    }

    function renderName(value: any, report: VulReport, index: number) {
        return <Link to={`/vul-reports/${report.id}`}>{value}</Link>;
    }

    function reload() {
        return vulReportService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setReports(value);

            setItemCount({});

            for (const report of value.data) {
                vulReportService.countItems(report).then(count => {
                    setItemCount(prevValue => {
                        return {
                            ...prevValue,
                            [report.id]: count
                        }
                    })
                })
            }
        });
    }
}

export default VulReportList;
