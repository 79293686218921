export enum SbomType {
    CYCLONE_DX_JSON = "CYCLONE_DX_JSON",
    CYCLONE_DX_XML = "CYCLONE_DX_XML",
    SPDX_DX_JSON = "SPDX_DX_JSON",
    SPDX_DX_XML = "SPDX_DX_XML"
}

export function formatSbomType(type?: SbomType, version?: string) {
    switch (type) {
        case SbomType.CYCLONE_DX_JSON:
        case SbomType.CYCLONE_DX_XML:
            return (version) ? `CycloneDX ${version}` : "CycloneDX";
        case SbomType.SPDX_DX_JSON:
        case SbomType.SPDX_DX_XML:
            return (version) ? `SPDX ${version}` : "SPDX";
    }
}
