.outer-box {
    position: relative;
}

.inner-box pre {
    white-space: pre-wrap;
}

.outer-box-less .inner-box {
    height: 160px;
    overflow: hidden;
}

.outer-box-less .more-less-button {
    bottom: 0;
    position: absolute;
}

.outer-box-less .inner-box::after {
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
    pointer-events: none;
    opacity: 1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
}

.more-less-button {
    left: calc(50% - 80px);
    padding: 0 !important;
    width: 140px;
    font-style: italic;
    margin-bottom: 8px;
}


