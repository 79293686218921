import SbomProject from "./SbomProject";
import {SbomProjectVersionStatus} from "./SbomProjectVersionStatus";
import SoftwareLicense from "./SoftwareLicense";
import {SbomType} from "./SbomType";

class SbomProjectVersion {

    constructor(
        public id?: string,
        public name?: string,
        public comparable?: string,
        public suppressInReports?: boolean,
        public sbomImportedAt?: Date,
        public sbomGeneratedAt?: Date,
        public componentEvaluatedAt?: Date,
        public sbomType?: SbomType,
        public sbomTypeVersion?: string,
        public tags?: string[],
        public project?: SbomProject,
        public findingStatistics?: any,
        public analysisStatistics?: any,
        public analysedFindingStatistics?: any,
        public licenseViolations?: string[],
        public componentsTotal?: number,
        public componentsVulnerable?: number,
        public componentsDuplicate?: number,
        public componentsUnknownLicenses?: number,
        public componentsOutdated?: number,
        public licenses?: SoftwareLicense[],
        public status?: SbomProjectVersionStatus) {
        //
    }

    public static fromPOJO(data: any) {
        if (!data) return null;

        const sbomProjectVersion = new SbomProjectVersion();

        sbomProjectVersion.id = data.id;
        sbomProjectVersion.suppressInReports = data.suppressInReports;
        sbomProjectVersion.sbomImportedAt = (data.sbomImportedAt) ? new Date(data.sbomImportedAt) : undefined;
        sbomProjectVersion.sbomGeneratedAt = (data.sbomGeneratedAt) ? new Date(data.sbomGeneratedAt) : undefined;
        sbomProjectVersion.componentEvaluatedAt = (data.componentEvaluatedAt) ? new Date(data.componentEvaluatedAt) : undefined;
        sbomProjectVersion.name = data.name;
        sbomProjectVersion.comparable = data.comparable;
        sbomProjectVersion.sbomType = data.sbomType;
        sbomProjectVersion.sbomTypeVersion = data.sbomTypeVersion;
        sbomProjectVersion.tags = data.tags;
        sbomProjectVersion.project = (data.project) ? SbomProject.fromPOJO(data.project) : undefined;
        sbomProjectVersion.findingStatistics = data.findingStatistics;
        sbomProjectVersion.analysisStatistics = data.analysisStatistics;
        sbomProjectVersion.analysedFindingStatistics = data.analysedFindingStatistics;
        sbomProjectVersion.licenseViolations = data.licenseViolations;
        sbomProjectVersion.componentsTotal = data.componentsTotal;
        sbomProjectVersion.componentsVulnerable = data.componentsVulnerable;
        sbomProjectVersion.componentsDuplicate = data.componentsDuplicate;
        sbomProjectVersion.componentsUnknownLicenses = data.componentsUnknownLicenses;
        sbomProjectVersion.componentsOutdated = data.componentsOutdated;
        sbomProjectVersion.licenses = (data.licenses) ? data.licenses.map(license => SoftwareLicense.fromPOJO(license)) : [];
        sbomProjectVersion.status = data.status;

        return sbomProjectVersion;
    }

}

export default SbomProjectVersion;
