import {DeleteFilled, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, message, Modal, Table, Tag} from "antd";
import React, {useContext, useEffect, useState} from "react";
import Organization from "../../domain/Organization";
import User from "../../domain/User";
import {DocumentTitle} from "../DocumentTitle";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, UserServiceContext} from "../../Contexts";
import {useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";

import * as styles from "./UserList.module.css";
import *as globalStyles from "../App.module.css";
import {ColumnsType} from "antd/es/table";
import {formatUserRole} from "../../domain/UserRole";

function UserList() {
    const persistentIdent = 'UserList';
    const appContext = useContext(AppContextContext);
    const userService = useContext(UserServiceContext);
    const navigate = useNavigate();
    const tableHandler = useTableHandler("username", {reloadFunction: reload, persistentIdent});
    const [users, setUsers] = useState<PagedResult<User>>();

    useEffect(() => tableHandler.reload(), [])

    const columns: ColumnsType<User> = [];

    columns.push(
        {
            dataIndex: "username",
            title: "Username",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            defaultSortOrder: "ascend",
            render: renderUsername
        }
    );

    if (appContext.user.isSystemAdmin()) {
        columns.push({
            dataIndex: ["organization", "name"],
            title: "Organization",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: renderOrganization
        });
    }

    if (appContext.user.isSystemAdmin()) {
        columns.push(
            {
                dataIndex: "role",
                title: "Role (legacy)",
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
                render: value => formatUserRole(value)
            }
        );
    }

    columns.push(
        {
            dataIndex: "roles",
            title: "Roles",
            render: roles => roles.map(role => <div><Link to={`/roles/${role.id}`}>{role.name}</Link></div>)
        }
    );

    columns.push(
        {
            title: "Actions",
            width: 100,
            className: globalStyles["table-actions"],
            render: renderAction
        }
    );

    const title = "Users";

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{title}</h1>

                <div className={globalStyles["common__top-button-bar"]}>
                    <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>
                    <Button type={"primary"}
                            icon={<PlusOutlined/>}
                            onClick={() => navigate("/users/add")}>Add</Button>
                </div>

                <Table className={`${appContext.user.isSystemAdmin() ? styles['admin-table'] : styles.table}`}
                       showSorterTooltip={false}
                       loading={tableHandler.loading}
                       dataSource={users?.data}
                       size="middle"
                       onChange={tableHandler.onTableChange}
                       pagination={tableHandler.pagination}
                       onRow={onRow}
                       rowKey="id"
                       columns={columns}
                />
            </>
        </DocumentTitle>
    )

    function renderUsername(value: any, user: User, index: number) {
        return <>
            <Link to={`/users/${user.id}`}>{value}</Link>

            {user.publicAccessAccount && <Link to={user.publicAccessUrl} target={"_blank"}><Tag color={"red"} style={{marginLeft: 8}}>public access</Tag></Link>}
        </>
    }

    function renderOrganization(value: Organization, user: User, index: number) {
        return <Link to={`/organizations/${user.organization.id}`}>{user.organization.name}</Link>;
    }

    function renderAction(value: any, user: User, index: number) {
        return (
            <>
                <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                        title={"Delete"}
                        onClick={() => {
                            Modal.confirm({
                                content: `Do you really want to delete user ${user.username}?`,
                                okText: "Delete",
                                cancelText: "Cancel",
                                onOk: () => onDeleteConfirm(user)
                            });
                        }}/>
            </>
        )
    }

    function onDeleteConfirm(user: User) {
        userService.delete(user)
            .then(() => {
                message.success(<>User <b>{user.username}</b> successfully deleted.</>);

                reload();
            });
    }

    function reload() {
        return userService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setUsers(value);
        });
    }

    function onRow(user: User, index?: number) {
        return {onDoubleClick: () => navigate(`/users/${user.id}`)}
    }
}

export default UserList;
