import Axios from "axios";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";
import SoftwareLicensePolicy from "../domain/SoftwareLicensePolicy";

class SoftwareLicensePolicyService {

    readonly basePath = "/software-license-policies";

    public add(policy: SoftwareLicensePolicy): Promise<string> {
        return Axios.post(`${this.basePath}`, policy).then(value => value.data.id)
    }

    public update(policyId: string, policy: SoftwareLicensePolicy): Promise<void> {
        return Axios.put(`${this.basePath}/${policyId}`, policy);
    }

    public get(id: string): Promise<SoftwareLicensePolicy> {
        return Axios.get(`${this.basePath}/${id}`).then(value => SoftwareLicensePolicy.fromPOJO(value.data))
    }

    public delete(policy: SoftwareLicensePolicy): Promise<void> {
        return Axios.delete(`${this.basePath}/${policy.id}`);
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<SoftwareLicensePolicy>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: SoftwareLicensePolicy[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(SoftwareLicensePolicy.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList, value.data.permissions);
            })
    }

}

export default SoftwareLicensePolicyService;
