/**
 * Value class for server sorting, pagination and filtering.
 */
class QueryOptions {

    public orderBy = "";

    public top: number;

    public skip: number;

    public filter = "";

    public rqbQuery: any;

    public quickSearch = "";

    constructor(orderBy: string, top: number, skip: number, filter?: string, quickSearch?: string, rqbQuery?: any) {
        this.orderBy = orderBy;
        this.top = top;
        this.skip = skip;

        if (filter !== undefined) {
            this.filter = filter;
        }

        if (quickSearch !== undefined) {
            this.quickSearch = quickSearch;
        }

        if (rqbQuery !== undefined) {
            this.rqbQuery = rqbQuery;
        }
    }

    public static newUnlimitedOrderedInstance(orderBy: string) {
        return new QueryOptions(orderBy, 1000000, 0);
    }

    public static newUnlimitedFilteredInstance(filter: string) {
        return new QueryOptions("", 1000000, 0, filter);
    }

    public static newUnlimitedRqbQueryInstance(rqbQuery: any) {
        return new QueryOptions("", 1000000, 0, undefined, undefined, rqbQuery);
    }

    public static newUnlimitedOrderedRqbQueryInstance(orderBy: string, rqbQuery: any) {
        return new QueryOptions(orderBy, 1000000, 0, undefined, undefined, rqbQuery);
    }

    public static newQuickSearchInstance(filter: string) {
        return new QueryOptions("", 1000000, 0, filter);
    }

    public toQueryParams(): string {
        const params = new URLSearchParams();

        params.append("$orderby", this.orderBy);
        params.append("$top", String(this.top));
        params.append("$skip", String(this.skip));

        if (this.quickSearch !== "") {
            const qsFilter = `$quickSearch$ ~ '${this.quickSearch}'`;

            if (this.filter !== "") {
                this.filter += ` and ${qsFilter}`;
            } else {
                this.filter = qsFilter;
            }
        }

        if (this.filter !== "") {
            params.append("$filter", this.filter);
        }

        if (this.rqbQuery !== undefined) {
            params.append("$rqbQuery", JSON.stringify(this.rqbQuery));
        }

        return params.toString();
    }
}

export default QueryOptions;
