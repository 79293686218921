import Axios from "axios";
import VulView from "../domain/VulView";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";

class VulViewService {

    readonly basePath = "/vul-views";

    public add(vulView: VulView): Promise<string> {
        return Axios.post(`${this.basePath}`, vulView).then(value => value.data.id)
    }

    public update(vulViewId: string, vulView: VulView): Promise<void> {
        return Axios.put(`${this.basePath}/${vulViewId}`, vulView);
    }

    public get(id: string): Promise<VulView> {
        return Axios.get(`${this.basePath}/${id}`)
            .then(value => VulView.fromPOJO(value.data))
    }

    public delete(vulView: VulView): Promise<void> {
        return Axios.delete(`${this.basePath}/${vulView.id}`);
    }

    public getListOfUsedForSource(queryOptions: QueryOptions, sourceId: string): Promise<PagedResult<VulView>> {
        return Axios.get(`${this.basePath}/for-source/${sourceId}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: VulView[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(VulView.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<VulView>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: VulView[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(VulView.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

}

export default VulViewService;
