import {LoadingOutlined, QuestionCircleTwoTone} from "@ant-design/icons";
import Collapse from "@kunukn/react-collapse";
import {Breadcrumb, Button, Descriptions, Form, Input, message, Modal, Popover, Select, Spin} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, SoftwareLicensePolicyServiceContext, SoftwareLicenseServiceContext} from "../../Contexts";
import Organization from "../../domain/Organization";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import "./SoftwareLicensePolicyDetail.module.css";
import *as globalStyles from "../App.module.css";
import PagedResult from "../../service/PagedResult";
import SoftwareLicense from "../../domain/SoftwareLicense";
import QueryOptions from "../../sal-ui/QueryOptions";
import SoftwareLicensePolicy from "../../domain/SoftwareLicensePolicy";
import {formatSoftwareLicenseCategory} from "../softwarelicense/functions";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function SoftwareLicensePolicyDetail() {
    const appContext = useContext(AppContextContext);
    const softwareLicenseService = useContext(SoftwareLicenseServiceContext);
    const softwareLicensePolicyService = useContext(SoftwareLicensePolicyServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const navigate = useNavigate();
    const {policyId}: any = useParams();
    const [editMode, setEditMode] = useState(false);
    const [editForm] = useForm();
    const [policy, setPolicy] = useState<SoftwareLicensePolicy>();
    const [organizations, setOrganizations] = useState<Organization[]>();
    const [categories, setCategories] = useState<string[]>();
    const [licenses, setLicenses] = useState<PagedResult<SoftwareLicense>>();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (appContext.user.isSystemAdmin()) {
            organizationService.getList(new QueryOptions("name", 100, 0)).then(value => {
                setOrganizations(value.data);
            });
        }

        softwareLicenseService.getCategories().then(setCategories);

        softwareLicenseService.getList(QueryOptions.newUnlimitedOrderedInstance("shortName")).then(setLicenses);

        reload();
    }, [])

    return (
        <DocumentTitle title={policy?.name}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/software-license-policies"}>Software license policies</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{policy?.name}</Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={!policy} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{policy?.name}</h1>

                    <div className={globalStyles["common__top-button-bar"]}>
                        <Button onClick={() => setEditMode(!editMode)}>Edit</Button>

                        <Button danger={true}
                                title={"Delete"}
                                onClick={() => {
                                    Modal.confirm({
                                        content: "Do you really want to delete this policy?",
                                        okText: "Delete",
                                        cancelText: "Cancel",
                                        okButtonProps: {danger: true},
                                        onOk: () => onDeleteConfirm(policy)
                                    });
                                }}>Delete</Button>
                    </div>

                    {renderEditForm()}

                    <Descriptions column={1} bordered={true} size="small" className={globalStyles.details}>
                        {appContext.user.isSystemAdmin() && <Descriptions.Item label={"Organization"}>
                            <Link to={`/organizations/${policy?.organization?.id}`}>{policy?.organization?.name}</Link>
                        </Descriptions.Item>}
                        <Descriptions.Item label={"Name"}>{policy?.name}</Descriptions.Item>
                        <Descriptions.Item label={"Categories"}>
                            {policy?.categories?.length === 0 && <i>no categories</i>}
                            {policy?.categories?.map(category => <div>{category} <Popover title={category} content={formatSoftwareLicenseCategory(category)} overlayStyle={{maxWidth: 400}}><QuestionCircleTwoTone/></Popover></div>)}
                        </Descriptions.Item>
                        <Descriptions.Item label={"Licenses"}>
                            {policy?.licenses?.length === 0 && <i>no licenses</i>}
                            {policy?.licenses?.map(license => <div>
                                <Link to={`/software-licenses/${license.id}`}>{license.shortName}</Link>
                            </div>)}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </>
        </DocumentTitle>
    )

    function renderEditForm() {
        return (
            <Collapse isOpen={editMode}>
                <h3>Edit of software license policy {policy?.name}</h3>

                <Form {...layout} form={editForm} className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`} onFinish={onFinishEdit}>

                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                            {validator: serverViolationsHolder.createServerValidator('UNIQUE', 'name, organization_id', undefined, {compareLowerCaseValues: true}), message: "Name is already used."}
                        ]}>
                        <Input autoFocus={true} maxLength={100}/>
                    </Form.Item>

                    {appContext.user.isSystemAdmin() &&
                        <Form.Item
                            name={"organization"}
                            label={"Organization"}
                            rules={[{required: true, message: "Organization is required."}]}>
                            <Select>
                                {organizations && organizations!.map(organization => {
                                    return <Select.Option key={organization?.name} value={organization?.id!}>{organization?.name}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    }

                    <Form.Item
                        name={"categories"}
                        label={"Categories"}>
                        <Select mode={"multiple"}>
                            {categories?.map(category => {
                                return <Select.Option key={category}
                                                      value={category}>{category} <Popover title={category} content={formatSoftwareLicenseCategory(category)} overlayStyle={{maxWidth: 400}}><QuestionCircleTwoTone/></Popover></Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={"licenseKeys"}
                        label={"Licenses"}>
                        <Select mode={"multiple"}>
                            {licenses?.data?.map(license => {
                                return <Select.Option key={license.licenseKey} value={license.licenseKey}>{license.shortName}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => setEditMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function onFinishEdit(values: any) {
        softwareLicensePolicyService.update(policyId, values)
            .then(
                (id) => {
                    message.success(<>Software license policy <b>{values.name}</b> successfully updated.</>);

                    setEditMode(false);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }

    function onDeleteConfirm(policy: SoftwareLicensePolicy) {
        softwareLicensePolicyService.delete(policy)
            .then(() => {
                message.success(<>Software license policy <b>{policy.name}</b> successfully deleted.</>);

                navigate(`/software-license-policies`)
            });
    }

    function reload() {
        softwareLicensePolicyService.get(policyId).then(policy => {
            setPolicy(policy);

            editForm.setFieldsValue({
                ...policy,
                organization: policy.organization?.id,
                licenseKeys: policy.licenses.map(value => value.licenseKey)
            });
        });
    }

}

export default SoftwareLicensePolicyDetail;
