import {DeleteFilled, LoadingOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import Collapse from "@kunukn/react-collapse";
import {Breadcrumb, Button, Checkbox, Form, Input, message, Modal, Popover, Select, Spin, Table, Tag} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContextContext, SbomProjectServiceContext, SbomProjectVersionServiceContext, SoftwareLicensePolicyServiceContext} from "../../Contexts";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import * as globalStyles from "../App.module.css";
import SbomProject from "../../domain/SbomProject";
import SbomProjectVersion from "../../domain/SbomProjectVersion";
import PagedResult from "../../service/PagedResult";
import * as styles from "./SbomProjectDetail.module.css";
import _ from "lodash";
import {ColumnsType} from "antd/es/table";
import FindingStatistics from "./FindingStatistics";
import {formatDateTime} from "../../utils/FormatUtils";
import FindingAnalysisStatistics, {AnalysisStyle} from "./FindingAnalysisStatistics";
import {useTableHandler} from "../../sal-ui/TableHandler";
import {Permission} from "../../domain/auth/Permission";
import SingleResult from "../../service/SingleResult";
import {colorForSbomProjectVersionStatus, formatSbomProjectVersionStatus, SbomProjectVersionStatus} from "../../domain/SbomProjectVersionStatus";
import SoftwareLicensePolicy from "../../domain/SoftwareLicensePolicy";
import QueryOptions from "../../sal-ui/QueryOptions";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function SbomProjectDetail() {
    const appContext = useContext(AppContextContext);
    const sbomProjectService = useContext(SbomProjectServiceContext);
    const sbomProjectVersionService = useContext(SbomProjectVersionServiceContext);
    const softwareLicensePolicyService = useContext(SoftwareLicensePolicyServiceContext);
    const navigate = useNavigate();
    const {sbomProjectId}: any = useParams();
    const [editMode, setEditMode] = useState(false);
    const tableHandler = useTableHandler("name desc", {reloadFunction: reloadProjectVersions});
    const [sbomProject, setSbomProject] = useState<SingleResult<SbomProject>>();
    const [sbomProjectVersions, setSbomProjectVersions] = useState<PagedResult<SbomProjectVersion>>();
    const [softwareLicensePolicies, setSoftwareLicensePolicies] = useState<PagedResult<SoftwareLicensePolicy>>();
    const [editForm] = useForm();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    const columns: ColumnsType<SbomProjectVersion> = [
        {
            dataIndex: "name",
            title: "Name",
            defaultSortOrder: "descend",
            className: styles['column-name'],
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: renderName
        },
        {
            dataIndex: "sbomGeneratedAt",
            title: "SBOM generated at",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: value => (value) ? formatDateTime(value) : <i>never</i>
        },
        {
            dataIndex: "sbomImportedAt",
            title: "SBOM imported at",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: value => (value) ? formatDateTime(value) : <i>never</i>
        },
        {
            dataIndex: "componentEvaluatedAt",
            title: "Last evaluation",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: value => (value) ? formatDateTime(value) : <i>never</i>
        },
        {
            dataIndex: "analysisStatistics",
            title: "Analysis",
            align: "center",
            render: renderAnalysisStatistics
        },
        {
            title: "Vulnerability findings",
            render: renderStatistics
        }
    ];

    if (appContext.user.hasPermission(Permission.SBOM_PROJECT__MANAGE_ORG) || sbomProject?.permissions?.includes(Permission.SBOM_PROJECT__MANAGE)) {
        columns.push(
            {
                dataIndex: "suppressInReports",
                title: "Suppress in reports",
                align: "center",
                render: value => <Checkbox checked={value} disabled={true}/>
            },
            {
                title: "Actions",
                render: renderAction
            }
        );
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        reload();

        tableHandler.reload();
    }, [])

    useEffect(() => {
        if (sbomProject === undefined) {
            return;
        }

        if (sbomProject.permissions.includes(Permission.SBOM_PROJECT__MANAGE)) {
            softwareLicensePolicyService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(setSoftwareLicensePolicies);
        }
    }, [sbomProject]);

    const hasManage = appContext.user.hasPermission(Permission.SBOM_PROJECT__MANAGE_ORG) || sbomProject?.permissions?.includes(Permission.SBOM_PROJECT__MANAGE);

    return (
        <DocumentTitle title={sbomProject?.data?.name}>
            <>
                <Breadcrumb
                    className={globalStyles["common__breadcrumb"]}
                    items={[
                        {title: appContext.config?.appName},
                        {title: <Link to={"/sbom-projects"}>SBOM projects</Link>},
                        {title: sbomProject?.data?.name}
                    ]}
                />

                <Spin spinning={!sbomProject} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{sbomProject?.data?.name}</h1>

                    {hasManage && <>
                        <div className={globalStyles["common__top-button-bar"]}>
                            <Button onClick={() => setEditMode(!editMode)}>Edit</Button>

                            {appContext.user.hasPermission(Permission.SBOM_PROJECT__MANAGE_ORG) &&
                                <Button danger={true}
                                        title={"Delete"}
                                        onClick={() => {
                                            Modal.confirm({
                                                content: "Do you really want to delete this SBOM project?",
                                                okText: "Delete",
                                                cancelText: "Cancel",
                                                okButtonProps: {danger: true},
                                                onOk: () => onDeleteConfirm()
                                            });
                                        }}>Delete</Button>
                            }
                        </div>

                        {renderEditForm()}
                    </>}

                    <h2 style={{marginTop: 0}}>Project versions</h2>

                    <div className={globalStyles["common__top-button-bar"]}>
                        <Button className={"btn-seamless"} icon={<ReloadOutlined/>} onClick={tableHandler.reload}/>

                        {(appContext.user.hasPermission(Permission.SBOM_PROJECT__MANAGE_ORG) || sbomProject?.permissions?.includes(Permission.SBOM_PROJECT__MANAGE)) &&
                            <Button type={"primary"}
                                    icon={<PlusOutlined/>}
                                    onClick={() => navigate(`/sbom-projects/${sbomProjectId}/add`)}>Add</Button>
                        }
                    </div>

                    <Table className={styles.table}
                           showSorterTooltip={false}
                           loading={tableHandler.loading}
                           dataSource={sbomProjectVersions?.data}
                           size="middle"
                           onChange={tableHandler.onTableChange}
                           pagination={tableHandler.pagination}
                           rowKey="id"
                           onRow={onRow}
                           columns={columns}
                    />
                </Spin>
            </>
        </DocumentTitle>
    )

    function renderEditForm() {
        return (
            <Collapse isOpen={editMode}>
                <h3>Edit of SBOM project {sbomProject?.data?.name}</h3>

                <Form {...layout} form={editForm} className={`${globalStyles["common__form"]} ${globalStyles["common_form--edit"]}`} onFinish={onFinishEdit}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                            {validator: serverViolationsHolder.createServerValidator('UNIQUE', 'name, organization_id', undefined, {compareLowerCaseValues: true}), message: "Name is already used."}
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"softwareLicensePolicy"}
                        label={"Software license policy"}>
                        <Select placeholder={"no policy applied"} allowClear={true}>
                            {softwareLicensePolicies?.data?.map(policy => {
                                return <Select.Option key={policy?.name} value={policy?.id}>{policy?.name}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>{"Save"}</Button>
                        <Button onClick={() => setEditMode(false)}>{"Cancel"}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function renderName(value: any, sbomProjectVersion: SbomProjectVersion) {
        return <>
            <Link to={`/sbom-projects/${sbomProjectId}/${sbomProjectVersion.id}`}>{value}</Link>

            {sbomProjectVersion.tags.length > 0 && <span style={{marginLeft: 8}}>{sbomProjectVersion.tags?.map(value => <Tag>{value}</Tag>)}</span>}

            {sbomProjectVersion?.licenseViolations?.length > 0 && <Tag style={{marginLeft: 8}} color={"orange"} title={"One or more licenses violates project's software license policy."}>license violation</Tag>}
        </>;
    }

    function renderAnalysisStatistics(findingStatistics: any, sbomProjectVersion: SbomProjectVersion) {
        if (_.isEqual(findingStatistics, sbomProjectVersion?.analysedFindingStatistics)) {
            return <>
                <FindingAnalysisStatistics analysisStatistics={sbomProjectVersion?.analysisStatistics} style={AnalysisStyle.PROGRESS}/>
            </>;
        } else {
            return <>
                <FindingAnalysisStatistics analysisStatistics={sbomProjectVersion?.analysisStatistics} style={AnalysisStyle.PROGRESS}/>
            </>;
        }
    }

    function renderStatistics(value: any, sbomProjectVersion: SbomProjectVersion) {
        switch (sbomProjectVersion.status) {
            case SbomProjectVersionStatus.WAITING_FOR_SBOM:
            case SbomProjectVersionStatus.WAITING_FOR_EVALUATION:
            case SbomProjectVersionStatus.NOT_VULNERABLE:
                return <Tag color={colorForSbomProjectVersionStatus(sbomProjectVersion.status)}>
                    {formatSbomProjectVersionStatus(sbomProjectVersion.status)}
                </Tag>;
            case SbomProjectVersionStatus.VULNERABLE:
                if (_.isEqual(sbomProjectVersion.findingStatistics, sbomProjectVersion.analysedFindingStatistics)) {
                    return <FindingStatistics findingStatistics={sbomProjectVersion.findingStatistics}/>;
                } else {
                    return <>
                        <Popover title="State before analysis" content={<FindingStatistics findingStatistics={sbomProjectVersion.findingStatistics}/>}>
                            <div style={{display: 'inline-block'}}>
                                <FindingStatistics findingStatistics={sbomProjectVersion.analysedFindingStatistics}/>
                            </div>
                        </Popover>
                    </>;
                }
        }
    }

    function renderAction(value: any, sbomProjectVersion: SbomProjectVersion) {
        return (
            <>
                {(appContext.user.hasPermission(Permission.SBOM_PROJECT__MANAGE_ORG) || sbomProject?.permissions?.includes(Permission.SBOM_PROJECT__MANAGE)) &&
                    <Button icon={<DeleteFilled/>} className={"ant-btn-icon-only"}
                            title={"Delete"}
                            onClick={() => {
                                Modal.confirm({
                                    title: <>Deletion confirmation</>,
                                    content: <>Do you really want to delete SBOM project version <b>{sbomProjectVersion.name}</b>?</>,
                                    okText: "Delete",
                                    cancelText: "Cancel",
                                    onOk: () => onDeleteVersionConfirm(sbomProjectVersion)
                                });
                            }}/>
                }
            </>
        )
    }

    function onRow(sbomProjectVersion: SbomProjectVersion, index?: number) {
        return {onDoubleClick: () => navigate(`/sbom-projects/${sbomProjectId}/${sbomProjectVersion.id}`)}
    }

    function onDeleteVersionConfirm(sbomProjectVersion: SbomProjectVersion) {
        sbomProjectVersionService.delete(sbomProjectVersion)
            .then(() => {
                message.success(<>SBOM project version <b>{sbomProjectVersion.name}</b> successfully deleted.</>);

                reloadProjectVersions();
            });
    }

    function onFinishEdit(values: any) {
        sbomProjectService.update(sbomProjectId!, values)
            .then(
                (id) => {
                    message.success(<>SBOM project <b>{values.name}</b> successfully updated.</>);

                    setEditMode(false);

                    reload();
                },
                error => serverViolationsHolder.handleServerError(error, editForm)
            );
    }

    function onDeleteConfirm() {
        sbomProjectService.delete(sbomProject.data)
            .then(() => {
                message.success(<>SBOM project <b>{sbomProject.data.name}</b> successfully deleted.</>);

                navigate(`/sbom-projects`)
            });
    }

    function reload() {
        sbomProjectService.get(sbomProjectId!).then(result => {
            setSbomProject(result);

            editForm.setFieldsValue({
                ...result.data,
                softwareLicensePolicy: result.data.softwareLicensePolicy?.id
            });
        });
    }

    function reloadProjectVersions() {
        return sbomProjectVersionService.getList(sbomProjectId, tableHandler.queryOptions).then(versions => {
            setSbomProjectVersions(versions);
        })
    }

}

export default SbomProjectDetail;
