import {Form, Input, message, Modal, Select} from "antd";
import {useForm} from "antd/es/form/Form";
import TextArea from "antd/lib/input/TextArea";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, TicketServiceContext, UserServiceContext} from "../../Contexts";
import Ticket from "../../domain/Ticket";
import {TicketSeverity} from "../../domain/TicketSeverity";
import User from "../../domain/User";
import VulSourceItem from "../../domain/VulSourceItem";
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";


const serverViolationsHolder = new ServerConstraintViolationsHolder();

interface Props {
    onTicketCreate: any;
    visible: boolean;
    vulSourceItem: VulSourceItem;
    onCancel: any;
}

function CreateTicketModal(props: Props) {
    const appContext = useContext(AppContextContext);
    const ticketService = useContext(TicketServiceContext);
    const userService = useContext(UserServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const navigate = useNavigate();
    const [form] = useForm();

    const [users, setUsers] = useState<User[]>();

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 8, span: 16},
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {

        if (props.vulSourceItem) {
            loadUsers();

            form.setFieldsValue({
                ticketTitle: createTicketTitle(props.vulSourceItem).substring(0, 250),
                ticketSeverity: guessTicketSeverity(props.vulSourceItem)
            });
        }
    }, [props.vulSourceItem])

    useEffect(() => {

    }, [])

    return (
        <Modal title={"Create ticket"}
               width={800}
               onOk={form.submit}
               onCancel={props.onCancel}
               open={props.visible}>

            <Form form={form} layout={"vertical"} onFinish={onFinish}>
                <Form.Item
                    name={"ticketTitle"}
                    label={"Title"}
                    requiredMark={"optional"}
                    rules={[{required: true}]}>
                    <Input/>
                </Form.Item>

                <Form.Item
                    name={"ticketSeverity"}
                    label={"Severity"}
                    requiredMark={"optional"}
                    rules={[{required: true}]}>
                    <Select>
                        {Object.keys(TicketSeverity).map(value => <Select.Option key={value}>{value}</Select.Option>)}
                    </Select>
                </Form.Item>

                <Form.Item
                    name={"assignees"}
                    label={"Assignees"}>
                    <Select showSearch={true} mode={"multiple"}>
                        {users?.map(user => (
                            <Select.Option key={user.id} value={user.id}>
                                {user.username}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name={"ticketDescription"}
                    label={"Description"}>
                    <TextArea/>
                </Form.Item>
            </Form>
        </Modal>
    )

    function createTicketTitle(item: VulSourceItem) {

        if (item.attributes.title && item.attributes.description) return item.attributes.title;
        if (item.attributes.title && !item.attributes.description) return item.attributes.title;
        if (item.attributes.description) return item.attributes.description;

        return "";
    }


    function onFinish(values: any) {

        const ticket = new Ticket();
        ticket.title = values.ticketTitle;
        ticket.severity = values.ticketSeverity;
        ticket.originalItem = props.vulSourceItem;
        ticket.assignees = values.assignees;
        ticket.description = values.ticketDescription;

        ticketService.add(ticket)
            .then(
                (id) => {
                    message.success("Ticket created successfully.");

                    navigate(`/tickets/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );

        props.onTicketCreate();
    }

    function loadUsers() {
        ticketService.getAvailableUserList(QueryOptions.newUnlimitedOrderedInstance("username")).then(value => setUsers(value.data));
    }

    function guessTicketSeverity(item: VulSourceItem): TicketSeverity {
        if (item.attributes && item.attributes.sealScore) {
            const score = item.attributes.sealScore;
            if (score <= 2) return TicketSeverity.LOW;
            if (score <= 4) return TicketSeverity.INFORMATIONAL;
            if (score <= 6) return TicketSeverity.MEDIUM;
            if (score <= 8) return TicketSeverity.HIGH;
            if (score > 8) return TicketSeverity.CRITICAL;
        } else {
            return TicketSeverity.UNKNOWN;
        }
    }

}

export default CreateTicketModal;
