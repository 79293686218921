import SingleResult from "../../service/SingleResult";
import SbomProject from "../../domain/SbomProject";
import SbomComponentFinding from "../../domain/SbomComponentFinding";
import React, {useContext, useState} from "react";
import {AppContextContext, SbomComponentServiceContext} from "../../Contexts";
import {Permission} from "../../domain/auth/Permission";
import {Button, Collapse as AntdCollapse, message, Tag} from "antd";
import * as styles from "./SbomProjectVersionDetail.module.css";
import * as statusStyles from "./FindingStatus.module.css";
import {formatFindingStatus} from "../../domain/FindingStatus";
import FindingStatusForm from "./FindingStatusForm";

function FindingStatusCollapse({sbomProject, finding, onFindingStatusSave}: { sbomProject: SingleResult<SbomProject>, finding: SbomComponentFinding, onFindingStatusSave: (finding: SbomComponentFinding) => void }) {
    const appContext = useContext(AppContextContext);
    const sbomComponentService = useContext(SbomComponentServiceContext);
    const [collapsed, setCollapsed] = useState(false);

    const allowModification = appContext.user.hasPermission(Permission.SBOM_PROJECT__MANAGE_ORG) || sbomProject?.permissions?.includes(Permission.SBOM_PROJECT__ANALYZE);

    return (
        <AntdCollapse
            size={"small"}
            expandIcon={null}
            style={{marginTop: 12}}
            className={styles['status-form']}
            destroyInactivePanel={true}
            activeKey={collapsed ? '1' : '0'}
            onChange={keys => {
                setCollapsed(keys.includes("1"));
            }}
            items={[
                {
                    key: '1',
                    label: <>Status: <Tag bordered={false} style={{marginLeft: 4}} className={statusStyles['status-' + finding?.status]}>{formatFindingStatus(finding?.status)}</Tag></>,
                    extra: <Button size={"small"}>{allowModification ? 'Edit' : 'Show'}</Button>,
                    children: <FindingStatusForm allowModification={allowModification} project={sbomProject.data} finding={finding} onCancel={onCancel} onFinish={onFinishFindingEdit}/>
                }]}
        />
    )

    function onCancel() {
        setCollapsed(false);
    }

    function onFinishFindingEdit(finding: SbomComponentFinding, values: any) {
        sbomComponentService.updateFinding(finding.id, values)
            .then(
                () => {
                    message.success(<>Vulnerability status successfully updated.</>);

                    setCollapsed(false);

                    onFindingStatusSave?.(finding);
                }
            );
    }
}

export default FindingStatusCollapse;
