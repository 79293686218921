import {Breadcrumb, Button, Form, Input, message, Select} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AppContextContext, OrganizationServiceContext, RoleServiceContext} from "../../Contexts";
import Organization from "../../domain/Organization";
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {DocumentTitle} from "../DocumentTitle";
import *as globalStyles from "../App.module.css";
import {ResourceOwnerType} from "../../domain/ResourceOwnerType";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function RoleAdd() {
    const appContext = useContext(AppContextContext);
    const roleService = useContext(RoleServiceContext);
    const organizationService = useContext(OrganizationServiceContext);
    const navigate = useNavigate();
    const [form] = useForm();
    const [organizations, setOrganizations] = useState<Organization[]>();
    const [owner, setOwner] = useState<ResourceOwnerType>(ResourceOwnerType.SYSTEM);

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (appContext.user.isSystemAdmin()) {
            organizationService.getList(new QueryOptions("name", 100, 0)).then(value => {
                setOrganizations(value.data);
            });
        }
    }, [])

    const title = "Add new role";

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/roles"}>{"User roles"}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{title}</h1>

                <Form {...layout} form={form} className={globalStyles["common__form"]} onFinish={onFinish}>
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."},
                            {
                                validator: serverViolationsHolder.createServerValidator('UNIQUE', 'uq_role_name', undefined, {compareLowerCaseValues: true}),
                                message: "Name is already used."
                            }
                        ]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    {appContext.user.isSystemAdmin() && <>
                        <Form.Item
                            name={["owner"]}
                            label={"Owner"}
                            rules={[{required: true, message: "Owner is required."}]}
                            initialValue={owner}>
                            <Select onChange={value => setOwner(value)}>
                                <Select.Option key={ResourceOwnerType.SYSTEM} value={ResourceOwnerType.SYSTEM}>system</Select.Option>
                                <Select.Option key={ResourceOwnerType.ORGANIZATION} value={ResourceOwnerType.ORGANIZATION}>organization</Select.Option>
                            </Select>
                        </Form.Item>

                        {owner === ResourceOwnerType.ORGANIZATION &&
                            <Form.Item
                                name={"organization"}
                                label={"Organization"}
                                rules={[{required: owner === ResourceOwnerType.ORGANIZATION, message: "Organization is required."}]}>
                                <Select allowClear={true}>
                                    {organizations && organizations!.map(organization => {
                                        return <Select.Option key={organization?.name} value={organization?.id!}>{organization?.name}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        }
                    </>}

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate("/roles")}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    );

    function onFinish(values: any) {
        roleService.add(values)
            .then(
                (id) => {
                    message.success(<>Role <b>{values.name}</b> successfully added.</>);

                    navigate(`/roles/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }
}

export default RoleAdd;
