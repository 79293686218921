import {RuleGroupType} from "@react-querybuilder/ts/dist/ruleGroups";
import {Card} from "antd";
import * as styles from "./ReadOnlyQueryView.module.css";
import _ from "lodash";
import VulSource from "../../domain/VulSource";
import {Link} from "react-router-dom";

interface Props {
    sources?: VulSource[];
    query: RuleGroupType;
}

function ReadOnlyQueryView(props: Props) {
    return <div className={styles['query-view']}>
        {renderNode(props.query)}
    </div>

    function renderNode(node: any) {
        if (node?.combinator) {
            return <Card type={"inner"} size={"small"} title={formatCombinator(node.combinator)}>
                <>
                    {node.rules.map((rule, index) => <div key={rule.id || index} className={rule.combinator ? styles['card-content'] : ''}>{renderNode(rule)}</div>)}
                </>
            </Card>
        } else {
            if (node?.rules !== undefined) {
                return node.rules.map(rule => <span key={rule.id}>{renderRule(rule)}</span>)
            } else {
                return renderRule(node);
            }
        }
    }

    function renderRule(rule: any) {
        if (rule.field === "attributes") {
            return <li>attribute <b>{rule.value[0]}</b> <i>{formatOperator(rule.operator)}</i> {renderValue(rule.value[1])}</li>
        } else if (rule.field === "source.id") {
            const sources = rule.value.map(id => props.sources?.find(s => s.id == id) || {id});

            const links = sources.map((source, index) => <span key={source.id}>
                {source.name && <Link to={`/vul-sources/${source.id}`}>{source.name}</Link>}
                {source.name === undefined && <i>Unknown ({source.id})</i>}
                {(index < (sources.length - 1)) && ', '}
            </span>);

            return <li><b>source</b> <i>{formatOperator(rule.operator)}</i> {links}</li>
        }

        switch (rule.operator) {
            case "jsonRegex":
            case "not(jsonRegex)":
                return <li><b>{rule.field}</b> <i>{formatOperator(rule.operator)}</i> "{renderValue(rule.value)}"</li>
            default:
                return <li>{rule.field} <i>{formatOperator(rule.operator)}</i> {renderValue(rule.value)}</li>
        }
    }

    function renderValue(value: any) {
        if (_.isArray(value)) {
            return value.join(", ");
        } else {
            return value;
        }
    }

    function formatCombinator(combinator: string) {
        switch (combinator) {
            case "and":
                return <><b>AND</b> - all contained rules must match condition</>;
            case "or":
                return <><b>OR</b> - at least one contained rule must match condition</>;
            default:
                return combinator;
        }
    }

    function formatOperator(operator: string) {
        switch (operator) {
            case "jsonRegex":
                return "matches";
            case "not(jsonRegex)":
                return "not matches";
            case "notIn":
                return "not in";
            case "jsonEq":
                return "equals";
            case "jsonGe":
                return "greater or equal";
            case "jsonLe":
                return "lesser or equal";
            default:
                return operator;
        }
    }

}

export default ReadOnlyQueryView;