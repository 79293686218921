import Axios from "axios";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";
import SoftwareLicense from "../domain/SoftwareLicense";

class SoftwareLicenseService {

    readonly basePath = "/software-licenses";

    public get(id: string): Promise<SoftwareLicense> {
        return Axios.get(`${this.basePath}/${id}`)
            .then(value => SoftwareLicense.fromPOJO(value.data))
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<SoftwareLicense>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: SoftwareLicense[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(SoftwareLicense.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public getCategories(): Promise<string[]> {
        return Axios.get(`${this.basePath}/categories`).then(value => value.data);
    }

}

export default SoftwareLicenseService;
