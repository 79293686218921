import Axios from "axios";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";
import ApiToken from "../domain/ApiToken";

class ApiTokenService {

    readonly basePath = "/api-tokens";

    public add(values: any): Promise<ApiToken> {
        return Axios.post(`${this.basePath}`, values).then(value => ApiToken.fromPOJO(value.data))
    }

    public delete(apiToken: ApiToken): Promise<void> {
        return Axios.delete(`${this.basePath}/${apiToken.id}`);
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<ApiToken>> {
        return Axios.get(`${this.basePath}?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: ApiToken[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(ApiToken.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

}

export default ApiTokenService;
