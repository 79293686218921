.attributes {
    display: flex;
    flex-wrap: wrap;
    gap: 0 8px;
}

.attributes-vertical {
    flex-direction: column;
    align-items: start;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 4px 0;
    margin-top: 8px;
}

.content img {
    max-width: 800px;
}

.affected-packages:global(.ant-descriptions) {
    margin-bottom: 0;
    background-color: transparent;
    max-width: initial;
}

.affected-packages :global(.ant-descriptions-item) {
    padding-bottom: 4px !important;
}

.affected-packages :global(.ant-descriptions-item-label) {
    width: auto !important;
}

.affected-package {
    border: 1px solid red;
    border-radius: 4px;
}


.affected-package-name {
    background-color: black;
    color: white;
}

.affected-package-versions {
}