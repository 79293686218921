import React, {useContext, useEffect, useState} from "react";
import {AppContextContext, TicketServiceContext} from "../../Contexts";
import {TicketSeverity} from "../../domain/TicketSeverity";
import {TicketState} from "../../domain/TicketState";
import User from "../../domain/User";
import {FilterItem, FilterItemProps, SelectEditor, TableFilter} from "../tablefilter/TableFilter";
import QueryOptions from "../../sal-ui/QueryOptions";

interface SeverityProps extends FilterItemProps {
    severities: TicketSeverity[];
}

function SeverityItem(props: SeverityProps) {
    return (
        <SelectEditor
            width={120}
            multiple={true}
            options={props.severities?.map(severity => ({
                value: severity,
                label: <span style={{display: "inline-block"}} className={"selection-item-text"}>{severity}</span>
            }))}
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

interface StateProps extends FilterItemProps {
    states: TicketState[];
}

function StateItem(props: StateProps) {
    return (
        <SelectEditor
            width={120}
            multiple={true}
            options={props.states?.map(state => ({
                value: state,
                label: <span style={{display: "inline-block"}} className={"selection-item-text"}>{state}</span>
            }))}
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}

interface AssigneeProps extends FilterItemProps {
    assignees: User[];
    loggedInUser: User;
}

function AssigneeItem(props: AssigneeProps) {

    return (
        <SelectEditor
            width={120}
            multiple={true}
            options={props.assignees?.map(assignee => ({
                value: assignee.id,
                label: <span style={{display: "inline-block"}} className={"selection-item-text"}>{assignee.username === props.loggedInUser.username ? `${assignee.username} (ME)` : assignee.username}</span>
            }))}
            value={props.value}
            onChange={value => props.onChange(value)}
        />
    )
}


interface TicketFilterProps {
    className?: string;
    values?: any;
    onChange: (values: any) => void;
    readOnly: boolean;
    immediateMode?: boolean,
}

function TicketFilter(props: TicketFilterProps) {

    const [users, setUsers] = useState<User[]>();
    const ticketService = useContext(TicketServiceContext);
    const appContext = useContext(AppContextContext);

    useEffect(() => {
        ticketService.getAvailableUserList(QueryOptions.newUnlimitedOrderedInstance("username")).then(value => {
                setUsers(value.data);
            }
        );
    }, []);

    const items: FilterItem[] = [
        {
            name: "severity",
            key: "severity",
            label: "Severity",
            multiple: false,
            render: (props) => <SeverityItem {...props} severities={Object.values(TicketSeverity)}/>
        },
        {
            name: "state",
            key: "state",
            label: "State",
            multiple: false,
            render: (props) => <StateItem {...props} states={Object.values(TicketState)}/>
        },
        {
            name: "assignee",
            key: "assignee",
            label: "Assignee",
            multiple: false,
            render: (props) => <AssigneeItem {...props} assignees={users} loggedInUser={appContext.user}/>
        }
    ];

    return (
        <TableFilter
            items={items}
            className={props.className}
            values={props.values}
            defaultValues={{severity: [], state: [], assignee: []}}
            readOnly={props.readOnly}
            immediateMode={props.immediateMode}
            onChange={onChange}
        />
    )

    function onChange(values: any) {
        props.onChange(values);
    }

}

export default TicketFilter;