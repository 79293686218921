.table {
    max-width: 1200px;
}

.admin-table {
    max-width: 1400px;
}

.column-name {
    max-width: 400px;
}

.description {
    margin-top: 8px;
    font-size: 12px;
}
