import {Scope} from "./Scope";

export enum Permission {
    SOURCE__READ_SEAL = "SOURCE__READ_SEAL",
    SOURCE__READ_ORG = "SOURCE__READ_ORG",
    SOURCE__MANAGE_ORG = "SOURCE__MANAGE_ORG",

    TICKET__MANAGE = "TICKET__MANAGE",

    VIEW__READ_SEAL = "VIEW__READ_SEAL",
    VIEW__READ_ORG = "VIEW__READ_ORG",
    VIEW__MANAGE_ORG = "VIEW__MANAGE_ORG",

    REPORT__READ = "REPORT__READ",
    REPORT__MANAGE = "REPORT__MANAGE",

    ATTRIBUTE__READ = "ATTRIBUTE__READ",

    SBOM_PROJECT__READ = "SBOM_PROJECT__READ",
    SBOM_PROJECT__ANALYZE = "SBOM_PROJECT__ANALYZE",
    SBOM_PROJECT__MANAGE = "SBOM_PROJECT__MANAGE",
    SBOM_PROJECT__MANAGE_ORG = "SBOM_PROJECT__MANAGE_ORG",

    USER__MANAGE = "USER__MANAGE"
}

export function withTransitivePermissions(permission: Permission) {
    return [
        permission,
        ...transitivePermissions(permission)
    ];
}

export function transitivePermissions(permission: Permission) {
    switch (permission) {
        case Permission.SOURCE__READ_SEAL:
        case Permission.SOURCE__READ_ORG:
            return [];
        case Permission.SOURCE__MANAGE_ORG:
            return [Permission.SOURCE__READ_ORG];
        case Permission.TICKET__MANAGE:
            return [Permission.SOURCE__READ_SEAL, Permission.SOURCE__READ_ORG];
        case Permission.VIEW__READ_SEAL:
            return [Permission.SOURCE__READ_SEAL, Permission.SOURCE__READ_ORG];
        case Permission.VIEW__READ_ORG:
            return [Permission.SOURCE__READ_SEAL, Permission.SOURCE__READ_ORG];
        case Permission.VIEW__MANAGE_ORG:
            return [Permission.SOURCE__READ_SEAL, Permission.SOURCE__READ_ORG, Permission.VIEW__READ_SEAL, Permission.VIEW__READ_ORG];
        case Permission.REPORT__READ:
            return [Permission.SOURCE__READ_SEAL, Permission.SOURCE__READ_ORG, Permission.VIEW__READ_SEAL, Permission.VIEW__READ_ORG];
        case Permission.REPORT__MANAGE:
            return [Permission.SOURCE__READ_SEAL, Permission.SOURCE__READ_ORG, Permission.VIEW__READ_SEAL, Permission.VIEW__READ_ORG, Permission.REPORT__READ];
        case Permission.ATTRIBUTE__READ:
            return [Permission.SOURCE__READ_SEAL, Permission.SOURCE__READ_ORG];
        case Permission.SBOM_PROJECT__READ:
            return [];
        case Permission.SBOM_PROJECT__ANALYZE:
            return [Permission.SBOM_PROJECT__READ];
        case Permission.SBOM_PROJECT__MANAGE:
            return [Permission.SBOM_PROJECT__READ, Permission.SBOM_PROJECT__ANALYZE];
        case Permission.SBOM_PROJECT__MANAGE_ORG:
            return [];
        case Permission.USER__MANAGE:
            return [Permission.SBOM_PROJECT__MANAGE_ORG];
    }
}

export function scopesForPermission(permission: Permission) {
    switch (permission) {
        case Permission.SOURCE__READ_SEAL:
        case Permission.SOURCE__READ_ORG:
        case Permission.SOURCE__MANAGE_ORG:
        case Permission.TICKET__MANAGE:
        case Permission.VIEW__READ_SEAL:
        case Permission.VIEW__READ_ORG:
        case Permission.VIEW__MANAGE_ORG:
        case Permission.REPORT__READ:
        case Permission.REPORT__MANAGE:
        case Permission.ATTRIBUTE__READ:
        case Permission.USER__MANAGE:
        case Permission.SBOM_PROJECT__MANAGE_ORG:
            return [];
        case Permission.SBOM_PROJECT__READ:
        case Permission.SBOM_PROJECT__ANALYZE:
        case Permission.SBOM_PROJECT__MANAGE:
            return [Scope.SBOM_PROJECT];
    }
}

export function formatPermission(permission: Permission) {
    switch (permission) {
        case Permission.SOURCE__READ_SEAL:
            return 'Read SEAL vulnerability sources';
        case Permission.SOURCE__READ_ORG:
            return 'Read organization vulnerability sources';
        case Permission.SOURCE__MANAGE_ORG:
            return 'Manage organization vulnerability sources';

        case Permission.TICKET__MANAGE:
            return 'Manage tickets';

        case Permission.VIEW__READ_SEAL:
            return 'Read SEAL vulnerability views';
        case Permission.VIEW__READ_ORG:
            return 'Read organization vulnerability views';
        case Permission.VIEW__MANAGE_ORG:
            return 'Manage organization vulnerability views';

        case Permission.REPORT__READ:
            return 'Read reports';
        case Permission.REPORT__MANAGE:
            return 'Manage reports';

        case Permission.ATTRIBUTE__READ:
            return 'View attributes and attribute definitions';

        case Permission.SBOM_PROJECT__READ:
            return 'Read SBOM project';
        case Permission.SBOM_PROJECT__ANALYZE:
            return 'Analyze SBOM project';
        case Permission.SBOM_PROJECT__MANAGE:
            return 'Manage SBOM project';
        case Permission.SBOM_PROJECT__MANAGE_ORG:
            return 'Manage organization SBOM projects';

        case Permission.USER__MANAGE:
            return 'Manage users';
    }

}
