import {DocumentTitle} from "../DocumentTitle";
import {Breadcrumb, Button, Checkbox, Form, Input, message, Select, Switch, Tooltip} from "antd";
import * as globalStyles from "../App.module.css";
import * as styles from "./VulReportDefinitionAdd.module.css";
import * as tackStyles from "../vulsourceitem/Tack.module.css";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {AppContextContext, VulReportDefinitionServiceContext, VulViewServiceContext} from "../../Contexts";
import {useForm} from "antd/es/form/Form";
import 'react-querybuilder/dist/query-builder.scss';
import QueryOptions from "../../sal-ui/QueryOptions";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import VulView, {vulViewSystemLastComparator} from "../../domain/VulView";
import _ from "lodash";
import VulReportDefinition from "../../domain/VulReportDefinition";
import * as descriptionStyles from "../SelectItemDescription.module.css";
import ReactMarkdown from "react-markdown";
import {QuestionCircleTwoTone} from "@ant-design/icons";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function VulReportDefinitionAdd() {
    const appContext = useContext(AppContextContext);
    const vulReportDefinitionService = useContext(VulReportDefinitionServiceContext);
    const vulViewService = useContext(VulViewServiceContext);
    const navigate = useNavigate();
    const [form] = useForm();
    const {definitionId}: any = useParams();
    const [vulViews, setVulViews] = useState<VulView[]>();
    const [vulReportDefinition, setVulReportDefinition] = useState<VulReportDefinition>();

    useEffect(() => {
        vulViewService.getList(QueryOptions.newUnlimitedOrderedInstance("name")).then(value => {
            setVulViews(value.data?.sort(vulViewSystemLastComparator));
        });
    }, []);

    useEffect(() => {
        if (definitionId === undefined) {
            return;
        }

        vulReportDefinitionService.get(definitionId).then(definition => {
            setVulReportDefinition(definition);

            form.setFieldsValue({
                ...definition,
                vulViews: definition.vulViews.map(value => value.id)
            });
        });
    }, [definitionId]);

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const tailLayout = {
        wrapperCol: {offset: 6, span: 18},
    };

    const title = (definitionId) ? <>Clone of definition <b>{vulReportDefinition?.name}</b></> : <>New vulnerability report definition</>;

    return (
        <DocumentTitle title={title}>
            <>
                <Breadcrumb className={globalStyles["common__breadcrumb"]}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/definitions/vul-reports"}>Vulnerability report definitions</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{title}</h1>

                <Form {...layout} form={form} className={globalStyles["common__form"]} onFinish={onFinish}>

                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            {required: true, message: "Name is required."}
                        ]}>
                        <Input autoFocus={true} maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"vulViews"}
                        label={"Views"}
                        rules={[{required: true, message: "View is required."}]}>
                        <Select
                            mode={"multiple"}
                            filterOption={(inputValue, option) => {
                                const normalizedInput = inputValue.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
                                const normalizedLabel = option.label?.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");

                                return normalizedLabel.includes(normalizedInput);
                            }}
                            optionLabelProp={"label"}
                            popupMatchSelectWidth={350}
                            virtual={false}
                            listHeight={300}
                        >
                            {vulViews?.map(vulView =>
                                <Select.Option key={vulView.id} value={vulView.id} label={vulView.name}>
                                    {vulView.owner === 'SYSTEM' && <div className={`${tackStyles.tack} ${tackStyles['tack-seal']}`}><span className={tackStyles['tack-text']} color={"#440084"}>SEAL</span></div>}
                                    {vulView.owner === 'ORGANIZATION' && <div className={`${tackStyles.tack} ${tackStyles['tack-org']}`}><span className={tackStyles['tack-text']} color={"#87d068"}>ORG</span></div>}

                                    <div className={`${tackStyles.item}`}>
                                        <div className={descriptionStyles['name-container']}>
                                            <span className={descriptionStyles.name}>{vulView.name}</span>
                                            {vulView.description && <Tooltip title={<ReactMarkdown className={globalStyles.markdown} children={vulView.description}/>}><QuestionCircleTwoTone/></Tooltip>}
                                        </div>
                                        <div className={descriptionStyles.description}>{vulView.description}</div>
                                    </div>
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={"days"}
                        label={"Days"}
                        initialValue={["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"]}>
                        <Checkbox.Group className={styles.days}>
                            <Checkbox value={"MONDAY"}>Monday</Checkbox>
                            <Checkbox value={"TUESDAY"}>Tuesday</Checkbox>
                            <Checkbox value={"WEDNESDAY"}>Wednesday</Checkbox>
                            <Checkbox value={"THURSDAY"}>Thursday</Checkbox>
                            <Checkbox value={"FRIDAY"}>Friday</Checkbox>
                            <Checkbox value={"SATURDAY"}>Saturday</Checkbox>
                            <Checkbox value={"SUNDAY"}>Sunday</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>

                    <Form.Item
                        name={"times"}
                        label={"Hours"}
                        initialValue={["08:00"]}>
                        <Checkbox.Group
                            className={styles.times}
                            options={_.range(24).map(index => String(index).padStart(2, '0') + ":00")}
                        />
                    </Form.Item>

                    <Form.Item
                        name={"timeZone"}
                        label={"Time zone"}
                        initialValue={new Intl.DateTimeFormat().resolvedOptions().timeZone}
                        rules={[{required: true, message: "Time zone is required."}]}>
                        <Select
                            options={Intl.supportedValuesOf('timeZone').map(value => { return { label: value, value }})}
                            showSearch={true}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        name={"email"}
                        label={"E-mail"}
                        extra={"Comma separated list of email addresses."}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        name={"enabled"}
                        label={"Enabled"}
                        valuePropName={"checked"}
                        initialValue={true}>
                        <Switch />
                    </Form.Item>

                    <Form.Item
                        name={"generateEmptyReport"}
                        label={"Generate empty report"}
                        valuePropName={"checked"}
                        initialValue={false}>
                        <Switch />
                    </Form.Item>

                    <Form.Item {...tailLayout} className={globalStyles["common__form-buttons"]}>
                        <Button type={"primary"} htmlType={"submit"}>Save</Button>
                        <Button onClick={() => navigate("/definitions/vul-reports")}>Cancel</Button>
                    </Form.Item>
                </Form>
            </>
        </DocumentTitle>
    );

    function onFinish(values: any) {
        vulReportDefinitionService.add(values)
            .then(
                (id) => {
                    message.success("Vulnerability report definition added.");

                    navigate(`/definitions/vul-reports/${id}`);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );
    }

}

export default VulReportDefinitionAdd;