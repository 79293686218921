import * as styles from "./Flag.module.css";
import React, {useContext, useEffect, useState} from "react";
import {AppContextContext, EnumServiceContext} from "../../Contexts";
import Country from "../../domain/Country";

interface Props {
    cca2?: string;
    children?: React.ReactNode;
}

function Flag({cca2, children}: Props) {
    const appContext = useContext(AppContextContext);
    const enumService = useContext(EnumServiceContext);
    const [country, setCountry] = useState<Country>();

    useEffect(() => {
        enumService.getCountries().then(countries => {
            setCountry(countries.find(c => c.cca2 === cca2));
        });
    }, []);

    const flagCca2 = (cca2) ? cca2.toLowerCase() : "xx";

    return (
        <span className={styles.container}>
            <span className={styles.flag}>
                <span className={`fi fi-${flagCca2}`} title={(country) ? `${country.translations[appContext.language]} [${country.cca2}]` : ''}/>
            </span>
            {children}
        </span>
    )
}

export default Flag;